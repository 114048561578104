import { createSlice } from "@reduxjs/toolkit"

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    notification: undefined,
    fetched: false,
  },
  reducers: {
    markAsRead: state => {
      state.notification = undefined
    },
    setNotification: (state, action) => {
      state.notification = action.payload
    },
    setFetched: state => {
      state.fetched = true
    },
  },
})

export const { markAsRead, setNotification, setFetched } =
  notificationsSlice.actions

export default notificationsSlice.reducer
