/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect, useDispatch } from "react-redux"
import { useHistory, useLocation, withRouter } from "react-router-dom"

import jwtDecode from "jwt-decode"
import ForgotPassword from "components/UserManagement/ForgotPassword"
import Login from "components/UserManagement/Login"
import SignUp from "components/UserManagement/SignUp"
import { logout } from "store/RTK-Store/authSlice"
import { removeAllComparedProjects } from "store/RTK-Store/comparisonSlice"
import { markAsRead, setNotification } from "store/RTK-Store/notificationsSlice"
import profileIcon from "../../../assets/images/pro-icon.svg"
import { useVerifyResetPasswordTokenMutation } from "../../../store/RTK-Store/services/resetPasswordTokenVerification"
import Swal from "sweetalert2"
import { checkAuthTokenValidity } from "core/ApiUsers"
import * as urls from "../../../helpers/url_helper"
import { setCurrentTab, setPageSizeReset, setSearchPalylod } from "store/RTK-Store/projectsSlice"
import { setPage } from "store/RTK-Store/tableSlice"

const ProfileMenu = props => {
  const dispatch = useDispatch()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const login = searchParams.get("login")
  const resetToken = searchParams.get("reset-token")
  let logoutParam = searchParams.get("logout")
  const [isLoading, setIsLoading] = useState(false)

  const history = useHistory()

  useEffect(() => {
    if (login) {
      setIsLoginModalOpen(true)
    }
  }, [login])

  const handleOnClick = () => {
    dispatch(checkAuthTokenValidity())
      .unwrap()
      .then(isLogout => {
        if (isLogout) {
          dispatch(removeAllComparedProjects())
          dispatch(logout())
          dispatch(markAsRead())
          dispatch(setPageSizeReset())
          Swal.fire({
            title: "Warning",
            text: "You have been logged out. Please contact support if you face any problems.",
            icon: "warning",
            toast: true,
            timer: 2000,
            position: "top-end",
            showConfirmButton: false,
            showCancelButton: false,
          })
          history.push("/")
        } else {
          // Proceed with navigating to the other service URL
          window.location.href = `${urls.REACT_APP_OTHER_SERVICE_URL}/loader?token=${token}`
        }
      })
      .catch(error => {
        Swal.fire({
          title: "Error",
          text: `Something went wrong! (${error.message})`,
          icon: "error",
        })
      })
      .finally(() => {
        setIsLoading(false) // Assuming setIsLoading is defined and used properly
      })
  }

  useEffect(() => {
    if (logoutParam) {
      dispatch(logout())
      dispatch(markAsRead())
      dispatch(setPageSizeReset())

      // Swal.fire({
      //   title: 'Success',
      //   text: 'Signed out successfully!',
      //   icon: 'success',
      //   toast: true,
      //   timer: 2000,
      //   position: 'top-end',
      //   showConfirmButton: false, showCancelButton: false,
      // })
      // history.push("/")
      //refresh window
      setTimeout(() => {
        window.location.href = "/"
      }, 1000);
    }
  }, [logoutParam])

  const [resetTokenLoading, setResetTokenLoading] = useState(false)
  const [verifyToken] = useVerifyResetPasswordTokenMutation()
  const [validationError, setValidationError] = useState()
  useEffect(() => {
    if (resetToken && !resetTokenLoading) {
      setResetTokenLoading(true)
      verifyToken({ token: resetToken })
        .unwrap()
        .then(data => {
          if (data?.error) {
            throw new Error(data?.payload?.message ?? "Something went wrong!")
          } else {
            setIsForgotPasswordOpen(true)
          }
        })
        .catch(error => {
          Swal.fire("Oops!", `${error.message}`, "error").then(res => {
            if (res.isConfirmed) {
              history.push("/")
            }
          })
        })
        .finally(() => setResetTokenLoading(false))
    }
  }, [resetToken])

  //
  const forgotToggle = () => setIsForgotPasswordOpen(!isForgotPasswordOpen)
  const signUpToggle = () => setIsSignupModalOpen(!isSignUpModalOpen)

  const [modal_contact_us, setmodal_contact_us] = useState(false)
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)
  const [isSignUpModalOpen, setIsSignupModalOpen] = useState(false)
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false)
  const [menu, setMenu] = useState(false)
  const [username, setusername] = useState("Admin")
  const token = localStorage.getItem("authToken")
  let userRole = null
  let userRoleName = ""
  let userName = ""
  const clearFilter = () => {
    const clearedPayload = {
      projectTitle: "",
      minCarbonCredits: null,
      maxCarbonCredits: null,
      country: [],
      projectTypes: [],
      sectoralScopes: [],
      registry: [],
      registryStatus: [],
      region: [],
      extraSeals: [],
      startCreditingPeriod: null,
      endCreditingPeriod: null,
      projectDeveloper: "",
      projectStatus: [],
    }
    dispatch(setSearchPalylod(clearedPayload))
    dispatch(setCurrentTab(1))
  }
  if (token) {
    const decodedToken = jwtDecode(token)
    userRole = decodedToken.role_id
    userName = decodedToken.username
    switch (userRole) {
      case 1:
        userRoleName = "Super Admin"
        break
      case 2:
        userRoleName = "Company Admin"
        break
      default:
        userRoleName = "User" // Handle unknown roles if needed.
    }
  }

  const toggleLoginModal = () => {
    setIsLoginModalOpen(false)
    const path = history.location.pathname
    history.push(path.replaceAll("?login=true", ""))
  }

  const handleLogout = e => {
    dispatch(removeAllComparedProjects())
    dispatch(logout())
    dispatch(setPageSizeReset())
    dispatch(markAsRead())
    clearFilter()
    dispatch(setPage(1))
    Swal.fire({
      title: "Success",
      text: "Signed out successfully!",
      icon: "success",
      toast: true,
      timer: 2000,
      position: "top-end",
      showConfirmButton: false,
      showCancelButton: false,
    })
    history.push("/")
  }

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.displayName)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.username)
      }
    }
  }, [props.success])

  const [showBookADemo, setShowBookADemo] = useState(true)
  useEffect(() => {
    if (token != null) {
      const decoded = jwtDecode(token)
      if (decoded?.role_id === 1 || decoded?.role_id === 2) {
        setShowBookADemo(false)
      } else {
        setShowBookADemo(true)
      }
    } else {
      setShowBookADemo(true)
    }
  }, [token])

  function tog_contact_us() {
    setmodal_contact_us(!modal_contact_us)
  }
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <div style={{ marginTop: "-14px" }}>
            {" "}
            <img height="30px" src={profileIcon} />
          </div>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {token ? (
            <>
              <DropdownItem tag="a" href="/profile">
                <h5>{userName}</h5>
                <h6
                  className="mb-0"
                  style={{ color: "#90BE83", fontWeight: 700 }}
                >
                  {userRoleName}
                </h6>
              </DropdownItem>
              <div className="dropdown-divider" />
              {/* <DropdownItem tag="a">{props.t("My Messages")}</DropdownItem> */}
              {userRole === 1 || userRole === 2 ? (
                <>
                  <DropdownItem
                    tag="a"
                    // onClick={() => {
                    //   if(token)
                    //   window.location.href =
                    //     process.env.REACT_APP_OTHER_SERVICE_URL +
                    //     "/loader?token=" +
                    //     token
                    // }}
                    onClick={handleOnClick}
                  >
                    {props.t("My Dashboard")}
                  </DropdownItem>
                </>
              ) : null}
              <DropdownItem
                onClick={() => {
                  history.push("/favorites")
                }}
              >
                {props.t("My Favorites")}
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  history.push("/profile")
                }}
              >
                {props.t("My Profile")}
              </DropdownItem>
            </>
          ) : (
            <>
              <DropdownItem
                tag="a"
                style={{ color: "#90BE83", fontWeight: 700 }}
                onClick={e => {
                  setIsSignupModalOpen(false)
                  setIsLoginModalOpen(true)
                }}
              >
                {props.t("Sign In")}
              </DropdownItem>
              <DropdownItem
                tag="a"
                style={{ color: "#495057", fontWeight: 400 }}
                onClick={e => {
                  setIsLoginModalOpen(false)
                  setIsSignupModalOpen(true)
                }}
              >
                {props.t("Sign Up")}
              </DropdownItem>
            </>
          )}

          <div className="dropdown-divider" />
          <DropdownItem tag="a" href="/contactus">
            {/* <i className="bx bx-lock-open font-size-16 align-middle me-1" /> */}
            {props.t("Contact Us")}
          </DropdownItem>
          {showBookADemo && (
            <DropdownItem
              className="d-block d-md-none"
              tag="a"
              href="/book-demo"
            >
              {/* <i className="bx bx-lock-open font-size-16 align-middle me-1" /> */}
              {props.t("Book a Demo")}
            </DropdownItem>
          )}
          <DropdownItem tag="a" href="/faq">
            {/* <i className="bx bx-lock-open font-size-16 align-middle me-1" /> */}
            {props.t("Help Center")}
          </DropdownItem>

          {/* <div className="dropdown-divider" /> */}
          {token ? (
            <DropdownItem
              tag="a"
              onClick={handleLogout}
              style={{
                color: "#EE1C25",
                fontWeight: 700,
                paddingBottom: "10px",
              }}
            >
              {/* <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" /> */}
              {props.t("Logout")}
            </DropdownItem>
          ) : (
            ""
          )}
        </DropdownMenu>
      </Dropdown>
      <Modal
        zIndex={1700}
        isOpen={isLoginModalOpen}
        toggle={toggleLoginModal}
        style={{ paddingTop: "3%" }}
      >
        <ModalBody style={{ backgroundColor: "#F3F3F8", borderRadius: "5px" }}>
          {isLoginModalOpen && (
            <Login
              openSignUp={() => {
                setIsLoginModalOpen(false)
                setIsSignupModalOpen(true)
              }}
              closeModal={() => {
                setIsLoginModalOpen(false)
                const path = history.location.pathname
                history.push(path.replaceAll("?login=true", ""))
              }}
              openForgotPassword={() => {
                setIsLoginModalOpen(false)
                setIsForgotPasswordOpen(true)
              }}
            />
          )}
          {isSignUpModalOpen && (
            <SignUp closeModal={() => setIsSignupModalOpen(false)} />
          )}
        </ModalBody>
      </Modal>
      <Modal
        toggle={signUpToggle}
        zIndex={1700}
        isOpen={isSignUpModalOpen}
        style={{ paddingTop: "3%" }}
      >
        <ModalBody style={{ backgroundColor: "#F3F3F8", borderRadius: "5px" }}>
          <SignUp
            openSignUp={() => {
              setIsLoginModalOpen(true)
              setIsSignupModalOpen(false)
            }}
            closeModal={() => {
              setIsSignupModalOpen(false)
            }}
          />
        </ModalBody>
      </Modal>
      <Modal
        toggle={forgotToggle}
        isOpen={isForgotPasswordOpen}
        style={{ paddingTop: "7%" }}
        zIndex={1700}
      >
        <ModalBody style={{ backgroundColor: "#F3F3F8", borderRadius: "5px" }}>
          <ForgotPassword
            openSignUp={() => {
              setIsLoginModalOpen(true)
              setIsSignupModalOpen(false)
              setIsForgotPasswordOpen(false)
            }}
            resetToken={resetToken}
            closeModal={() => {
              setIsSignupModalOpen(false)
              setIsForgotPasswordOpen(false)
            }}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
