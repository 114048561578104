import RegistryLabel from "components/DetailsView/RegistryLabel"
import { icon } from "leaflet"
import _, { set } from "lodash"
import React, { useEffect, useRef, useState } from "react"
import { useMediaQuery } from "react-responsive"
import { Image } from "react-bootstrap"
import {
  MapContainer,
  Marker,
  TileLayer,
  Tooltip,
  GeoJSON,
} from "react-leaflet"
import { Col, Row } from "reactstrap"
import CountryIndicator from "../CountryIndicator"
import ExpandableText from "../ExpandableText"
import LocationsDropdown from "../LocationsDropdown"
import SectoralScopeIndicator from "../SectoralScopeIndicator"
import Disclaimer from "../Disclaimer"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
// import { useLazyGetProjectKMLQuery } from "store/RTK-Store/services/projectApi"
// import ReactLeafletKml from "react-leaflet-kml"
// import { kml as kmlConvert } from "@mapbox/togeojson"
import L from "leaflet"
import { InfoIcon } from "lucide-react"

const customIcon = L.icon({
  iconUrl: "/map-icon.svg",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  shadowSize: [41, 41],
})

let testXML = ``

const ProjectDetails = ({ tooManyPoints, data, id, kml }) => {
  // const { id } = useParams()
  // console.log(id);
  // const [kml, setKml] = useState([])
  // const [getProjectKML, { data: kmlData }] = useLazyGetProjectKMLQuery()
  const [zoom, setZoom] = useState(5)

  const [firstMapLoc, setFirstMapLoc] = useState([])
  const mapRef = useRef(null)
  useEffect(() => {
    if (firstMapLoc.length) {
      mapRef.current?.setView(firstMapLoc, 10)
    }
  }, [firstMapLoc])

  useEffect(() => {
    const coordinates = _.get(data, "coordinates", [])
    if (coordinates.length) {
      const { latitude, longitude } = coordinates[0]
      setFirstMapLoc([latitude, longitude])
    }
  }, [data])

  // useEffect(() => {
  //   console.log("id: ", id, kml);

  //   if (id && kml.length === 0) {
  //     getProjectKML({ slug: id })
  //       .unwrap()
  //       .then(async response => {
  //         let kml = _.get(response, "payload.data", "")
  //         if (kml) {
  //           let kmlArray = []
  //           await kml.map(async item => {
  //             try {
  //               const parser = new DOMParser()
  //               let kmlString = item

  //               // kmlString = kmlString.replace(/<\?xml.*?\?>/, '');

  //               if (!kmlString.includes("xmlns:xsi")) {
  //                 kmlString = kmlString.replace(
  //                   "<kml ",
  //                   '<kml xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" '
  //                 )
  //               }

  //               const kmlData = parser.parseFromString(kmlString, "text/xml")
  //               const converted = kmlConvert(kmlData)
  //               kmlArray.push(converted)
  //               // console.log(kmlData)
  //             } catch (error) {
  //               console.log("Error: ", error)
  //             }
  //           })
  //           setZoom(5)
  //           // const combinedGeojson = {
  //           //   type: "FeatureCollection",
  //           //   features: kmlArray.flatMap(geojson => geojson.features)
  //           // };
  //           // console.log(combinedGeojson);
  //           // console.log(kmlArray);

  //           setKml(kmlArray)
  //         } else {
  //           setKml("")
  //         }
  //         // setKml(response)
  //       })
  //       .catch(error => {
  //         console.log("Error: ", error)
  //         setKml([])
  //       })
  //   }
  // }, [id])

  const isMobile = useMediaQuery({ maxWidth: 767 })
  const isDisclaimer =
    _.get(data, "isDisclaimer", false) ||
    _.get(data, "projectDescription", "").indexOf(
      "The CarbonHub team is currently training its AI system to retrieve this information from project documents."
    ) > -1
  const disclaimerIcon = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 11C9.45 11 9 10.55 9 10V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V10C11 10.55 10.55 11 10 11ZM11 15H9V13H11V15Z"
        fill="#F0A656"
      />
    </svg>
  )

  const checkForInvalidCoordinates = coordinates => {
    if (Array.isArray(coordinates)) {
      for (let coord of coordinates) {
        if (Array.isArray(coord)) {
          if (checkForInvalidCoordinates(coord)) {
            return true // If any nested array has invalid coordinates
          }
        } else if (isNaN(coord)) {
          return true // If any coordinate is NaN
        }
      }
    }
    return false
  }

  const isValidCountry = (_.get(data, "country", "") === "International" || _.get(data, "country", "") === "" || _.get(data, "country", "") === "N/A") ? false : true;

  return (
    <React.Fragment>
      <Row>
        <Col xs={12} lg={6}>
          {/* Title & description */}
          <h1 className="mb-2 fs-1" style={{ fontWeight: "500" }}>
            {_.get(data, "project_title", "No title available.")}
          </h1>
          <Row className="mb-2 gap-3 gap-md-0 row-cols-1 row-cols-md-3 align-items-center justify-content-center">
            <Col>
              <SectoralScopeIndicator
                scopes={_.get(data, "projectSectoralScopes_ids", "").split(",")}
              />
            </Col>
            <Col className="d-flex justify-content-start align-items-center">
              <CountryIndicator
                code={_.get(data, "countryEmojis.code", "").toLowerCase()}
                country={_.get(data, "country", "N/A")}
              />
            </Col>
            <Col className="d-flex justify-content-start align-items-center">
              <RegistryLabel registry={_.get(data, "registry")} />
            </Col>
          </Row>
          {isDisclaimer ? (
            <Disclaimer
              className="mt-3"
              icon={disclaimerIcon}
              title="Disclaimer"
              subTitle={_.get(data, "projectDescription")}
            />
          ) : (
            <ExpandableText
              text={_.get(data, "projectDescription")}
              length={800}
            />
          )}
        </Col>
        <Col xs={12} lg={6}>
          <div className="mt-2 mt-lg-0" />
          {/* Maps */}
          <div style={{ borderRadius: 16 }} className="mb-3">
            <div>
              {isValidCountry ? (
                <MapContainer
                  center={[0, 0]}
                  zoom={zoom}
                  style={{ height: 400, borderRadius: 16 }}
                  ref={mapRef}
                  worldCopyJump={true}
                >
                  <TileLayer url="https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}" />
                  {_.get(data, "coordinates", []).map(item => {
                    return (
                      <Marker
                        key={item.countryName}
                        // icon={icon({ iconUrl: "/map-icon.svg" })}
                        icon={customIcon}
                        position={[item?.latitude, item?.longitude]}
                      >
                        <Tooltip
                          className="rounded rounded-4 shadow shadow-lg border px-3 bg-white"
                          permanent
                          direction="bottom"
                        >
                          <div
                            className="fs-5 fw-bold"
                            style={{ fontFamily: "Poppins" }}
                          >
                            {item.countryName}
                          </div>
                          <div
                            className="text-muted"
                            style={{ fontFamily: "Poppins" }}
                          >
                            {item.state_province}
                          </div>
                          <small
                            className="me-2 text-secondary"
                            style={{ fontFamily: "Poppins" }}
                          >
                            {item.latitude}° N
                          </small>
                          <small
                            className="text-secondary"
                            style={{ fontFamily: "Poppins" }}
                          >
                            {item.longitude}° E
                          </small>
                        </Tooltip>
                      </Marker>
                    )
                  })}
                  {kml &&
                    Array.isArray(kml) &&
                    kml.map((item, index) => (
                      // <ReactLeafletKml key={index} kml={item} kmlOptions={{
                      // }} />
                      <GeoJSON
                        key={index}
                        data={item}
                        style={() => ({
                          color: "red",
                          weight: 2,
                          opacity: 1,
                          fillOpacity: 0.4,
                        })}
                        onEachFeature={(feature, layer) => {
                          if (feature.properties && feature.properties.name) {
                            layer.bindPopup(feature.properties.name)
                          }
                        }}
                        pointToLayer={(feature, latlng) => {
                          let iconUrl = _.get(feature, "properties.icon", "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon-2x.png");
                          // console.log(feature);

                          if (iconUrl && !tooManyPoints) {
                            return L.marker(latlng, {
                              icon: L.icon({
                                iconUrl,
                                iconSize: [25, 41],
                                iconAnchor: [12, 41],
                                popupAnchor: [1, -34],
                                shadowUrl:
                                  "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
                                shadowSize: [41, 41],
                              }),

                            })
                          } else {
                            if (!tooManyPoints) {
                              return L.circleMarker(latlng, {
                                radius: 5,
                                fillColor: "#ff7800",
                                color: "#000",
                                weight: 1,
                                opacity: 1,
                                fillOpacity: 0.8
                              });
                            }
                          }
                        }}
                      />
                    ))}
                </MapContainer>
              ) : (
              <div style={{ position: 'relative', height: 400, borderRadius: 16 }}>
                <MapContainer
                  center={[10, 10]}
                  zoom={1}
                  style={{ height: '100%', borderRadius: 16,  opacity: 0.8 }}
                  worldCopyJump={true}
                  zoomControl={false} 
                >
                  <TileLayer url="https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}" />
                </MapContainer>
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.1)', 
                    zIndex: 998,
                    borderRadius: 16, 
                    pointerEvents: 'none', 
                  }}
                ></div>
                <div
                  className="d-flex rounded text-center w-100 align-items-center justify-content-center fw-bold"
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 999,
                    padding: '10px 20px',
                    borderRadius: '8px',
                    color: 'rgb(255, 255, 255)',
                    fontSize: '16px',
                  }}
                >
                  <div className="p-3 rounded-2 text-secondary text-sm" style={{background:'rgba(255,255,255,0.75)'}}>
                  <InfoIcon/> Location data not available in the registry
                  </div>
                </div>
              </div>)}
            </div>
          </div>
          <Row>
            {isMobile && data?.project_area && (
              <Col>
                {_.get(data, "project_area", null) !== null ? (
                  <div
                    className="d-flex align-items-center"
                    style={{ color: "#A1A1A1" }}
                  >
                    <Image src="/icons/area-icon.png" width={18} height={18} />
                    <span
                      className="fw-bold ms-3"
                      style={{ color: "#A1A1A1", fontSize: "14px" }}
                    >
                      {_.get(data, "project_area")}
                    </span>
                  </div>
                ) : null}
              </Col>
            )}
            {!isMobile && (
              <Col>
                {_.get(data, "project_area", null) !== null ? (
                  <div
                    className="d-flex align-items-center"
                    style={{ color: "#A1A1A1" }}
                  >
                    <Image src="/icons/area-icon.png" width={18} height={18} />
                    <span
                      className="fw-bold ms-3"
                      style={{ color: "#A1A1A1", fontSize: "14px" }}
                    >
                      {_.get(data, "project_area")}
                    </span>
                  </div>
                ) : null}
              </Col>
            )}
            <Col>
              <LocationsDropdown locations={_.get(data, "coordinates", [])} />
            </Col>
            <Col className="d-none d-md-flex" />
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ProjectDetails
