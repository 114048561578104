import FavoritesTableView from "components/Home/FavoritesList"
import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from "reactstrap"
import { Redirect } from "react-router-dom";

export default function index() {
  const token = localStorage.getItem("authToken")
  if (!token) {
    return <Redirect to="/home" />
  }
  return (
    <React.Fragment>
      <Helmet>
        <title>Favorites | CarbonHub</title>
      </Helmet>
      <div
        style={{
          backgroundColor: "#28516A",
          height: "103px",
          marginTop: "55px",
        }}
        className="d-flex align-self-center align-items-center p-4"
      >
        <div
          className="p-0"
          style={{
            color: "#fff",
            fontSize: "29px",
            fontWeight: 700,
          }}
        >
          My Favorites
        </div>
      </div>
      <Container fluid className="bg-white py-3">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={`/`}>Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={"/carbon-projects"}>Projects</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>My Favorites</BreadcrumbItem>
        </Breadcrumb>
      </Container>
      <Container fluid style={{ minHeight: "70vh", paddingBottom: 130 }} className="bg-white px-4">
        <FavoritesTableView />
      </Container>
    </React.Fragment>
  )
}
