/* eslint-disable no-undef */
import axios from "axios"
// import config from "../config/urls"
import * as urls from "../helpers/url_helper"

const api = axios.create({
  baseURL: urls.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
  },
})

export default api
