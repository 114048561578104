/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input , Form, FormFeedback } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { registerUser, apiError } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ggle from '../../assets/images/google.png'
import fbook from '../../assets/images/facebook.png'

// eslint-disable-next-line no-unused-vars
const Register = props => {

   //meta title
   document.title="Register | CarbonHub";

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      username: Yup.string().required("Please Enter Your Fullname"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(registerUser(values));
    }
  });

  const { user, registrationError, loading } = useSelector(state => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError,
    loading: state.Account.loading,
  }));
  if(process.env.NODE_ENV==="development"){
  console.log("user",user);
  }

  useEffect(() => {
    dispatch(apiError(""));
  }, []);

  const googleResponse = response => {
    signIn(response, "google");
  };

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                <div>
                    <Col>
                      <div className="modal-header">
                        <h5 className="modal-title mt-0">Register Account</h5>
                        <button
                          onClick={() => {
                          //setmodal_view_screen(false)
                          //clearFilterStates()
                          }}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    </Col>  
                </div>
                <div>
                  <div className="modal-body">
                    <h4>Welcome to CarbonSpace</h4>
                    <h6>Get your Carbon Project account now.</h6>
                  </div>
                </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {user && user ? (
                        <Alert color="success">
                          Register User Successfully
                        </Alert>
                      ) : null}

                      {registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                      ) : null}

                      <div className="topping">
                        <input type="checkbox"  
                        value="Lister" 
                        /> Lister
                      &nbsp; &nbsp;
                        <input type="checkbox"  
                        value="Seeker" 
                        /> Seeker
                      </div>
<br/>

                      <div className="mb-3">
                        
                        <Input
                          name="username"
                          type="text"
                          placeholder="Fullname*"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username || ""}
                          invalid={
                            validation.touched.username && validation.errors.username ? true : false
                          }
                        />
                        {validation.touched.username && validation.errors.username ? (
                          <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Email*"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="contact-phone">
              <Row>
                        <Col md="12">
                        <Input
                        type="phone-no"
                        className="phone-no"
                        placeholder="Phone"
                        />
                        </Col>
                    </Row>
                </div>  
<br/>
                <div className="contact-sub">
              <Row>
                        <Col md="12">
                       
                        <Input
                        type="subject"
                        className="subject"
                        placeholder="Company"
                        />
                        </Col>
                    </Row>
                </div> 

              <br/>
                      <div className="mb-3">
    
                        <Input
                          name="password"
                          type="password"
                          placeholder="Password*"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-4 text-justify">
                        <p className="mb-0">
                          By selecting Register, you agree to the CarbonSpace's {" "}
                          <Link to="#" className="text-primary">
                            Terms of Service
                          </Link>, &nbsp;
                          <Link to="#" className="text-primary">
                            Payments Terms of Service
                          </Link>, and acknowledge the&nbsp;
                          <Link to="#" className="text-primary">
                            Privacy Policy
                          </Link>.
                        </p>
                      </div>

                      <div className="mt-4">
                        <button
                          className="register-btn"
                          type="submit"
                        >
                          Register
                        </button>
                      </div>
                      <br/>
                      <h6 className="or-letter"><span>Or</span></h6>

                      {/* <FacebookLogin
                              appId={facebook.APP_ID}
                              autoLoad={false}
                              callback={facebookResponse}
                              render={renderProps => (
                                <Link
                                  to="#"
                                  onClick={renderProps.onClick}
                                >
  

                                  <button
                                  className="fb-btn">
                                    {/* <i className="mdi mdi-facebook mdi-1x"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                                    {/* <img src={fbook} className="social-fb"></img>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Sign in with Facebook
                                  </button>
                                </Link>
                              )}
                            /> */} 

                      <button className="face-btn">
                        <img src={fbook} className="social-fb"></img>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <a href="https://www.facebook.com">Sign in with Facebook</a>
                        </button>

                        <button className="face-btn">
                        <img src={ggle} className="social-fb"></img>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <a href="https://accounts.google.com/">Sign in with Google</a>
                        </button>

                            {/* <GoogleLogin
                              clientId={google.CLIENT_ID}
                              render={renderProps => (
                                <Link
                                  to="#"
                                  className=""
                                  onClick={renderProps.onClick}
                                >
                                   <button
                                  className="fb-btn">
                                    {/* <i className="fab fa-google fa-1x"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                                    {/* <img src={ggle} className="social-fb"></img> 
                                   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Sign in with Google
                                  </button>
                                  
                                </Link>
                              )}
                              onSuccess={googleResponse}
                              onFailure={() => { }}
                            />  */}

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          Already have an account ?{" "}
                          <Link to="/login" className="text-primary">
                            Login
                          </Link>
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              {/* <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p>
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Register;
