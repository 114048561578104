import { Button, Spinner } from "reactstrap"
import React, { useEffect, useState } from "react"
import { useAddToFavoritesMutation, useRemoveFromFavoritesMutation } from "../../store/RTK-Store/services/favoriteApi"
import { useHistory } from "react-router-dom"
import Swal from "sweetalert2"

function FavoritesButton({
                           favorite = false,
                           projectId,
                           hasText = true,
                           iconWidth = 17,
                           iconHeight = 22,
                           styles = {},
                           onToggle
                         }) {
  const [isFavorite, setIsFavorite] = useState(favorite)
  const [loading, setLoading] = useState(false)
  const [addToFavourites] = useAddToFavoritesMutation()
  const [removeFromFavorites] = useRemoveFromFavoritesMutation()
  const token = localStorage.getItem("authToken")
  const history = useHistory()
  const handleToggleFavorites = e => {
    e.stopPropagation()
    if (token) {
      if (!isFavorite) {
        setLoading(true)
        addToFavourites({ project_id: projectId })
          .unwrap()
          .then(data => {
            if (data?.error === false) {
              if (onToggle) {
                onToggle(true)
              }
              setIsFavorite(true)
            }
          })
          .finally(() => {
            setLoading(false)
            Swal.fire({
              title: "Success",
              text: "Project added to favorites.",
              toast: true,
              timer: 2000,
              showConfirmButton: false,
              showCancelButton: false,
              icon: "success",
              position: "top-end"
            })
          })
          .catch(error => {
            console.error(error)
          })
      } else {
        setLoading(true)
        removeFromFavorites({ project_id: projectId })
          .unwrap()
          .then(data => {
            if (data?.error === false) {
              if (onToggle) {
                onToggle(false)
              }
              setIsFavorite(false)
            }
          })
          .finally(() => {
            Swal.fire({
              title: "Success",
              text: "Project removed from favorites.",
              toast: true,
              timer: 2000,
              showConfirmButton: false,
              showCancelButton: false,
              icon: "success",
              position: "top-end"
            })
            setLoading(false)
          })
          .catch(error => {
            console.error(error)
          })
      }
    } else {
      const path = history.location.pathname
      history.push(`${path}?login=true`)
    }
  }
  useEffect(() => {
    if (typeof favorite === "boolean") {
      setIsFavorite(favorite)
    }
  }, [favorite])
  return (
    <Button
      size="sm"
      onClick={handleToggleFavorites}
      color="link"
      style={{
        textDecoration: "underline",
        color: "#495057",
        height: 28,
        ...styles
      }}
    >
      {loading ? (
        <>
          <Spinner size="md" style={{ height: iconWidth, width: iconWidth }} />
        </>
      ) : (
        <>
          {!isFavorite ? (
            // <svg
            //   width={iconWidth}
            //   height={iconHeight}
            //   viewBox="0 0 22 28"
            //   fill="none"
            //   xmlns="http://www.w3.org/2000/svg"
            // >
            //   <path
            //     d="M1.01571 3.11174V3.11111C1.01571 1.95533 1.96352 1 3.14286 1H18.8571C20.043 1 21 1.96183 21 3.11111V26.4895L11.3905 22.4128L11 22.2471L10.6095 22.4128L1.00095 26.4891L1.01571 3.11174Z"
            //     stroke="#CED4DA"
            //     strokeWidth="2"
            //   />
            // </svg>
            <svg width={iconWidth} height={iconHeight} viewBox="0 0 17 23" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.01214 2.94507V2.94444C1.01214 2.13958 1.65777 1.5 2.42857 1.5H14.5714C15.3487 1.5 16 2.14613 16 2.94444V20.9796L8.89609 17.9151L8.5 17.7443L8.10391 17.9151L1.00094 20.9791L1.01214 2.94507Z"
                stroke="#CED4DA" stroke-width="2" />
            </svg>

          ) : (
            <svg
              width={iconWidth}
              height={iconHeight}
              viewBox="0 0 22 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.8571 0H3.14286C1.41429 0 0.0157146 1.4 0.0157146 3.11111L0 28L11 23.3333L22 28V3.11111C22 1.4 20.5857 0 18.8571 0Z"
                fill="#3F6A84"
              />
            </svg>
          )}
          {hasText ? (
            !isFavorite ? (
              <span
                className="ms-2 d-none d-md-inline"
                style={{ fontSize: 14, color: "#495057", paddingLeft: 7, fontWeight: 300 }}
              >
                Add to favorites
              </span>
            ) : null
          ) : null}
        </>
      )}
    </Button>
  )
}

export default FavoritesButton
