import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"

/*
    This component is a simple component that displays the title of a section.
    It takes a single prop, title, which is the title of the section.
  
    // Example usage:
    <SectionHeader title="Project Comparison" />
  
    required props:
    - title: string (title of the section)
*/

function useElementPosition(threshold, aboveThresholdFunc, belowThresholdFunc) {
  const elementRef = useRef(null)
  const [isAboveThreshold, setIsAboveThreshold] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const element = elementRef.current
    if (!element) return

    const checkPosition = () => {
      if (!isVisible) return

      const rect = element.getBoundingClientRect()
      const elementTop = rect.top

      if (elementTop <= threshold && !isAboveThreshold) {
        setIsAboveThreshold(true)
        aboveThresholdFunc()
      } else if (elementTop > threshold && isAboveThreshold) {
        setIsAboveThreshold(false)
        belowThresholdFunc()
      }
    }

    // Intersection Observer for visibility
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting)
        if (entry.isIntersecting) {
          checkPosition()
        }
      },
      { threshold: 0 }
    )

    observer.observe(element)

    // Scroll event listener
    window.addEventListener("scroll", checkPosition)

    // Cleanup
    return () => {
      observer.disconnect()
      window.removeEventListener("scroll", checkPosition)
    }
  }, [
    threshold,
    aboveThresholdFunc,
    belowThresholdFunc,
    isAboveThreshold,
    isVisible,
  ])

  return elementRef
}

export default function SectionHeader({ title, onScrollUp, onScrollDown }) {
  const elementRef = useElementPosition(
    100,
    () => {
      if (onScrollUp) {
        onScrollUp(title)
      }
    },
    () => {
      if (onScrollDown) {
        onScrollDown(title)
      }
    }
  )
  return (
    <div
      ref={elementRef}
      style={{
        backgroundColor: "#EFF4F4",
        borderRadius: "4px",
        padding: "16px",
        overflowX: "auto",
      }}
    >
      <p
        className="m-0 p-0"
        style={{
          color: "#495057",
          fontFamily: "poppins",
          fontWeight: "700",
          fontSize: "18px",
          textAlign: "left",
          lineHeight: "27px",
        }}
      >
        {title}
      </p>
    </div>
  )
}

SectionHeader.propTypes = {
  title: PropTypes.string,
}
