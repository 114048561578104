import ApprovalsCertifications from "components/DetailsView/ApprovalsCertifications"
import Documents from "components/DetailsView/Documents"
import GeneralInformation from "components/DetailsView/GeneralInformation"
import IssuanceHistory from "components/DetailsView/IssuanceHistory"
import ProjectDetails from "components/DetailsView/ProjectDetails"
import ProjectInformation from "components/DetailsView/ProjectInformation"
import ProjectLinks from "components/DetailsView/ProjectLinks"
import ProponentInformation from "components/DetailsView/ProponentInformation"
import ViewIndicator from "components/DetailsView/ViewIndicator"
import ProjectCard from "components/Home/ProjectCard"
import "leaflet/dist/leaflet.css"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import {
  Alert,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Container,
  Row,
} from "reactstrap"

import { Link, useHistory, useParams } from "react-router-dom"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import { useGetProjectByCSPIdQuery } from "store/RTK-Store/services/projectApi"
import { useIncreaseClickCountMutation } from "../../store/RTK-Store/services/clickTrackingApi"
import CreditsOverview from "../../components/DetailsView/CreditsOverview"
import FavoritesButton from "./FavoritesButton"
import ComparisonButton from "components/Home/Comparison/CardButton"
import LoadingShimmer from "../../components/DetailsView/LoadingShimmer"
import ShareButton from "./ShareButton"
import { useLazyGetProjectKMLQuery } from "store/RTK-Store/services/projectApi"
import ReactLeafletKml from "react-leaflet-kml"
import { kml as kmlConvert } from "@mapbox/togeojson"
import JSZip from "jszip"

export function formatClicks(num) {
  if (typeof num != "number") {
    return 0
  }
  if (num >= 1000000000) {
    const result = (num / 1000000000).toFixed(1)
    return result.endsWith(".0") ? result.slice(0, -2) + "B" : result + "B"
  } else if (num >= 1000000) {
    const result = (num / 1000000).toFixed(1)
    return result.endsWith(".0") ? result.slice(0, -2) + "M" : result + "M"
  } else if (num >= 1000) {
    const result = (num / 1000).toFixed(1)
    return result.endsWith(".0") ? result.slice(0, -2) + "K" : result + "K"
  } else {
    return num.toString()
  }
}

function DetailsView() {
  const { id } = useParams()
  const history = useHistory()
  const [cspId, setCSPId] = useState()
  const [loading, setLoading] = useState(true)
  const [increaseClickCount] = useIncreaseClickCountMutation()
  const [getProjectKML, { data: kmlData }] = useLazyGetProjectKMLQuery()
  const [kml, setKml] = useState([])
  const [tooManyPoints, setTooManyPoints] = useState(false)

  const checkForInvalidCoordinates = coordinates => {
    if (Array.isArray(coordinates)) {
      for (let coord of coordinates) {
        if (Array.isArray(coord)) {
          if (checkForInvalidCoordinates(coord)) {
            return true // If any nested array has invalid coordinates
          }
        } else if (isNaN(coord)) {
          console.log("Invalid coordinate: ", coord)
          return true // If any coordinate is NaN
        }
      }
    }
    return false
  }

  useEffect(() => {
    if (id && kml.length === 0) {
      getProjectKML({ slug: id })
        .unwrap()
        .then(async response => {
          let kml = _.get(response, "payload.data", "")
          if (kml) {
            let kmlArray = []
            await Promise.all(
              kml.map(async item => {
                try {
                  const parser = new DOMParser()
                  let kmlString = item

                  if (!kmlString.includes("xmlns:xsi")) {
                    kmlString = kmlString.replace(
                      "<kml ",
                      '<kml xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" '
                    )
                  }

                  const kmlData = parser.parseFromString(kmlString, "text/xml")
                  const converted = kmlConvert(kmlData)

                  let featureCount = []
                  // Count the number of Point features
                  let pointFeatureCount = 0
                  converted.features.forEach(feature => {
                    if (feature.geometry.type === "Point") {
                      pointFeatureCount++
                    }
                    featureCount.push(feature.geometry.type)
                  })

                  let featureSet = new Set(featureCount)

                  // Remove the icon property if there are more than 10 Point features
                  if (pointFeatureCount > 50 && featureSet.size > 1) {
                    // converted.features.forEach(feature => {
                    //   if (feature.geometry.type === "Point") {
                    //     delete feature.properties.icon;
                    //   }
                    // });
                    setTooManyPoints(true)
                  }

                  let filteredKml = converted.features.filter(feature => {
                    const coordinates = feature.geometry.coordinates
                    return !checkForInvalidCoordinates(coordinates)
                  })

                  kmlArray.push(filteredKml)
                } catch (error) {
                  console.log("Error: ", error)
                }
              })
            )
            // console.log("KML Array: ", kmlArray);

            setKml(kmlArray)
          } else {
            setKml("")
          }
        })
        .catch(error => {
          console.log("Error: ", error)
          setKml([])
        })
    }
  }, [id])

  useEffect(() => {
    if (id) {
      setCSPId(id)
    }
  }, [id])
  const { data, isFetching, isLoading, error } = useGetProjectByCSPIdQuery(
    { id: cspId },
    { skip: !cspId }
  )
  const similarProject = _.get(data, "similarProjects", [])
  const [details, setDetails] = useState({})

  useEffect(() => {
    if (data) {
      if (_.hasIn(data, "data")) {
        setDetails(_.get(data, "data"))
      } else {
        if (_.hasIn(data, "error")) {
          history.push("/404")
        } else if (`${data}`.indexOf("No project details found") > -1) {
          history.push("/404")
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (isFetching || isLoading) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [isFetching, isLoading])
  // Handling parent path
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const from = searchParams.get("from")
  let parentPath = "Projects"
  if (from) {
    if (from === "favorites") {
      parentPath = "My Favorites"
    } else if (from === "latest-updates") {
      parentPath = "Latest Updates"
    }
  }
  // SEO changes
  let title =
    "[Project Title] in [Country] | Carbon Reduction Initiative - CarbonHub"
  let description =
    "Join the fight against climate change with the [Project Title] on CarbonHub."

  if (details) {
    title = title
      .replaceAll(
        "[Project Title]",
        _.get(details, "headerData.project_title", "Loading...")
      )
      .replaceAll(
        "[Country]",
        _.get(data, "data.headerData.country", "Loading...")
      )
    description = description.replaceAll(
      "[Project Title]",
      _.get(details, "headerData.project_title", "Loading...")
    )
  }

  const triggerClickCount = () => {
    const id = _.get(details, "commonInformation.id", 0)
    if (id) {
      increaseClickCount(id).catch(error => console.error(error))
    }
  }

  useEffect(() => {
    triggerClickCount()
  }, [_.get(details, "commonInformation.id", 0)])

  const mainUrl = window.location.origin
  const url = window.location.href

  const truncateText = (text, maxWordsStart = 1, maxWordsEnd = 1) => {
    const words = text.split(' ');
    if (words.length <= maxWordsStart + maxWordsEnd) {
      return text;
    }
    const startWords = words.slice(0, maxWordsStart).join(' ');
    const endWords = words.slice(-maxWordsEnd).join(' ');
    return `${startWords} ... ${endWords}`;
  };

  const BreadcrumbItemWithTruncate = ({ details }) => {
    const projectTitle = _.get(details, "headerData.project_title", "Loading Project Title");
    // console.log(window.innerWidth);
    let width = window.innerWidth;
    
    const truncatedTitle = width < 768
      ? truncateText(projectTitle)
      : projectTitle;
  
    return (
      <BreadcrumbItem active>
        {truncatedTitle}
      </BreadcrumbItem>
    );
  };
  
  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={`${mainUrl}/static/media/CHLogoTitleDark.fed94076681c49db9def.png`}
        />
      </Helmet>
      <div style={{ background: "#F3F3F9" }} className="min-vh-100">
        <div
          className="container min-vh-100"
          style={{ paddingTop: 70, maxWidth: "1400px", paddingBottom: "1rem" }}
        >
          {/* Breadcrumb */}
          <div className="row">
            <div className="col">
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to={"/"}>Home</Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Link to={from ? `/${from}` : "/carbon-projects"}>
                    {parentPath}
                  </Link>
                </BreadcrumbItem>
                {details && (
                  <BreadcrumbItemWithTruncate details={details} />
                )}
              </Breadcrumb>
            </div>
          </div>
          {/* Card */}
          {error?.code ? (
            <Alert color="danger">Something went wrong!</Alert>
          ) : null}
          <div
            className="card min-vh-100"
            style={{ borderRadius: 32, background: "#fcfcfc" }}
          >
            <div className="card-body p-4">
              {/* Loading indicator */}
              {loading && (
                <div className="d-flex align-items-center justify-content-center">
                  <LoadingShimmer />
                </div>
              )}
              {/* View indicator & favorites button */}
              {!isFetching && (
                <>
                  <div className="row gap-3 d-flex align-items-center">
                    <div className="col">
                      <ViewIndicator
                        count={_.get(details, "commonInformation.clicks", 0)}
                      />
                    </div>
                    <div
                      className="d-flex col gap-3 text-end"
                      style={{
                        height: 28,
                        paddingBottom: 0,
                        justifyContent: "flex-end",
                        textWrap: "nowrap",
                      }}
                    >
                      <ComparisonButton
                        project_data={{
                          id: _.get(details, "commonInformation.id", 0),
                          project_name: _.get(
                            details,
                            "headerData.project_title",
                            "Loading..."
                          ),
                          countryEmojis: {
                            name: _.get(
                              details,
                              "headerData.country",
                              "Loading..."
                            ),
                          },
                          slug: _.get(details, "commonInformation.slug", null),
                        }}
                        iconWidth={22}
                        iconHeight={28}
                        location="detail"
                        imageStyles={{ marginTop: 4 }}
                        textMargin={"-34px"}
                        style={{ marginRight: 0, marginTop: 0 }}
                      />
                      <FavoritesButton
                        favorite={_.get(
                          details,
                          "commonInformation.favourite",
                          false
                        )}
                        projectId={_.get(details, "commonInformation.id")}
                        width={22}
                        height={28}
                        onToggle={isFav => {
                          if (!isFav) {
                            if (from && from === "favorites") {
                              history.push("/favorites")
                            }
                          }
                        }}
                      />
                      <ShareButton shareURL={window.location.href} />
                    </div>
                  </div>
                  <div className="mb-4" />
                  {/* Project details */}
                  <ProjectDetails
                    id={id}
                    tooManyPoints={tooManyPoints}
                    kml={kml}
                    data={_.get(details, "headerData")}
                  />
                  <div className="mb-5" />
                  {/* Credit information */}
                  <CreditsOverview
                    data={_.get(details, "creditingPddDetails", {})}
                  />
                  {/* Project Information */}
                  <ProjectInformation
                    data={_.get(details, "projectInformation", [])}
                  />
                  {/* General Information */}
                  <GeneralInformation
                    data={_.get(details, "generalInformation", [])}
                  />
                  {/* Approvals */}
                  <ApprovalsCertifications
                    data={_.get(details, "approvalsCertification", [])}
                  />
                  {/* Proponent details */}
                  <ProponentInformation
                    data={_.get(details, "proponentDetails", [])}
                  />
                  {/* Project links */}
                  <ProjectLinks data={_.get(details, "projectLink", [])} />
                  {/* Issuance */}
                  <IssuanceHistory
                    data={_.get(details, "issuanceHistory", [])}
                    registry={_.get(
                      details,
                      "headerData.registry",
                      "Loading..."
                    )==="Climate Action Reserve"}
                  />
                  {/*  Documents */}
                  <Documents
                    data={_.get(details, "resourceDetails", [])}
                    date={_.get(details, "commonInformation", [])}
                    dateVisible={true}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#F3F3F9" }}>
        <Container
          style={{
            background: "#F3F3F9",
            maxWidth: "1400px",
            marginBottom: "5rem",
          }}
        >
          <Row style={{ marginBottom: "2rem" }}>
            <Col>
              <h2
                style={{ fontWeight: "bold", fontSize: 24, color: "#495057" }}
              >
                Similar Projects
              </h2>
            </Col>
          </Row>
          <Row
            className="g-3 row-cols-1 row-cols-md-2 row-cols-lg-4"
            style={{ paddingBottom: "5rem" }}
          >
            {_.defaultTo(similarProject, []).map(project => {
              return (
                <Col key={project.slug}>
                  <ProjectCard
                    project={project}
                    onProjectSelected={slug => {
                      history.push("/carbon-project/" + slug)
                      window.scrollTo(0, 0)
                    }}
                  />
                </Col>
              )
            })}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DetailsView
