import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import _ from "lodash"
import * as urls from "../../helpers/url_helper"

export const homeApi = createApi({
  reducerPath: "homeApi",
  keepUnusedDataFor: 60,
  baseQuery: fetchBaseQuery({ baseUrl: urls.REACT_APP_API_URL }),
  endpoints: builder => ({
    getMetricsData: builder.query({
      query: () => {
        return `/publish/getMetricsWeb`
      },
      transformResponse: response => {
        return _.get(response, "payload", [])
      },
    }),
    getLastUpdatedDate: builder.query({
      query: () => {
        return `/publish/getTheLatestUpdateDate`
      },
      transformResponse: response => {
        return _.get(response, "payload", [])
      },
    }),
    getFeaturedProjects: builder.query({
      query: () => {
        return {
          url: "/publish/getFeatureProjects?page=1&pageSize=20",
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            // Authorization: `Bearer ${token}`,
          },
        }
      }
    })
  }),
})

export const {
  useGetMetricsDataQuery,
  useLazyGetMetricsDataQuery,
  useGetLastUpdatedDateQuery,
  useLazyGetLastUpdatedDateQuery,
  useGetFeaturedProjectsQuery,
} = homeApi
export default homeApi.reducer
