import { Card, CardBody, Col, Row } from "reactstrap"
import React from "react"
import CardSectionTitle from "../CardSectionTitle"
import DoubleLineItem from "../TwoLineItem"
import _ from "lodash"

const CustomLink = ({ url }) => {
  if (!url || url === "N/A") {
    return <span>N/A</span>
  }
  return <a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    style={{ color: "#006080" }}
  >
    <img alt={url} className="me-2" src="/icons/link.svg" />
    {url}
  </a>
}

const ProjectLinks = ({ data }) => {
  return <Card className="shadow-none rounded rounded-5 border bg-transparent">
    <CardBody>
      <CardSectionTitle title="Project Links" icon={<img alt="Project Links" src="/icons/project-links.svg" />} />
      <Row>
        {data.map((item, index) => {
          return <Col xs={12} md={6} key={index}>
            <DoubleLineItem title={_.get(item, "label", "N/A")}
                            body={<CustomLink url={_.get(item, "value", "N/A")} />} />
          </Col>
        })}
      </Row>
    </CardBody>
  </Card>
}

export default ProjectLinks