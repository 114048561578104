import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { removeFromComparedProjects } from "store/RTK-Store/comparisonSlice"
import {
  button_header,
  closeIcon,
} from "../../components/Home/Comparison/LogoSVGs/index"
import Popover from "rsuite/Popover"
import Whisper from "rsuite/Whisper"
import "rsuite/dist/rsuite.min.css" // Adjust the path as necessary
import { useHistory } from "react-router-dom/cjs/react-router-dom"

// import '../../assets/scss/dev.css'

const ComparisonButton = ({
  location,
  text,
  onClick,
  style,
  iconWidth,
  iconHeight,
  project_data,
  imageStyles,
  isLanding,
  side
}) => {
  const dispatch = useDispatch()
  const comparedProjects = useSelector(
    state => state.comparison.comparedProjects
  )
  const whisperRef = useRef(null)
  const popoverRef = useRef(null)
  const [showPopover, setShowPopover] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [locationSide, setLocationSide] = useState(side? true : false)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const isLargeScreen = windowWidth >= 1504

  useEffect(() => {
    function handleClickOutside(event) {
      // console.log(popoverRef);
      if (
        whisperRef.current &&
        !whisperRef.current.contains(event.target) &&
        popoverRef.current &&
        !popoverRef.current.contains(event.target)
      ) {
        // console.log("outside both");
        setShowPopover(false)
      }
    }

    function handleScroll(event) {
      // console.log(whisperRef, popoverRef, event)
      // setShowPopover(false);
      if (
        whisperRef.current &&
        !whisperRef.current.contains(event.target) &&
        popoverRef.current &&
        !popoverRef.current.contains(event.target)
      ) {
        // console.log("outside both scroll");
        setShowPopover(false)
      }
    }

    document.addEventListener("click", handleClickOutside)
    window.addEventListener("scroll", handleScroll) // Added listener for scroll events

    return () => {
      document.removeEventListener("click", handleClickOutside)
      window.removeEventListener("scroll", handleScroll) // Remove listener for scroll events
    }
  }, [whisperRef])

  function debounce(func, wait) {
    let timeout
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout)
        func(...args)
      }
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }

  const togglePopover = () => {
    setShowPopover(prevShowPopover => {
      return !prevShowPopover
    })
  }

  const debouncedTogglePopover = debounce(togglePopover, 100)

  const removeProject = project => {
    // console.log(project);
    event.stopPropagation()
    if (project) {
      let id = project.id
      dispatch(removeFromComparedProjects(id))
    }
  }

  const comparedProjectCard = ({ project, removeProject }) => {
    let title = project?.title || 'N/A'
    const truncateMiddle = (title, maxLength) => {
      if (title?.length <= maxLength) {
        return title
      }

      const middle = Math.floor(maxLength / 2)
      return `${title?.substr(0, middle)}...${title?.substr(
        title?.length - middle,
        title?.length
      )}`
    }
    return (
      <div
        className="d-flex align-items-center justify-content-between"
        style={{
          width: 276,
          height: 66,
          borderRadius: 5,
          backgroundColor: "#EEF5FA",
          marginBottom: 10,
        }}
      >
        {/* <div style={{padding: 10}}> */}
        <div
          style={{
            fontSize: 12,
            fontWeight: 400,
            color: "#015F81",
            marginBottom: 6,
            padding: "6px 14px 6px 8px",
          }}
        >
          {truncateMiddle(title, 90)}
        </div>
        <button
          className="btn btn-primary btn-sm comparison_grid_button"
          style={{
            ...imageStyles,
            backgroundColor: "transparent",
            border: "none",
            padding: "0px 13.45px 0px 0px",
          }}
          onClick={() => {
            removeProject(project)
          }}
        >
          {text}
          {closeIcon({ iconWidth: 12.55, iconHeight: 12 })}
        </button>
        {/* </div> */}
      </div>
    )
  }

  const handleClick = () => {
    if (onClick) {
      onClick()
    }
    // Additional actions can be added here if needed
  }
  const history = useHistory()

  const navigateWithComparedProjects = projects => {
    if (comparedProjects.length) {
      // window.location.href = `/comparison`
      history.push("/comparison")
    } else {
      const comparedProjectIds = projects.map(project => project.id)
      const queryParams = new URLSearchParams({ ids: comparedProjectIds })
      // console.log(`/comparison?${queryParams.toString()}`);
      // window.location.href = `/comparison?${queryParams.toString()}`
      history.push(`/comparison?${queryParams.toString()}`)
    }
  }

  const speaker = (
    <Popover
      style={{
        width: 297,
        minHeight: 180,
        borderRadius: 10,
        display: showPopover ? "block" : "none",
      }}
    >
      <div ref={popoverRef}>
        <div className="dflex justify-content-center">
          <div
            style={{
              fontSize: 16,
              fontWeight: 700,
              color: "#3F6A84",
              marginBottom: 6,
              // marginLeft: 20,
            }}
          >
            Comparison List
          </div>
        </div>
        <div
          className="divider-line"
          style={{
            borderTop: "1px solid #E0E0E0",
            paddingLeft: 10.5,
            paddingRight: 10.5,
            marginBottom: 10,
          }}
        ></div>
        <div
          style={{
            // overflow: "auto",
            // maxHeight: "160px",
            // minHeight: "160px",
            marginBottom: 10,
          }}
        >
          {comparedProjects.length > 0 ? (
            comparedProjects.map((project, index) =>
              comparedProjectCard({ project, removeProject })
            )
          ) : (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                width: 276,
                height: 66,
                borderRadius: 5,
                backgroundColor: "#EEF5FA",
                textAlign: "center",
                marginBottom: 10,
                padding: "6px 14px 6px 8px",
                fontSize: 12,
                fontWeight: 400,
                color: "#959595",
              }}
            >
              Please select at least two projects for comparison.
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex",
            marginTop: 12
          }}
        >
          <button
            className="btn btn-sm comparison_grid_button3"
            style={{
              width: 277,
              height: 33,
              backgroundColor: "#3F6A84",
              color: "#FFFFFF",
              alignSelf: "flex-end",
            }}
            onClick={() => {
              navigateWithComparedProjects(comparedProjects)
            }}
          >
            Compare
          </button>
        </div>
      </div>
    </Popover>
  )

  return (
    <div
      style={isLargeScreen ? { paddingRight: 24 } : { paddingRight: 8 }}
      ref={whisperRef}
    >
      <Whisper
        // ref={whisperRef}
        placement={isLargeScreen || locationSide ? "bottom" : "bottomEnd"}
        trigger="click"
        controlId="control-id-click"
        open={showPopover}
        speaker={speaker}
      >
        <div
          className={`d-flex align-items-center ${
            location === "detail" ? "comparison_grid_button" : ""
          }`}
          style={{
            ...style,
            cursor: "pointer",
            width: "100%",
          }}
          // onClick={togglePopover}
          onClick={debouncedTogglePopover}
        >
          <button
            className="btn btn-primary btn-sm comparison_grid_button"
            style={{
              ...imageStyles,
              backgroundColor: "transparent",
              border: "none",
              pointerEvents: location === "grid" ? "auto" : "none",
            }}
            onClick={handleClick}
          >
            <div style={{ position: "relative", display: "inline-block" }}>
              {text}
              {button_header({ iconWidth, iconHeight })}
              {comparedProjects.length > 0 && (
                <span
                  style={{
                    position: "absolute",
                    top: "-5px",
                    right: "-5px",
                    backgroundColor: "#F46A6A",
                    color: "white",
                    fontSize: "10px",
                    fontWeight: "bold",
                    width: "17px",
                    height: "17px",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {comparedProjects.length}
                </span>
              )}
            </div>
          </button>
          {location === "detail" && (
            <div
              className="ms-2 d-none d-md-inline hide_large"
              style={{
                fontSize: "13px",
                color: isLanding ? "#FFFFFF" : "#495057",
                fontWeight: 400,
                marginLeft: "14px",
                // paddingRight: 48
              }}
            >
              Compare
            </div>
          )}
          {location === "landing" && (
            <div
              className="ms-2 d-none d-md-inline"
              style={{
                fontSize: "13px",
                color: isLanding ? "#FFFFFF" : "#495057",
                fontWeight: 400,
                marginLeft: "12px",
                paddingRight: 16,
              }}
            >
              Compare
            </div>
          )}
        </div>
      </Whisper>
    </div>
  )
}

export default ComparisonButton
