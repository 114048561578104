import React from "react"
import ACR from "../../../src/assets/images/registry/ACR.png"
import CAR from "../../../src/assets/images/registry/CAR.jpg"
import CD from "../../../src/assets/images/registry/CD.png"
import GS from "../../../src/assets/images/registry/GS.jpg"
import VERA from "../../../src/assets/images/registry/VERA.png"

export default function RegistryLabel({ registry }) {
  if (registry) {
    return (
      <div
        title={registry}
        className="d-flex align-items-center justify-content-center justify-content-md-end"
      >
        {registry === "Clean Development Mechanism" ? (
          <img src={CD} height={32} alt="CD" />
        ) : registry === "Verra" ? (
          <img src={VERA} height={32} alt="VERA" />
        ) : registry === "Gold Standard" ? (
          <img src={GS} height={32} alt="GS" />
        ) : registry === "Climate Action Reserve" ? (
          <img src={CAR} height={32} alt="CAR" />
        ) : registry === "American Carbon Registry" ? (
          <img
            style={{ objectFit: "contain" }}
            src={ACR}
            height={32}
            alt="ACR"
          />
        ) : null}
        <span
          className="fs-6 ms-2"
          style={{
            fontWeight: "bold",
            color: "#959595"
          }}
        >
          {registry}
        </span>
      </div>
    )
  }
  return (
    <div style={{ fontSize: "14px", fontWeight: "bold", color: "#959595" }}>
      No registry
    </div>
  )
}
