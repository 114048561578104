/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import * as urls from "../../../helpers/url_helper"


export const clickTrackingApi = createApi({
  reducerPath: "clickTrackingApi",
  baseQuery: fetchBaseQuery({ baseUrl: urls.REACT_APP_API_URL }),
  endpoints: builder => ({
    increaseClickCount: builder.mutation({
        query: (id) => {
          return { url: `/publish/increaseClicks/${id}`, method: "POST" }
        }
      }
    )
  })
})

export const { useIncreaseClickCountMutation } = clickTrackingApi
