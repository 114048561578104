import "./AnimatedLogo.css"
export default function AnimatedLogo({ size = 200 }) {
  return (
    <div style={{ width: size, height: size, position: "relative" }}>
      <img
        className="svg-rotate"
        src="/ring.svg"
        style={{ position: "absolute", top: 0, left: 0 }}
      />
      <img
        src="/logo.svg"
        style={{ position: "absolute", top: 0, left: 0, padding: 16 }}
      />
    </div>
  )
}
