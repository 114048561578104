import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  Row,
  Col,
  Card,
  Form,
  Nav,
  NavItem,
  NavLink,
  CardBody,
  CardTitle,
  CardText,
  Container,
  Table,
  TabContent,
  TabPane,
  Button,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

//Import Breadcrumb
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
import emailsuccess from '../../assets/images/email-error.svg'
const ContactErroring = () => {
    
    

    return (
        <React.Fragment>
          <div className="">
            <Container fluid={true}>
            <div className="cont-success">
            <h3>Your message wasn't delivered.</h3>
            <h6>While transferring your message, there was an issue.</h6>
            <h6>Please try once again later.</h6><br/>
            <img src={emailsuccess} className="email-suc"></img>
            </div>
            <br/>
            
            </Container>
          </div>
        </React.Fragment>
      );
    }

export default ContactErroring
