import { Card, CardBody, Col, Row } from "reactstrap"
import React from "react"
import DoubleLineItem from "../TwoLineItem"
import _ from "lodash"
import CardSectionTitle from "../CardSectionTitle"

const GeneralInformation = ({ data = [] }) => {
  return (
    <Card className="shadow-none rounded rounded-5 border bg-transparent">
      <CardBody>
        <CardSectionTitle
          title="General Information"
          icon={<img alt="General Information" src={"/icons/general.svg"} />}
        />
        <Row
          style={{ marginLeft: "20px" }}
          className="row-cols-1 row-cols-md-3 row-cols-lg-4"
        >
          {data.map((item, index) => (
            <Col key={index}>
              <DoubleLineItem
                title={_.get(item, "label", "Sample title")}
                body={_.get(item, "value", "N/A")}
                tooltip={_.get(item, "tooltip", "Sample tooltip")}
              />
            </Col>
          ))}
        </Row>
      </CardBody>
    </Card>
  )
}

export default GeneralInformation
