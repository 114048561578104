import { ReactComponent as AgentIcon } from "./agent.svg"
import "../../caronespaceai.css"
import "../../Chatbox.css"
function Loading() {
  return (
    <div className="chat-box-loading-container">
      <AgentIcon className="chat-box-loading-icon" />
    </div>
  )
}

export default Loading
