import React, { useState, useEffect } from "react"
import "../../caronespaceai.css"
import "../../Chatbox.css"
function Thinking({ messages, duration = 30000 }) {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    const changeMessage = () => {
      setIsVisible(false)

      setTimeout(() => {
        if (currentIndex < messages.length - 1) {
          setCurrentIndex(prevIndex => prevIndex + 1)
        }

        setIsVisible(true)
      }, 500)
    }

    const interval = setInterval(() => {
      if (currentIndex < messages.length - 1) {
        changeMessage()
      }
    }, Math.random() * 8000 + 1000)

    const timeout = setTimeout(() => {
      clearInterval(interval)
    }, duration)

    return () => {
      clearInterval(interval)
      clearTimeout(timeout)
    }
  }, [currentIndex, messages.length, duration])

  return (
    <div className="chat-box-thinking-container">
      {/* <div className="chat-box-thinking-avatar-container">
        <div className="chat-box-thinking-avatar"></div>
      </div> */}
      <div className="chat-box-thinking-message-container">
        <div
          className={`chat-box-thinking-message-text ${
            isVisible
              ? "chat-box-thinking-message-visible"
              : "chat-box-thinking-message-hidden"
          }`}
        >
          {messages[currentIndex]}
        </div>
      </div>
    </div>
  )
}

export default Thinking
