import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import * as urls from "../../../helpers/url_helper"


export const dropdownApi = createApi({
  reducerPath: "dropDownApi",
  baseQuery: fetchBaseQuery({ baseUrl: urls.REACT_APP_API_URL }),
  endpoints: builder => ({
    getDropDownData: builder.query({
      query: ({ type }) => {
        return `/publish/getDropDownData/${type}`
      },
      transformResponse: response => {
        return _.get(response, "payload", [])
      },
    }),
  }),
})
export const { useGetDropDownDataQuery } = dropdownApi

export default dropdownApi.reducer
