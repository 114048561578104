// import "../../caronespaceai.css"
import React from "react"
import { ReactComponent as AgentIcon } from "./agent.svg"
import "../../caronespaceai.css"
import "../../Chatbox.css"

function Agent() {
  return (
    <div className="chat-agent-view-container">
      <AgentIcon className="chat-agent-view-icon" />
    </div>
  )
}

export default Agent
