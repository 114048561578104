// import "../../caronespaceai.css"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import rehypeRaw from "rehype-raw"
import "../../Chatbox.css"

const Message = ({ message, actor }) => {
  return (
    <div
      className={`chat-box-message-container ${
        actor === "assistant"
          ? "chat-box-message-row-assistant"
          : "chat-box-message-row-user"
      }`}
    >
      {/* avatar */}
      <div className="chat-box-message-avatar">
        <div
          className={`chat-box-message-avatar-icon ${
            actor === "assistant"
              ? "chat-box-message-avatar-assistant"
              : "chat-box-message-avatar-user"
          }`}
        ></div>
      </div>
      {/* container */}
      <div
        className={`chat-box-message-content ${
          actor === "assistant"
            ? "chat-box-message-content-assistant"
            : "chat-box-message-content-user"
        }`}
      >
        {/* message */}
        <div
          className={`chat-box-message-text ${
            actor === "assistant"
              ? "chat-box-message-text-assistant"
              : "chat-box-message-text-user"
          }`}
        >
          {message !== "" ? (
            <ReactMarkdown
              className={`chat-box-message-markdown ${
                actor === "assistant"
                  ? "chat-box-message-markdown-assistant"
                  : "chat-box-message-markdown-user"
              }`}
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
            >
              {message ?? ""}
            </ReactMarkdown>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default Message
