import React, { useState } from "react"
import { Col, Modal, ModalBody, Row } from "reactstrap"
import ComparisonHeaderArrow from "../../assets/images/ComparisonHeaderArrow.svg"


const OPTIONS = [
  {
    id: 1,
    label: "Project Brief",
    value: "projectBrief"
  },
  {
    id: 2,
    label: "Project Information",
    value: "projectInformation"
  },
  {
    id: 3,
    label: "Registry Information",
    value: "registryInformation"
  },
  {
    id: 4,
    label: "Production Profile",
    value: "productionProfile"
  },
  {
    id: 5,
    label: "Issuance History",
    value: "issuanceHistory"
  },
  {
    id: 6,
    label: "Proponent Information",
    value: "proponentInformation"
  },
  {
    id: 7,
    label: "Approvals & Certifications",
    value: "approvalsAndCertifications"
  },
  {
    id: 8,
    label: "Project Links",
    value: "projectLinks"
  }
]

const TopHeader = ({ activeHash, setActiveHash, count }) => {


  const [modal, setModal] = useState(false)

  const selectedHash = OPTIONS.find(item => item["value"] === activeHash)

  return <>
    <Row className="d-flex py-3 align-items-center justify-content-center ps-2 pe-2">
      <Col xs={5}>
        <div className="p-2">
          <div style={{ fontSize: 14 }}>Projects Selected</div>
          <div className="fw-bold" style={{ fontSize: 20 }}>
              <svg
              width="18"
              height="21"
              viewBox="0 0 39 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.5 0L39 11V33L19.5 44L0 33V10.9999L19.5 0ZM4.33327 15.6443V30.5555L17.3333 37.8888V22.9777L4.33327 15.6443ZM34.6666 15.6444L21.6666 22.9777V37.8888L34.6666 30.5555V15.6444ZM19.5 4.88887L6.71663 12.1001L19.5 19.3111L32.2834 12.0999L19.5 4.88887Z"
                fill="#3F6A84"
              />
            </svg>
            <span style={{
              marginLeft: 10
            }}>
            {count || 0}
            </span>
            </div>
        </div>
      </Col>
      <Col xs={7}>
        <div onClick={() => {
          setModal(!modal)
        }} style={{
          borderRadius: 8,
          backgroundColor: "#EFF4F4"
        }} className="p-3 d-flex justify-content-between">
          <div className="pe-2" style={{
            color: "#015F81",
            fontWeight: 700,
            fontSize: 18,
            marginLeft: 20
          }}>{selectedHash?.label}</div>
          <div><img alt="Section Selector" src={ComparisonHeaderArrow} /></div>
        </div>
      </Col>
    </Row>
    <Modal
      isOpen={modal}
      toggle={() => setModal(!modal)}
      size="lg"
      style={{
        width: 350,
        height: 571,
        margin: "auto",
        marginTop: 200,
        borderRadius: 8
      }}
    >
      <ModalBody>
        <Row className="mb-2">
          <Col>
            <div className="p-3 fw-bold text-center" style={{ background: "#EFF4F4", borderRadius: 8 }}>
              Select Section
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {OPTIONS.map((option) => (
              <div
                className="p-3 text-center"
                style={{
                  color: "#3F6A84",
                  fontWeight: activeHash === option.value ? "bold" : "normal",
                  cursor: "pointer"
                }}
                key={option.id}
                onClick={() => {
                  setActiveHash(option.value)
                  setModal(!modal)
                }}
              >
                {option.label}
              </div>
            ))}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  </>

  return (
    <Row
    >
      <Col sm={6}>
        <div style={{ fontSize: 10 }}>Projects Selected</div>
        <div style={{ fontSize: 18 }}>{count || 0}</div>
      </Col>
      <Col sm={6}>
        <div>
          {OPTIONS.filter(option => option.value === activeHash).map((option) => (
            <div
              key={option.id}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: 39,
                height: "auto", // Adjusted to auto to accommodate variable content height
                // minWidth: 193,
                maxWidth: 200,
                width: "100%",
                padding: "0 10px",
                backgroundColor: "#EFF4F4",
                borderRadius: 8,
                cursor: "pointer",
                color: "#015F81",
                fontSize: 14,
                fontWeight: "bold",
                border: "none",
                whiteSpace: "normal", // Allows text to wrap
                wordWrap: "break-word" // Ensures words can be broken to prevent overflow
              }}
              onClick={() => setModal(!modal)}
            >
              {option.label}
              <img src={ComparisonHeaderArrow} alt="arrow" style={{ marginLeft: 10 }} />
            </div>
          ))}
        </div>
      </Col>

    </Row>
  )
}

export default TopHeader