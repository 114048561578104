/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-key */
import _ from "lodash"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Helmet } from "react-helmet"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { Nav, NavItem, NavLink } from "reactstrap"
import { useDispatch } from "react-redux"
import { setSearchPalylod, setSearchPalylodEmpty, setSeoTitle, setCurrentTab,setPageSizeReset } from "store/RTK-Store/projectsSlice"
import { markAsRead, setFetched, setNotification } from "store/RTK-Store/notificationsSlice"
import AlertsBanner from "components/AlertsBanner"
import { useGetLatestProjectsCountQuery } from "store/RTK-Store/services/notificationsApi"

export default function ProjectTypePicker({
  onTabSelected,
  preSelectedTab,
  children,
}) {
  const { search } = useLocation()
  const token = localStorage.getItem('authToken');
  const history = useHistory()
  const dispatch = useDispatch()
  const searchPayload = useSelector(state => state.projects.searchPalylod)
  const notification = useSelector(state => state.notifications.notification)
  const { data, isFetching, refetch } = useGetLatestProjectsCountQuery({ skip: token === null })
  const { sectoral_scope } = useParams()
  const params = new URLSearchParams(search)
  const tab = _.defaultTo(params.get("tab"), preSelectedTab)
  const searchWord = params.get("search")
  const projectTypes = [
    {
      name: "All",
      icon: "fa-grip-horizontal",
      id: 1,
    },
    {
      icon: "fa-tree",
      name: "Nature Based",
      seo_title:
        "Explore Nature-Based Carbon Projects for Climate Action | CarbonHub",
      id: 2,
    },
    {
      icon: "fa-cloud-sun-rain",
      name: "Energy",
      seo_title:
        "Sustainable Energy Carbon Projects | Renewable Solutions | CarbonHub",
      id: 3,
    },
    {
      icon: "fa-home",
      name: "Household",
      seo_title:
        "Eco-Friendly Household Projects for Carbon Reduction | CarbonHub",
      id: 4,
    },
    {
      icon: "fa-industry",
      name: "Industrial",
      seo_title:
        "Carbon Reduction in Industry | Sustainable Industrial Projects | CarbonHub",
      id: 5,
    },
    {
      icon: "fa-trash-restore",
      name: "Waste Management",
      seo_title:
        "Waste Management Carbon Projects | Sustainable Practices | CarbonHub",
      id: 6,
    },
    {
      icon: "fa-train",
      name: "Transport",
      seo_title: "Carbon Reduction through Sustainable Transport | CarbonHub",
      id: 7,
    },
    {
      icon: "fa-poll-h",
      name: "Other",
      seo_title:
        "Unique and Innovative Carbon Projects | Diverse Solutions | CarbonHub",
      id: 8,
    },
  ]
  let [selectedTab, setSelectedTab] = useState(preSelectedTab)
  useEffect(() => {
    let t = parseInt(tab)
    if (selectedTab !== t) {
      onTabSelected(t)
      setSelectedTab(t)
    }
  }, [tab, searchWord])
  const location = useLocation()
  // make navlink active based on the sectoral_scope in the url and match it with the projectTypes array
  useEffect(() => {
    if(searchPayload){ 
      // console.log(searchPayload);
      
      if(searchPayload.sectoralScopes && (searchPayload.sectoralScopes.length > 1 || searchPayload.sectoralScopes.length == 0)){
        setSelectedTab(1)
      }
    }
  }, [searchPayload])
  
  const isActive = type => {
    let sect_scope = ''
    if(searchPayload?.sectoralScopes && searchPayload.sectoralScopes.length > 1){
      let ids = searchPayload?.sectoralScopes;
      if(ids && ids.length > 1){
        // dispatch(setCurrentTab(type.id))
        sect_scope = 'all'
        return type.name ==='All'
      }
    }
    let payload_scope = projectTypes.find((type)=> type.id == searchPayload?.sectoralScopes)
    

    if (searchPayload?.sectoralScopes && searchPayload.sectoralScopes.includes(',')) {
      return false;
    }
    
    if(sectoral_scope){
      sect_scope = sectoral_scope.toLowerCase()
    }
    else if (payload_scope) {
      sect_scope = payload_scope.name
    }
    const keywordMap = {
      "innovative-carbon-projects": "other",
      "sustainable-transport-projects": "transport",
      "waste-management-solutions": "waste-management",
      "household-carbon-projects": "household",
      "nature-based-projects": "nature-based",
      "industrial-carbon-solutions": "industrial",
      "renewable-energy-projects": "energy"
    }

    const trueKeyWord = keywordMap[sect_scope] ?? sect_scope

    return sect_scope ? type?.name?.toLowerCase() ===
      trueKeyWord.replaceAll("-", " ").toLowerCase()
      : type.id === selectedTab
  }
  useEffect(() => {
    if (data) {
      const { error, payload } = data
      if (!error) {
        const { message, time } = payload
        if (message !== "Alert is already read.") {
          dispatch(
            setNotification({
              title: message,
              timestamp: time,
              destination: "/latest-updates",
            })
          )
        }
        dispatch(setFetched())
      }
    }
  }, [data])
  return (
    <div className="sticky-top" style={{ top: 52, zIndex: 100 }}>
      <Nav
        justified
        tabs
        className="nav-tabs-custom bg-white"
        id="stickyNavBar"
      >
        {[
          ...projectTypes.map(type => {
            return (
              <NavItem
                key={type.id}
                onClick={ e => {
                  // const path = `${location.pathname}?tab=${type.id}`
                  const basePath = "carbon-projects/sectoral-scopes" // assuming the base path is the first segment
                  dispatch(setSeoTitle(type.seo_title || type.name))
                  const path = `/${basePath}/${type.name
                    .replaceAll(" ", "-")
                    .toLowerCase()}`
                  history.push(path)
                  dispatch(setSearchPalylod({ 
                    projectTitle: searchPayload.projectTitle || "",
                    minCarbonCredits: searchPayload.minCarbonCredits || null,
                    maxCarbonCredits: searchPayload.maxCarbonCredits || null,
                    country: searchPayload.country || [],
                    projectTypes: searchPayload.projectTypes || [],
                    sectoralScopes: type.id === 1 ? [] : [type.id],
                    registryStatus: searchPayload.registryStatus || [],
                    region: searchPayload.region || [],
                    registry: searchPayload.registry || [],
                    extraSeals: searchPayload.extraSeals || [],
                    startCreditingPeriod: searchPayload.startCreditingPeriod || null,
                    endCreditingPeriod: searchPayload.endCreditingPeriod || null,
                    projectDeveloper: searchPayload.projectDeveloper || "",
                    projectStatus: searchPayload.projectStatus || [],
                  }))
                  dispatch(setCurrentTab(type.id))
                }}
                className="bg-white"
                style={{ cursor: "pointer" }}
              >
                <Helmet>
                  <title>{type.seo_title || type.name}</title>
                </Helmet>
                <NavLink active={isActive(type)} as="div">
                  <i className={"fas fs-4 " + type.icon}></i>
                  <div
                    className="d-none d-md-block"
                    style={{
                      fontSize: "0.8rem",
                      fontWeight: 600,
                    }}
                  >
                    {type.name}
                  </div>
                </NavLink>
              </NavItem>
            )
          }),
          <NavItem>{children}</NavItem>,
        ]}
      </Nav>
      {/* alert banner */}
      {notification && (
        <AlertsBanner
          title={notification.title}
          timestamp={notification.timestamp}
          destination={notification.destination}
          onClose={() => {
            dispatch(markAsRead(notification.id))
          }}
        />
      )}
    </div>
  )
}
