import React, { useState } from "react"
import { Helmet } from "react-helmet"
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap"
import { Link } from "react-router-dom"
import Select from "react-select"
import useDevice from "helpers/useDevice"

const chartItems = [
  {
    name: "Supply by Project Type",
    url: "https://app.powerbi.com/view?r=eyJrIjoiZTJhMGMyYjctOGJjNi00ZDYxLTg5MzAtZDgyYmQ5MWVhZTQzIiwidCI6IjEzYzE5YWZmLWE3ZGYtNDFiNS04YzEyLTYwM2QwOTM0OTdiZSIsImMiOjEwfQ%3D%3D",
  },
  {
    name: "Retirement by Project Type",
    url: "https://app.powerbi.com/view?r=eyJrIjoiOTlhY2Q4ZDEtNTMzNC00N2IwLWIyMTUtYzA2YmQwMTcwYmFmIiwidCI6IjEzYzE5YWZmLWE3ZGYtNDFiNS04YzEyLTYwM2QwOTM0OTdiZSIsImMiOjEwfQ%3D%3D",
  },
  {
    name: "Supply by Developers",
    url: "https://app.powerbi.com/view?r=eyJrIjoiZTgxNjAxZjktNmY2Zi00MGFmLTkyN2EtZDQwNjgyZTMyMTVlIiwidCI6IjEzYzE5YWZmLWE3ZGYtNDFiNS04YzEyLTYwM2QwOTM0OTdiZSIsImMiOjEwfQ%3D%3D",
  },
  {
    name: "Project per Status and Categories",
    url: "https://app.powerbi.com/view?r=eyJrIjoiNjA0MjI1NjktM2MxZi00ZjgwLTk0ZjktYTViNTQ4OTNhODBhIiwidCI6IjEzYzE5YWZmLWE3ZGYtNDFiNS04YzEyLTYwM2QwOTM0OTdiZSIsImMiOjEwfQ%3D%3D",
  },
  // {
  //   name: "Retirement by Project Category",
  //   url: "https://app.powerbi.com/view?r=eyJrIjoiOTlhY2Q4ZDEtNTMzNC00N2IwLWIyMTUtYzA2YmQwMTcwYmFmIiwidCI6IjEzYzE5YWZmLWE3ZGYtNDFiNS04YzEyLTYwM2QwOTM0OTdiZSIsImMiOjEwfQ%3D%3D"
  // },
  {
    name: "Issuance by Registry",
    url: "https://app.powerbi.com/view?r=eyJrIjoiNGY0YjQwMWYtYmM3Mi00NTFjLTk4NDAtZTJlMDY2NjYyNDQ1IiwidCI6IjEzYzE5YWZmLWE3ZGYtNDFiNS04YzEyLTYwM2QwOTM0OTdiZSIsImMiOjEwfQ%3D%3D",
  },
]

function Analytics() {
  const [selectedURL, setSelectedURL] = useState(chartItems[0].url)

  const {isMobile} = useDevice();

  return (
    <React.Fragment>
      <Helmet>
        <title>Analytics | CarbonHub</title>
      </Helmet>
      <div
        style={{
          backgroundColor: "#28516A",
          height: "60px",
          marginTop: "55px",
        }}
        className="d-flex align-self-center align-items-center ps-4"
      >
        <div
          style={{
            color: "#fff",
            fontSize: "19px",
            fontWeight: 700,
          }}
        >
          Analytics
        </div>
      </div>
      <Container fluid className="bg-white">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={`/`}>Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={"/carbon-projects"}>Projects</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Analytics</BreadcrumbItem>
        </Breadcrumb>
      </Container>
      <Container
        fluid={isMobile? false:true}
        style={{ minHeight: "80vh", paddingBottom: "40px" }}
        className="bg-white rounded-3 mb-5 pb-md-0 pt-3 px-3"
      >
        <Select
          options={chartItems.map(item => ({
            value: item.url,
            label: item.name,
            id: item.url,
          }))}
          onChange={item => {
            setSelectedURL(item.id)
          }}
          className="mb-3 d-block d-md-none"
        />
        <Row>
          <Col md={4} lg={3} xs={12} className="d-none d-md-flex">
            <Card className="shadow-0 border mb-0" style={{ boxShadow: "none" }}>
              <CardBody>
                {chartItems.map(item => {
                  return (
                    <div
                      style={{
                        background:
                          selectedURL === item.url ? "#DDE6EB91" : "white",
                        cursor: "pointer",
                      }}
                      className="rounded rounded-3 mb-2 p-3"
                      key={item.url}
                      onClick={() => {
                        setSelectedURL(item.url)
                      }}
                    >
                      {item.name}
                    </div>
                  )
                })}
              </CardBody>
            </Card>
          </Col>
          <Col md={8} lg={9} xs={12} className="text-center">
            <iframe
              src={selectedURL}
              allowFullScreen={true}
              className="w-100 m-0 p-0 mb-5 mb-md-0"
              style={{ height: "calc(100vh - 300px)"}}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default Analytics
