import React, { useState } from "react"
import _ from "lodash"

const ExpandableText = ({ text = "", length = 0 }) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <p style={{ fontSize: 16, textAlign: "justify" }}>
      {expanded ? text.trim() : _.truncate(text, { length: length }).trim()}
      {text.length > length ? (
        <a className="ms-2" style={{ color: "#5989a7", fontSize: 14 }}
           onClick={() => {
             setExpanded(!expanded)
           }}
        >
          {expanded ? "Show less" : "Show more"}
        </a>
      ) : null}
    </p>
  )
}

export default ExpandableText
