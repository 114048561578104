import React from "react"
import { Col, Row } from "reactstrap"

const LoadingShimmer = () => {
  return <Row className="py-5 row-cols-1 row-cols-md-2">
    <Col>
      <img alt="Shimmer" className="img-fluid" src="/shimmer-1.svg" />
    </Col>
    <Col>
      <img alt="Shimmer" className="img-fluid" src="/shimmer-2.svg" />
    </Col>
  </Row>
}

export default LoadingShimmer