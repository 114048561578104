import api from "./api";

const { createAsyncThunk } = require("@reduxjs/toolkit");

  export const postContactUs = createAsyncThunk(
    '/email/contactUs',
    async (payload) => {
      try {
        const response = await api.post(
          '/email/contactUs',
          payload,
          {
            headers: {
              'Content-type': 'application/json; charset=UTF-8'
            }
          }
        );
        const data = response.data
        if (data['error']) {
          throw new Error(data['payload'])
        }
        return data['payload']
      } catch (error) {
        throw error
      }
    }
  )

  


