/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-catch */
import _ from "lodash"
import api from "./api"

import { createAsyncThunk } from "@reduxjs/toolkit"

export const getAllProjects = createAsyncThunk(
  "/publish/getProjectDataGrid/",
  async payload => {
    try {
      const token = localStorage.getItem("authToken") // Assuming you store the token in your Redux state
      const response = await api.get(
        `/publish/getProjectDataGrid/${payload.secId}?page=${payload.page}&pageSize=12`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const data = response.data
      if (_.get(data, "error", true)) {
        throw new Error("Something went wrong!")
      }
      const projects = _.get(data, "payload.projectData", [])
      return projects
      // if (data.error) {
      //   throw new Error(data.payload)
      // }
      // return data.payload.projectData
    } catch (error) {
      throw error
    }
  }
)

export const getAllTableProjects = createAsyncThunk(
  "/publish/getProjectDataTable/",
  async ({ sec_id, currentPage }, { getState }) => {
    try {
      const token = localStorage.getItem("authToken") // Assuming you store the token in your Redux state
      const response = await api.get(
        `/publish/getProjectDataTable/${sec_id}?page=${currentPage}&pageSize=12`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const data = response.data
      const projects = _.get(data, "projectData", [])
      return projects
    } catch (error) {
      throw error
    }
  }
)

export const getProjectMetrics = createAsyncThunk(
  "/publish/getMetricsWeb",
  async (_, { getState }) => {
    try {
      const token = localStorage.getItem("authToken") // Assuming you store the token in your Redux state
      const response = await api.get("/publish/getMetricsWeb", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      const data = response.data
      if (data.error) {
        throw new Error(data.payload)
      }
      return data.payload
    } catch (error) {
      throw error
    }
  }
)

export const getProjectByCSPID = createAsyncThunk(
  "/publish/detailViewPageByCSPID/",
  async (id, { getState }) => {
    // try {
    //   const token = localStorage.getItem("authToken")
    //   const response = await api.get(`/publish/detailViewPageByCSPID/${id}`, {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   })
    //   const data = response.data
    //   if (data.error) {
    //     throw new Error(data.payload)
    //   }
    //   // console.log("payload: ", data.payload)
    //   return data.payload
    // } catch (error) {
    //   throw error
    // }
  }
)

export const getDropdownDataApi = createAsyncThunk(
  "/dropdown/getDropdownData",
  async (path, filter) => {
    try {
      let excludedPaths = [
        "/contactus",
        "/helpcenter",
        "/aboutus",
        "/conditions",
        "/disclaimer",
        "/policy",
        "/profile",
        "/home",
      ]
      let projectPathRegex = /^\/project\//
      let isProjectPath = projectPathRegex.test(path)
      let payload = {
        dropdownData: [],
        projectType: [],
        sectoralScope: [],
        registryStaus: [],
      }
      // Regular dropdown data
      let response = await api.get(
        "/dropdown/getAllCountriesAndRegionsAndRegistry"
      )
      let data = response.data
      if (data["error"]) {
        throw new Error(data["payload"])
      }
      payload["dropdownData"] = data["payload"]

      // Project Types data
      response = await api.get("/dropdown/getAllProjectTypes")
      data = response.data
      if (data["error"]) {
        throw new Error(data["payload"])
      }
      payload["projectType"] = data["payload"]

      // sectoral Scope data
      if (!excludedPaths.includes(path) && !isProjectPath) {
        response = await api.get("/dropdown/getAllSectoralScopes")
        data = response.data
        if (data["error"]) {
          throw new Error(data["payload"])
        }
        payload["sectoralScopeData"] = data["payload"]
      }

      // registry Staus data
      if (!excludedPaths.includes(path) && !isProjectPath) {
        response = await api.get("/dropdown/getAllRegisterStatus")
        data = response.data
        if (data["error"]) {
          throw new Error(data["payload"])
        }
        payload["registryStausData"] = data["payload"]
      }

      // Seals data
      if (!excludedPaths.includes(path) && !isProjectPath) {
        response = await api.get("/dropdown/getSeals")
        data = response.data
        if (data["error"]) {
          throw new Error(data["payload"])
        }
        payload["sealsData"] = data["payload"]
      }

      return payload
    } catch (error) {
      throw error
    }
  }
)
