import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  Row,
  Col,
  Card,
  Form,
  Nav,
  NavItem,
  NavLink,
  CardBody,
  CardTitle,
  CardText,
  Container,
  Table,
  TabContent,
  TabPane,
  Button,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

//Import Breadcrumb
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"

const CarbonVersioning = () => {
    
    

    return (
        <React.Fragment>
          <div className="">
            <Container fluid={true}>
             <div className="dollar_industry">
                    <h5>How fighting climate change became a billion-dollar industry?</h5>
                    
                    <p>As governments pressure the private sector to limit greenhouse gas emissions, the world’s largest companies have turned to a financial product to offset their environmental footprints — carbon credits. <br/><br/>
                    It’s a hot market, hitting all-time highs <a href="https://www.ecosystemmarketplace.com/articles/press-release-voluntary-carbon-markets-rocket-in-2021-on-track-to-break-1b-for-first-time/">in volume</a> and on track to be worth $1 billion in 2021, according to Ecosystem Marketplace, a market publication run by the environmental finance research nonprofit Forest Trends. And just ahead of the United Nations Climate Change Conference starting Sunday, the U.N. Environment Programme <a href="https://www.unep.org/resources/emissions-gap-report-2021">issued a report </a> 
                     that said carbon markets could “help slash emissions” with clearly defined rules and transparency.<br/><br/>
                    But why are carbon credits important? And why does it matter whether they’re used or not?
                    </p><br/>
                    
                    </div>

                    <div className="credit_carbon">
                    <h5>What is a carbon credit?</h5>
                    <p>A carbon credit is a kind of permit that represents  1 ton of carbon dioxide removed from the atmosphere. They can be purchased by an individual or, more commonly, a company to make up for carbon dioxide emissions that come from industrial production, delivery vehicles or travel.<br/><br/>
        
                    Carbon credits are most often created through agricultural or forestry practices, although a credit can be made by nearly any project that reduces, avoids, destroys or captures emissions. Individuals or companies looking to offset their own greenhouse gas emissions can buy those credits through a middleman or those directly capturing the carbon. In the case of a farmer that plants trees, the landowner gets money; the corporation pays to offset their emissions; and the middleman, if there is one, can earn a profit along the way.<br/><br/>
                    
                    But this only goes for what is called the “voluntary market.” There is also something called the involuntary or “compliance market.”
                    </p>
                    </div>

                    <div className="compliance_market">
                    <h5>What is the “compliance market” for carbon credits?</h5>
                    <p>In the compliance market, or involuntary market, governments set a cap on how many tons of emissions certain sectors — oil, transportation, energy or waste management — can release. <br/><br/>
                    If an oil company, for example, goes over the prescribed emissions limit,  it must buy or use saved credits to stay under the emissions cap. If a company stays under that cap,  it can save or sell those credits. This is known as a <a href="https://www.edf.org/climate/how-cap-and-trade-works">cap-and-trade market</a>. The cap is the amount of greenhouse  gases a government will allow to be released into the atmosphere and emitters must trade to stay within that limit. <br/><br/>
                    Article 6 of the 2015 Paris  Agreement tasks national leaders with figuring this out on a global scale. So far, about 64 carbon compliance markets are now in operation around the world, the World Bank <a href="https://www.worldbank.org/en/news/press-release/2021/05/25/carbon-prices-now-apply-to-over-a-fifth-of-global-greenhouse-gases">reported</a> in May. The largest carbon compliance markets are in the European Union, China, Australia and Canada.<br/><br/> 
                    While politicians and business executives have discussed putting a <a href="https://www.scientificamerican.com/article/experts-clash-over-cost-of-carbon/">price</a> on carbon, the U.S. does not have a federal, wide-ranging cap-and-trade market for greenhouse gases. <br/><br/>
                    
                    Regulators, businesses and environmentalists have debated globalizing a cap-and-trade market for carbon. But it is challenging to agree on a common  time frame, common price, common measurement and transparency, said Alok Sharma, president of this year’s United Nations Climate Change Conference, also called COP26.
                    </p>
                    </div>

                    <div className="credit_market">
                    <h5>How big is the carbon credit market?</h5>
                    <p>The voluntary market is on track to reach a record of $6.7 billion at the end of 2021, <a href="https://www.ecosystemmarketplace.com/articles/press-release-voluntary-carbon-markets-rocket-in-2021-on-track-to-break-1b-for-first-time/">according</a> to a September report from Ecosystem Marketplace. Currently, traders in the European compliance market project carbon prices to increase 88 percent to about $67 per metric ton by 2030, <a href="https://www.ieta.org/Annual-GHG-Market-Sentiment-Survey">according</a> to a survey released in June by the International Emissions Trading Association. <br/><br/>
                    
                    The voluntary market’s rapid acceleration over the course of the year is largely driven by recent corporate net-zero goals and interest in meeting international climate goals set out in the Paris Agreement to limit global warming to 1.5 degrees Celsius over  preindustrial levels.
                    </p>
                    </div>

                    <div className="pushback">
                    <h5>What is the pushback?</h5>
                    <p>Critics of the voluntary market, where a company buys carbon credits from a business outside of a regulated exchange, point out that this does not lower the overall amount of greenhouse  gases released by buyers. They are simply offset, which gives corporations a way to claim they are eco-friendly without reducing their overall emissions. Critics call this “greenwashing.”<br/><br/>
                    Carbon credits can also be bought from projects that would have happened anyway. For instance, one investment company says they pay farmers to convert their fields into forests and sell those credits to corporations, <a href="https://www.bloomberg.com/news/features/2020-12-17/the-real-trees-delivering-fake-climate-progress-for-corporate-america?sref=7mwUMHq1">according</a> to Bloomberg. But several farmers claim they already planted trees through a government conservation program.<br/><br/>
                    
                    Also, some of these carbon credits through these projects are not permanent. For instance, the international soccer governing body FIFA bought credits to help offset emissions from the World Cup in Brazil. But soon after, the trees were cut down. The project was <a href="https://features.propublica.org/brazil-carbon-offsets/inconvenient-truth-carbon-credits-dont-work-deforestation-redd-acre-cambodia/">suspended</a> in 2018 after more trees were logged than all the credits sold.
                    </p>
                    </div>

                    <div className="reg_oversight">
                    <h5>What regulations or oversight does this market have?</h5>
                    <p>The voluntary market operates largely unchecked by federal or local regulators. <br/>
                    Because the voluntary market does not have a cap on how many tons of emissions can be offset, the driving oversight is a set of standards. There are a few respected standards organizations that validate carbon credits. <br/><br/>
                    Verra, a Washington, D.C.-based  nonprofit group founded in 2007 by environmental and business leaders to  improve quality assurance in voluntary carbon markets, has set the most widely used standard to validate those credits, called the Verified Carbon Standard. Since the organization’s launch,  it has registered 1,750 projects around the world and verified almost 796 million carbon units.<br/><br/>
                    The three main things that make up the Verra Carbon Standard are: accounting methodologies specific to the project type, independent auditing and a registry system. This is to “make sure that both the buyer has confidence that they’re buying something that is actually legit, and that the sellers themselves have something valuable,” Verra CEO David Antonioli told NBC News.<br/><br/>
                    Still the firm supports accountability in the market space,  he said.<br/><br/>
                    
                    “[If the voluntary market] is going to be effective at helping achieve the targets of the Paris Agreement, it is going to have to complement … either government action, or individual, or company internal reductions,” Antonioli said. “We want actual solutions here. And if someone’s just offsetting, that’s no good … we don’t support that.”<br/><br/>
                    </p>
                    </div>

                    <div className="government">
                    <h5>What is the U.S. government doing about carbon credits?</h5>
                    <p>The  U.S. Department of Agriculture has not adopted or set  its own standards for carbon credits. But it does finance carbon capturing projects and publishes data to help agricultural businesses capitalize on the market. <br/><br/>
                    “We need to scale up … with the recognition that there’s going to be a lot of private investment,” said Robert Bonnie, the senior climate  adviser to the USDA secretary. “We don’t want to displace that investment. We want to, in essence, sort of encourage it to come in.”<br/><br/>
                    The USDA recently jump-started federal carbon credit regulation with a proposed <a href="https://www.usda.gov/media/press-releases/2021/09/29/usda-announces-3-billion-investment-agriculture-animal-health-and">climate partnership initiative</a> , which would fund conservation projects on working land and quantify the carbon and sustainability benefits that come as a result of those projects. <br/><br/>
                    The <a href="https://www.agriculture.senate.gov/newsroom/dem/press/release/growing-climate-solutions-act-passes-us-senate">Growing Solutions Act</a>, which is waiting to be heard in the House, would help farmers, ranchers and foresters learn about carbon markets and sell carbon credits through a third-party certification process overseen by the USDA. <br/><br/>
                    The Environmental Protection Agency currently runs an acid rain program, which cuts emissions of sulfur dioxide by setting a similar cap-and-trade program. Under this program, emitters of sulfur dioxide can sell or save excess sulfur dioxide permits if they reduce emissions and have more than they need, or buy permits if they are unable to keep emissions below the determined level.
                    </p>
                    </div>

                    <h5>Are states creating any kind of market for carbon trading?</h5>
                    <p>California is the only state with a <a href="https://ww2.arb.ca.gov/sites/default/files/cap-and-trade/guidance/cap_trade_overview.pdf">state cap-and-trade market</a> for carbon. By 2030, the state <a href="https://www.c2es.org/content/california-cap-and-trade/">aims</a> to lower emissions to 40 percent below 1990 levels. About 450 entities targeted by the market must deliver an overall 15 percent reduction in greenhouse gas emissions compared to the ”business-as-usual” scenario in 2020. Companies covered by the state law can purchase a certain percentage of carbon credits to stay under the emissions cap. California carbon credits are expected to increase by about 66 percent to $41 by 2030, <a href="https://www.ieta.org/Annual-GHG-Market-Sentiment-Survey">according</a> to the International Emissions Trading Association.<br/><br/>
                    Aside from California, Oregon considered a bill this year that would limit emissions from regulated sectors to reach a 45 percent reduction from 1990 levels by 2035, and an 80 percent reduction below 1990 levels by 2050.<br/><br/>
                    Washington recently passed a law this year that puts a limit on the amount of greenhouse gases that can be emitted and then auction off allowances to certain highly pollutive sectors until that cap is reached. The state’s goal is to reduce emissions by 95 percent below 1990 levels by 2050. Each year until then, the cap will be reduced allowing total emissions to fall. The program’s first compliance period will begin in 2023.
                    </p>
                    <p><strong>Source:</strong></p>
                    <a href="https://www.nbcnews.com/business/business-news/are-carbon-credits-fighting-climate-change-became-billion-dollar-indus-rcna3228">https://www.nbcnews.com/business/business-news/are-carbon-credits-fighting-climate-change-became-billion-dollar-indus-rcna3228</a><br/><br/>

                    
                  
            </Container>
          </div>
        </React.Fragment>
      );
    }

export default CarbonVersioning
