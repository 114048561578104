import PropTypes from "prop-types"
import React from "react"
import { Alert, Card, CardBody, Col, Container, Form, FormFeedback, Input, Row } from "reactstrap"

//redux
import { useDispatch, useSelector } from "react-redux"

import { Link, withRouter } from "react-router-dom"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { userForgetPassword } from "../../store/actions"


const ForgetPasswordPage = props => {
  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: ""
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email")
    }),
    onSubmit: (values) => {
      dispatch(userForgetPassword(values, props.history))
    }
  })

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg
  }))

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={9} lg={6} xl={5}>
              <Card className="overflow-hidden">

                <CardBody className="pt-0">
                  <div>
                    <Col>
                      <div className="modal-header">
                        <h5 className="modal-title mt-0">Change password</h5>
                        <button
                          onClick={() => {
                            // setmodal_view_screen(false)
                            // clearFilterStates()
                          }}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    </Col>
                  </div>
                  <div>
                    <div className="modal-body">
                      <h4>Forgot Password?</h4>
                      <h6>Enter the email address associated with your account, and we'll email you a link to reset your
                        password.</h6>
                    </div>
                  </div>

                  <div className="p-2">
                    {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <div className="mb-3">
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Email*"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            !!(validation.touched.email && validation.errors.email)
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="send-btn"
                            type="submit"
                          >
                            Send Reset Link
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object
}

export default withRouter(ForgetPasswordPage)
