import React, { useEffect, useState, useRef } from "react"
import L from "leaflet"
import { addressPoints } from "./realworld" // Make sure this points to your data file
import MarkerClusterGroup from "react-leaflet-cluster"
import "leaflet/dist/leaflet.css"
import { divIcon, DomEvent, LatLng } from "leaflet"
import _ from "lodash"
import ReactDOMRender from "react-dom/server"
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  Tooltip,
  useMap,
  useMapEvents,
} from "react-leaflet"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import {
  setCountryMarkers,
  setCurrentCenter,
  setCurrentView,
  setCurrentZoom,
  setError,
  setLoading,
  setPayload,
  setProjectMarkers,
  setProjectType,
  setRegionMarkers,
  setVisibleCountryMarkers,
  setVisibleProjectMarkers,
} from "store/RTK-Store/mapSlice"
import { setSearchPalylod } from "store/RTK-Store/projectsSlice"
import { useGetMapDataMutation } from "store/RTK-Store/services/projectApi"
import ProjectCountIndicator from "../ProjectCountIndicator"
import { Helmet } from "react-helmet"

import { InfinitySpin } from "react-loader-spinner"
import { Col, Row } from "reactstrap"
import MapLegend from "../MapLegend"
import Energy from "../../../assets/images/landing/energy.svg"
import Green from "../../../assets/images/landing/green.svg"
import Household from "../../../assets/images/landing/household_pin.svg"
import Industrial from "../../../assets/images/landing/industrial_pin.svg"
// import Multiple from "../../assets/images/landing/multiplesectoral.svg"
import Multiple from "../../../assets/images/multiple_sectoral.svg"

import NatureBased from "../../../assets/images/landing/naturebased_pin.svg"
import LightBlue from "../../../assets/images/landing/lightblue.svg"
import Other from "../../../assets/images/landing/other_pin.svg"
import Transport from "../../../assets/images/landing/transport_pin.svg"
import WasteMng from "../../../assets/images/landing/wastemgt_pin.svg"
import DarkBlue from "../../../assets/images/landing/darkblue.svg"

import "leaflet/dist/leaflet.css"

import MapCard from "../MapCard"
import MapToolTip from "../MapTooltip"
import useDevice from "helpers/useDevice"

import transformPayload from "../payloadTransform"

const customIcon = new L.Icon({
  iconUrl: require("./location.svg").default,
  iconSize: new L.Point(40, 47),
})

const createClusterCustomIcon = function (cluster) {
  return L.divIcon({
    html: `<span>${cluster.getChildCount()}</span>`,
    className: "custom-marker-cluster",
    iconSize: L.point(33, 33, true),
  })
}

const REGION_MAX = 3,
  COUNTRY_MAX = 5

function MapController({ onZoomChange, setTitle, setMetaDesc }) {
  const dispatch = useDispatch()
  const map = useMap()
  const { isMobile } = useDevice()
  const debounceTimeoutRef = useRef(null);
  const payload = useSelector(state => state.projects.searchPalylod)
  const projectType = useSelector(state => state.projects.currentTab)
  const allProjects = useSelector(state => state.map.projectMarkers)

  const allCountries = useSelector(state => state.map.countryMarkers)
  const view = useSelector(state => state.map.currentView)
  const projectView = useSelector(state => state.projects.projectView)
  const dropdownValues = useSelector(state => state.projects.dropdownData)
  const projectType_table = useSelector(
    state => state.projects.projectsTypeData
  )
  const registryStausData = useSelector(
    state => state.projects.registryStausData
  )
  const dropdownLoading = useSelector(state => state.projects.loading)
  const {
    certified,
    sectoral_scope,
    country,
    project_types,
    developer,
    region,
    status,
    seal,
  } = useParams()
  const extraSealsData = useSelector(state => state.projects.sealsData)
  const searchPayload = useSelector(state => state.projects.searchPalylod)

  // const debouncedHandleBounds = _.debounce(() => {
  //   console.log("debounced");

  //   const bounds = map.getBounds()

  //   if (view === "projects") {
  //     const visible = _.defaultTo(allProjects, []).filter(project => {
  //       const loc = new LatLng(project.lat, project.long)
  //       return bounds.contains(loc)
  //     })
  //     dispatch(setVisibleProjectMarkers(visible))
  //   } else if (view === "country") {
  //     const visible = _.defaultTo(allCountries, [])
  //       .filter(country => country.coordinates.length)
  //       .filter(country => {
  //         const coords = country.coordinates[0]
  //         const loc = new LatLng(coords.latitude, coords.longitude)
  //         return bounds.contains(loc)
  //       })
  //     dispatch(setVisibleCountryMarkers(visible))
  //   }
  // }, 1000)

  const debouncedHandleBounds = () => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(() => {
      handleBounds();
      debounceTimeoutRef.current = null;
    }, 500);
  };


  const handleBounds = () => {
    let bounds = map.getBounds()
    if (view === "projects") {
      const visible = _.defaultTo(allProjects, []).filter(project => {
        const loc = new LatLng(project.lat, project.long)
        return bounds.contains(loc)
      })
      dispatch(setVisibleProjectMarkers(visible))
    } else if (view === "country") {
      const visible = _.defaultTo(allCountries, [])
        .filter(country => country.coordinates.length)
        .filter(country => {
          const coords = country.coordinates[0]
          const loc = new LatLng(coords.latitude, coords.longitude)
          return bounds.contains(loc)
        })
      dispatch(setVisibleCountryMarkers(visible))
    }
  }

  useEffect(() => {
    handleBounds()
  }, [view])
  const handleZoom = zoom => {
    if (zoom <= REGION_MAX) {
      dispatch(setCurrentView("region"))
    } else if (zoom > REGION_MAX && zoom <= COUNTRY_MAX) {
      dispatch(setCurrentView("country"))
    } else {
      dispatch(setCurrentView("projects"))
    }
  }
  useMapEvents({
    zoomend: () => {
      const zoom = map.getZoom()
      handleZoom(zoom)
      dispatch(setCurrentZoom(zoom))
    },
    dragend: () => {
      debouncedHandleBounds()
      const center = map.getCenter()
      dispatch(setCurrentCenter([center.lat, center.lng]))
    },
  })

  const [getProjects, { isLoading: isProjectsLoading }] =
    useGetMapDataMutation()
  const existingPayload = useSelector(state => state.map.existingPayload)
  const currentType = useSelector(state => state.map.existingType)

  function capitalizeEachWord(str) {
    // Split the string into an array of words
    const words = str.split(" ")

    // Iterate through each word and capitalize the first letter
    const capitalizedWords = words.map(word => {
      let lowerCaseWord = word.toLowerCase()
      // Capitalize the first letter of each word
      return lowerCaseWord.charAt(0).toUpperCase() + lowerCaseWord.slice(1)
    })

    // Join the capitalized words back into a single string
    return capitalizedWords.join(" ")
  }

  const projectTypes = [
    {
      name: "All",
      icon: "fa-grip-horizontal",
      id: 1,
    },
    {
      icon: "fa-tree",
      name: "Nature Based",
      id: 2,
    },
    {
      icon: "fa-cloud-sun-rain",
      name: "Energy",
      id: 3,
    },
    {
      icon: "fa-home",
      name: "Household",
      id: 4,
    },
    {
      icon: "fa-industry",
      name: "Industrial",
      id: 5,
    },
    {
      icon: "fa-trash-restore",
      name: "Waste Management",
      id: 6,
    },
    {
      icon: "fa-train",
      name: "Transport",
      id: 7,
    },
    {
      icon: "fa-poll-h",
      name: "Other",
      id: 8,
    },
  ]
  // // generate Random Number Up to Five Decimal places
  // const generateRandomNumber = () => {
  //   return Math.random().toFixed(5)
  // }
  // // // Bug fix for changin view when pages are different between grid and table
  useEffect(() => {
    let sectoralPayload = payload?.sectoralScopes
    let countryPayload = payload?.country
    let regionPayload = payload?.region
    let projectTypePayload = payload?.projectTypes
    let registryPayload = payload?.registry
    let statusPayload = payload?.registryStatus
    let projectTitle = payload?.projectTitle
    let maxCarbonCreditsPayload = payload?.maxCarbonCredits
    let minCarbonCreditsPayload = payload?.minCarbonCredits
    let endPeriodPayload = payload?.endCreditingPeriod
    let startCreditingPeriodPayload = payload?.startCreditingPeriod
    // let projectStatusPayload = searchPayload?.projectStatus;
    let developerPayload = payload?.projectDeveloper
    let extraSeals = payload?.extraSeals
    let tempPayload = payload

    if (
      (seal ||
        certified ||
        sectoral_scope ||
        country ||
        project_types ||
        developer ||
        region ||
        status) &&
      projectType_table?.length != 0
    ) {
      // console.log('triggered');
      let payload = {
        projectTitle: tempPayload?.projectTitle || "",
        minCarbonCredits: tempPayload?.minCarbonCredits || null,
        maxCarbonCredits: tempPayload?.maxCarbonCredits || null,
        country: tempPayload?.country || [],
        projectTypes: tempPayload?.projectTypes || [],
        sectoralScopes: tempPayload?.sectoralScopes || [],
        registryStatus: tempPayload?.registryStatus || [],
        region: tempPayload?.region || [],
        registry: tempPayload?.registry || [],
        extraSeals: tempPayload.extraSeals || [],
        startCreditingPeriod: tempPayload?.startCreditingPeriod || null,
        endCreditingPeriod: tempPayload?.endCreditingPeriod || null,
        projectDeveloper: tempPayload?.projectDeveloper || "",
        projectStatus: tempPayload?.projectStatus || [],
      }
      let title = ""
      let metaDescStr = ""
      if (dropdownValues && country) {
        const con = country.replaceAll("-", " ").toLowerCase()
        title = capitalizeEachWord(con)
        const countryId = dropdownValues.host_coun.find(
          item => item.label.replaceAll("-", " ").toLowerCase() === con
        )
        if (!countryId) {
          history.push("/404")
        }
        metaDescStr = `Explore the top carbon projects in ${title} on CarbonHub. From renewable energy to conservation, discover how each project is contributing to the country’s carbon reduction and environmental sustainability efforts.`
        payload.country = countryId?.id.toString() ?? ""
      }
      if (projectType_table && project_types) {
        const project_type = project_types.replaceAll("-", " ")
        title = capitalizeEachWord(project_type)
        const typeID = projectType_table.find(
          item =>
            item.label
              .replaceAll("-", " ")
              .replaceAll("/", " ")
              .replace(/\s+/g, " ")
              .toLowerCase() === title.toLowerCase()
        )
        if (!typeID) {
          history.push("/404")
        }
        payload.projectTypes = typeID?.id.toString() ?? ""
        metaDescStr = `Find the best ${title} projects on CarbonHub. Each project contributes to global sustainability goals through innovative and effective carbon reduction and sequestration practices.`
      }
      if (dropdownValues && region) {
        const region_title = region.replaceAll("-", " ")
        title = capitalizeEachWord(region_title)
        const typeID = dropdownValues?.regi?.find(
          item =>
            item.label.replaceAll("-", " ").toLowerCase() ===
            title.toLowerCase()
        )
        if (!typeID) {
          history.push("/404")
        }
        payload.region = typeID?.id.toString() ?? ""
        metaDescStr = `Explore the top carbon credit projects in ${title} on CarbonHub. Find impactful initiatives in ${title} that are driving progress in sustainability and offering valuable carbon reduction solutions.`
      }
      if (registryStausData && status) {
        const status_title = status.replaceAll("-", " ")
        title = capitalizeEachWord(status_title)
        const typeID = registryStausData?.find(
          item =>
            item.label.replaceAll("-", " ").toLowerCase() ===
            title.toLowerCase()
        )
        if (!typeID) {
          history.push("/404")
        }
        payload.registryStatus = typeID?.id.toString() ?? ""
      }
      if (developer) {
        const project_type = developer.replaceAll("-", " ")
        title = developer
        payload.projectDeveloper = title ?? ""
        metaDescStr = `Discover a range of ${title} carbon projects at CarbonHub. Delve into projects that are shaping the future of sustainability with innovative strategies for reducing carbon emissions.`
      }
      if (projectTypes && sectoral_scope) {
        setLoading(true)
        const sectoralScope = sectoral_scope.replaceAll("-", " ").toLowerCase()
        // Keyword map
        const keywordMap = {
          "innovative carbon projects": "other",
          "sustainable transport projects": "transport",
          "waste management solutions": "waste management",
          "household carbon projects": "household",
          "nature based projects": "nature based",
          "industrial carbon solutions": "industrial",
          "renewable energy projects": "energy",
        }

        metaDescStr = `
        Explore ${capitalizeEachWord(sectoralScope)}
        carbon
        projects
        to
        invest
        on
        CarbonHub.Discover
        initiatives
        committed
        to
        reducing
        emissions
        and
        advancing
        sustainability in the [Sector
        Name
      ]
        sector.`

        // Check if sectoralScope exists in the keywordMap
        const trueKeyWord = keywordMap[sectoralScope] ?? sectoralScope

        title = capitalizeEachWord(sectoralScope)
        const sectoralScopeId = projectTypes.find(
          item => item.name.toLowerCase() === trueKeyWord.toLowerCase()
        )
        if (!sectoralScopeId) {
          history.push("/404")
        }
        if (sectoralScopeId) {
          const id = sectoralScopeId.id
          if (id !== 1) {
            payload = {
              ...payload,
              sectoralScopes: `${id}`,
            }
          }
        }
      }
      if (dropdownValues && certified) {
        const registry = certified
          .replaceAll("-certified-projects", "")
          .replaceAll("-", " ")
        title = capitalizeEachWord(registry)
        title = `Comprehensive List of ${title} Certified Carbon Projects | CarbonHub`
        metaDescStr = `Join CarbonHub in advancing climate action through ${title} certified carbon projects. Explore a curated list spanning renewable energy, forestry, and beyond to support verified carbon offset and reduction initiatives worldwide.`
        const registryId = dropdownValues?.regi_str?.find(
          item => item.label.toLowerCase() === registry
        )
        if (!registryId) {
          history.push("/404")
        }
        if (tempPayload) {
          payload = { ...tempPayload }
        }
        payload = {
          ...payload,
          registry: registryId?.id ?? "",
        }
      }
      if (seal && extraSealsData) {
        const seal_title = seal.replaceAll("-", " ").toLowerCase().trim()
        title = capitalizeEachWord(seal_title)
        const sealId = extraSealsData?.find(
          item => item.label.toLowerCase() === seal_title
        )
        title = sealId?.label ?? "Certified Carbon Projects | CarbonHub"
        if (!sealId) {
          history.push("/404")
        }
        if (searchPayload) {
          payload = { ...searchPayload }
        }
        payload = {
          ...payload,
          extraSeals: sealId?.id ?? "",
        }
        metaDescStr = `Explore carbon projects on CarbonHub with the esteemed ${title} certification. These projects meet high standards for sustainability and carbon reduction, contributing to environmental excellence.`
      }
      dispatch(setSearchPalylod(payload))

      if (status) {
        let dateNow = new Date().getFullYear()
        let titleString = `
        Best
        Carbon
        Reduction: ${title} in ${dateNow}
      |
        CarbonHub`
        metaDescStr = `Discover a range of ${title} carbon projects at CarbonHub. Delve into projects that are shaping the future of sustainability with innovative strategies for reducing carbon emissions.`

        setTitle(titleString)
      } else if (sectoral_scope) {
        let dateNow = new Date().getFullYear()
        let titleWords = title.split(" ")

        if (
          titleWords[titleWords.length - 1] === "Projects" ||
          titleWords[titleWords.length - 1] === "Solutions"
        ) {
          titleWords.pop()
        }

        let newTitle = titleWords.join(" ")

        let titleString
        if (newTitle === "Other") {
          titleString = `
        Unique
        and
        Innovative
        Carbon
        Projects
        to
        Invest in ${dateNow} | Innovative
        Solutions
        CarbonHub`
        } else if (newTitle === "Nature Based") {
          titleString = `
        Best
        Top ${newTitle}
        Carbon
        Projects
        to
        Invest in ${dateNow} | ${newTitle} Solutions - CarbonHub`
        } else {
          titleString = `
        Best
        Top ${newTitle}
        Based
        Carbon
        Projects
        to
        Invest in ${dateNow} | ${newTitle} Solutions - CarbonHub`
        }
        setTitle(titleString)
      } else if (developer) {
        let titleString = `${title}
        Carbon
        Reduction
        Projects | CarbonHub`
        setTitle(titleString)
      } else if (project_types) {
        let dateNow = new Date().getFullYear()
        let titleString = `${title}
        Carbon
        Reduction
        Projects
        to
        Invest in ${dateNow} | CarbonHub`
        setTitle(titleString)
      } else if (region) {
        let dateNow = new Date().getFullYear()
        let titleString = `${title}
        Region
        Carbon
        Credit
        Projects
        To
        Invest in ${dateNow} | Leading
        Initiatives - CarbonHub`
        setTitle(titleString)
      } else if (country) {
        let dateNow = new Date().getFullYear()
        let titleString = `
        Best
        Carbon
        Reduction
        Projects
        to
        Invest in ${title} ${dateNow} | CarbonHub`
        setTitle(titleString)
      } else if (seal) {
        let titleString = `Certified ${title} Carbon Projects | CarbonHub`
        setTitle(titleString)
      } else {
        setTitle(title)
      }
      setMetaDesc(metaDescStr)
    } else {
      if (
        !sectoralPayload &&
        !countryPayload &&
        !regionPayload &&
        !projectTypePayload &&
        !registryPayload &&
        !statusPayload &&
        !developerPayload &&
        !projectTitle &&
        !extraSeals &&
        !maxCarbonCreditsPayload &&
        !minCarbonCreditsPayload &&
        !endPeriodPayload &&
        !startCreditingPeriodPayload
      ) {
        console.log("triggered")
        let payload = {
          projectTitle: "",
          minCarbonCredits: null,
          maxCarbonCredits: null,
          country: [],
          projectTypes: [],
          sectoralScopes: [],
          registryStatus: [],
          region: [],
          registry: [],
          extraSeals: [],
          startCreditingPeriod: null,
          endCreditingPeriod: null,
          projectDeveloper: "",
          projectStatus: [],
        }
        dispatch(setSearchPalylod(payload))
      }
    }
  }, [
    certified,
    dropdownValues,
    sectoral_scope,
    country,
    project_types,
    projectType,
  ])
  useEffect(() => {
    if (projectView == "map") {
      // console.log(payload, existingPayload);
      // if (payload && (JSON.stringify(existingPayload).length !== JSON.stringify(payload).length || projectType !== currentType)) {
      if (
        payload &&
        (!_.isEqual(existingPayload, payload) || projectType !== currentType)
      ) {
        dispatch(setPayload(payload))
        dispatch(setCurrentCenter([0, 0]))
        dispatch(setCurrentZoom(3))
        dispatch(setProjectType(projectType))
        let tempPayload = searchPayload
        const transformedPayload = transformPayload(tempPayload)
        getProjects({ type: projectType, payload: transformedPayload })
          .unwrap()
          .then(res => {
            if (res["error"]) {
              throw new Error(res["payload"])
            } else {
              const { CountByCountries, CountByRegions, Projects } =
                res["payload"]
              // Iterate through projects and add - 0.05 to +0.05 to lat and long of each project
              // to avoid overlapping of markers

              const tempProjects = Projects

              const adjustedProjects = tempProjects.map(project => {
                // const isGenericLocation = project?.isGenericLocation || false

                const isGenericLocation = _.get(
                  project,
                  "isGenericLocation",
                  false
                )
                if (isGenericLocation) {
                  try {
                    const adjustedProject = { ...project }
                    // adjustedProject.lat =
                    //   parseFloat(adjustedProject.lat) + generateRandomNumber()
                    // adjustedProject.long =
                    //   parseFloat(adjustedProject.long) + generateRandomNumber()
                    const newLat = parseFloat(adjustedProject.lat)
                    const newLon = parseFloat(adjustedProject.long)
                    if (!isNaN(newLat)) {
                      adjustedProject.lat = newLat
                    }
                    if (!isNaN(newLon)) {
                      adjustedProject.long = newLon
                    }

                    return adjustedProject
                  } catch (e) {
                    console.log(e)
                  }
                }
                return project
              })

              dispatch(setProjectMarkers(adjustedProjects))
              dispatch(setRegionMarkers(CountByRegions))
              dispatch(setCountryMarkers(CountByCountries))
              dispatch(setCurrentView("region"))
              map.setZoom(isMobile ? 1 : 3)
              map.setView([0, 0])
            }
          })
          .catch(error => {
            dispatch(setError(error.message))
          })
      }
    }
  }, [payload, projectType])
  useEffect(() => {
    dispatch(setLoading(isProjectsLoading))
  }, [isProjectsLoading])
  return null
}

function MapView() {
  const history = useHistory()
  const error = useSelector(state => state.map.error)
  const view = useSelector(state => state.map.currentView)
  const loading = useSelector(state => state.map.loading)
  const projectMarkers = useSelector(state => state.map.visibleProjectMarkers)
  const totalMarkers = useSelector(state => state.map.projectMarkers)
  const regionMarkers = useSelector(state => state.map.regionMarkers)
  const countryMarkers = useSelector(state => state.map.visibleCountryMarkers)
  const { isMobile } = useDevice()
  const mapRef = useRef(null)

  const getSectoralIcon = sectoral => {
    if (sectoral === 2) {
      return NatureBased
    } else if (sectoral === 3) {
      return Energy
    } else if (sectoral === 4) {
      return Household
    } else if (sectoral === 5) {
      return Industrial
    } else if (sectoral === 6) {
      return WasteMng
    } else if (sectoral === 7) {
      return Transport
    } else if (sectoral === 8) {
      return Other
    } else {
      return Multiple
    }
  }

  const icons = {
    natureBased: new L.icon({
      iconUrl: NatureBased,
      iconSize: new L.Point(40, 47),
    }),
    energy: new L.icon({
      iconUrl: Energy,
      iconSize: new L.Point(40, 47),
    }),
    household: new L.icon({
      iconUrl: Household,
      iconSize: new L.Point(40, 47),
    }),
    industrial: new L.icon({
      iconUrl: Industrial,
      iconSize: new L.Point(40, 47),
    }),
    wasteMng: new L.icon({
      iconUrl: WasteMng,
      iconSize: new L.Point(40, 47),
    }),
    transport: new L.icon({
      iconUrl: Transport,
      iconSize: new L.Point(40, 47),
    }),
    other: new L.icon({
      iconUrl: Other,
      iconSize: new L.Point(40, 47),
    }),
  }
  const [showPopup, setShowPopup] = useState(false)
  const [arrowLocationSide, setArrowLocationSide] = useState(false)
  const [arrowLocationPosition, setArrowLocationSidePosition] = useState(false)
  const [activePopup, setActivePopupID] = useState(null)
  const [location, setLocation] = useState(undefined)
  const [projectId, setProjectId] = useState("")
  const zoom = useSelector(state => state.map.zoom)
  const center = useSelector(state => state.map.currentCenter)
  const [title, setTitle] = useState(
    "Carbon Projects Database - Find Certified and Non-Certified Initiatives"
  )
  const [metaDesc, setMetaDesc] = useState(
    "Browse through the most comprehensive carbon project database. From renewable energy to forestry and industrial projects, find initiatives that align with your sustainability goals."
  )

  const legendItems = [
    {
      src: Green,
      alt: "Green",
      width: 32,
      height: 32,
      text: isMobile ? "0-10" : "0-10  projects",
    },
    {
      src: LightBlue,
      alt: "LightBlue",
      width: 32,
      height: 32,
      text: isMobile ? "10-100" : "10-100 projects",
    },
    {
      src: DarkBlue,
      alt: "DarkBlue",
      width: 32,
      height: 32,
      text: isMobile ? "100 +" : "100 and above projects",
    },
    {
      src: Multiple,
      alt: "Multiple",
      width: 25,
      height: 30,
      text: "Multiple Sectoral Scope",
    },
  ]

  return (
    <React.Fragment>
      <div
        className="position-relative"
        style={{ height: isMobile ? '71.5vh' : '77vh' }}
      >
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={metaDesc} />
        </Helmet>
        <MapContainer
          style={{ height: "100%" }}
          zoom={isMobile ? 1 : 4}
          scrollWheelZoom={true}
          center={center}
          preferCanvas
          maxZoom={18}
          minZoom={isMobile ? 1 : 3}
          ref={mapRef}
        >
          <MapController
            onZoomChange={() => {
              // setShowPopup(false)
              // setActivePopupID(null)
            }}
            setTitle={setTitle}
            setMetaDesc={setMetaDesc}
          />
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          />
          <MarkerClusterGroup
            chunkedLoading={true}
            chunkDelay={50}
            zoomToBoundsOnClick={true}
            removeOutsideVisibleBounds={true}
            maxClusterRadius={zoom => {
              if (zoom > 3 && zoom < 6) {
                return 0
              }
              else {
                return 25
              }
            }}
            spiderfyOnMaxZoom={true}
            showCoverageOnHover={false}
            iconCreateFunction={cluster => {
              const html = ReactDOMRender.renderToStaticMarkup(
                <ProjectCountIndicator count={cluster.getChildCount()} />
              )
              return divIcon({
                html: html,
                className: "bg-transparent",
              })
            }}
          >
            {/* {Projects.map((marker, index) => {
              if (!marker.lat || !marker.long) return null
              return (
                <Marker
                  key={index}
                  position={[marker.lat, marker.long]}
                  icon={
                    new L.Icon({
                      iconUrl: getSectoralIcon(
                        Number(marker.project_sectoral_scopes_ids)
                      ),
                      iconSize: new L.Point(40, 47),
                    })
                  }
                >
                  <Popup>
                    <MapCard
                      id={marker.id}
                      onProjectSelected={slug => {
                        history.push("/carbon-project/" + slug)
                      }}
                    />
                  </Popup>
                </Marker>
              )
            })} */}
            {/* {totalMarkers.map((marker, index) => {
              if (!marker.lat || !marker.long) return null
              return (
                <Marker
                  key={index}
                  position={[marker.lat, marker.long]}
                  icon={
                    new L.Icon({
                      iconUrl: getSectoralIcon(
                        Number(marker.project_sectoral_scopes_ids)
                      ),
                      iconSize: new L.Point(40, 47),
                    })
                  }
                >
                  <Popup>
                    <MapCard
                      id={marker.id}
                      onProjectSelected={slug => {
                        history.push("/carbon-project/" + slug)
                      }}
                    />
                  </Popup>
                </Marker>
              )
            })} */}
            {view === "region" &&
              regionMarkers.map(marker => {
                return (
                  <Marker
                    riseOnHover
                    position={[marker.lat, marker.long]}
                    icon={divIcon({
                      html: ReactDOMRender.renderToStaticMarkup(
                        <ProjectCountIndicator count={marker.count} />
                      ),
                      className: "bg-transparent",
                    })}
                    eventHandlers={{
                      click: e => {
                        mapRef.current?.setView([marker.lat, marker.long], zoom + 1);
                      }
                    }}
                  />
                )
              })}
            {view === "country" &&
              countryMarkers
                .filter(marker => marker.coordinates.length)
                .map(marker => {
                  const coords = marker.coordinates[0]
                  return (
                    <>
                      <Marker
                        position={[coords.latitude, coords.longitude]}
                        icon={divIcon({
                          html: ReactDOMRender.renderToStaticMarkup(
                            <ProjectCountIndicator count={marker.count} />
                          ),
                          className: "bg-transparent",
                        })}
                        eventHandlers={{
                          click: e => {
                            mapRef.current?.setView([coords.latitude, coords.longitude], zoom + 1);

                          }
                        }}
                      />
                    </>
                  )
                })}
            {view === "projects" &&
              projectMarkers.map((marker, index) => {
                if (!marker.lat || !marker.long) return null
                return (
                  <Marker
                    key={index}
                    position={[marker.lat, marker.long]}
                    icon={
                      new L.Icon({
                        iconUrl: getSectoralIcon(
                          Number(marker.project_sectoral_scopes_ids)
                        ),
                        iconSize: new L.Point(40, 47),
                      })
                    }
                  >
                    <Popup className="map_popup">
                      <MapCard
                        id={marker.id}
                        onProjectSelected={slug => {
                          history.push("/carbon-project/" + slug)
                        }}
                      />
                    </Popup>
                    <Tooltip position={[marker.lat, marker.long]}>
                      <MapToolTip marker={marker} />
                    </Tooltip>
                  </Marker>
                )
              })}
          </MarkerClusterGroup>
          <MapLegend legendItems={legendItems} isMobile={isMobile} />
        </MapContainer>
        {(loading) && (
          <div
            className="d-flex align-items-center justify-content-center "
            style={{
              background: "rgba(0,0,0,0.6)",
              position: "fixed",
              zIndex: 90,
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            {/* {error && (
              <div className="text-danger bg-white rounded p-3">
                Unable to load the projects. Please try again. ( `${error}`)
              </div>
            )} */}
            {loading && (
              <div>
                <InfinitySpin color="#36d7b7" secondaryColor="#36d7b7" />
              </div>
            )}
            {!loading && !error & !projectMarkers.length ? (
              <div className="text-danger bg-white rounded p-3">
                <div className="d-flex align-items-center justify-content-center">
                  <span className="text-secondary">
                    There are no projects available that match your query;
                    please try again using different parameters.
                  </span>
                </div>
              </div>
            ) : null}
          </div>
        )}
        {!loading && totalMarkers.length < 1 && (
          <div
            className="d-flex align-items-center justify-content-center "
            style={{
              background: "rgba(0,0,0,0.6)",
              position: "absolute",
              height: "calc(100vh - 160px)",
              width: "100vw",
              zIndex: 90,
              top: 0,
              right: 0,
              left: 0,
            }}
          >
            <div className="text-danger bg-white rounded p-3">
              <div className="d-flex align-items-center justify-content-center">
                <span className="text-secondary">
                  There are no projects available that match your query;
                  please try again using different parameters.
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default MapView
