import AnimatedLogo from "components/AnimatedLogo"
import ProfileMenu from "components/CommonForBoth/TopbarDropdown/ProfileMenu"
import { checkAuthTokenValidity } from "core/ApiUsers"
import useDevice from "helpers/useDevice"
import jwtDecode from "jwt-decode"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { Col, Row } from "reactstrap"
import { logout } from "store/RTK-Store/authSlice"
import { markAsRead } from "store/RTK-Store/notificationsSlice"
import { setSearchPalylod } from "store/RTK-Store/projectsSlice"
import Swal from "sweetalert2"
import AnalyticsIcon from "../../assets/images/analyticsIcon.svg"
import Logo from "../../assets/images/CHLogoLight.png"
import Earth from "../../assets/images/earth.svg"
import MetricsCard from "../../components/Landing/MetricsCard"
import CompareButton from "../../components/VerticalLayout/CompareButton"
import {
  useLazyGetMetricsDataQuery,
  useGetFeaturedProjectsQuery,
} from "../../store/api/homeApi"
import { setLoading, setMetrics } from "../../store/slices/homeSlice"
import _ from "lodash"
import FeatureCarousel from "components/Landing/FeaturedProjects"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

const Home = () => {
  const history = useHistory()
  const { isMobile } = useDevice()
  useEffect(() => {
    if (token) {
      dispatch(checkAuthTokenValidity())
        .unwrap()
        .then(isLogout => {
          if (isLogout) {
            dispatch(logout())
            dispatch(markAsRead())
            Swal.fire({
              title: "Warning",
              text: "You have been logged out.  Please contact support if you face any problems.",
              icon: "warning",
              toast: true,
              timer: 2000,
              position: "top-end",
              showConfirmButton: false,
              showCancelButton: false,
            })
            history.push("/")
          }
        })
        .catch(error => {
          Swal.fire({
            title: "Error",
            text: `Something went wrong! (${error.message})`,
            icon: "error",
          })
        })
    }
  }, [])
  // Search query
  const initialPayload = {
    projectTitle: "",
    minCarbonCredits: null,
    maxCarbonCredits: null,
    country: [],
    projectTypes: [],
    sectoralScopes: [],
    registryStatus: [],
    region: [],
    registry: [],
    extraSeals: [],
    startCreditingPeriod: null,
    endCreditingPeriod: null,
    projectDeveloper: "",
    projectStatus: [],
  }
  const [searchQuery, setSearchQuery] = useState("")
  // Metrics
  const metrics = useSelector(state => state.home?.metrics)
  // Metrics query
  const [getMetrics] = useLazyGetMetricsDataQuery()
  const { data: fp_data, isLoading: fp_loading } = useGetFeaturedProjectsQuery()
  const [featuredProjects, setFeaturedProjects] = useState([])

  const dispatch = useDispatch()
  const searchPayload = useSelector(state => state.projects.searchPalylod)
  useEffect(() => {
    if (metrics) {
      dispatch(setLoading(true))
      getMetrics()
        .unwrap()
        .then(res => {
          dispatch(setMetrics(res))
        })
        .finally(() => {
          dispatch(setLoading(false))
        })
    }
  }, [])
  // Search handler
  const handleKeyPress = event => {
    if (event.key === "Enter") {
      const payload = {
        ...initialPayload,
        projectTitle: searchQuery,
      }
      dispatch(setSearchPalylod(payload))
      history.push("/carbon-projects")
    }
  }
  const handleSearch = () => {
    const payload = {
      ...initialPayload,
      projectTitle: searchQuery,
    }
    dispatch(setSearchPalylod(payload))
    history.push("/carbon-projects")
  }
  const projects = metrics.availableProjects
    ? `Search ${Intl.NumberFormat().format(
        Math.round(metrics?.availableProjects / 100) * 100
      )}+ Projects`
    : "Loading…"
  useEffect(() => {
    setSearchQuery(searchPayload?.projectTitle || "")
  }, [searchPayload])

  const redirectToBookDemo = () => {
    history.push("/book-demo")
  }
  const token = localStorage.getItem("authToken")
  // let showBookADemo = true;
  const [showBookADemo, setShowBookADemo] = useState(true)
  useEffect(() => {
    if (token != null) {
      const decoded = jwtDecode(token)
      if (decoded?.role_id === 1) {
        setShowBookADemo(false)
      } else {
        setShowBookADemo(true)
      }
    } else {
      setShowBookADemo(true)
    }
  }, [token])

  useEffect(() => {
    if (fp_data) {
      let temp = _.get(fp_data, "payload", [])
      setFeaturedProjects(temp)
    }
  }, [fp_data])

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="description"
          content="Dive into Best Carbon offsets, the ultimate platform for certified and non-certified carbon projects. Discover, engage, and contribute to global carbon reduction efforts with us."
        />
        <title>
          CarbonHub: Your Gateway to the World's Largest Carbon Project Database
        </title>
      </Helmet>
      <div className="landing-bg pb-5">
        <div className="landing-content">
          {/* {floatingButton()} */}
          <div
            className="d-flex mr-3 justify-content-end align-items-center"
            style={{ paddingTop: "10px" }}
          >
            {showBookADemo ? (
              <div>
                <button
                  onClick={redirectToBookDemo}
                  className="landing-btn-search d-none d-md-block"
                  style={{
                    height: 30,
                    fontSize: 12,
                    fontWeight: 700,
                    width: "114px",
                    marginTop: -12,
                  }}
                >
                  Book a Demo
                </button>
              </div>
            ) : null}

            <button
              onClick={() => {
                history.push("/analytics")
              }}
              className="btn btn-text m-o p-0  me-md-3 d-flex align-items-center"
              style={{ marginTop: -12, color: "#FFFFFF", border: "none" }}
            >
              <img
                className="pt-4 pb-4 m-0 p-0"
                src={AnalyticsIcon}
                style={{ borderRadius: "10px", width: "45px" }}
                alt="Logo"
              />
              <span className="d-none d-md-flex">Analytics</span>
            </button>
            {token && (
              <CompareButton
                // project_data={details}
                iconWidth={22}
                iconHeight={28}
                isLanding={true}
                location="landing"
                side="center"
                // imageStyles={{marginTop: 4}}
                style={{ marginRight: 0, marginTop: -14 }}
              />
            )}
            <div
              className="d-flex align-items-center"
              style={{ paddingRight: isMobile ? 0 : "15px" }}
            >
              <img className="lan-btn2" src={Earth} />
              <div className="lantext-btn2">EN</div>
            </div>

            <div style={{ paddingRight: "10px" }}>
              <ProfileMenu />
            </div>
          </div>

          <div className="text-content">
            <div className="landing-logo text-center d-flex align-items-center justify-content-center">
              {isMobile ? (
                <AnimatedLogo size={180} />
              ) : (
                <AnimatedLogo size={250} />
              )}
            </div>
            <div className="landing-title">
              <h1 className="text-white">
                The World’s Largest Carbon Project Database
              </h1>
            </div>
            <div className="landing-subtitle">
              <p>
                Search for a carbon project across registries<br></br>and in all
                stages of development
              </p>
            </div>

            <div className="landing-serch-bar">
              <Row>
                <Col>
                  <div className="d-flex">
                    <div style={{ paddingTop: "6px", paddingRight: "10px" }}>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="fi-rr-search" clipPath="url(#clip0_2953_10586)">
                          <path
                            id="Vector"
                            d="M19.7552 18.5768L14.781 13.6027C16.1365 11.9448 16.803 9.82941 16.6425 7.69397C16.4821 5.55852 15.507 3.56644 13.919 2.12976C12.331 0.693089 10.2515 -0.0782598 8.1107 -0.0247333C5.96991 0.0287933 3.93158 0.9031 2.41734 2.41734C0.9031 3.93158 0.0287933 5.96991 -0.0247333 8.1107C-0.0782598 10.2515 0.693089 12.331 2.12976 13.919C3.56644 15.507 5.55852 16.4821 7.69397 16.6425C9.82941 16.803 11.9448 16.1365 13.6027 14.781L18.5768 19.7552C18.734 19.907 18.9445 19.991 19.163 19.9891C19.3815 19.9872 19.5905 19.8995 19.745 19.745C19.8995 19.5905 19.9872 19.3815 19.9891 19.163C19.991 18.9445 19.907 18.734 19.7552 18.5768ZM8.33266 14.9993C7.01412 14.9993 5.72519 14.6083 4.62886 13.8758C3.53253 13.1433 2.67805 12.1021 2.17347 10.8839C1.66888 9.66571 1.53686 8.32527 1.79409 7.03206C2.05133 5.73885 2.68627 4.55097 3.61862 3.61862C4.55097 2.68627 5.73885 2.05133 7.03206 1.79409C8.32527 1.53686 9.66571 1.66888 10.8839 2.17347C12.1021 2.67805 13.1433 3.53253 13.8758 4.62886C14.6083 5.72519 14.9993 7.01412 14.9993 8.33266C14.9973 10.1002 14.2943 11.7947 13.0445 13.0445C11.7947 14.2943 10.1002 14.9973 8.33266 14.9993Z"
                            fill="#374957"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2953_10586">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        paddingRight: "8px",
                      }}
                    >
                      <input
                        type="text"
                        value={searchQuery}
                        onKeyDown={handleKeyPress}
                        onChange={e => setSearchQuery(e.target.value)}
                        placeholder={`${projects}`}
                        className="text-search w-100"
                      />
                      {searchQuery ? (
                        <div
                          style={{ padding: "8px 8px 0 0" }}
                          onClick={() => setSearchQuery("")}
                        >
                          <svg
                            height="20"
                            fill="#495057"
                            width="20"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                            focusable="false"
                            className="css-tj5bde-Svg"
                          >
                            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                          </svg>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <button
                      onClick={handleSearch}
                      className="landing-btn-search d-block d-md-none icon-only"
                      style={{ marginTop: "-3.5px", marginRight: "-12px" }}
                    >
                      Search
                    </button>
                    <button
                      onClick={handleSearch}
                      className="landing-btn-search d-none d-md-block"
                      style={{ marginTop: "-3.5px", marginRight: "-13px" }}
                    >
                      Search
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
            {/* Browse projects */}
            <div className="text-end mb-md-5 mb-3">
              <Link className="landing-browse" as={"a"} to="/carbon-projects">
                Browse All Projects
              </Link>
            </div>
            {/* Metrics */}
            <div className="d-flex justify-content-center">
              <div style={{ maxWidth: 1366 }}>
                <Row className="row-cols-1 row-cols-md-3">
                  <Col>
                    <MetricsCard
                      title="Total Credits"
                      value={metrics?.cumulativeCreditsGenerated?.replace(
                        " ",
                        ""
                      )}
                    />
                  </Col>
                  <Col>
                    <MetricsCard
                      title="Available Credits"
                      value={metrics?.cumulativeCreditsAvaliable?.replace(
                        " ",
                        ""
                      )}
                    />
                  </Col>
                  <Col>
                    <MetricsCard
                      title="Retired Credits"
                      value={metrics?.cumulativeCreditsRetired?.replace(
                        " ",
                        ""
                      )}
                    />
                  </Col>
                </Row>
              </div>
            </div>
            <div className="mb-5" />
          </div>
        </div>
        <div
          style={{
            minHeight: 324,
          }}
        >
          {featuredProjects?.projectData?.length > 0 && !fp_loading ? (
            <div>
              <div className="d-flex justify-content-center">
                <div className="landing-subtitle">
                  <p>Featured Projects</p>
                </div>
              </div>
              <div className="feature_cards d-flex justify-content-center px-5 ms-4">
                <FeatureCarousel featuredProjects={featuredProjects} />
              </div>
            </div>
          ) : (
            // <div className="d-flex justify-content-center">
            //   <h1 className="text-center text-white mt-5 fs-2">Loading...</h1>
            // </div>
            <div className="d-flex justify-content-center">
              <div className="px-5" style={{
                marginTop: 50
              }}>
                <Skeleton
                  inline
                  width={250}
                  height={150}
                  baseColor="#80a0aebf"
                  count={1}
                  style={{
                    marginRight: 10,
                  }}
                />
                <Skeleton
                  className="mobile_styles"
                  inline
                  width={250}
                  height={150}
                  baseColor="#80a0aebf"
                  count={1}
                  style={{
                    marginRight: 10,
                  }}
                />
                <Skeleton
                  className="mobile_styles"
                  width={250}
                  height={150}
                  baseColor="#80a0aebf"
                  count={1}
                  style={{
                    marginRight: 10,
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <ChatBox userId={user} /> */}
    </React.Fragment>
  )
}

export default Home
