import { createSlice, current } from '@reduxjs/toolkit';



// Function to save state to localStorage
const saveToLocalStorage = (state) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('comparedProjects', serializedState);
    } catch (e) {
      console.error("Could not save state", e);
    }
  };
  
  // Function to load state from localStorage
  const loadFromLocalStorage = () => {
    try {
      const serializedState = localStorage.getItem('comparedProjects');
      if (serializedState === null) return [];
      return JSON.parse(serializedState);
    } catch (e) {
      console.error("Could not load state", e);
      return [];
    }
  };

export const comparisonSlice = createSlice({
    name: 'comparison',
    initialState: {
        comparedProjects: loadFromLocalStorage(),
    },
    reducers: {
        addToComparedProjects: (state, action) => {
            state.comparedProjects.push(action.payload);
            saveToLocalStorage(current(state).comparedProjects);
        },
        removeFromComparedProjects: (state, action) => {
            state.comparedProjects = state.comparedProjects.filter(
                (project) => project.id !== action.payload
            );
            saveToLocalStorage(current(state).comparedProjects);
        },
        removeAllComparedProjects: (state) => {
            console.log(state);
            state.comparedProjects = [];
            saveToLocalStorage(current(state).comparedProjects);
        },
    },
});

export const {
    addToComparedProjects,
    removeFromComparedProjects,
    removeAllComparedProjects,
} = comparisonSlice.actions;
export default comparisonSlice.reducer;
