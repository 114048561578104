import React from "react"
import PropTypes from "prop-types"
/*
    This component is a simple component that displays the project details selected by the user.
    It takes four props:
    - title: string (title of the project)
    - subTitle: string (sub-title of the project)
    - onclose: function (callback function to close the project)
    - onAddProject: function (callback function to add a project)
    - maxWith: string (max width of the component)

    // Example usage:
    <SelectedProjectCard
        title
        subTitle
        onclose
        onAddProject
        maxWith
    />

    required props:
    - title: string (title of the project)
    - subTitle: string (sub-title of the project)
    - onclose: function (callback function to close the project)
    - onAddProject: function (callback function to add a project)
    - maxWith: string (max width of the component)
*/

export default function SelectedProjectCard({
  id,
  title,
  subTitle,
  onclose,
  maxWith,
  onAddProject,
  onRemoveProject,
  slug,
}) {
  return (
    <div>
      {title && subTitle ? (
        <div
          className="mobile_card"
          style={{
            backgroundColor: "#f8f9fa",
            padding: "10px",
            borderRadius: "6px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            maxWidth: maxWith || "420px",
            minWidth: "250px",
            
          }}
        >
          <div className="">
            <p
              style={{
                padding: 0,
                margin: 0,
                color: "#015F81",
                fontFamily: "poppins",
                fontWeight: "600",
                fontSize: "16px",
                lineHeight: "24px",
                minHeight: "48px",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              onClick={() => window.open(`/carbon-project/${slug}`, "_blank")}
            >
              {title}
            </p>
            <p
              style={{
                margin: 0,
                padding: 0,
                color: "#495057",
                fontFamily: "poppins",
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "18px",
              }}
            >
              {subTitle}
            </p>
          </div>
          <div
            style={{
              cursor: "pointer",
              width: "24px",
            }}
            className="ms-2"
            onClick={() => {
              onRemoveProject(id)
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_9766_17655)">
                <path
                  d="M20.4792 3.5101C19.3657 2.39451 18.0429 1.50982 16.5866 0.906788C15.1304 0.30376 13.5694 -0.00572371 11.9932 -0.00389822C5.36519 -0.00389822 -0.0078125 5.3691 -0.0078125 11.9971C-0.0078125 15.3111 1.33619 18.3121 3.50819 20.4841C4.62165 21.5997 5.94447 22.4844 7.40073 23.0874C8.85699 23.6904 10.418 23.9999 11.9942 23.9981C18.6222 23.9981 23.9952 18.6251 23.9952 11.9971C23.9952 8.6831 22.6512 5.6821 20.4792 3.5101ZM18.9372 18.9371C18.026 19.8497 16.9437 20.5736 15.7523 21.0671C14.5609 21.5606 13.2838 21.8141 11.9942 21.8131C6.57119 21.8131 2.17519 17.4171 2.17519 11.9941C2.17417 10.7045 2.42769 9.42739 2.92122 8.23597C3.41474 7.04454 4.13856 5.96224 5.05119 5.0511C5.96221 4.1386 7.04435 3.41484 8.23559 2.92132C9.42683 2.4278 10.7038 2.17422 11.9932 2.1751C17.4152 2.1751 21.8112 6.5711 21.8112 11.9931C21.8121 13.2825 21.5585 14.5595 21.065 15.7507C20.5714 16.9419 19.8477 18.0241 18.9352 18.9351L18.9372 18.9371Z"
                  fill="#F46A6A"
                  fill-opacity="0.3"
                />
                <path
                  d="M13.5336 12.0005L17.3886 8.14548C17.5788 7.93821 17.6816 7.6655 17.6755 7.38428C17.6694 7.10306 17.5549 6.83506 17.3559 6.63622C17.1569 6.43739 16.8889 6.32308 16.6077 6.31717C16.3264 6.31125 16.0538 6.41419 15.8466 6.60448L15.8476 6.60348L11.9926 10.4585L8.13764 6.60348C7.93036 6.41332 7.65766 6.31057 7.37643 6.31666C7.09521 6.32276 6.82722 6.43724 6.62838 6.6362C6.42954 6.83517 6.31524 7.10324 6.30933 7.38446C6.30341 7.66569 6.40634 7.93833 6.59664 8.14548L6.59564 8.14448L10.4506 11.9995L6.59564 15.8545C6.48717 15.954 6.39997 16.0744 6.33927 16.2085C6.27857 16.3426 6.24563 16.4876 6.24244 16.6348C6.23925 16.7819 6.26587 16.9282 6.3207 17.0648C6.37553 17.2014 6.45744 17.3255 6.56148 17.4296C6.66553 17.5337 6.78957 17.6157 6.92614 17.6706C7.0627 17.7255 7.20897 17.7523 7.35613 17.7492C7.50329 17.7461 7.6483 17.7132 7.78244 17.6526C7.91657 17.592 8.03706 17.5049 8.13664 17.3965L8.13764 17.3955L11.9926 13.5405L15.8476 17.3955C15.9471 17.5039 16.0676 17.5911 16.2017 17.6518C16.3358 17.7125 16.4808 17.7455 16.6279 17.7487C16.7751 17.7519 16.9214 17.7252 17.058 17.6704C17.1946 17.6156 17.3187 17.5337 17.4228 17.4296C17.5269 17.3256 17.6089 17.2015 17.6638 17.065C17.7187 16.9284 17.7454 16.7821 17.7423 16.635C17.7392 16.4878 17.7064 16.3428 17.6458 16.2087C17.5852 16.0745 17.498 15.9541 17.3896 15.8545L17.3886 15.8535L13.5336 12.0005Z"
                  fill="#F46A6A"
                  fill-opacity="0.3"
                />
              </g>
              <defs>
                <clipPath id="clip0_9766_17655">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      ) : (
        <div
          style={{
            padding: "37px",
            borderRadius: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: maxWith || "420px",
            minWidth: "250px",
            border: "1px dashed #7AA5BF80",
            borderWidth: "2px",
          }}
        >
          <div
            className="d-flex"
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              onAddProject()
            }}
          >
            <p
              style={{
                padding: 0,
                margin: 0,
                color: "#7AA5BF",
                fontFamily: "poppins",
                fontWeight: "400",
                fontSize: "16px",
                marginRight: "8px",
                lineHeight: "24px",
              }}
            >
              Add Project
            </p>
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_9446_32325)">
                <path
                  d="M12.5 0C5.87188 0 0.5 5.37188 0.5 12C0.5 18.6281 5.87188 24 12.5 24C19.1281 24 24.5 18.6281 24.5 12C24.5 5.37188 19.1281 0 12.5 0ZM19.4984 12.9984C19.4984 13.5516 19.0531 13.9969 18.5 13.9969H14.5016V18C14.5016 18.5531 14.0562 18.9984 13.5031 18.9984H11.5016C10.9484 18.9984 10.5031 18.5484 10.5031 18V14.0016H6.5C5.94688 14.0016 5.50156 13.5516 5.50156 13.0031V11.0016C5.50156 10.4484 5.94688 10.0031 6.5 10.0031H10.4984V6C10.4984 5.44688 10.9438 5.00156 11.4969 5.00156H13.4984C14.0516 5.00156 14.4969 5.45156 14.4969 6V9.99844H18.5C19.0531 9.99844 19.4984 10.4484 19.4984 10.9969V12.9984Z"
                  fill="#7AA5BF"
                  fill-opacity="0.5"
                />
              </g>
              <defs>
                <clipPath id="clip0_9446_32325">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      )}
    </div>
  )
}

SelectedProjectCard.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  onclose: PropTypes.func,
  onAddProject: PropTypes.func,
  maxWith: PropTypes.string,
}
