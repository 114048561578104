import createSagaMiddleware from "redux-saga"

import { configureStore } from "@reduxjs/toolkit"
import rootReducer from "./reducers"
import rootSaga from "./sagas"
import { projectsApi } from "./RTK-Store/services/projectApi"
import { homeApi } from "./api/homeApi"
import { favoriteApi } from "./RTK-Store/services/favoriteApi"
import { clickTrackingApi } from "./RTK-Store/services/clickTrackingApi"
import { dropdownApi } from "./RTK-Store/services/dropDownApi"
import { resetPasswordTokenValidationApi } from "./RTK-Store/services/resetPasswordTokenVerification"
import { notificationsApi } from "./RTK-Store/services/notificationsApi"
import { comparisonApi } from "./RTK-Store/services/comparisonApi"

const sagaMiddleware = createSagaMiddleware()
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = configureStore({
  reducer: rootReducer,
  devTools: {
    trace: true,
    traceLimit: 25,
  },
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware()
      .concat(sagaMiddleware)
      .concat(projectsApi.middleware)
      .concat(homeApi.middleware)
      .concat(favoriteApi.middleware)
      .concat(clickTrackingApi.middleware)
      .concat(dropdownApi.middleware)
      .concat(resetPasswordTokenValidationApi.middleware)
      .concat(notificationsApi.middleware)
      .concat(comparisonApi.middleware)
  }
})

sagaMiddleware.run(rootSaga)

export default store
