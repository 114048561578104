import React, { useRef } from "react"

import { Card, CardBody, Col, Row } from "reactstrap"

import image1 from "../../assets/images/Getting Started with CarboneSpace.png"
import image2 from "../../assets/images/Search a project at CarboneSpace.png"
import image3 from "../../assets/images/Creating a Project at CarboneSpace.png"
import image4 from "../../assets/images/Create an Account at CarboneSpace.png"

import Accordion from "./Accordion"
import { Helmet } from "react-helmet"

const HelpCenter = () => {
  const ref_carbon_space = useRef(null)
  const search_project = useRef(null)
  const creating_project = useRef(null)
  const create_account = useRef(null)

  const handleClickCarbonSpace = () => {
    ref_carbon_space.current?.scrollIntoView({ behavior: "smooth" })
  }
  const handleClickSearchProject = () => {
    search_project.current?.scrollIntoView({ behavior: "smooth" })
  }
  const handleClickCreatingProject = () => {
    creating_project.current?.scrollIntoView({ behavior: "smooth" })
  }
  const handleClickCreateAccount = () => {
    create_account.current?.scrollIntoView({ behavior: "smooth" })
  }

  const accordionData1 = [
    {
      title: "Why CarbonHub?",
      content: `CarbonHub is the most comprehensive source of carbon project data. We feature projects in various stages of development across all major carbon registries within carbon markets. If you're looking for a specific project or want to browse through and compare all available projects, CarbonHub is here to help. Start your search with us and trust us to connect you with the right project.`,
    },
    {
      title: "What is the aim of CarbonHub?",
      content: `Our goal is to centralize all relevant information about carbon projects, making it easier for organizations and individuals to search and compare projects across registries and sectoral scopes.`,
    },
    {
      title: "What are the features available on CarbonHub?",
      content: (
        <div className="sub-point">
          <p>
            <i className="fas fa-check-circle"></i>&nbsp;&nbsp;
            <span style={{ fontWeight: 600 }}>Global Project Database : </span>
            Comprehensive database of carbon projects in different stages of
            development across all major carbon registries.
          </p>
          <p>
            <i className="fas fa-check-circle"></i>&nbsp;&nbsp;
            <span style={{ fontWeight: 600 }}>Credits Summary : </span>
            Detailed summary of credits expected and issued to projects
            worldwide.
          </p>
          <p>
            <i className="fas fa-check-circle"></i>&nbsp;&nbsp;{" "}
            <span style={{ fontWeight: 600 }}>Diverse viewing options : </span>
            Three distinct browsing options for project research: table, map and
            grid view.
          </p>
          <p>
            <i className="fas fa-check-circle"></i>&nbsp;&nbsp;
            <span style={{ fontWeight: 600 }}>
              Simple Filters and Search :{" "}
            </span>
            User-friendly filters and a powerful search function.
          </p>
          <p>
            <i className="fas fa-check-circle"></i>&nbsp;&nbsp;
            <span style={{ fontWeight: 600 }}>Interactive Map : </span>
            Explore projects across an interactive map including credit and
            registry information.
          </p>
          <p>
            <i className="fas fa-check-circle"></i>&nbsp;&nbsp;
            <span style={{ fontWeight: 600 }}>Advanced Analytics : </span>
            Detailed analytics on credit issuances, retirements, and more to
            track and evaluate project performance.
          </p>
          <p>
            <i className="fas fa-check-circle"></i>&nbsp;&nbsp;
            <span style={{ fontWeight: 600 }}>AI-Powered Search : </span>
            Utilize AI-driven search capabilities for more accurate and relevant
            results.
          </p>
          <br />
        </div>
      ),
    },
    {
      title: "Why should I choose CarbonHub?",
      content: (
        <div className="sub-header">
          <div className="sub-point">
            <p>
              <i className="fas fa-check-circle"></i>&nbsp;&nbsp;{" "}
              <span style={{ fontWeight: 600 }}>Save Time: </span>
              Streamline your search across several carbon Registries.
            </p>
            <p>
              <i className="fas fa-check-circle"></i>&nbsp;&nbsp;{" "}
              <span style={{ fontWeight: 600 }}>Find the Right Project: </span>
              Connect with projects that align with your objectives.
            </p>
            <p>
              <i className="fas fa-check-circle"></i>&nbsp;&nbsp;{" "}
              <span style={{ fontWeight: 600 }}>
                Access Up-to-Date Information:{" "}
              </span>
              Rely on accurate data updated periodically.
            </p>
            <p>
              <i className="fas fa-check-circle"></i>&nbsp;&nbsp;{" "}
              <span style={{ fontWeight: 600 }}>Leverage Data Analysis: </span>
              Use comprehensive data analysis for project comparison and
              market-wide insights.
            </p>
            <p>
              <i className="fas fa-check-circle"></i>&nbsp;&nbsp;{" "}
              <span style={{ fontWeight: 600 }}>Global Reach: </span>
              Benefit from a truly global platform.
            </p>
          </div>
        </div>
      ),
    },
    {
      title: "Who can view Projects on CarbonHub?",
      content: `Any visitor to www.carbonhub.ai can browse through projects. Registered users will benefit from additional features.`,
    },
    {
      title: "Who can register for CarbonHub?",
      content: `Anyone may register for a CarbonHub account with minimum information. All data shared will follow our confidentiality policy.`,
    },
    {
      title: "How can I see the detailed credit summary?",
      content: `CarbonHub’s homepage displays a snapshot of the projects available on the website, as well as available credits and buffer credit details. In addition, users can use our AI Search or Analytics Graphs to research specific queries.`,
    },
  ]

  const accordionData2 = [
    {
      title:
        "How can I search for a project if I know the parameters I want to research?",
      content: (
        <div className="sub-point">
          <p>To search for a project on CarbonHub based on your interests:</p>
          <p>
            <span style={{ fontWeight: 600 }}>1. Category: </span>
            Select a project category that meets your search parameters, such as
            renewable energy, sustainable agriculture, or REDD+.
          </p>{" "}
          <p>
            <span style={{ fontWeight: 600 }}>2. Country: </span>
            Specify the country to narrow the search.
          </p>{" "}
          <p>
            <span style={{ fontWeight: 600 }}>3. Project Type: </span>
            Choose the type of project you're interested in, whether it's solar,
            energy efficiency projects, or others.
          </p>{" "}
          <p>
            <span style={{ fontWeight: 600 }}>4. Credits: </span>
            Consider filters related to the number of carbon credits associated
            with the projects.
          </p>{" "}
          <p>
            <span style={{ fontWeight: 600 }}>5. Keyword Search: </span>
            Use specific keywords to narrow down your search to projects that
            match particular technologies, methodologies, or themes.
          </p>{" "}
          <p>
            <span style={{ fontWeight: 600 }}>6. Developer: </span>
            Select projects developed by specific project developers
          </p>{" "}
          <p>
            <span style={{ fontWeight: 600 }}>
              7. Seals and Certifications:{" "}
            </span>
            Look for projects that have obtained certifications, or other
            recognized seals such as CCB or SDVISta.
          </p>
          <br />
        </div>
      ),
    },
    {
      title: "How often is the project data on the CarbonHub website updated?",
      content: `Project data is updated in biweekly cycles, one registry at a time. Users can view the latest data update date on the website’s main footer. The latest updated projects can be accessed by clicking on the footer’s date or using the “Updated Project” tab on the center-right of the screen.`,
    },
    {
      title: "What is the project profile on CarbonHub?",
      content: `The project profile serves as a comprehensive overview of each project featured on our platform. Accessible through a project pin on the map, a project line in table view, or a project card in grid view, each profile provides essential project data. This includes detailed information about the project owner, specific credit details, project location, and other project information. It is designed to offer clear and thorough understanding of each project's scope, impact, and investment potential within the sustainability and environmental sectors.`,
    },
    {
      title: "What do I do if I see an error with the project's data?",
      content: (
        <div>
          Please email us at{" "}
          <a href="mailto:support@carbonhub.com">support@carbonhub.com</a> with
          the relevant information, and we will resolve it.
        </div>
      ),
    },
    {
      title: "Are there any fees for the user who are seeking projects?",
      content: `There are no fees for users browsing projects on CarbonHub. For more details on additional features, please refer to the Pricing section on the website.`,
    },
    {
      title:
        'Watch the video below, "Search a Project at CarbonHub," to get an overview of our website listings page.',
      content: (
        <div className="card-body">
        <div className="ratio ratio-16x9">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/LV4RBckkejc"
            title="Description of the YouTube video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>    
      ),
    },
  ]

  const accordionData3 = [
    {
      title: "How can I create a Project at CarbonHub?",
      content: `Anyone at CarbonHub can create a Project by clicking on the "Add Project" button on the home page, which takes them to the Project creation page. When the project is finished, the user will be prompted to register on the website in order to save it.`,
    },
    {
      title:
        "Is there any limit to adding several projects under the same profile?",
      content: `CarbonHub allows users to add several projects to one profile, with no restriction to the number of projects that can be uploaded.`,
    },
    {
      title:
        "After adding a project, how much time will it take for the project to go live?",
      content: `When a user adds a project to the website, it is sent to the CarbonHub team for verification. The project will go live as soon as the CarbonHub team approves it.`,
    },
    {
      title: "Do I need to pay before adding the project?",
      content: `No, adding a project to the CarbonHub website is free; however, the user will be invoiced for the subscription and add-ons that they select.`,
    },
    {
      title: "How can I edit my project?",
      content: `CarbonHub allows users to amend their projects as needed, however any changes made are subject to internal approval by the CarbonHub staff before the project is published live on the website.`,
    },
    {
      title:
        "What information is required to create a project? Do I need to specify confidential information?",
      content: `CarbonHub requires publicly available information and basic data to build a project, and there is no requirement for confidential data to start a project on our platform. We strongly discourage the use of confidential data and insist that users disclose as much non-secret data as possible in order to get traction.`,
    },
    {
      title: "Will my data be secure?",
      content: `CarbonHub is a secure website, and data will be protected in accordance with the website's terms and conditions.`,
    },
    {
      title:
        "Can I add a project which is not listed on any registry across globe?",
      content: `We promote all types of initiatives, regardless of their relationship with any registry. A project that is not listed elsewhere can be listed on the website. We may assist the project host/developer with registration and any investment requirements.`,
    },
    {
      title:
        'Watch the below “Create a Project at CarbonHub" video to get an overview of our website and create a Project page.',
      content: (
        <div className="card-body">
        <div className="ratio ratio-16x9">
        </div>
      </div>      
      ),
    },
  ]

  const accordionData4 = [
    {
      title: "Do I need to pay to create an account at CarbonHub?",
      content: `Creating an account with CarbonHub is free. However, additional services or premium features are offered with a paid subscription.  For more details on additional features, please refer to the Pricing section on the website`,
    },
    {
      title: "Are the payments at CarbonHub Secured?",
      content: `CarbonHub uses Stripe to safeguard all payments. To make a payment, users must either add their Visa/Mastercard/Amex/Other  or contact us at support@carbonhub.com to pay using PayPal.`,
    },
    {
      title: "Can I have multiple accounts for a company?",
      content: `Yes, users can have multiple accounts for a company on CarbonHub. However, each account must be registered with a separate email address or contact details. This allows for better management and organization of different projects or departments within the same company.`,
    },
    {
      title: "How can I deactivate my account from the CarbonHub website?",
      content: `To deactivate your account on the CarbonHub website, simply write to us at support@carbonhub.com with your account details. Our support team will assist you in deactivating your account.`,
    },
    {
      title:
        'Watch the "Create an Account at CarbonHub " video below to get an overview of creating an account on our website.',
      content: (
        <div className="card-body">
          <div className="ratio ratio-16x9">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/lUWirBkLnqg"
              title="Video 1"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
            <br />
          </div>
        </div>

      ),
    },
  ]

  return (
    <React.Fragment>
      <Helmet>
        <title>CarbonHub FAQ: Answers to Your Carbon Project Queries</title>
        <meta
          name="description"
          content="Have questions about carbon projects or CarbonHub? Find all the answers in our FAQ section, helping you understand more about carbon reduction and our platform."
        />
      </Helmet>
      <div className="image-container">
        <div className="helpcenter-bg">
          <div className="page-content-web ">
            <div className="text-content">
              <h1 className="text-white" style={{ fontSize: 33 }}>
                Help Centre
              </h1>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div>
        <Card className="help-center">
          <CardBody>
            <div className="topic">
              We are Here to Help you!!
              <br />
              <br />
            </div>

            <Row className="justify-content-center">
              <Col sm="3">
                <a onClick={handleClickCarbonSpace}>
                  <div className="image-box">
                    <img src={image1} className="faq-images"></img>
                    <br />
                    <br />
                    <div className="heading-text">
                      Getting Started with
                      CarbonHub
                    </div>
                  </div>
                </a>
              </Col>

              <Col sm="3">
                <a onClick={handleClickSearchProject}>
                  <div className="image-box">
                    <img src={image2} className="faq-images"></img>
                    <br />
                    <br />
                    <div className="heading-text">
                      Search a Project at
                      CarbonHub
                    </div>
                  </div>
                </a>
              </Col>

              {/* <Col sm="3">
                <a onClick={handleClickCreatingProject}>
                  <div className="image-box">
                    <img src={image3} className="faq-images"></img>
                    <br />
                    <br />
                    <div className="heading-text">
                      Creating a project at CarbonHub
                    </div>
                  </div>
                </a>
              </Col> */}

              <Col sm="3">
                <a onClick={handleClickCreateAccount}>
                  <div className="image-box">
                    <img src={image4} className="faq-images"></img>
                    <br />
                    <br />
                    <div className="heading-text">
                      Create an Account at
                      CarbonHub
                    </div>
                    <div ref={ref_carbon_space}></div>
                  </div>
                </a>
              </Col>
            </Row>

            <br />
            <br />

            <div className="heading-text">Getting started with CarbonHub</div>
            <div className="accordion">
              {accordionData1.map(({ title, content }, index) => (
                <Accordion key={index} title={title} content={content} />
              ))}
              <div ref={search_project}></div>
            </div>
            <br />
            <br />

            <div className="heading-text">
              Search for a Project on CarbonHub
            </div>
            <div className="accordion">
              {accordionData2.map(({ title, content }, index) => (
                <Accordion key={index} title={title} content={content} />
              ))}
              <div ref={creating_project}></div>
            </div>
            <br />
            <br />

            {/* <div className="heading-text">Creating a project at CarbonHub</div>
            <div className="accordion">
              {accordionData3.map(({ title, content }, index) => (
                <Accordion key={index} title={title} content={content} />
              ))}
              <div ref={create_account}></div>
            </div> */}
            {/* <br /> */}
            {/* <br /> */}

            <div className="heading-text">Create an Account at CarbonHub</div>
            <div className="accordion">
              {accordionData4.map(({ title, content }, index) => (
                <Accordion key={index} title={title} content={content} />
              ))}
              <div ref={create_account}></div>

            </div>
            <br />
            <br />
            <br />
            <br />
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default HelpCenter
