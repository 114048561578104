import eye from "assets/images/eye.png"
import { checkEmail, postSignUp, resendVerificationEmail } from "core/ApiUsers"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Alert, Col, Form, FormFeedback, Input, Row } from "reactstrap"
import backArrow from "../../assets/images/back_arrow.png"
import successIcon from "../../assets/images/success_Icon.png"

// Formik validation
import { useFormik } from "formik"
import * as Yup from "yup"

// import images
import Logo_cs from "assets/images/CarbonhubAiFinalLogo.svg"
import Swal from "sweetalert2"

const SignUp = props => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Please enter you email")
        .email("Invalid email"),
    }),
    onSubmit: payload => {
      setIsLoading(true)
      dispatch(checkEmail({ email: payload.email }, props.history)).then(
        response => {
          setEmail(payload.email)
          setIsLoading(false)
          if (response && response.payload.error === false) {
            setActiveTab(2)
          } else {
            Swal.fire({
              title: "Oops!",
              text: response?.payload?.payload,
              icon: "error",
              iconColor: "#F46A6A",
              confirmButtonText: "Login",
              cancelButtonText: "Close",
              confirmButtonColor: "#7AA5BF",
              cancelButtonColor: "#F46A6A",
              showCancelButton: true,
            }).then(result => {
              if (result.isConfirmed) {
                props.openSignUp()
              }
            })
          }
        }
      )
    },
  })

  const validationFinishForm = useFormik({
    enableReinitialize: true,

    initialValues: {
      fullName: "",
      organizationName: "",
      phoneNumber: "",
      password: "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("Please enter your full name"),
      organizationName: Yup.string(),
      phoneNumber: Yup.string().matches(
        /^[\d+]+$/,
        "Phone number can only contain numbers and +"
      ),
      password: Yup.string().required("Please enter your password"),
    }),
    onSubmit: payload => {
      setIsLoading(true)
      dispatch(postSignUp({ ...payload, email: email }, props.history))
        .then(response => {
          const token = response.payload.verficationToken
          if (response.payload && token) {
            setActiveTab(3)
          } else {
            throw new Error()
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
        .catch(error => {
          Swal.fire({
            title: "Oops!",
            text: "Something went wrong! Please try again!",
            icon: "error",
          })
        })
    },
  })
  const handleResendVerificationEmail = () => {
    setIsLoading(true)
    dispatch(resendVerificationEmail({ email: email }))
      .unwrap()
      .then(res => {
        const message = res?.message ?? ""
        if (message.indexOf("already") !== -1) {
          throw new Error(message)
        } else {
          Swal.fire({ title: "Success!", text: message, icon: "success" })
        }
      })
      .catch(error => {
        Swal.fire({
          title: "Oops!",
          text: error.message,
          icon: "error",
        })
      })
      .finally(() => setIsLoading(false))
  }
  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))
  const [activeTab, setActiveTab] = useState(1)
  const [email, setEmail] = useState("")
  useEffect(() => {
    validationFinishForm.setValues({
      fullName: "",
      organizationName: "",
      phoneNumber: "",
      password: "",
    })
  }, [activeTab])
  return (
    <React.Fragment>
      <div style={{ padding: " 5px 30px", backgroundColor: "#F3F3F8" }}>
        {activeTab === 1 && (
          <div id="sec-1">
            <div className="text-center mb-4">
              <img
                className="pt-4 pb-4"
                src={Logo_cs}
                style={{ borderRadius: "10px" }}
                alt="Logo"
              />
              <h3 className="pt-1 pb-0">Signup</h3>
              <h6
                className="pt-0 pb-2"
                style={{ marginBottom: "2px", color: "#495057" }}
              >
                Sign up to explore about Carbon Projects Database!
              </h6>
            </div>
            <div className="p-2">
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                {error ? <Alert color="danger">{error}</Alert> : null}
                <div className="mb-3">
                  <Row>
                    <Col md="12">
                      <label htmlFor="email">Email</label>
                      <Input
                        name="email"
                        className="form-control"
                        placeholder="Enter Your Email"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                          !!(
                            validation.touched.email && validation.errors.email
                          )
                        }
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">
                          {validation.errors.email}
                        </FormFeedback>
                      ) : null}
                    </Col>
                  </Row>
                </div>
                <div className="mt-4 mb-3 d-grid">
                  <button
                    style={{ fontWeight: 700, backgroundColor: "#7AA5BF" }}
                    className="login-button btn btn-secondary"
                    type="submit"
                    disabled={isLoading}
                  >
                    Continue
                  </button>
                </div>
                <div className="mt-4 text-center">
                  <p>
                    Already have an account?{" "}
                    <button
                      type="button"
                      className="btn btn-text p-0 me-1"
                      style={{ fontWeight: "700", color: "#495057" }}
                      onClick={() => props.openSignUp()}
                    >
                      Login
                    </button>
                  </p>
                </div>{" "}
                <div className=" text-center" style={{ marginTop: 94 }}>
                  <p>Powered by CarbonHub © {new Date().getFullYear()}</p>
                </div>
              </Form>
            </div>
          </div>
        )}
        {activeTab === 2 && (
          <div id="sec-2">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                onClick={() => {
                  setIsLoading(false)
                  setActiveTab(1)
                }}
                src={backArrow}
                style={{ height: "17px", marginRight: "10px" }}
              />
              <h3 style={{ flex: 1, textAlign: "center", margin: "0" }}>
                Finish Signing up
              </h3>
            </div>

            <div className="p-2" style={{ marginTop: "49px" }}>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validationFinishForm.handleSubmit()

                  return false
                }}
              >
                {error ? <Alert color="danger">{error}</Alert> : null}
                <div className="mb-3">
                  <Row>
                    <Col md="12">
                      <label htmlFor="email">Full Name*</label>
                      <Input
                        name="fullName"
                        className="form-control"
                        placeholder="Enter Your Full Name"
                        type="text"
                        onChange={validationFinishForm.handleChange}
                        onBlur={validationFinishForm.handleBlur}
                        value={validationFinishForm.values.fullName || ""}
                        invalid={
                          !!(
                            validationFinishForm.touched.fullName &&
                            validationFinishForm.errors.fullName
                          )
                        }
                      />
                      {validationFinishForm.touched.fullName &&
                      validationFinishForm.errors.fullName ? (
                        <FormFeedback type="invalid">
                          {validationFinishForm.errors.fullName}
                        </FormFeedback>
                      ) : null}
                    </Col>
                  </Row>
                </div>
                <div className="mb-3">
                  <Row>
                    <Col md="12">
                      <label htmlFor="email">Company</label>
                      <Input
                        name="organizationName"
                        className="form-control"
                        placeholder="Enter Your Company"
                        type="text"
                        onChange={validationFinishForm.handleChange}
                        onBlur={validationFinishForm.handleBlur}
                        value={
                          validationFinishForm.values.organizationName || ""
                        }
                        invalid={
                          !!(
                            validationFinishForm.touched.organizationName &&
                            validationFinishForm.errors.organizationName
                          )
                        }
                      />
                      {validationFinishForm.touched.organizationName &&
                      validationFinishForm.errors.organizationName ? (
                        <FormFeedback type="invalid">
                          {validationFinishForm.errors.organizationName}
                        </FormFeedback>
                      ) : null}
                    </Col>
                  </Row>
                </div>{" "}
                <div className="mb-3">
                  <Row>
                    <Col md="12">
                      <label htmlFor="email">Phone Number</label>
                      <Input
                        name="phoneNumber"
                        className="form-control"
                        placeholder="Enter Phone Number"
                        type="text"
                        onChange={validationFinishForm.handleChange}
                        onBlur={validationFinishForm.handleBlur}
                        value={validationFinishForm.values.phoneNumber || ""}
                        invalid={
                          !!(
                            validationFinishForm.touched.phoneNumber &&
                            validationFinishForm.errors.phoneNumber
                          )
                        }
                      />
                      {validationFinishForm.touched.phoneNumber &&
                      validationFinishForm.errors.phoneNumber ? (
                        <FormFeedback type="invalid">
                          {validationFinishForm.errors.phoneNumber}
                        </FormFeedback>
                      ) : null}
                    </Col>
                  </Row>
                </div>
                <div className="mb-3">
                  <label htmlFor="email">Create Password*</label>
                  <div className="position-relative  align-items-center">
                    <div className="d-flex">
                      <Input
                        name="password"
                        className="form-control"
                        placeholder="Enter New Password"
                        type={!showPassword ? "password" : "text"}
                        onChange={validationFinishForm.handleChange}
                        onBlur={validationFinishForm.handleBlur}
                        value={validationFinishForm.values.password || ""}
                        invalid={
                          !!(
                            validationFinishForm.touched.password &&
                            validationFinishForm.errors.password
                          )
                        }
                      />
                      <div className="position-absolute" style={{ right: 17 }}>
                        <button
                          size="sm"
                          type="button"
                          className="btn-link btn"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <img
                            src={eye}
                            style={{ borderRadius: "10px" }}
                            height="10px"
                            alt="eye"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  {validationFinishForm.touched.password &&
                  validationFinishForm.errors.password ? (
                    <p
                      className="text-danger mt-1"
                      style={{ fontSize: "10.4px" }}
                      type="invalid"
                    >
                      {validationFinishForm.errors.password}
                    </p>
                  ) : null}
                </div>
                <div className="mt-4 mb-3 d-grid">
                  <button
                    style={{ fontWeight: 700, backgroundColor: "#7AA5BF" }}
                    className="login-button btn btn-secondary"
                    type="submit"
                    disabled={isLoading}
                  >
                    Sign up
                  </button>
                </div>
                <div className="mt-4 text-center">
                  <p>
                    By signing up, I agree to CarbonHub{" "}
                    <Link to="/terms-and-conditions">
                      Terms of Payment and Services,
                    </Link>{" "}
                    and acknowledge the{" "}
                    <Link to="/privacy-policy">Privacy Policy.</Link>
                  </p>
                </div>
                <div className=" text-center" style={{ marginTop: 50 }}>
                  <p>Powered by CarbonHub © {new Date().getFullYear()}</p>
                </div>
              </Form>
            </div>
          </div>
        )}
        {activeTab === 3 && (
          <div id="sec-1">
            <div className="text-center">
              <img
                className="pt-4 pb-4"
                src={Logo_cs}
                style={{ borderRadius: "10px" }}
                alt="Logo"
                width={90}
              />
            </div>
            <div className="text-center mb-4">
              <h3 className="pt-1 pb-0">Confirm Your Email</h3>
            </div>{" "}
            <div className="text-center mb-4">
              <img
                onClick={() => setActiveTab(1)}
                src={successIcon}
                style={{ height: "108px" }}
              />
            </div>
            <div className="mt-4 text-center">
              <p>
                We have sent a link to {email}. Please click the link to confirm
                your email.
              </p>
            </div>
            <div className="mt-4 mb-3 d-grid">
              <button
                style={{ fontWeight: 700, backgroundColor: "#7AA5BF" }}
                className="login-button btn btn-secondary"
                type="button"
                onClick={handleResendVerificationEmail}
                disabled={isLoading}
              >
                Resend Email
              </button>
            </div>
            <div className=" text-center" style={{ marginTop: 94 }}>
              <p>Powered by CarbonHub © {new Date().getFullYear()}</p>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default withRouter(SignUp)

SignUp.propTypes = {
  history: PropTypes.object,
}
