import { formatClicks } from "pages/DetailsView"
import FavoritesButton from "pages/DetailsView/FavoritesButton"
import ComparisonButton from "./Comparison/CardButton"
import React from "react"

export default function GridCardHeader(props) {
  const project_data = props.project
  const showActions = props.showActions ?? true
  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <svg
            height="14"
            viewBox="0 0 14 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_5270_20034)">
              <path
                d="M7.1224 4.875C6.69142 4.875 6.27809 5.0462 5.97335 5.35095C5.6686 5.6557 5.4974 6.06902 5.4974 6.5C5.4974 6.93098 5.6686 7.3443 5.97335 7.64905C6.27809 7.9538 6.69142 8.125 7.1224 8.125C7.55337 8.125 7.9667 7.9538 8.27144 7.64905C8.57619 7.3443 8.7474 6.93098 8.7474 6.5C8.7474 6.06902 8.57619 5.6557 8.27144 5.35095C7.9667 5.0462 7.55337 4.875 7.1224 4.875ZM7.1224 9.20833C6.4041 9.20833 5.71523 8.92299 5.20731 8.41508C4.6994 7.90717 4.41406 7.21829 4.41406 6.5C4.41406 5.78171 4.6994 5.09283 5.20731 4.58492C5.71523 4.07701 6.4041 3.79167 7.1224 3.79167C7.84069 3.79167 8.52957 4.07701 9.03748 4.58492C9.54539 5.09283 9.83073 5.78171 9.83073 6.5C9.83073 7.21829 9.54539 7.90717 9.03748 8.41508C8.52957 8.92299 7.84069 9.20833 7.1224 9.20833ZM7.1224 2.4375C4.41406 2.4375 2.10115 4.12208 1.16406 6.5C2.10115 8.87792 4.41406 10.5625 7.1224 10.5625C9.83073 10.5625 12.1436 8.87792 13.0807 6.5C12.1436 4.12208 9.83073 2.4375 7.1224 2.4375Z"
                fill="#959595"
              />
            </g>
            <defs>
              <clipPath id="clip0_5270_20034">
                <rect
                  width="13"
                  height="13"
                  fill="white"
                  transform="translate(0.625)"
                />
              </clipPath>
            </defs>
          </svg>
          <div
            style={{
              fontSize: "10px",
              fontWeight: 400,
              color: "#959595",
              marginLeft: "5px",
              marginBottom: 0,
            }}
          >
            {formatClicks(project_data?.clicks)} Views
          </div>
        </div>
        {showActions ? (
          <div className="d-flex align-items-center" style={{ marginTop: -4, marginRight: -4 }}>
            <ComparisonButton
              iconWidth={12}
              iconHeight={15}
              location="grid"
              project_data={
                project_data ? project_data : { id: 0, project_name: "" }
              }
            />
            <FavoritesButton
              iconWidth={12}
              iconHeight={15}
              favorite={project_data?.isFavourite}
              projectId={project_data?.id}
              hasText={false}
            />
          </div>
        ) : null}
      </div>
    </>
  )
}
