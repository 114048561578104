import React from "react"
import L from "leaflet"
import { MapContainer, Marker, TileLayer, Popup } from "react-leaflet"
import { addressPoints } from "./realworld" // Make sure this points to your data file
import MarkerClusterGroup from "react-leaflet-cluster"
import "leaflet/dist/leaflet.css"
import { Helmet } from "react-helmet"

const customIcon = new L.Icon({
  iconUrl: require("./location.svg").default,
  iconSize: new L.Point(40, 47),
})

const createClusterCustomIcon = function (cluster) {
  return L.divIcon({
    html: `<span>${cluster.getChildCount()}</span>`,
    className: "custom-marker-cluster",
    iconSize: L.point(33, 33, true),
  })
}

function MapView() {
  return (
    <React.Fragment>
      <div>
        <Helmet>
          <title>Map Test</title>
          <meta name="description" content={""} />
        </Helmet>
        <MapContainer
          style={{ height: "800px" }}
          center={[-41.975762, 172.934298]}
          zoom={4}
          scrollWheelZoom={true}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          />
          <MarkerClusterGroup chunkedLoading>
            {addressPoints.map((address, index) => (
              <Marker
                icon={customIcon}
                key={index}
                position={[address[0], address[1]]}
                title={address[2]}
              >
                <Popup>
                  <span>{address[2]}</span>
                </Popup>
              </Marker>
            ))}
          </MarkerClusterGroup>
        </MapContainer>
      </div>
    </React.Fragment>
  )
}

export default MapView
