import { Button, Table } from "reactstrap"
import React, { useEffect, useMemo, useState } from "react"
import useDevice from "helpers/useDevice"
import { Tooltip, Whisper } from "rsuite"
import moment from "moment"
import _ from 'lodash'

const TableHeader = ({
  title = "N/A",
  onSort,
  width = "auto",
  isAsc = true,
  fontSize,
  tooltip = "",
  registry = false
}) => {
  return (
    <th
      width={width}
      className="bg-transparent"
      style={{ fontSize: fontSize ? fontSize : 15, color: "#9C9C9C" }}
    >
      <div className="d-flex flex-row align-items-center">
      {
          tooltip === "" ? 
          <span>{title}</span>
           : (
            <Whisper placement="top" trigger="hover" speaker={<Tooltip>{tooltip}</Tooltip>}>
            <span>{title}</span>
          </Whisper>
          )
        }
        <Button
          size="sm"
          type="button"
          color="link"
          onClick={() => {
            onSort()
          }}
        >
          {isAsc ? (
            <svg
              width="15"
              height="8"
              viewBox="0 0 15 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.5 8L7.5 6.11959e-07L0.5 8L14.5 8Z"
                fill="#9C9C9C"
              />
            </svg>
          ) : (
            <svg
              width="15"
              height="8"
              viewBox="0 0 15 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.5 0L7.5 8L14.5 0H0.5Z"
                fill="#9C9C9C"
              />
            </svg>
          )}
        </Button>
      </div>
    </th>
  )
}

const TableCell = ({ cellData }) => (
  <td className="bg-transparent border-0 pb-3" style={{ fontSize: 16 }}>
    {cellData}
  </td>
)

const TableCellWithLink = ({ cellData, fileInput, downloadName }) => {

  const downloadFile = async (fileUrl) => {
    try {
      const response = await fetch(fileUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf', // Adjust the content type as needed
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const extension = fileUrl.split('.').pop();

      if (downloadName.includes('.')) {
        downloadName = downloadName.split('.').slice(0, -1).join('.');
      }

      if (downloadName.startsWith('(')) {
        downloadName = downloadName.substring(1);
      }

      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = downloadName + "." + extension;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  return (
    <td className="bg-transparent border-0 pb-3" style={{ fontSize: 16, textAlign: "left" }}>
      <div
        onClick={() => downloadFile(fileInput)}
        style={{ color: "black", border: "none", background: "none", cursor: "pointer" }}
      >
        {cellData}
      </div>
    </td>
  )
}



const CustomTable = ({
  data = [],
  headers = [],
  fontSize,
  tableType,
  date,
  dateVisible,
  registry
}) => {
  const updatedAt = _.get(date, "updated_at");
  const formattedDate = moment(updatedAt).local().format("DD MMM YYYY");
  const [sortData, setSortData] = useState({
    key: "",
    order: true,
  })
  const pageSize = 10
  const [activePage, setActivePage] = useState(1)
  const { isMobile } = useDevice()

  const pages = useMemo(() => {
    return Math.ceil(data.length / pageSize)
  }, [data.length])
  const [visibleData, setVisibleData] = useState([])
  useEffect(() => {
    let newData = [...data]
    if (sortData.key !== "") {
      newData.sort((a, b) => {
        if (a[sortData.key] < b[sortData.key]) {
          return sortData.order ? -1 : 1
        }
        if (a[sortData.key] > b[sortData.key]) {
          return sortData.order ? 1 : -1
        }
        return 0
      })
    }
    setVisibleData(
      newData.slice(
        (activePage - 1) * pageSize,
        (activePage - 1) * pageSize + pageSize
      )
    )
  }, [data, sortData, activePage])
  const setSortKey = key => {
    const existing = { ...sortData }
    if (existing.key === key) {
      existing.order = !existing.order
    } else {
      existing.order = true
    }
    existing.key = key
    setSortData(existing)
  }
  return (
    <>
      <div className="overflow-x-auto mb-3">
        <Table style={{ minWidth: 1024 }}>
          <thead>
            <tr>
              {headers.map((item, index) => {
                return (
                  <TableHeader
                    tooltip={registry && item.key === 'bufferCredits' ? "Aggregate of total credits in CAR and ARB buffer pools" : ""}
                    key={index}
                    isAsc={sortData.key === item.key ? sortData.order : false}
                    width={item.width ?? ""}
                    title={item.label}
                    fontSize={fontSize}
                    onSort={() => {
                      setSortKey(item.key)
                    }}
                  />
                )
              })}
            </tr>
          </thead>
          {data.length ? (
            <tbody>
              {visibleData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {headers.map((header, cellIndex) =>
                    tableType === "documents" &&
                      header.key === "document_name" ? (
                      <TableCellWithLink
                        key={cellIndex}
                        cellData={row[header.key]}
                        downloadName={row["document_name"]}
                        fileInput={row["file_input"]}
                      />
                    ) : (
                      <TableCell key={cellIndex} cellData={row[header.key]} />
                    )
                  )}
                </tr>
              ))}
            </tbody>
          ) : null}
        </Table>
      </div>
      {!data.length ? (
        <div
          className="py-5 text-center fw-bold"
          style={{ color: "#C4C4C4", fontSize: 14 }}
        >
          {tableType == "documents"
            ? "It seems like there are no records to display"
            : "Credits issuance data not available in the registry"}
        </div>
      ) : null}

      {!data.length ? (
        <div>
          {dateVisible && formattedDate && !isMobile && (
            <p
              style={{
                color: "#959595",
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 600,
                fontStyle: "italic",
              }}
            >
              Project data updated on {formattedDate}.
            </p>
          )}
          {formattedDate && dateVisible && isMobile && (
            <p
              style={{
                color: "#959595",
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 600,
                fontStyle: "italic",
              }}
            >
              Project data updated on {formattedDate}.
            </p>
          )}
        </div>
      ) : (
        <div
          className="d-flex flex gap-2 align-items-center"
          style={{
            justifyContent: dateVisible
              ? !isMobile
                ? "space-between"
                : "center"
              : "end",
            paddingInline: isMobile ? 0 : "10px",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          {formattedDate && !isMobile && dateVisible && (
            <p
              style={{
                color: "#959595",
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 600,
                fontStyle: "italic",
              }}
            >
              Project data updated on {formattedDate}.
            </p>
          )}
          <div>
            <Button
              disabled={activePage === 1}
              style={{ borderColor: "#CCCCCC" }}
              outline
              size="sm"
              onClick={() => {
                if (activePage !== 1) {
                  setActivePage(activePage - 1)
                }
              }}
            >
              <svg
                width="8"
                height="13"
                viewBox="0 0 8 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.53636 12.4677C7.71212 12.2919 7.81085 12.0535 7.81085 11.8049C7.81085 11.5563 7.71212 11.3179 7.53636 11.1421L2.89574 6.50145L7.53636 1.86083C7.70714 1.68401 7.80163 1.4472 7.79949 1.20139C7.79736 0.955577 7.69876 0.72044 7.52494 0.546619C7.35112 0.3728 7.11599 0.274204 6.87018 0.272069C6.62437 0.269933 6.38755 0.364427 6.21074 0.5352L0.907301 5.83864C0.731546 6.01444 0.632813 6.25286 0.632813 6.50145C0.632813 6.75004 0.731546 6.98845 0.9073 7.16426L6.21074 12.4677C6.38654 12.6435 6.62496 12.7422 6.87355 12.7422C7.12214 12.7422 7.36055 12.6435 7.53636 12.4677Z"
                  fill="#CCCCCC"
                />
              </svg>
            </Button>
            {Array.from({ length: pages }).map((_, index) => (
              <a
                key={index}
                onClick={() => {
                  setActivePage(index + 1)
                }}
                className="btn btn-sm btn-text fw-bold"
                style={{
                  color: index + 1 === activePage ? "#495057" : "#CCCCCC",
                }}
              >
                {index + 1}
              </a>
            ))}
            <Button
              disabled={activePage === pages}
              onClick={() => {
                if (activePage < pages) {
                  setActivePage(activePage + 1)
                }
              }}
              style={{ borderColor: "#CCCCCC" }}
              outline
              size="sm"
            >
              <svg
                width="8"
                height="13"
                viewBox="0 0 8 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.463638 0.5323C0.287884 0.708107 0.189151 0.946521 0.189151 1.19511C0.189151 1.4437 0.287884 1.68212 0.463638 1.85792L5.10426 6.49855L0.463638 11.1392C0.292865 11.316 0.19837 11.5528 0.200506 11.7986C0.202642 12.0444 0.301238 12.2796 0.475058 12.4534C0.648878 12.6272 0.884015 12.7258 1.12982 12.7279C1.37563 12.7301 1.61245 12.6356 1.78926 12.4648L7.0927 7.16136C7.26845 6.98556 7.36719 6.74714 7.36719 6.49855C7.36719 6.24996 7.26845 6.01155 7.0927 5.83574L1.78926 0.5323C1.61346 0.356545 1.37504 0.257812 1.12645 0.257812C0.877859 0.257812 0.639446 0.356545 0.463638 0.5323Z"
                  fill="#CCCCCC"
                />
              </svg>
            </Button>
          </div>
          {formattedDate && dateVisible && isMobile && (
            <p
              style={{
                color: "#959595",
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 600,
                marginTop: "44px",
                fontStyle: "italic",
              }}
            >
              Project data updated on {formattedDate}.
            </p>
          )}
        </div>
      )}
    </>
  )
}

export default CustomTable
