import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import React, { useState } from "react"
import _ from "lodash"
import { Button, Col, Row } from "reactstrap"
import { formatClicks } from "../../../pages/DetailsView"

const nf = Intl.NumberFormat("en-US")

const LegendItem = ({ color, label }) => {
  return <div className="d-flex gap-3 align-items-center">
    <div
      style={{ borderRadius: 16, backgroundColor: color, display: "inline-block", width: "16px", height: "16px" }} />
    <div>{label}</div>
  </div>
}

const Chart = ({ data }) => {
  const years = _.get(data, "value.year", [])
  const chartData = _.get(data, "value.series.0.data", []).map((item, index) => {
    return {
      "actual": item,
      "year": years[index]
    }
  })
  let pages = 1
  const [currentPage, setCurrentPage] = useState(1)
  const pageSize = 10
  if (years.length > pageSize) {
    pages = Math.ceil(years.length / pageSize)
  }
  let paginatedData = chartData.slice((currentPage - 1) * pageSize, currentPage * pageSize)

  const isDataEmpty = chartData.length === 0

  paginatedData = isDataEmpty ? Array.from({ length: 5 }, (_, index) => {
    const year = new Date().getFullYear() - (4 - index);
    return { actual: 0, year: year.toString() };
  }) : paginatedData;

  return <>
    <Row className="mb-3">
      <Col xs={6}>
        <div className="fs-6 fw-bold" style={{ color: "#495057" }}>{_.get(data, "label", "N/A")}</div>
      </Col>
      <Col xs={6} className="d-flex justify-content-end">
        <LegendItem color="#015E81" label="Actual" />
      </Col>
    </Row>
    <ResponsiveContainer height={320}>
      <BarChart
        data={paginatedData}
      >
        <XAxis
          dataKey="year"
          angle={-45}
          tick={{ fontWeight: "bold" }}
          textAnchor="end"
          interval={0}
          padding={{ left: 30, right: 30 }}
          tickLine={false}
          axisLine={false}
          tickMargin={-1}
        />

        {!isDataEmpty && (
          <YAxis
            tickLine={false}
            axisLine={false}
            tick={{ fontWeight: 'bold', color: '#959595' }}
            tickFormatter={(value) => formatClicks(value)}
          />
        )}

        {
          !isDataEmpty && (
            <Tooltip />
          )
        }

        <CartesianGrid horizontal={true} vertical={false} />
        <Bar
          dataKey="actual"
          barSize={10}
          name="Actual"
          label="Actual"
          fill="#015E81"
          radius={[5, 5, 0, 0]}
        />
      </BarChart>
      {isDataEmpty && (
          <div
            className="d-flex rounded text-center w-100 align-items-center justify-content-center fw-bold"
            style={{
              position: 'absolute',
              top: '60px', // Adjust based on the height of the chart title and spacing
              left: 0,
              height: '200px', // Same as the height of the BarChart
              zIndex: 10,
              color: 'rgb(196, 196, 196)',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              padding: '20px',
            }}
          >
           Credits issuance data not available in the registry
          </div>
      )}
    </ResponsiveContainer>
    {chartData.length ? <div className="d-flex flex mt-2 gap-2 align-items-center justify-content-center">
      <Button disabled={currentPage === 1} onClick={() => {
        if (currentPage !== 1) {
          setCurrentPage(currentPage - 1)
        }
      }} style={{ borderColor: "#CCCCCC" }} outline size="sm">
        <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.53636 12.4677C7.71212 12.2919 7.81085 12.0535 7.81085 11.8049C7.81085 11.5563 7.71212 11.3179 7.53636 11.1421L2.89574 6.50145L7.53636 1.86083C7.70714 1.68401 7.80163 1.4472 7.79949 1.20139C7.79736 0.955577 7.69876 0.72044 7.52494 0.546619C7.35112 0.3728 7.11599 0.274204 6.87018 0.272069C6.62437 0.269933 6.38755 0.364427 6.21074 0.5352L0.907301 5.83864C0.731546 6.01444 0.632813 6.25286 0.632813 6.50145C0.632813 6.75004 0.731546 6.98845 0.9073 7.16426L6.21074 12.4677C6.38654 12.6435 6.62496 12.7422 6.87355 12.7422C7.12214 12.7422 7.36055 12.6435 7.53636 12.4677Z"
            fill="#CCCCCC" />
        </svg>
      </Button>
      {paginatedData && paginatedData[0].year} - {paginatedData && paginatedData[paginatedData.length - 1].year}
      <Button disabled={currentPage === pages} style={{ borderColor: "#CCCCCC" }} outline size="sm" onClick={() => {
        if (currentPage < pages) {
          setCurrentPage(currentPage + 1)
        }
      }}>
        <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.463638 0.5323C0.287884 0.708107 0.189151 0.946521 0.189151 1.19511C0.189151 1.4437 0.287884 1.68212 0.463638 1.85792L5.10426 6.49855L0.463638 11.1392C0.292865 11.316 0.19837 11.5528 0.200506 11.7986C0.202642 12.0444 0.301238 12.2796 0.475058 12.4534C0.648878 12.6272 0.884015 12.7258 1.12982 12.7279C1.37563 12.7301 1.61245 12.6356 1.78926 12.4648L7.0927 7.16136C7.26845 6.98556 7.36719 6.74714 7.36719 6.49855C7.36719 6.24996 7.26845 6.01155 7.0927 5.83574L1.78926 0.5323C1.61346 0.356545 1.37504 0.257812 1.12645 0.257812C0.877859 0.257812 0.639446 0.356545 0.463638 0.5323Z"
            fill="#CCCCCC" />
        </svg>
      </Button>
    </div>
      : null}
  </>
}

export default Chart