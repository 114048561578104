import React from "react"
import PropTypes from "prop-types"

/*
  This component is a reusable component that displays a disclaimer message.
  It takes in three props:
  - icon: a svg icon that represents the disclaimer
  - title: a string that represents the title of the disclaimer
  - subTitle: a string that represents the subtitle of the disclaimer
*/
export default function Disclaimer(props) {
  const { icon, title, subTitle } = props
  return (
    <div
      style={{
        borderColor: "#F2A54D",
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: "10px",
        backgroundColor: "#FDF6ED",
        padding: "16px"
      }}
      {...props}
    >
      <p
        style={{
          fontFamily: "Poppins",
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "24px",
          margin: 0
        }}
      >
        <span style={{ marginRight: "9px" }}>{icon}</span>
        {title}
      </p>
      <p
        style={{
          paddingLeft: "30px",
          margin: 0
        }}
      >
        {subTitle}
      </p>
    </div>
  )
}

Disclaimer.prototypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string
}
