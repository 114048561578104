import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { addToComparedProjects } from "store/RTK-Store/comparisonSlice"
import Swal from "sweetalert2"
import {
  disabledButton_detail,
  disabledButton_Grid,
  enabledButton_detail,
  enabledButton_Grid,
} from "./LogoSVGs/index"

const ComparisonButton = ({
  location,
  text,
  style,
  iconWidth,
  iconHeight,
  project_data,
  imageStyles,
  textMargin,
}) => {
  const token = localStorage.getItem("authToken")
  const history = useHistory()

  const chooseButtons = ({ type, location }) => {
    if (type === "enabled") {
      if (location === "grid") {
        return enabledButton_Grid({ iconHeight, iconWidth })
      }
      if (location === "detail") {
        return enabledButton_detail({ iconHeight, iconWidth })
      }
    } else if (type === "disabled") {
      if (location === "grid") {
        return disabledButton_Grid({ iconHeight, iconWidth })
      }
      if (location === "detail") {
        return disabledButton_detail({ iconHeight, iconWidth })
      }
    }
  }

  const dispatch = useDispatch()
  const [enabledButton, setEnabledButton] = useState(
    chooseButtons({ type: "enabled", location })
  )
  const [disabledButton, setDisabledButton] = useState(
    chooseButtons({ type: "disabled", location })
  )
  const comparedProjects = useSelector(
    state => state.comparison.comparedProjects
  )
  const [enabled, setEnabled] = useState(false)

  useEffect(() => {
    if (comparedProjects && project_data.id) {
      let comparedProjectsIds = comparedProjects.map(project => project.id)
      if (comparedProjectsIds.includes(project_data.id)) {
        setEnabled(false)
      } else {
        setEnabled(true)
      }
    }
  }, [comparedProjects, project_data.id])

  const addToComparison = project_data => {
    if (token) {
      let comparedIds = comparedProjects.map(project => project.id)
      if (comparedIds.includes(project_data.id)) {
        Swal.fire({
          title: "Already added",
          text: "This project is already added to the comparison list.",
          toast: true,
          timer: 1000,
          showConfirmButton: false,
          showCancelButton: false,
          icon: "warning",
          position: "top-end",
        }).then()
      } else if (comparedIds.length >= 3) {
        Swal.fire({
          title: "Maximum limit reached",
          text: "You can only compare upto 3 projects.",
          toast: true,
          timer: 1300,
          showConfirmButton: false,
          showCancelButton: false,
          icon: "warning",
          position: "top-end",
        }).then()
      } else {
        dispatch(
          addToComparedProjects({
            id: project_data.id,
            title: project_data.project_name,
            slug:project_data.slug,
            country:
              project_data?.countryEmojis?.name || project_data?.country || "-",
          })
        )
      }
    } else {
      const path = history.location.pathname
      history.push(`${path}?login=true`)
    }
  }
  const isDisabled = () => {
    let comparedIds = comparedProjects.map(project => project.id)
    if (comparedIds.includes(project_data.id)) {
      return true
    }
    return false
  }
  // console.log(enabled)
  return (
    <div
      className={`d-flex align-items-center ${location === "detail" ? "comparison_grid_button" : ""
        }`}
      style={{
        ...style,
        cursor: "pointer",
      }}
      onClick={() => {
        if (location === "detail") {
          addToComparison(project_data)
        }
      }}
    >
      <button
        // onClick={onClick}
        onClick={() => {
          if (location === "grid") {
            addToComparison(project_data)
          }
        }}
        className="btn btn-primary btn-sm comparison_grid_button"
        style={{
          ...imageStyles,
          backgroundColor: "transparent",
          border: "none",
          pointerEvents: location === "grid" ? "auto" : "none",
        }}
      >
        {text}
        {enabled ? enabledButton : disabledButton}
      </button>
      {location === "detail" && (
        <div
          className="ms-2 d-none d-md-inline"
          style={{
            fontSize: "14px",
            color: isDisabled() ? "#BAB9B9" : "#495057",
            fontWeight: 300,
            marginTop: "5px",
            marginLeft: "14px",
            marginRight: textMargin ? textMargin : "",
            textDecoration: enabled ? "underline" : "none",
            paddingRight: 48,
          }}
        >
          Add to comparison
        </div>
      )}
    </div>
  )
}

export default ComparisonButton
