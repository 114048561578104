import PropTypes from "prop-types"
import { Image } from "react-bootstrap"
import "./sharePopUp.css"
import React from "react"

/*
required props:
- icon: string (path to image)
- label: string (text to display)
*/

export default function ShareButton({ icon, label, onClick, type}) {
  return (
    <div onClick={onClick}>
      <div className="mb-2 share-button" style={{
        display: "flex", // Use flexbox
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        padding: "1rem", background: "#EEF3F6", cursor: "pointer", borderRadius: "100%"
      }}>
        <Image src={icon} width={32} height={type=='copy'?28:32} />
      </div>
      <div style={{
        color: "#495057",
        fontSize: 14,
        fontWeight: 500
      }} className="text-center">{label}</div>
    </div>
  )
}

ShareButton.propTypes = {
  icons: PropTypes.array,
  label: PropTypes.string,
  onClick: PropTypes.func
}
