/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import * as urls from "../../../helpers/url_helper"

export const comparisonApi = createApi({
  reducerPath: "comparisonApi",
  baseQuery: fetchBaseQuery({ baseUrl: urls.REACT_APP_API_URL }),
  endpoints: builder => ({
    getComparisonData: builder.mutation({
      query: ids => {
        const token = localStorage.getItem("authToken")

        return {
          url: "comparison/comparisonProjects",
          method: "POST",
          body: {
            projectIds: ids,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
          timeout: 10000,
        }
      },
    }),
  }),
})

export const { useGetComparisonDataMutation } = comparisonApi
