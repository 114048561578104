import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import {
  Row,
  Col,
  Form,
  Input,
  Container,
  Button,
  Modal,
  FormFeedback,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import "react-phone-number-input/style.css"
// import { sendEmail,setEmailSucess } from "store/contactus/actions"
import ContactSuccessing from "components/ContactUs/ContactSuccess"
import ContactErroring from "components/ContactUs/ContactError"
import { postContactUs } from "core/ApiContactUs"
import { Helmet } from "react-helmet"

//var SibApiV3Sdk = require('sib-api-v3-sdk');
// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'

const Contacting = () => {
  const history = useHistory()

  function handleContinueClick() {
    history.push("/")
  }

  const dispatch = useDispatch()
  const validationType = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      fullname: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    },

    validationSchema: Yup.object({
      fullname: Yup.string().required("This value is required"),
      email: Yup.string()
        .email("Must be a valid Email")
        .max(255)
        .required("Email is required"),
      phone: Yup.string().max(255),

      subject: Yup.string().required("This value is required"),
      message: Yup.string().required("Message is required"),
    }),

    onSubmit: values => {
      sendMail(values)
      tog_cont_success()
      // eslint-disable-next-line no-undef
      if (process.env.NODE_ENV === "development") {
        // console.log("values", data);
      }
    },
  })

  const [modal_cont_success, setmodal_cont_success] = useState(false)
  const [modal_cont_error, setmodal_cont_error] = useState(false)

  function tog_cont_success() {
    setmodal_cont_success(!modal_cont_success)
  }

  function tog_cont_error() {
    setmodal_cont_error(!modal_cont_error)
  }

  function sendMail(values) {
    var data = {
      email: values.email,
      name: values.fullname,
      message: values.message,
      subject: values.subject,
      phone: values.phone,
    }

    dispatch(postContactUs(data))
  }

  // Contact us page  End here
  function clear() {
    validationType.resetForm({
      values: {
        fullname: "",
        email: "",
      },
    })
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Contact Us - Get in Touch with CarbonHub Today</title>
        <meta
          name="description"
          content="Need assistance or have inquiries about carbon projects? Contact CarbonHub for support and information on how we can help you with your carbon reduction goals."
        />
      </Helmet>
      <div className="contact-us-card ">
        <Row>
          <Col md="4"></Col>
          <Col md="4" className="con-card">
            <div className="card p-3">
              <div className="contact-us-header">
                <h1 style={{ fontSize: 33 }}>Contact Us</h1>
              </div>
              <div>
                <h6 className="text-center">
                  Please send your questions our way. We'll get back soon.
                </h6>
              </div>

              {/* <Contacting /> */}

              <div className="">
                <Container fluid={true}>
                  <div className="contact-container">
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        validationType.handleSubmit()
                        return false
                      }}
                    >
                      <div className="contact-full">
                        <Row>
                          <Col md="12">
                            <Input
                              name="fullname"
                              placeholder="Full name*"
                              type="text"
                              onChange={validationType.handleChange}
                              onBlur={validationType.handleBlur}
                              value={validationType.values.fullname || ""}
                              invalid={
                                validationType.touched.fullname &&
                                validationType.errors.fullname
                                  ? true
                                  : false
                              }
                            />
                            {validationType.touched.fullname &&
                            validationType.errors.fullname ? (
                              <FormFeedback type="invalid">
                                {validationType.errors.fullname}
                              </FormFeedback>
                            ) : null}

                            {/* <div className="invalid-tip" name="validate" id="validate1">&nbsp;Your name is required</div> */}
                          </Col>
                        </Row>
                      </div>
                      <br />
                      <div className="contact-mail">
                        <Row>
                          <Col md="12">
                            <Input
                              name="email"
                              placeholder="Email*"
                              type="email"
                              onChange={validationType.handleChange}
                              onBlur={validationType.handleBlur}
                              value={validationType.values.email || ""}
                              invalid={
                                validationType.touched.email &&
                                validationType.errors.email
                                  ? true
                                  : false
                              }
                            />

                            {validationType.touched.email &&
                            validationType.errors.email ? (
                              <FormFeedback type="invalid">
                                {validationType.errors.email}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                      </div>
                      <br />
                      <div className="contact-phone">
                        <Row>
                          <Col md="12">
                            {/* <PhoneInput
                                      country={'us'}
                                      className="phone-no"
                                      placeholder="Phone"
                                      onChange={validationType.handleChange}
                                    /> */}
                            <Input
                              name="phone"
                              type="number"
                              className="phone-no"
                              placeholder="Phone"
                              onChange={validationType.handleChange}
                              onBlur={validationType.handleBlur}
                            />
                          </Col>
                        </Row>
                      </div>
                      <br />
                      <div className="contact-sub">
                        <Row>
                          <Col md="12">
                            <Input
                              name="subject"
                              type="subject"
                              className="subject"
                              placeholder="Subject*"
                              onChange={validationType.handleChange}
                              onBlur={validationType.handleBlur}
                              value={validationType.values.subject || ""}
                              invalid={
                                validationType.touched.subject &&
                                validationType.errors.subject
                                  ? true
                                  : false
                              }
                            />
                            {validationType.touched.subject &&
                            validationType.errors.subject ? (
                              <FormFeedback type="invalid">
                                {validationType.errors.subject}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                      </div>
                      <br />
                      <div className="contact-message">
                        <Row>
                          <Col md="12">
                            <Input
                              name="message"
                              type="textarea"
                              className="message"
                              placeholder="Message*"
                              onChange={validationType.handleChange}
                              onBlur={validationType.handleBlur}
                              value={validationType.values.message || ""}
                              invalid={
                                validationType.touched.message &&
                                validationType.errors.message
                                  ? true
                                  : false
                              }
                              rows="5"
                            />
                            {validationType.touched.message &&
                            validationType.errors.message ? (
                              <FormFeedback type="invalid">
                                {validationType.errors.message}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                      </div>
                      {/* For success message */}
                      <Button
                        type="submit"
                        className="send-button"
                        style={{
                          backgroundColor: "#7AA5BF",
                          color: "white",
                          border: "none",
                        }}
                        onClick={() => {
                          setTimeout(() => {
                            // console.log(process.env.REACT_APP_APIKEY);
                          }, 200)
                        }}
                      >
                        Send
                      </Button>{" "}
                      {/* For error message */}
                      {/* <Button
                              className="send-button" 
                              onClick={async () => {
                                setTimeout(() => {
                                  tog_cont_error()
                                }, 200);
                                
                                }}>Send
                          </Button> */}
                    </Form>
                  </div>
                </Container>
              </div>

              <Modal
                size="lg"
                isOpen={modal_cont_success}
                toggle={() => {
                  tog_cont_success()
                }}
                className="modal-popscreen"
              >
                <div className="modal-header">
                  <h5
                    className="modal-title mt-0"
                    id="exampleModalFullscreenLabel"
                  >
                    Contact us
                  </h5>
                  <button
                    onClick={() => {
                      clear()
                      setmodal_cont_success(false)
                      handleContinueClick()
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <ContactSuccessing />
                </div>
                <div className="modal-footer">
                  <Button
                    onClick={() => {
                      setmodal_cont_success(false)
                      // setmodal_contact_us(false)
                      handleContinueClick()
                    }}
                    className="continue-btn"
                    type="submit"
                    data-dismiss="modal"
                  >
                    Continue
                  </Button>
                </div>
              </Modal>

              <Modal
                size="lg"
                isOpen={modal_cont_error}
                toggle={() => {
                  tog_cont_error()
                }}
                className="modal-popscreen"
              >
                <div className="modal-header">
                  <h5
                    className="modal-title mt-0"
                    id="exampleModalFullscreenLabel"
                  >
                    Contact us
                  </h5>
                  <button
                    onClick={() => {
                      setmodal_cont_error(false)
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <ContactErroring />
                </div>
                <div className="modal-footer">
                  <Button
                    className="continue-btn"
                    onClick={() => {
                      setmodal_cont_error(false)
                    }}
                    type="submit"
                  >
                    Try again
                  </Button>
                </div>
              </Modal>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default Contacting
