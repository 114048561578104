import LatestProjectIcon from "../../assets/images/latestProjectIcon.svg"
import LatestProjectsIconWhite from "../../assets/images/latestUpdatesWhite.svg"
import { useHistory } from "react-router-dom"  
import { useState } from "react"

const FloatingButton = () => {

  const history = useHistory()
  let path = window.location.pathname
  const [backgroundColour, setbackGroundColour] = useState(path === "/" ? "#FFFFFF" : "#015F81")
  const [textColour, setTextColour] = useState(path === "/" ? "#015F81" : "#FFFFFF")
  const [latestIcon, setLatestIcon] = useState(path === "/" ? LatestProjectIcon : LatestProjectsIconWhite)
  const [hide, setHide] = useState(path === "/latest-updates" ? "none" : "block")

    return(
        <div 
        className="floating-button" 
        onClick={()=>{
            history.push("/latest-updates")
        }}
        style={{
            backgroundColor: backgroundColour,
            color: textColour,
            display: hide,
        }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{
                marginTop: -7,
                paddingRight: 5,
                marginLeft: -3,
              }}> 
                <img
                  src={latestIcon}
                  height={13}
                  width={15}
                />
              </div>
              <div style={{
                // fontSize: "1.5rem",
                // fontWeight: "bold",
                // color: "#FFFFFF",
                marginTop: -6,
              }}>
                Latest Projects
              </div>
            </div>
          </div>
    )
}

export default FloatingButton;