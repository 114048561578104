import { Card, CardBody } from "reactstrap"
import React from "react"
import CardSectionTitle from "../CardSectionTitle"
import CustomTable from "../CustomTable"
import _ from "lodash"

const IssuanceHistory = ({ data,registry }) => {
  return (
    <Card className="shadow-none bg-transparent">
      <CardBody>
        <CardSectionTitle
          title="Issuance History"
          icon={
            <img src="/icons/issuance_history.svg" alt="Issuance history" />
          }
        />
        <div className="mx-4">
          <CustomTable
            fontSize={16}
            data={_.get(data, "[0].data", [])}
            headers={_.get(data, "[0].headers", []).map(item => {
              return {
                label: item.label,
                key: item.key
              }
            })}
            registry={registry}
          />
        </div>
      </CardBody>
    </Card>
  )
}

export default IssuanceHistory
