import React from "react"
import Green from "../../assets/images/landing/green.svg"
import LightBlue from "../../assets/images/landing/lightblue.svg"
import DarkBlue from "../../assets/images/landing/darkblue.svg"

function ProjectCountIndicator({ count }) {
  let pin = Green
  if (count > 10) {
    pin = LightBlue
  }
  if (count > 100) {
    pin = DarkBlue
  }
  return (
    <div
      style={{ width: 40, height: 40 }}
      className="d-flex align-items-center justify-content-center"
    >
      <img
        src={pin}
        style={{
          position: "absolute",
          width: 64,
          height: 64,
        }}
      ></img>
      <div
        style={{
          zIndex: 100,
          color: "white",
          fontFamily: "Poppins",
          fontWeight: "bold",
        }}
      >
        {count}
      </div>
    </div>
  )
}

export default ProjectCountIndicator
