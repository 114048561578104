import { Col, Row } from "react-bootstrap"
import { useEffect, useState } from "react"
import GridCardHeader from "./GridCardHeader"
import { CircleFlag } from "react-circle-flags"
import { RegistryIcon } from "components/Home/RegistryLabel"

export const sectoralScopes = [
  {
    id: 2,
    label: "Nature Based",
    img: (
      <i
        className="fas fa-tree"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Nature Based"
      />
    ),
  },
  {
    id: 3,
    label: "Energy",
    img: (
      <i
        className="fas fa-cloud-sun-rain"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Energy"
      ></i>
    ),
  },
  {
    id: 4,
    label: "Household",
    img: (
      <i
        className="fas fa-home"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Household"
      ></i>
    ),
  },
  {
    id: 5,
    label: "Industrial",
    img: (
      <i
        className="fas fa-industry"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Industrial"
      ></i>
    ),
  },
  {
    id: 6,
    label: "Waste Management",
    img: (
      <i
        className="fas fa-trash-restore"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Waste Management"
      ></i>
    ),
  },
  {
    id: 7,
    label: "Transport",
    img: (
      <i
        className="fas fa-train"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Transport"
      ></i>
    ),
  },
  {
    id: 8,
    label: "Other",
    img: (
      <i
        className="fas fa-poll-h"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Other"
      ></i>
    ),
  },
]

const FeaturedCard = ({ project, isFetching }) => {
  const [registryStatus, setRegistryStatus] = useState("")
  const [secIds, setSecIds] = useState("")
  const [sectoralScopeIcons, setSectoralScopeIcons] = useState([])
  const [top, setTop] = useState("12px")
  const [left, setLeft] = useState("-8px")
  const [borderLeft, setBorderLeft] = useState("12px solid white")
  const [borderRight, setBorderRight] = useState("12px solid white")

  const displayTitle = _.truncate(_.get(project, "project_name"), {
    length: 40,
  })

  useEffect(() => {
    if (project) {
      setSecIds(_.get(project, "project_sectoral_scopes_ids", ""))
      setRegistryStatus(_.get(project, "project_status", "N/A"))
    }
  }, [project])

  useEffect(() => {
    if (typeof secIds === "string" && secIds.length) {
      setSectoralScopeIcons(secIds.split(",").map(id => parseInt(id)))
    }
  }, [secIds])

  return (
    <div>
      {/* larger screens */}
      <div
        className="card mapcard larger_screen_map_card"
        style={{
          height: 171,
          width: 300,
          borderRadius: 16,
          // boxShadow: "rgba(0, 0, 0, 0.25) 0px 2px 7px",
          position: "relative", // Add this to position the arrow relative to the card
        }}
      >
        {isFetching ? (
          <div className="card-body" style={{ height: "100%" }}>
            <p
              className="card-text"
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                width: "100%",
                textAlign: "center",
              }}
            >
              <InfinitySpin color="#025f81" />
            </p>
          </div>
        ) : (
          <Col>
            <div
              className="bg-white h-100 hovered-card"
              style={{
                overflow: "hidden",
                borderRadius: "15px",
                paddingLeft: "18px",
                paddingRight: "18px",
                paddingBottom: "10px",
                paddingTop: "10px",
                // boxShadow:
                //   "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
              }}
              key={project?.id}
            >
              <GridCardHeader project={project} />
              <div className="mb-1" />
              <div
                // onClick={() => onProjectSelected(project?.slug)}
                onClick={() =>
                  window.open(`/carbon-project/${project?.slug}`, "_blank")
                }
                style={{ cursor: "pointer" }}
              >
                <div
                  style={{
                    color: "#495057",
                    fontWeight: 600,
                    fontSize: 16,
                    overflow: "clip",
                    textOverflow: "ellipsis",
                    lineHeight: "1.2rem",
                    height: "2.4rem",
                  }}
                  className="mb-1"
                  title={_.get(project, "project_name")}
                >
                  {displayTitle}
                </div>

                <Row className="mb-0">
                  <Col className="">
                    <div
                      className=" text-start pt-3 pb-4"
                      style={{
                        fontSize: "10px",
                        color: "#959595",
                        lineHeight: "10px",
                        maxHeight: "14px",
                        // textOverflow: 'ellipsis',
                        marginTop: "-5px",
                        overflow: "clip",
                        fontWeight: 500,
                      }}
                    >
                      {/* {_.truncate(registryStatus, 10).toUpperCase()} */}
                      {registryStatus}
                    </div>
                    <div className="d-flex justify-content-start align-items-center">
                      {_.get(project, "countryEmojis.code", undefined) ? (
                        <CircleFlag
                          className="me-2"
                          countryCode={_.get(
                            project,
                            "countryEmojis.code"
                          ).toLowerCase()}
                          height="18px"
                        />
                      ) : null}
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                          fontSize: "11px",
                          lineHeight: "11px",
                          height: "20px",
                          fontWeight: 700,
                        }}
                      >
                        {_.truncate(_.get(project, "country", "N/A"), {
                          length: 20,
                        })}
                      </div>
                    </div>
                  </Col>
                  <Col className="">
                    <div
                      className="d-flex justify-content-end align-items-center text-start pt-3 pb-4"
                      style={{
                        fontSize: "11px",
                        fontFamily: "Poppins",
                        color: "#495057",
                        lineHeight: "10px",
                        maxHeight: "10px",
                        // textOverflow: 'ellipsis',
                        overflow: "clip",
                        fontWeight: 500,
                      }}
                    >
                      {/* {_.truncate(registryStatus, 10).toUpperCase()} */}
                      Credits Issued
                    </div>
                    <div
                      className="mb-0 d-flex justify-content-end align-items-center"
                      style={{
                        fontSize: "29px",
                        color: "#34C38F",
                        fontWeight: 700,
                        lineHeight: "10px",
                      }}
                    >
                      {/* {_.truncate(registryStatus, 10).toUpperCase()} */}
                      {_.get(project, "total_credits_issued", "N/A")}
                    </div>
                  </Col>
                </Row>
                <div className="bottemofcard">
                  <div
                    style={{
                      width: "100%",
                      borderBottom: "1px solid rgba(217, 217, 217, 0.23)",
                    }}
                    className="my-2"
                  ></div>
                </div>
                <Row
                  className="row-cols-2 d-flex align-items-start"
                  style={{ height: "18px", marginBottom: "10px" }}
                >
                  <Col md={8} className="registry-label1 d-flex">
                    <div
                      style={{
                        width: 20,
                      }}
                    >
                      <RegistryIcon registry={_.get(project, "registry")} />
                    </div>
                    <div>
                      <span
                        style={{ color: "#959595" }}
                        className="registry-label ms-1 fw-bold"
                      >
                        {_.get(project, "registry", "N/A").toUpperCase()}
                      </span>
                    </div>
                  </Col>
                  <Col className="text-end" md={4}>
                    {sectoralScopeIcons.slice(0, 3).map((icon, index) => {
                      const scope = sectoralScopes.find(
                        item => item.id === icon
                      )
                      return (
                        <span key={index}>
                          <span
                            style={{
                              fontSize: 16,
                              marginRight: "4px",
                              opacity: 0.75,
                            }}
                          >
                            {
                              sectoralScopes.find(item => item.id === scope?.id)
                                ?.img
                            }
                          </span>
                        </span>
                      )
                    })}
                    {sectoralScopeIcons.length > 3 && (
                      <span
                        className="fw-bold ms-2"
                        style={{
                          fontSize: "11px",
                          color: "#495057",
                          position: "absolute",
                          top: "-3px",
                          right: "5px",
                        }}
                      >
                        <sup>+{sectoralScopeIcons.length - 3}</sup>
                      </span>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        )}
        {/* <div
          style={{
            position: "absolute",
            top: top, // Adjust this to move the arrow up
            left: left,
            width: 14,
            height: 14,
            borderTop: "9px solid transparent",
            borderBottom: "9px solid transparent",
            borderLeft: borderLeft,
            borderRight: borderRight,
          }}
        /> */}
      </div>
      {/* smaller screens */}
      <div
        className="card mapcard smaller_screen_map_card"
        style={{
          height: 191,
          width: 300,
          borderRadius: 16,
          boxShadow: "rgba(0, 0, 0, 0.25) 0px 2px 7px",
          position: "relative", // Add this to position the arrow relative to the card
        }}
      >
        {isFetching ? (
          <div className="card-body" style={{ height: "100%" }}>
            <p
              className="card-text"
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                width: "100%",
                textAlign: "center",
              }}
            >
              <InfinitySpin color="#025f81" />
            </p>
          </div>
        ) : (
          <Col>
            <div
              className="bg-white h-100 hovered-card"
              style={{
                overflow: "hidden",
                borderRadius: "15px",
                paddingLeft: "18px",
                paddingRight: "18px",
                paddingBottom: "18px",
                paddingTop: "10px",
                // boxShadow:
                //   "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"
              }}
              key={project?.id}
            >
              <GridCardHeader project={project} />
              <div className="mb-1" />
              <div
                // onClick={() => onProjectSelected(project?.slug)}
                onClick={() =>
                  window.open(`/carbon-project/${project?.slug}`, "_blank")
                }
                style={{ cursor: "pointer" }}
              >
                <div
                  style={{
                    color: "#495057",
                    fontWeight: 600,
                    fontSize: 16,
                    overflow: "clip",
                    textOverflow: "ellipsis",
                    lineHeight: "1.2rem",
                    height: "2.4rem",
                  }}
                  className="mb-1"
                  title={_.get(project, "project_name")}
                >
                  {displayTitle}
                </div>

                <Row className="mb-0">
                  <Col className="">
                    <div
                      className=" text-start pt-3 pb-4"
                      style={{
                        fontSize: "10px",
                        color: "#959595",
                        lineHeight: "10px",
                        maxHeight: "10px",
                        // textOverflow: 'ellipsis',
                        overflow: "clip",
                        marginTop: "-5px",
                        marginBottom: "5px",
                        fontWeight: 500,
                      }}
                    >
                      {/* {_.truncate(registryStatus, 10).toUpperCase()} */}
                      {registryStatus}
                    </div>
                    <div className="d-flex justify-content-start align-items-center">
                      {_.get(project, "countryEmojis.code", undefined) ? (
                        <CircleFlag
                          className="me-2"
                          countryCode={_.get(
                            project,
                            "countryEmojis.code"
                          ).toLowerCase()}
                          height="18px"
                        />
                      ) : null}
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                          fontSize: "11px",
                          lineHeight: "11px",
                          height: "18px",
                          // overflow: "clip",
                          fontWeight: 700,
                        }}
                      >
                        {_.truncate(_.get(project, "country", "N/A"), {
                          length: 18,
                        })}
                      </div>
                    </div>
                  </Col>
                  <Col className="">
                    <div
                      className="d-flex justify-content-end align-items-center text-end pt-3 pb-4"
                      style={{
                        fontSize: "11px",
                        fontFamily: "Poppins",
                        color: "#495057",
                        lineHeight: "10px",
                        maxHeight: "10px",
                        // textOverflow: 'ellipsis',
                        overflow: "clip",
                        fontWeight: 500,
                      }}
                    >
                      {/* {_.truncate(registryStatus, 10).toUpperCase()} */}
                      Credits Issued
                    </div>
                    <div
                      className="credit mb-0 d-flex justify-content-end align-items-center"
                      style={{
                        fontSize: "29px",
                        color: "#34C38F",
                        fontWeight: 700,
                        lineHeight: "10px",
                      }}
                    >
                      {/* {_.truncate(registryStatus, 10).toUpperCase()} */}
                      {_.get(project, "total_credits_issued", "N/A")}
                    </div>
                  </Col>
                </Row>
                <div className="bottemofcard">
                  <div
                    style={{
                      width: "100%",
                      borderBottom: "1px solid rgba(217, 217, 217, 0.23)",
                    }}
                    className="my-2"
                  ></div>
                </div>
                <Row
                  className="row-cols-2 d-flex align-items-center"
                  style={{ height: "18px", marginBottom: "10px" }}
                >
                  <Col md={8} className="registry-label1 d-flex">
                    <div>
                      <RegistryIcon registry={_.get(project, "registry")} />
                    </div>
                    <div>
                      <span
                        style={{ color: "#959595", fontSize: "10px" }}
                        className="registry-lable  ms-1 fw-bold text-end"
                      >
                        {_.get(project, "registry", "N/A").toUpperCase()}
                      </span>
                    </div>
                  </Col>
                  <Col className="text-end" md={4}>
                    {sectoralScopeIcons.slice(0, 3).map((icon, index) => {
                      const scope = sectoralScopes.find(
                        item => item.id === icon
                      )
                      return (
                        <span key={index}>
                          <span
                            style={{
                              fontSize: 16,
                              marginRight: "4px",
                              opacity: 0.75,
                            }}
                          >
                            {
                              sectoralScopes.find(item => item.id === scope?.id)
                                ?.img
                            }
                          </span>
                        </span>
                      )
                    })}
                    {sectoralScopeIcons.length > 3 && (
                      <span
                        className="fw-bold ms-2"
                        style={{
                          fontSize: "11px",
                          color: "#495057",
                          position: "absolute",
                          top: "-3px",
                          right: "5px",
                        }}
                      >
                        <sup>+{sectoralScopeIcons.length - 3}</sup>
                      </span>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        )}
        {/* <div style={{
                  position: "absolute",
                  top: '-14px',
                  left: '150px',
                  width: 14,
                  height: 14,
                  borderTop: "9px solid transparent",
                  borderBottom: "9px solid transparent",
                  borderLeft : borderLeft,
                  borderRight: borderRight,
                  transform: "rotate(-90deg)"
                }} /> */}
      </div>
    </div>
  )
}

export default FeaturedCard
