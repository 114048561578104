/* eslint-disable no-undef */
/* eslint-disable no-useless-catch */
import api from "./api"

const { createAsyncThunk } = require("@reduxjs/toolkit")

export const postLogin = createAsyncThunk("/auth/login", async payload => {
  try {
    const response = await api.post("/auth/login", payload, {
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    })
    return response.data
  } catch (error) {
    throw error
  }
})
export const checkEmail = createAsyncThunk(
  "/auth/checkUserEmail",
  async payload => {
    try {
      const response = await api.post("/auth/checkUserEmail", payload, {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      })
      return response.data
    } catch (error) {
      throw error
    }
  }
)
export const initPasswordReset = createAsyncThunk(
  "/auth/initResetPasswordLink",
  async payload => {
    try {
      const response = await api.post("/auth/initResetPasswordLink", payload, {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      })
      return response.data
    } catch (error) {
      // throw error
    }
  }
)

export const postSignUp = createAsyncThunk(
  "/user/createUser",
  async payload => {
    try {
      const response = await api.post("/auth/sendVerificationEmail", payload, {
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      })
      const data = response.data
      return data["payload"]
    } catch (error) {
      throw error
    }
  }
)

export const resendVerificationEmail = createAsyncThunk(
  "/user/createUser",
  async payload => {
    try {
      const response = await api.post(
        "/auth/resendVerificationEmail",
        payload,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8"
          }
        }
      )
      const data = response.data
      return data["payload"]
    } catch (error) {
      throw error
    }
  }
)

export const resetPassword = createAsyncThunk(
  "/auth/createUser",
  async payload => {
    try {
      const response = await api.post(
        `/auth/resetPassword/${payload.token}`,
        { password: payload.password },
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8"
          }
        }
      )
      const data = response.data
      return data
    } catch (error) {
      throw error
    }
  }
)
export const verifyEmail = createAsyncThunk("/user/createUser", async token => {
  try {
    const response = await api.post(`/auth/verifyEmail/${token}`, {
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    })
    return response.data
  } catch (error) {
    return error.message
  }
})

export const postCreateUser = createAsyncThunk(
  "user/createUser",
  async payload => {
    try {
      const token = localStorage.getItem("authToken")
      const response = await api.post(
        "/user/createUser",
        payload, // Move payload here
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8"
          }
        }
      )
      const data = response.data
      if (data["error"]) {
        throw new Error(data["payload"])
      }
      return data["payload"]
    } catch (error) {
      throw error
    }
  }
)

export const postPasswordChnage = createAsyncThunk(
  "/user/changePassword",
  async payload => {
    try {
      const token = localStorage.getItem("authToken")
      const response = await api.post("/user/changePassword", payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8"
        }
      })
      const data = response.data
      if (data["error"]) {
        throw new Error(data["payload"])
      }
      return data["payload"]
    } catch (error) {
      throw error
    }
  }
)

export const postUserActivation = createAsyncThunk(
  "/user/disableUser",
  async ({ payload }) => {
    try {
      const token = localStorage.getItem("authToken")
      const response = await api.post(`/user/disableUser`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8"
        }
      })
      const data = response.data
      if (data["error"]) {
        throw new Error(data["payload"])
      }
      return data["payload"]
    } catch (error) {
      throw error
    }
  }
)

export const updateUser = createAsyncThunk("/user/editUser", async payload => {
  try {
    const token = localStorage.getItem("authToken")
    const response = await api.post(
      "/user/editUser",
      payload, // Move payload here
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8"
        }
      }
    )
    const data = response.data
    if (data["error"]) {
      throw new Error(data["payload"])
    }
    return data["payload"]
  } catch (error) {
    throw error
  }
})

export const checkAuthTokenValidity = createAsyncThunk("/auth/checkToken", async (_, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem("authToken");
    const response = await api.post("/auth/checkToken", { token });
    const { payload } = response.data;
    
    if (payload.error) {
      return rejectWithValue(payload.error);
    }

    
    return payload.isLogout;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});
