import Logo_cs from "assets/images/CarbonhubAiFinalLogo.svg"
import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { Container } from "reactstrap"
import successIcon from "../../assets/images/success_Icon.png"
import { RotatingLines } from "react-loader-spinner"
import { verifyEmail } from "core/ApiUsers"
import { useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom"
import Swal from "sweetalert2"

const VerifyEmail = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState("Please wait…")
  const [error, setError] = useState(false)
  const dispatch = useDispatch()
  const { token } = useParams()
  React.useEffect(() => {
    setIsLoading(true)
    if (token) {
      dispatch(verifyEmail(token))
        .unwrap()
        .then(response => {
          if (response.error) {
            setError(true)
          }
          setMessage(response?.payload?.message)
        })
        .catch(error => {
          Swal.fire({
            title: "Error",
            text: `Something went wrong! (${error.message})`,
            icon: "error"
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [token])

  return (
    <React.Fragment>
      <Helmet>
        <title>Verify your email | CarbonHub</title>
      </Helmet>
      <Container
        fluid
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "100vh" }}
      >
        <div
          id="sec-1"
          style={{
            borderRadius: "20px",
            border: "#F3F3F8 1px solid",
            padding: "50px",
            backgroundColor: "#F3F3F8",
            boxShadow: "0px 1px 3px 1px #0000003D",
            minWidth: "600px"
          }}
        >
          <div className="text-center">
            <img
              className="pt-4 pb-4"
              src={Logo_cs}
              style={{ borderRadius: "10px" }}
              alt="Logo"
            />
          </div>
          <div className="text-center mb-4">
            <h3 className="pt-1 pb-0">
              {isLoading
                ? "Please wait…"
                : error
                  ? "Email Verification failed!"
                  : "Great job!"}
            </h3>
          </div>
          {" "}
          <div className="text-center mb-4">
            {isLoading ? (
              <RotatingLines
                visible={true}
                height="30"
                width="30"
                color="grey"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperClass=""
              />
            ) : error ? (
              <svg
                width="105"
                height="105"
                viewBox="0 0 105 105"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M52.5 78.5283C53.5189 78.5283 54.3705 78.1842 55.055 77.4958C55.7433 76.8075 56.0875 75.9539 56.0875 74.935C56.0875 73.92 55.7433 73.0683 55.055 72.38C54.3705 71.6917 53.5189 71.3475 52.5 71.3475C51.4811 71.3475 50.6294 71.6917 49.945 72.38C49.2605 73.0683 48.9164 73.92 48.9125 74.935C48.9086 75.95 49.2528 76.8036 49.945 77.4958C50.6294 78.1842 51.4811 78.5283 52.5 78.5283ZM49.5833 59.2317H55.4166V24.2317H49.5833V59.2317ZM52.5175 105C45.2608 105 38.4358 103.623 32.0425 100.87C25.653 98.1128 20.0939 94.3717 15.365 89.6467C10.6361 84.9217 6.89305 79.3683 4.13583 72.9867C1.37861 66.605 0 59.7819 0 52.5175C0 45.2531 1.37667 38.4281 4.13 32.0425C6.88333 25.6569 10.6244 20.0978 15.3533 15.365C20.0822 10.6322 25.6355 6.88917 32.0133 4.13584C38.3911 1.38251 45.2141 0.0038971 52.4825 8.21596e-06C59.7508 -0.00388067 66.5758 1.37279 72.9575 4.13001C79.3391 6.88723 84.8983 10.6283 89.635 15.3533C94.3716 20.0783 98.1147 25.6317 100.864 32.0133C103.614 38.395 104.992 45.2181 105 52.4825C105.008 59.7469 103.631 66.5719 100.87 72.9575C98.1089 79.343 94.3678 84.9022 89.6466 89.635C84.9255 94.3678 79.3722 98.1108 72.9866 100.864C66.6011 103.618 59.778 104.996 52.5175 105ZM52.5 99.1667C65.5278 99.1667 76.5625 94.6458 85.6041 85.6042C94.6458 76.5625 99.1666 65.5278 99.1666 52.5C99.1666 39.4722 94.6458 28.4375 85.6041 19.3958C76.5625 10.3542 65.5278 5.83334 52.5 5.83334C39.4722 5.83334 28.4375 10.3542 19.3958 19.3958C10.3542 28.4375 5.83333 39.4722 5.83333 52.5C5.83333 65.5278 10.3542 76.5625 19.3958 85.6042C28.4375 94.6458 39.4722 99.1667 52.5 99.1667Z"
                  fill="#F46A6A"
                />
              </svg>
            ) : (
              <img
                src={successIcon}
                style={{ height: "108px" }}
                alt="Success Icon"
              />
            )}
          </div>
          <div className="mt-4 text-center d-flex justify-content-center">
            <div style={{ width: "350px" }}>{message}</div>
          </div>
          <div className="mt-4 mb-3 d-grid">
            <Link
              style={{ fontWeight: 700, backgroundColor: "#7AA5BF" }}
              className="login-button btn btn-secondary link"
              disabled={isLoading}
              to={error ? "/" : "/?login=true"}
            >
              {error ? "Back to Home" : "Continue"}
            </Link>
          </div>
        </div>
      </Container>
    </React.Fragment>
  )
}

export default VerifyEmail
