import React from "react"
import { CircleFlag } from "react-circle-flags"

const CountryIndicator = ({ code, country }) => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <CircleFlag className="me-2" countryCode={code} height={28} width={28} />
      <span
        className="fs-6"
        style={{
          color: "#959595",
          fontWeight: "bold",
        }}
      >
        {country}
      </span>
    </div>
  )
}

export default CountryIndicator
