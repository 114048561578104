import React from "react"
import _ from "lodash"
import { Card, CardBody, Col, Row } from "reactstrap"
import { statusColors } from "./statusColors"
import Chart from "./Chart"
import DoubleLineItem from "../TwoLineItem"

const nf = Intl.NumberFormat()

const formatNumber = value => {
  try {
    const num = _.toNumber(value)
    if (!_.isNaN(num)) {
      return nf.format(value)
    }
  } catch (e) {
    console.error(e)
  }
  return value
}

const CreditsOverview = ({ data = [] }) => {
  const status = _.get(data, "0", {})
  const statusUC = _.get(status, "value", "").toUpperCase()
  const color = statusColors[statusUC]
  const totalCreditsIssued = _.get(_.find(data, { label: 'Total Credits Issued' }), 'value', 0);

  let totalCreditsGenerated = formatNumber(totalCreditsIssued)
  const chartData = _.get(data, "5", {})
  return <Card className="shadow-sm border" style={{ borderRadius: 32 ,padding:'20px'}}>
    <CardBody className="p-4" >
      <Row className="mb-4">
        <Col>
          <div className="p-3 d-flex align-items-center justify-content-center position-relative"
               style={{ background: color, borderRadius: 32 }}>
            <i className="bx bx-info-circle me-2 position-absolute d-none d-md-block"
               style={{ cursor: "pointer", color: "#495057", fontSize: "30px", left: 16 }}
               title="Stage of the project in the registration and crediting process" />
            <div className="text-center d-block d-md-none">
              <i className="bx bx-info-circle me-2"
                 style={{ cursor: "pointer", color: "#495057", fontSize: "30px", left: 16 }}
                 title="Stage of the project in the registration and crediting process" />
            </div>
            <div className="text-center w-100"
                 style={{ fontSize: 16, color: "#495057", fontWeight: "bold" }}>STATUS: {statusUC}</div>
          </div>
        </Col>
      </Row>
      <Row className="d-flex mb-5 align-items-start justify-content-center">
        <Col xs={12} sm={4}>
          <div className="d-flex mb-3 mb-md-0 justify-content-center justify-content-md-start">
            <div className="d-flex w-100 p-3 flex-column align-items-center justify-content-center"
                 style={{ background: "#8DD98E", borderRadius: 16, aspectRatio: 1 }}>
              <svg className="mb-2" width="95" height="79" viewBox="0 0 95 79" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M72.5064 17.8533C60.3255 -0.0226657 35.7336 -4.68228 17.8577 7.49857C-0.0182837 19.6794 -4.6779 44.2714 7.50296 62.1473C13.5934 71.0853 22.7348 76.9924 32.6528 78.8009L34.5167 68.9641C27.1391 67.5662 20.2239 63.3547 15.573 56.5293C6.38201 43.0411 9.85083 24.7338 23.339 15.5427C36.8273 6.35174 55.1346 9.82056 64.3256 23.3088C68.9211 30.0529 69.9824 37.8964 68.5033 45.3294L53.6115 42.5077L70.2217 66.884L94.598 50.2737L78.34 47.1932C80.2039 37.3564 78.5414 26.71 72.451 17.772L72.5064 17.8533Z"
                  fill="white" />
              </svg>
              <div className="mt-4 fw-bold text-white text-center" style={{ fontSize: 15 }}>Total Credits Issued</div>
              <div className="fw-bold" style={{ color: "#006080", fontSize: 42 }}>{totalCreditsGenerated}</div>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={8}>
          <Chart data={chartData} />
        </Col>
      </Row>
      <Row className="gap-3">
        <Col xs={12} md={4}>
          <Card className="shadow-sm border rounded-4 h-100 credit-overview-card">
            <CardBody className="pb-0 px-3">
              <DoubleLineItem marginBottom={"0"} tooltip={_.get(data, "2.tooltip", "N/A")} title="Credits Retired"
                              body={
                                <div style={{ color: "#495057" }}
                                     className="fw-bold credits-issued">{formatNumber(_.get(data, "2.value", "N/A"))}</div>
                              } />
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} md={4}>
          <Card className="shadow-sm border rounded-4 h-100">
            <CardBody className="d-flex px-3 pb-0 h-100 flex-column">
              <DoubleLineItem marginBottom={"0rem"} tooltip={_.get(data, "3.tooltip", "N/A")}
                              title={_.get(data, "3.label", "N/A")}
                              body={
                                <div style={{ fontSize: 22, color: "#495057" }}
                                     className="fw-bold">{formatNumber(_.get(data, "3.value", "N/A"))}</div>
                              } />
              <DoubleLineItem marginBottom={"0rem"} tooltip={_.get(data, "4.tooltip", "N/A")}
                              title={_.get(data, "4.label", "N/A")}
                              body={
                                <div style={{ fontSize: 22, color: "#495057" }}
                                     className="fw-bold">{formatNumber(_.get(data, "4.value", "N/A"))}</div>
                              } />
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card className="shadow-sm border rounded-4 h-100">
            <CardBody className="d-flex px-3 pb-0 h-100 flex-column">
              <DoubleLineItem marginBottom={"0rem"} tooltip={_.get(data, "6.tooltip", "N/A")}
                              title={_.get(data, "6.label", "N/A")}
                              body={
                                <>
                                  <Row className="d-flex mb-3 align-items-center">
                                    <Col xs={12} lg={5}>
                                      <span style={{
                                        fontWeight: "bold",
                                        fontSize: 14, color: "#959595"
                                      }}>{_.get(data, "6.value.0.label", "N/A")}</span>
                                    </Col>
                                    <Col xs={12} lg={7}>
                                      <span style={{ fontSize: 22, fontWeight: "bold" }}>
                                        {formatNumber(_.get(data, "6.value.0.value", "N/A"))}
                                      </span>
                                    </Col>
                                  </Row>
                                  <Row className="d-flex align-items-center">
                                    <Col xs={12} lg={5}>
                                      <span style={{
                                        fontWeight: "bold",
                                        fontSize: 14, color: "#959595"
                                      }}>{_.get(data, "6.value.1.label", "N/A")}</span>
                                    </Col>
                                    <Col xs={12} lg={7}>
                                      <span style={{ fontSize: 22, fontWeight: "bold" }}>
                                        {formatNumber(_.get(data, "6.value.1.value", "N/A"))}
                                      </span>
                                    </Col>
                                  </Row>
                                </>
                              } />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </CardBody>
  </Card>
}

export default CreditsOverview