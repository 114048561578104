const transformPayload = (payload) => {
    const transformedPayload = { ...payload };
  
    for (const key in transformedPayload) {
      if (Array.isArray(transformedPayload[key]) && transformedPayload[key].every(Number.isInteger)) {
        transformedPayload[key] = transformedPayload[key].join(',');
      }
    }
  
    return transformedPayload;
  };


export default transformPayload;