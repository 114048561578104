import ProjectPicker from "components/ProjectComparison/ProjectPicker"
import ListItem from "components/ProjectComparison/Section/ListItem"
import SectionHeader from "components/ProjectComparison/Section/SectionHeader"
import TableHeader from "components/ProjectComparison/Section/TableHeader"
import SelectedProjectCard from "components/ProjectComparison/SelectedProjectCard"
import SelectedProjects from "components/ProjectComparison/SelectedProjects"
import React, { useCallback, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { InfinitySpin } from "react-loader-spinner"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from "reactstrap"
import {
  addToComparedProjects,
  removeFromComparedProjects,
} from "store/RTK-Store/comparisonSlice"
import { useGetComparisonDataMutation } from "store/RTK-Store/services/comparisonApi"
import TopHeader from "../../components/ProjectComparison/topHeader"
import Swal from "sweetalert2"
import TopHeaderMobile from "components/ProjectComparison/topHeaderMobile"
import SectionHeaderMobile from "../../components/ProjectComparison/Section/SectionHeader/indexMobile"
import { Redirect } from "react-router-dom"
import "./Comparison.css"

const Comparison = () => {
  const [activeHash, setActiveHash] = useState("projectBrief")
  const history = useHistory()
  const dispatch = useDispatch()
  const token = localStorage.getItem("authToken")

  if (!token) {
    return <Redirect to="/home" />
  }
  const comparedProjects = useSelector(
    state => state.comparison.comparedProjects
  )

  const projectsToShow = Array.from({ length: 3 }).map(
    (_, index) => comparedProjects[index] || null
  )
  const [comparisonData, setComparisonData] = useState(null)
  const [headerData, setHeaderData] = useState(null)

  const [getComparisonData, { error, isLoading }] =
    useGetComparisonDataMutation()

  const hashToggle = hash => {
    setActiveHash(hash)
    history.push(`/comparison/#${hash}`) // Redirect to the new hash URL using history.push
  }

  const onRemoveProject = id => {
    if (id) {
      dispatch(removeFromComparedProjects(id))
    }
  }

  const [title, setTitle] = useState("")
  // console.log(comparedProjects.length);

  // useEffect(() => {
  //   if (comparedProjects.length > 0) {
  //     const ids = comparedProjects.map(project => project.id)
  //     getComparisonData(ids)
  //       .unwrap()
  //       .then(response => {
  //         let rowData = _.get(response, "payload.data", null)
  //         let headerData = _.get(response, "payload.headers", null)
  //         setComparisonData(rowData)
  //         setHeaderData(headerData)
  //       })
  //       .catch(error => {
  //         console.log(error)
  //       })
  //   } else {
  //     setComparisonData(null)
  //     setHeaderData(null)
  //   }
  // }, [comparedProjects])

  useEffect(() => {
    const getComparisonDataWithRetry = (ids, attempt = 1, maxAttempts = 5) => {
      getComparisonData(ids)
        .unwrap()
        .then(response => {
          let rowData = _.get(response, "payload.data", null);
          let headerData = _.get(response, "payload.headers", null);
          setComparisonData(rowData);
          setHeaderData(headerData);
        })
        .catch(error => {
          console.log(error);
          if (attempt < maxAttempts) {
            setTimeout(() => {
              getComparisonDataWithRetry(ids, attempt + 1, maxAttempts);
            }, 2000 * attempt); // Exponential backoff: increase delay with each attempt
          }
        });
    };

    if (comparedProjects.length > 0) {
      const ids = comparedProjects.map(project => project.id);
      getComparisonDataWithRetry(ids);
    } else {
      setComparisonData(null);
      setHeaderData(null);
    }
  }, [comparedProjects]);

  useEffect(() => {
    const hash = window.location.hash.replace("#", "")
    if (hash) {
      setActiveHash(hash)
      const element = document.getElementById(hash)
      if (element) {
        const offset = 280 // Adjust this value based on the height of your fixed header or any other offset
        const elementPosition =
          element.getBoundingClientRect().top + window.pageYOffset
        const offsetPosition = elementPosition - offset
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        })
      }
    }
  }, [isLoading, activeHash])

  const [showProjectPicker, setShowProjectPicker] = useState(false)

  const [elements, setElements] = useState({ div1: null, div2: null })

  // Gimmic to sync scroll between two divs
  const syncScroll = useCallback(
    e => {
      const { div1, div2 } = elements
      if (!div1 || !div2) return

      const other = e.target === div1 ? div2 : div1
      other.scrollLeft = e.target.scrollLeft
    },
    [elements]
  )

  useEffect(() => {
    const findElements = () => {
      const div1 = document.getElementById("header")
      const div2 = document.getElementById("contents")
      if (div1 && div2) {
        setElements({ div1, div2 })
      }
    }

    findElements()

    // Try to find elements every 100ms if they're not found initially
    const interval = setInterval(() => {
      if (!elements.div1 || !elements.div2) {
        findElements()
      } else {
        clearInterval(interval)
      }
    }, 100)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const { div1, div2 } = elements
    if (!div1 || !div2) return

    div1.addEventListener("scroll", syncScroll)
    div2.addEventListener("scroll", syncScroll)

    return () => {
      div1.removeEventListener("scroll", syncScroll)
      div2.removeEventListener("scroll", syncScroll)
    }
  }, [elements, syncScroll])

  return (
    <>
      <Helmet>
        <title>Compare Projects | CarbonHub</title>
      </Helmet>
      <div
        style={{
          backgroundColor: "#28516A",
          height: "103px",
          marginTop: "55px",
          // paddingBottom: "120px",
        }}
        className="d-flex align-self-center align-items-center p-4"
      >
        <div
          className="p-0"
          style={{
            color: "#fff",
            fontSize: "29px",
            fontWeight: 700,
          }}
        >
          Project Comparison
        </div>
      </div>
      <Container fluid className="bg-white">
        <Row className="pt-3 px-2">
          <Col>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to={`/`}>Home</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <Link to={"/carbon-projects"}>Projects</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>Project Comparison</BreadcrumbItem>
            </Breadcrumb>
          </Col>
        </Row>
      </Container>
      <Container
        fluid
        style={{ minHeight: "80vh", paddingBottom: 100 }}
        className="bg-white mb-5 pb-5 pb-md-0 comparison_container"
      >
        <div
          className="d-none d-md-block"
          style={{
            position: "sticky",
            top: 40,
            zIndex: 1000,
            backgroundColor: "#fff",
            paddingBottom: 20,
          }}
        >
          <Row className="px-4">
            <Col>
              <TopHeader activeHash={activeHash} setActiveHash={hashToggle} />
            </Col>
          </Row>
          <Row
            className="px-4 pt-4 d-none d-md-flex"
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: 4,
                overflowX: "auto",
                minWidth: "100%",
              }}
            >
              <Col lg={4}>
                <SelectedProjects count={comparedProjects?.length} />
              </Col>

              {projectsToShow.map((project, index) => {
                return (
                  <Col style={{ minWidth: 300, paddingLeft: 16 }} key={index}>
                    <SelectedProjectCard
                      key={index}
                      id={project?.id}
                      title={project?.title}
                      subTitle={project?.country}
                      index={index}
                      onRemoveProject={onRemoveProject}
                      activeHash={activeHash}
                      maxWith="100%"
                      slug={project?.slug}
                      onAddProject={() => setShowProjectPicker(true)}
                    />
                  </Col>
                )
              })}
            </div>
          </Row>
        </div>
        <div
          className="d-block d-md-none"
          style={{
            position: "sticky",
            top: 40,
            zIndex: 1000,
            backgroundColor: "#fff",
            paddingTop: 10,
          }}
        >
          <TopHeaderMobile
            activeHash={activeHash}
            setActiveHash={hashToggle}
            count={comparedProjects?.length}
          />
          <div style={{ overflowX: "auto" }} id="header">
            <div className="overFlow_compare">
              <SectionHeaderMobile
                title={title}
                comparedProjects={comparedProjects}
                projectsToShow={projectsToShow}
                onRemoveProject={onRemoveProject}
                activeHash={activeHash}
                onAddProject={() => setShowProjectPicker(true)}
                maxWith="100%"
              />
            </div>
          </div>
        </div>
        {isLoading && (
          <div
            className="d-flex mt-4 justify-content-center align-items-center w-100 h-100"
            style={{
              width: "100%",
              height: 568,
            }}
          >
            <InfinitySpin color="#015F81" />
          </div>
        )}
        {!isLoading && !comparisonData && (
          <div
            className="d-flex mt-4 justify-content-center align-items-center "
            style={{
              width: "100%",
              height: 568,
              fontWeight: 400,
              fontSize: 14,
              color: "#959595",
            }}
          >
            <p>
              No data to display. Please select a project to view the
              information.
            </p>
          </div>
        )}
        {!isLoading && comparisonData && (
          <div
            style={{
              overflowX: "auto",
              minWidth: "100%",
            }}
            id="contents"
          >
            <div className="overFlow_compare" id="projectBrief">
              <div className="d-none d-md-block px-4 pt-4">
                <SectionHeader
                  title={comparisonData["Project Brief"]?.title}
                  onScrollUp={title => {
                    setTitle(title)
                  }}
                />
              </div>
              {comparisonData["Project Brief"].items &&
                comparisonData["Project Brief"].items[0].map((item, index) => (
                  <Row key={index} className="px-4">
                    <ListItem data={item} />
                  </Row>
                ))}
            </div>
            <div className="overFlow_compare" id="projectInformation">
              <div className="p-4">
                <SectionHeader
                  title={comparisonData["Project Information"]?.title}
                  onScrollUp={title => {
                    setTitle(title)
                  }}
                  onScrollDown={() => {
                    setTitle("Project Brief")
                  }}
                />
              </div>
              {comparisonData["Project Information"].items &&
                comparisonData["Project Information"].items[0].map(
                  (item, index) => (
                    <Row key={index} className="px-4">
                      <ListItem data={item} />
                    </Row>
                  )
                )}
            </div>
            <div className="overFlow_compare" id="registryInformation">
              <div className="p-4">
                <SectionHeader
                  title={comparisonData["Registry Information"]?.title}
                  onScrollUp={title => {
                    setTitle(title)
                  }}
                  onScrollDown={() => {
                    setTitle("Registry Information")
                  }}
                />
              </div>
              {comparisonData["Registry Information"].items &&
                comparisonData["Registry Information"].items[0].map(
                  (item, index) => (
                    <Row key={index} className="px-4">
                      <ListItem data={item} />
                    </Row>
                  )
                )}
            </div>
            <div className="overFlow_compare" id="productionProfile">
              <Row className="p-4">
                <SectionHeader
                  title={comparisonData["Production Profile"]?.title}
                  onScrollUp={title => {
                    setTitle(title)
                  }}
                  onScrollDown={() => {
                    setTitle("Product Profile")
                  }}
                />
              </Row>
              {comparisonData["Production Profile"].items &&
                comparisonData["Production Profile"].items[0].map(
                  (item, index) => (
                    <Row key={index} className="px-4">
                      <ListItem data={item} />
                    </Row>
                  )
                )}
            </div>
            <div className="overFlow_compare" id="issuanceHistory">
              <div className="p-4">
                <SectionHeader
                  title={comparisonData["Issuance History"]?.title}
                  onScrollUp={title => {
                    setTitle(title)
                  }}
                  onScrollDown={() => {
                    setTitle("Issuance History")
                  }}
                />
              </div>
              {comparisonData["Issuance History"]?.table &&
                comparisonData["Issuance History"].table[0].map(
                  (item, index) => (
                    // Wrap everything in a fragment to ensure a single parent
                    <React.Fragment key={index}>
                      <Row className="px-4">
                        <TableHeader title={item.title} />
                      </Row>
                      {item.items &&
                        item.items.map((subItem, subIndex) => (
                          <Row key={subIndex} className="px-4">
                            <ListItem data={subItem} />
                          </Row>
                        ))}
                    </React.Fragment>
                  )
                )}
            </div>
            <div className="overFlow_compare" id="proponentInformation">
              <div className="p-4">
                <SectionHeader
                  title={comparisonData["Proponent Information"]?.title}
                  onScrollUp={title => {
                    setTitle(title)
                  }}
                  onScrollDown={() => {
                    setTitle("Proponent Information")
                  }}
                />
              </div>
              {comparisonData["Proponent Information"].items &&
                comparisonData["Proponent Information"].items[0].map(
                  (item, index) => (
                    <Row key={index} className="px-4">
                      <ListItem data={item} />
                    </Row>
                  )
                )}
            </div>
            <div className="overFlow_compare" id="approvalsAndCertifications">
              <div className="p-4">
                <SectionHeader
                  title={comparisonData["Approvals and Certifications"]?.title}
                  onScrollUp={title => {
                    setTitle(title)
                  }}
                  onScrollDown={() => {
                    setTitle("Approvals and Certifications")
                  }}
                />
              </div>
              {comparisonData["Approvals and Certifications"].items &&
                comparisonData["Approvals and Certifications"].items[0].map(
                  (item, index) => (
                    <Row key={index} className="px-4">
                      <ListItem data={item} isSTGs={true} />
                    </Row>
                  )
                )}
            </div>
            <div className="overFlow_compare" id="projectLinks">
              <div className="p-4">
                <SectionHeader
                  title={comparisonData["linksData"]?.title}
                  onScrollUp={title => {
                    setTitle(title)
                  }}
                  onScrollDown={() => {
                    setTitle("Project Links")
                  }}
                />
              </div>
              {comparisonData["linksData"]?.links &&
                comparisonData["linksData"]?.links[0]?.map((item, index) => (
                  <Row key={index} className="px-4">
                    <ListItem isLinks={true} data={item} />
                  </Row>
                ))}
            </div>
          </div>
        )}
        {/* </div> */}
      </Container>
      {showProjectPicker && (
        <ProjectPicker
          open={showProjectPicker}
          onProjectSelected={project_data => {
            let comparedIds = comparedProjects.map(project => project.id)
            if (comparedIds.includes(project_data.id)) {
              Swal.fire({
                title: "Project already added",
                text: "You have already added this project for comparison.",
                toast: true,
                timer: 1300,
                showConfirmButton: false,
                showCancelButton: false,
                icon: "warning",
                position: "top-end",
              }).then()
            } else if (comparedIds.length >= 3) {
              Swal.fire({
                title: "Maximum limit reached",
                text: "You can only compare upto 3 projects.",
                toast: true,
                timer: 1300,
                showConfirmButton: false,
                showCancelButton: false,
                icon: "warning",
                position: "top-end",
              }).then()
            } else {
              setShowProjectPicker(false)
              dispatch(
                addToComparedProjects({
                  id: project_data.id,
                  slug: project_data.slug,
                  title: project_data.project_name,
                  country: project_data?.countryEmojis?.name || project_data?.country || '-',
                })
              )
            }
          }}
          toggle={() => {
            setShowProjectPicker(!showProjectPicker)
          }}
        />
      )}
    </>
  )
}
export default Comparison
