import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: false,
    user: null,
    token: localStorage.getItem("authToken"), // Initialize the token from localStorage
    // other authentication-related state
  },
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null; // Clear the token here
      localStorage.removeItem("authToken"); // Clear token from localStorage
      localStorage.removeItem("comparedProjects"); // Clear comparedProjects from localStorage
      // Reset other authentication-related state
    },
    setToken: (state, action) => {
      state.token = action.payload;
    }
    // other reducers
  },
});

export const { logout, setToken} = authSlice.actions;
export default authSlice.reducer;
