import React from "react"
import { Image } from "react-bootstrap"
import { Col, Row } from "reactstrap"
import PropTypes from "prop-types"
import Disclaimer from "components/DetailsView/Disclaimer"
import useDevice from "helpers/useDevice"

/*
    This component is a simple component that displays a list of items.
    It takes a single prop, data, which is an array of items to display.
    if the item is an array, it will display the items as images.
    
    // Example usage:
    <ListItem data={[
        "Project 1",
        ["https://hatscripts.github.io/circle-flags/flags/ca.svg", "https://beta.carbonhub.ai/static/media/VERA.529fb22661e06f354efd.png"] ,
        "Project 3",
        ""]} />

    required props:
    - data: array (items to display)
*/

const sectoralScopeIconsId = [
  {
    id: 768,
    label: 1,
    title: "1.No Poverty",
  },
  {
    id: 769,
    label: 2,
    title: "2.Zero Hunger",
  },
  {
    id: 770,
    label: 3,
    title: "3.Good Health and well being",
  },
  {
    id: 771,
    label: 4,
    title: "4.Quality education",
  },
  {
    id: 772,
    label: 5,
    title: "5.Gender equality",
  },
  {
    id: 773,
    label: 6,
    title: "6.Clean water and sanitation",
  },
  {
    id: 774,
    label: 7,
    title: "7.Affordable and clean energy",
  },
  {
    id: 775,
    label: 8,
    title: "8.Decent work and economic growth",
  },
  {
    id: 776,
    label: 9,
    title: "9.Industry, innovation and infrastructure",
  },
  {
    id: 777,
    label: 10,
    title: "10.Reduced inequalities",
  },
  {
    id: 778,
    label: 11,
    title: "11.Sustainable cities and communities",
  },
  {
    id: 779,
    label: 12,
    title: "12.Responsible consumption and production",
  },
  {
    id: 780,
    label: 13,
    title: "13.Climate action",
  },
  {
    id: 781,
    label: 14,
    title: "14.Life below water",
  },
  {
    id: 782,
    label: 15,
    title: "15.life on land",
  },
  {
    id: 783,
    label: 16,
    title: "16.Peace, justice and strong institution",
  },
  {
    id: 784,
    label: 17,
    title: "17.Partnership for the goals",
  },
]

export default function ListItem({ data, isLinks, isSTGs }) {
  const splitSGDs = inputString => {
    // console.log(inputString);
    const idArray = inputString.filter(id => id !== null)
    // console.log(idArray);
    return idArray.map(id => {
      return sectoralScopeIconsId.find(icon => icon.id === parseInt(id))
    })
  }
  // console.log(data);
  let dataArray = [data.label, ...data.values]

  while (dataArray.length < 4) {
    dataArray.push("-")
  }

  const disclaimerText =
    "The CarbonHub team is currently training its AI system to retrieve this information from project documents."
  const compareTexts = text => {
    if (typeof text !== "string") {
      return false
    }

    const normalizedText = text
      .trim()
      .split("\n")
      .map(line => line.trim())
      .join(" ")

    return normalizedText.includes(disclaimerText)
  }
  const disclaimerIcon = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 11C9.45 11 9 10.55 9 10V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V10C11 10.55 10.55 11 10 11ZM11 15H9V13H11V15Z"
        fill="#F0A656"
      />
    </svg>
  )

  const formatNumber = number => {
    //check if string contains only a number and format
    if (/^\d+$/.test(number)) {
      return new Intl.NumberFormat("en-US").format(number)
    }
    else{
      return number
    }
  }
  const {isMobile} = useDevice()
  return (
    <div>
      <div
        style={{
          minWidth: "100%",
          backgroundColor: "#fff",
          paddingTop: "20px",
          paddingBottom: "20px",
        }}
      >
        <Row>
          {dataArray?.map((item, index) => (
            <Col
              xs={2}
              sm={3}
              lg={index == 0 ? 4 : ""}
              key={index}
              className="border-end text-start"
              style={{
                paddingLeft: isMobile? "10px" : "20px",
                minWidth: 10,
                maxWidth: index == 0 ? "120%" : "100%",
                display: "flex",
                // overflow: "hidden",
              }}
            >
              {Array.isArray(item) && item.length > 0 ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignContent: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  {/* {item.map((subItem, subIndex) => (
                    <Image
                      key={subIndex}
                      src={subItem}
                      alt="logo"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "10px",
                      }}
                    />
                  ))} */}
                  {splitSGDs(item)?.map((element, index) => (
                    <div
                      key={index}
                      style={{
                        display: "inline-block",
                        marginRight: "5px",
                        marginBottom: "8px",
                      }}
                    >
                      <img
                        className="table-sdg-icon"
                        style={{
                          width: "37px",
                          height: "37px",
                          borderRadius: 6,
                        }}
                        src={`/icons/sdg/${element.label}.jpg`}
                        alt={element.title}
                        title={element.title}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div
                  style={{
                    wordBreak: "break-word",
                    fontSize: "14px",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  {Array.isArray(item) && (
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      -
                    </span>
                  )}
                  {isLinks && index != 0 ? (
                    <a
                      style={{
                        fontWeight: 400,
                      }}
                      href={item}
                    >
                      {item || "-"}
                    </a>
                  ) : compareTexts(item) ? (
                    <Disclaimer
                      className="mt-3"
                      icon={disclaimerIcon}
                      title="Disclaimer"
                      subTitle={item}
                    />
                  ) : (
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: index === 0 ? 600 : 400,
                      }}
                    >
                      {item?formatNumber(item):index === 0 ? "" : "-"}
                    </span>
                  )}
                </div>
              )}
            </Col>
          ))}
        </Row>
      </div>
    </div>
  )
}

ListItem.propTypes = {
  data: PropTypes.array,
}
