import { set } from "lodash"
import { useState, useEffect } from "react"
import { Nav, NavItem, NavLink } from "reactstrap"

const ViewSelector = ({ view, onChange }) => {
  const [bottom, setBottom] = useState("72px")
  const [view_temp, setView_temp] = useState(view)
  const [left, setLeft] = useState("25vw")
  const [right, setRight] = useState("25vw")

  useEffect(() => {
    const updateBottomValue = () => {
      if (window.innerWidth > 2500) {
        if (view === "table" || view === "grid" || view === "map") {
          setBottom("172px")
        }
      } else if (view === "map" && window.innerWidth > 768) {
        setBottom("72px")
        setLeft("25vw")
      } else if (view === "table" && window.innerWidth < 768) {
        setBottom("144px")
        setLeft("20vw")
      } else if (view === "grid" && window.innerWidth < 768) {
        setBottom("125px")
        setLeft("20vw")
      } else if (view === "map" && window.innerWidth < 537) {
        setBottom("128px")
        setLeft("20vw")
      } else {
        setBottom("72px")
        setLeft("25vw")
      }
    }
    window.addEventListener("resize", updateBottomValue)
    updateBottomValue()
    return () => window.removeEventListener("resize", updateBottomValue)
  }, [view])

  // useEffect(() => {
  //   // console.log(view_temp);
  //   if(view_temp=="map"){
  //     if(window.innerWidth > 940){
  //       const scrollHeight = document.documentElement.scrollHeight;
  //       const scrollPosition = window.pageYOffset + window.innerHeight;
  //       const bottomMargin = scrollHeight - scrollPosition;

  //       const minBottom = 125;
  //       const maxBottom = 130;
  //       const minMargin = 0;
  //       const maxMargin = 80;

  //       const marginRatio = (maxMargin - bottomMargin) / (maxMargin - minMargin);

  //       const bottom = minBottom + (maxBottom - minBottom) * marginRatio;
  //       if(bottom < minBottom) {setBottom(`${minBottom}px`)}
  //       else{ setBottom(`${bottom}px`)}
  //     }
  //     if(window.innerWidth < 940){
  //       const scrollHeight = document.documentElement.scrollHeight;
  //       const scrollPosition = window.pageYOffset + window.innerHeight;
  //       const bottomMargin = scrollHeight - scrollPosition;

  //       const minBottom = window.innerWidth < 932 ? 87 : 72;
  //       const maxBottom = window.innerWidth < 932 ? 190 : 125;
  //       const minMargin = 0;
  //       const maxMargin = window.innerWidth < 932 ? 120 : 72;

  //       const marginRatio = (maxMargin - bottomMargin) / (maxMargin - minMargin);

  //       const bottom = minBottom + (maxBottom - minBottom) * marginRatio;
  //       // console.log(maxBottom, window.innerWidth);
  //       if(bottom < minBottom) {setBottom(`${minBottom}px`)}
  //       else{ setBottom(`${bottom}px`)}
  //     }
  //     const handleScroll = () => {
  //       const scrollHeight = document.documentElement.scrollHeight;
  //       const scrollPosition = window.pageYOffset + window.innerHeight;
  //       const bottomMargin = scrollHeight - scrollPosition;

  //       const minBottom = window.innerWidth < 932 ? 87 : 72;
  //       const maxBottom = window.innerWidth < 932 ? 190 : 125;
  //       const minMargin = 0;
  //       const maxMargin = window.innerWidth < 932 ? 120 : 72;

  //       const marginRatio = (maxMargin - bottomMargin) / (maxMargin - minMargin);

  //       const bottom = minBottom + (maxBottom - minBottom) * marginRatio;
  //       // console.log(maxBottom, window.innerWidth);
  //       if(bottom < minBottom) return setBottom(`${minBottom}px`);
  //       setBottom(`${bottom}px`);
  //     };
  //     window.addEventListener("scroll", handleScroll);
  //     return () => window.removeEventListener("scroll", handleScroll);
  //   }
  //   else{
  //     let bottom_temp = window.innerWidth < 932 ? 85 : 72;
  //     setBottom(`${bottom_temp}px`);
  //   }
  //   // console.log(view);
  // }, [view_temp]);

  // console.log(bottom);

  return (
    <div
      className="position-fixed d-flex align-items-center justify-content-center"
      style={{ zIndex: 1100, left: left, right: right, bottom: bottom }}
    >
      <Nav pills justified className="navtab-bg m1ioqu24 nav-bg">
        <NavItem
          className="nav-bg"
          onClick={() => {
            onChange("map")
            setView_temp("map")
          }}
        >
          <NavLink className={view === "map" ? "active1" : "text-white"}>
            <i className="fas fa-map" /> MAP
          </NavLink>
        </NavItem>
        <NavItem
          className="nav-bg"
          onClick={() => {
            onChange("table")
            setView_temp("table")
          }}
        >
          <NavLink className={view === "table" ? "active1" : "text-white"}>
            <i className="fas fa-table" /> TABLE
          </NavLink>
        </NavItem>
        <NavItem
          className="nav-bg"
          onClick={() => {
            onChange("grid")
            setView_temp("grid")
          }}
        >
          <NavLink className={view === "grid" ? "active1" : "text-white"}>
            <i className="fas fa-clone" /> GRID
          </NavLink>
        </NavItem>
      </Nav>
    </div>
  )
}

export default ViewSelector
