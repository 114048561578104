import React from "react"

export default function CardSectionTitle({ title, icon, hasBottomMargin = true }) {
  return (
    <div className="fw-bold d-flex align-items-center" style={{ marginBottom: hasBottomMargin ? "32px" : 0 }}>
      {icon ? (
        <>
          <span>{icon}</span>
          <span style={{ fontSize: "24px", color: "#495057" }} className="ms-2">{title}</span>
        </>
      ) : (
        <span style={{ fontSize: "24px", color: "#495057", paddingLeft: "35px" }}>{title}</span>
      )}
    </div>
  )
}
