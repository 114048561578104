import { useEffect, useState } from "react";

function useDevice() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 932);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 932);
    };

    window.addEventListener('resize', handleResize);
    
    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { isMobile };
}

export default useDevice;
