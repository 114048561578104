import React, { useState } from "react"
import { Button } from "reactstrap"
import SharePopUp from "../../components/SharePopUp"

const ShareIcon = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 15q-.834 0-1.417-.656Q8 13.687 8 12.75q0-.132.017-.272.015-.14.05-.253l-4.7-3.075q-.284.282-.634.441T2 9.75q-.834 0-1.417-.656Q0 8.437 0 7.5t.583-1.594Q1.167 5.25 2 5.25q.384 0 .733.16.35.16.634.44l4.7-3.075a1.5 1.5 0 0 1-.05-.253A2 2 0 0 1 8 2.25q0-.937.583-1.594Q9.167 0 10 0t1.417.656Q12 1.313 12 2.25t-.583 1.594Q10.833 4.5 10 4.5q-.384 0-.733-.159a2.2 2.2 0 0 1-.634-.441l-4.7 3.075q.035.113.05.254a2.3 2.3 0 0 1 0 .543 1.5 1.5 0 0 1-.05.253l4.7 3.075q.284-.28.634-.44T10 10.5q.834 0 1.417.656.583.657.583 1.594t-.583 1.594T10 15"
        fill="#CED4DA"
      />
    </svg>
  )
}

const ShareButton = ({ shareURL }) => {
  let url = shareURL
  if (url.indexOf("localhost") > -1) {
    url = shareURL.replaceAll(
      "http://localhost:3000/",
      "https://beta.carbonhub.ai/"
    )
  }
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <Button
        size="sm"
        onClick={() => {
          setShowModal(true)
        }}
        color="link"
        style={{
          textDecoration: "underline",
          color: "#495057",
          height: 28,
        }}
      >
        <ShareIcon width={17} height={22} />
        <span
          className="ms-2 d-none d-md-inline"
          style={{
            fontSize: 14,
            color: "#495057",
            paddingLeft: 7,
            fontWeight: 300,
          }}
        >
          Share
        </span>
      </Button>
      {showModal ? (
        <SharePopUp
          projectUrl={url}
          open={showModal}
          onClose={() => {
            setShowModal(!showModal)
          }}
        />
      ) : null}
    </>
  )
}

export default ShareButton
