import "./featuredProjectStyles.css"
import React, { useState } from "react"
import { useEffect } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Row } from "reactstrap"
import FeaturedCard from "./FeaturedCard"
import Slider from "react-slick"

const FeatureCarousel = ({ featuredProjects }) => {
  const [projects, setProjects] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (featuredProjects) {
      // console.log(featuredProjects.projectData);
      // setProjects(featuredProjects.projectData?.slice(0, 3))
      setProjects(featuredProjects.projectData)
      setLoading(false)
    }
  }, [featuredProjects])

  // console.log(projects);
  

  return (
    <React.Fragment>
      <Row className="mainDiv slider-container">
        {/* <Carousel
          swipeable={true}
          partialVisbile={false}
          draggable={true}
          showDots={true}
          arrows={true}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={false}
          autoPlaySpeed={1000}
          keyBoardControl={true}
        //   customTransition="all .5"
        //   transitionDuration={500}
        //   containerClass="carousel-container"
        //   removeArrowOnDeviceType={["tablet", "mobile"]}
        //   deviceType={this.props.deviceType}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
            {projects.map((item, index) => (
                <FeaturedCard key={index} project={item} />
            ))}
        </Carousel> */}
        {!loading && (
          <Slider
            swipeToSlide={true}
            infinite={true}
            slidesToShow={3}
            arrows={projects.length > 3}
            slidesToScroll={1}
            // initialSlide={0}
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  arrows: projects.length > 3,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  centerMode: true,
                  variableWidth: true,
                },
              },
              {
                breakpoint: 600,
                settings: {
                  arrows: false,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  centerMode: true,
                  variableWidth: true,
                },
              },
              {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    variableWidth: true,
                },
              },
            ]}
          >
            {projects.map((item, index) => (
              <FeaturedCard key={index} project={item} />
            ))}
            {/* <div
              style={{
                display: "none",
              }}
            >
              <h3>12</h3>
            </div> */}
          </Slider>
        )}
      </Row>
    </React.Fragment>
  )
}

export default FeatureCarousel
