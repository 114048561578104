import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import authReducer from "./RTK-Store/authSlice"
import { projectsApi } from "./RTK-Store/services/projectApi"
import homeApiReducer from "./api/homeApi"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Login from "./auth/login/reducer"
import Profile from "./auth/profile/reducer"
import Account from "./auth/register/reducer"
import tableReducer, { tableSlice } from "./RTK-Store/tableSlice"
import mapReducer, { mapSlice } from "./RTK-Store/mapSlice"
import homeReducer from "./slices/homeSlice"
import projectsReducer from "./RTK-Store/projectsSlice"
import dropdownApiReducer from "./RTK-Store/services/dropDownApi"
import { resetPasswordTokenValidationApi } from "./RTK-Store/services/resetPasswordTokenVerification"
import notificationsReducer, { notificationsSlice } from "./RTK-Store/notificationsSlice"
import { notificationsApi } from "./RTK-Store/services/notificationsApi"
import conparisonReducer, { comparisonSlice } from "./RTK-Store/comparisonSlice"
import { comparisonApi } from "./RTK-Store/services/comparisonApi"

const rootReducer = combineReducers({
  // Public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  // Protected
  auth: authReducer,
  projects: projectsReducer,
  projectsApi: projectsApi.reducer,
  homeApi: homeApiReducer,
  [tableSlice.name]: tableReducer,
  [mapSlice.name]: mapReducer,
  //   New slices
  home: homeReducer,
  dropdownApi: dropdownApiReducer,
  resetPasswordTokenValidationApi: resetPasswordTokenValidationApi,
  // Notification alerts
  [notificationsSlice.name]: notificationsReducer,
  notificationsApi: notificationsApi.reducer,
  [comparisonSlice.name]: conparisonReducer,
  comparisonApi: comparisonApi.reducer,
})

export default rootReducer
