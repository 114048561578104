import _ from "lodash"
import { CircleFlag } from "react-circle-flags"
import { Col, Row } from "reactstrap"
import GridCardHeader from "./GridCardHeader"
import { RegistryIcon } from "./RegistryLabel"
export const sectoralScopes = [
  {
    id: 2,
    label: "Nature Based",
    img: (
      <i
        className="fas fa-tree"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Nature Based"
      ></i>
    ),
  },
  {
    id: 3,
    label: "Energy",
    img: (
      <i
        className="fas fa-cloud-sun-rain"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Energy"
      ></i>
    ),
  },
  {
    id: 4,
    label: "Household",
    img: (
      <i
        className="fas fa-home"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Household"
      ></i>
    ),
  },
  {
    id: 5,
    label: "Industrial",
    img: (
      <i
        className="fas fa-industry"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Industrial"
      ></i>
    ),
  },
  {
    id: 6,
    label: "Waste Management",
    img: (
      <i
        className="fas fa-trash-restore"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Waste Management"
      ></i>
    ),
  },
  {
    id: 7,
    label: "Transport",
    img: (
      <i
        className="fas fa-train"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Transport"
      ></i>
    ),
  },
  {
    id: 8,
    label: "Other",
    img: (
      <i
        className="fas fa-poll-h"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Other"
      ></i>
    ),
  },
]
export default function ProjectCard({ project, onProjectSelected }) {
  const displayTitle = _.truncate(_.get(project, "project_name"), {
    length: 70,
  })
  let sectoralScopeIcons = []
  const secIds = _.get(project, "project_sectoral_scopes_ids", "")
  const registryStatus = _.get(project, "project_status", "N/A")

  if (secIds?.length) {
    sectoralScopeIcons = secIds.split(",")?.map(id => parseInt(id))
  }
  return (
    <Col>
      <div
        className="bg-white h-100 hovered-card"
        style={{
          overflow: "hidden",
          borderRadius: "15px",
          paddingLeft: "18px",
          paddingRight: '18px',
          paddingBottom: '18px',
          paddingTop: '10px',
          boxShadow:
            "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
        }}
        key={project.id}
      >
        <GridCardHeader project={project} />
        <div className="mb-1" />
        <div
          // onClick={() => onProjectSelected(project.slug)}
          onClick={() => window.open(`/carbon-project/${project.slug}`, '_blank')}
          style={{ cursor: "pointer" }}
        >
          <div
            style={{
              color: "#495057",
              fontWeight: 600,
              fontSize: 16,
              overflow: "clip",
              textOverflow: "ellipsis",
              lineHeight: "1.2rem",
              height: "2.4rem",
            }}
            className="mb-1"
            title={_.get(project, "project_name")}
          >
            {displayTitle}
          </div>

          <div
            className=" text-start pt-3 pb-4"
            style={{
              fontSize: "10px",
              color: "#959595",
              lineHeight: "10px",
              maxHeight: "10px",
              // textOverflow: 'ellipsis',
              overflow: "clip",
              fontWeight: 500,
            }}
          >
            {_.truncate(registryStatus, 10).toUpperCase()}
          </div>
          <Row className="row-cols-2">
            <Col>
              <div className="d-flex flex-row align-items-center">
                <div className="me-1">
                  <span
                    style={{
                      padding: "4px",
                      background: "#57C89C",
                      color: "white",
                      fontSize: "8px",
                      borderRadius: "2px",
                    }}
                  >
                    DEV
                  </span>
                </div>
                <div
                  style={{
                    fontSize: "11px",
                    lineHeight: "11px",
                    overflow: "clip",
                    color: "#495057",
                    fontWeight: 700,
                    height: "11px",
                  }}
                >
                  {_.truncate(_.get(project, "project_developer"), 10)}
                </div>
              </div>
            </Col>
            <Col className="text-end">
              <div className="d-flex justify-content-end align-items-center">
                {_.get(project, "countryEmojis.code", undefined) ? (
                  <CircleFlag
                    className="me-2"
                    countryCode={_.get(
                      project,
                      "countryEmojis.code"
                    ).toLowerCase()}
                    height="18px"
                  />
                ) : null}
                <div
                  className="text-end"
                  style={{
                    fontSize: "11px",
                    lineHeight: "11px",
                    height: "11px",
                    overflow: "clip",
                    fontWeight: 700,
                  }}
                >
                  {_.truncate(_.get(project, "country", "N/A"), { length: 10 })}
                </div>
              </div>
            </Col>
          </Row>
          <div className="bottemofcard">
            <div
              style={{
                width: "100%",
                borderBottom: "1px solid rgba(217, 217, 217, 0.23)",
              }}
              className="my-3"
            ></div>
          </div>
          <Row
          className="row-cols-2 d-flex align-items-start"
          style={{ height: "18px" }}
        >
          <Col md={8} className="registry-label1">
            <RegistryIcon registry={_.get(project, "registry")} />
            <span
              style={{ color: "#959595" }}
              className="registry-label ms-1 fw-bold text-end"
            >
              {_.get(project, "registry", "N/A").toUpperCase()}
            </span>
          </Col>
          <Col className="text-end" md={4}>
            {sectoralScopeIcons.slice(0, 3).map((icon, index) => {
              const scope = sectoralScopes.find(item => item.id === icon);
              return (
                <span key={index}>
                  <span
                    style={{
                      fontSize: 16,
                      marginRight: "4px",
                      opacity: 0.75,
                    }}
                  >
                    {sectoralScopes.find(item => item.id === scope?.id)?.img}
                  </span>
                </span>
              );
            })}
            {sectoralScopeIcons.length > 3 && (
              <span
                className="fw-bold ms-2"
                style={{
                  fontSize: "11px",
                  color: '#495057',
                  position: 'absolute',
                  top: '-3px',
                  right: '-2px'
                }}
              >
                <sup>+{sectoralScopeIcons.length - 3}</sup>
              </span>
            )}
          </Col>
        </Row>

        </div>
      </div>
    </Col>
  )
}

// {sectoralScopes?.length < 3 && scopes?.length > 1 && (
//   <span className="fw-bold ms-2" style={{ fontSize: "16px" }}>
//     <div className="fs-3">{scope.img}</div>
//   </span>
// )}
// {sectoralScopes?.length >= 3 && (
// <span className="fw-bold ms-2" style={{ fontSize: "75px" }}>
// <div className="fs-5">{scope.img}</div>
// </span>
// )}  

