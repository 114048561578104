import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { Link, useHistory, useLocation } from "react-router-dom"
import {
  Alert,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Container,
  Row,
} from "reactstrap"
import { useGetLatestProjectsQuery } from "../../store/RTK-Store/services/projectApi"
import LatestProjectsTable from "./TableView"
import { InfinitySpin } from "react-loader-spinner"

function LatestUpdates() {
  const history = useHistory()
  const location = useLocation()

  const [page, setPage] = useState(1)
  const pageSize = 25
  const [pageCount, setPageCount] = useState(0)
  const {
    data,
    isFetching: loading,
    refetch: refetchProjects,
  } = useGetLatestProjectsQuery(
    { page, pageSize: 25 },
    {
      skip: !page || !pageSize,
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    }
  )
  useEffect(() => {
    if (!location.search.includes("login=true")) {
      refetchProjects()
    }
  }, [location.search])

  const [projects, setProjects] = useState([])
  useEffect(() => {
    if (data) {
      const { error, payload } = data
      if (
        error &&
        payload.message === "User not authenticated.Please login first."
      ) {
        sessionStorage.setItem("previousPath", location.pathname)
        history.push(`?login=true`)
      }

      if (!error && payload) {
        const { projectData, totalPages } = payload
        setProjects(projectData)
        setPageCount(totalPages)
      }
    }
  }, [data])
  useEffect(() => {
    if (page) {
      // refetchProjects()
    }
  }, [page])
  return (
    <React.Fragment>
      <Helmet>
        <title>Latest Updates | CarbonHub</title>
      </Helmet>
      <div
        style={{
          backgroundColor: "#28516A",
          height: "103px",
          marginTop: "55px",
        }}
        className="d-flex align-self-center align-items-center p-4"
      >
        <div
          className="p-0"
          style={{
            color: "#fff",
            fontSize: "29px",
            fontWeight: 700,
          }}
        >
          Latest Updates
        </div>
      </div>
      <Container fluid className="bg-white py-3">
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={`/`}>Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={"/carbon-projects"}>Projects</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Latest Updates</BreadcrumbItem>
        </Breadcrumb>
      </Container>
      <Container
        fluid
        style={{ paddingBottom: 160 }}
        className="bg-white"
      >
        <div className="px-2 px-md-3">
          <Row>
            <Col>
              {loading ? (
                <div className="d-flex align-items-center justify-content-center">
                  <div
                    className="d-flex flex-column align-items-center justify-content-center"
                    style={{
                      minHeight: "80vh",
                    }}
                  >
                    <InfinitySpin color="#36d7b7" secondaryColor="#36d7b7" />
                    <br />
                    <span className="text-secondary">Loading Projects...</span>
                  </div>
                </div>
              ) : (
                <div>
                  {projects?.length > 0 ? (
                    <LatestProjectsTable
                      projects={projects}
                      maxPages={pageCount}
                      page={page}
                      loading={loading}
                      onPageChange={page => {
                        setPage(page.selected + 1)
                      }}
                    />
                  ) : (
                    <Alert
                      className="text-center bg-white shadow shadow-sm"
                      style={{
                        marginTop: "180px",
                      }}
                    >
                      <div className="d-flex align-items-center justify-content-center">
                        <span className="text-secondary">
                          There are currently no new projects available. Please
                          check back later for updates.
                        </span>
                      </div>
                    </Alert>
                  )}
                </div>
              )}
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  )
}

export default LatestUpdates
