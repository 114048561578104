import React from "react"

import { Card, CardBody, Container } from "reactstrap"
import { Helmet } from "react-helmet"

const Disclaimer = () => {

  return (
    <React.Fragment>
      <Helmet>
        <title>Disclaimer | Legal Information and Conditions | CarbonHub</title>
        <meta name="description" content="Read CarbonHub's Disclaimer for an understanding of the legal information and conditions governing the use of our platform. Learn about the limitations of liability and copyright information."/>
      </Helmet>
      <Container fluid>
        <div className="disclaimer-container">

          <Card>
            <CardBody>
              <div className="disclaimer-card">
                <h1 className="disc-h">Disclaimer</h1><br></br>
                <div className="header">
                  <h2>Interpretation and Definitions</h2>
                </div>
                <div className="sub-header">
                  <p><strong>Interpretation</strong></p>
                  <p>The words of which the initial letter is capitalized have meanings defined under the following
                    conditions. The following definitions shall have the same meaning regardless of whether they appear
                    in singular or in plural.</p>
                </div>
                &nbsp;

                <div className="sub-header">
                  <p><strong>Definitions</strong></p>
                  <p>For the purposes of this Disclaimer:</p>
                  <p><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this
                    Disclaimer) refers to CarbonHub Enterprises Ltd., Vancouver, BC, Canada.</p>
                  <p><strong>Service</strong> refers to the Website.</p>
                  <p><strong>You</strong> means the individual accessing the Service, or the company, or other legal
                    entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
                  <p className="carbon-link"><strong>Website</strong> refers to CarbonHub, accessible from: <a
                    href="">https://www.carbonhub.ai</a></p>


                </div>
                <br></br>

                <div className="title-bar">
                  <h2>Disclaimer</h2><br></br>
                  <p>The information contained on the Service is for general information purposes
                    only.<br></br><br></br>The Company assumes no responsibility for errors or omissions in the contents
                    of the Service.<br></br><br></br>In no event shall the Company be liable for any special, direct,
                    indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of
                    contract, negligence or other tort, arising out of or in connection with the use of the Service or
                    the contents of the Service. The Company reserves the right to make additions, deletions, or
                    modifications to the contents on the Service at any time without prior notice. This Disclaimer has
                    been created with the help of the Disclaimer Generator.<br></br><br></br>The Company does not
                    warrant that the Service is free of viruses or other harmful components.
                  </p><br></br>
                </div>

                <div className="title-bar">
                  <h3>External Links Disclaimer</h3>
                  <p>The Service may contain links to external websites that are not provided or maintained by or in any
                    way affiliated with the Company. Please note that the Company does not guarantee the accuracy,
                    relevance, timeliness, or completeness of any information on these external websites.
                  </p><br></br>
                </div>

                <div className="title-bar">
                  <h3 className="omi-disc">Errors and Omissions Disclaimer</h3>
                  <p>The information given by the Service is for general guidance on matters of interest only. Even if
                    the Company takes every precaution to insure that the content of the Service is both current and
                    accurate, errors can occur. Plus, given the changing nature of laws, rules and regulations, there
                    may be delays, omissions or inaccuracies in the information contained on the Service.</p>
                  <p>The Company is not responsible for any errors or omissions, or for the results obtained from the
                    use of this information.
                  </p><br></br>
                </div>

                <div className="title-bar">
                  <h3>Fair Use Disclaimer</h3><br></br>
                  <p>The Company may use copyrighted material which has not always been specifically authorized by the
                    copyright owner. The Company is making such material available for criticism, comment, news
                    reporting, teaching, scholarship, or research.<br></br><br></br>The Company believes this
                    constitutes a "fair use" of any such copyrighted material as provided for in section 107 of the
                    United States Copyright law.<br></br><br></br>If You wish to use copyrighted material from the
                    Service for your own purposes that go beyond fair use, You must obtain permission from the copyright
                    owner.
                  </p><br></br>
                </div>

                <div className="title-bar">
                  <h3 className="omi-disc">Views Expressed Disclaimer</h3>
                  <p>The Service may contain views and opinions which are those of the authors and do not necessarily
                    reflect the official policy or position of any other author, agency, organization, employer or
                    company, including the Company.<br></br><br></br>Comments published by users are their sole
                    responsibility and the users will take full responsibility, liability and blame for any libel or
                    litigation that results from something written in or as a direct result of something written in a
                    comment. The Company is not liable for any comment published by users and reserves the right to
                    delete any comment for any reason whatsoever.
                  </p><br></br>
                </div>

                <div className="title-bar">
                  <h3 className="omi-disc">No Responsibility Disclaimer</h3>
                  <p>The information on the Service is provided with the understanding that the Company is not herein
                    engaged in rendering legal, accounting, tax, or other professional advice and services. As such, it
                    should not be used as a substitute for consultation with professional accounting, tax, legal or
                    other competent advisers.<br></br><br></br>In no event shall the Company or its suppliers be liable
                    for any special, incidental, indirect, or consequential damages whatsoever arising out of or in
                    connection with your access or use or inability to access or use the Service.
                  </p><br></br>
                </div>

                <div className="title-bar">
                  <h3>"Use at Your Own Risk" Disclaimer</h3>
                  <p>All information in the Service is provided "as is", with no guarantee of completeness, accuracy,
                    timeliness or of the results obtained from the use of this information, and without warranty of any
                    kind, express or implied, including, but not limited to warranties of performance, merchantability
                    and fitness for a particular purpose.<br></br><br></br>The Company will not be liable to You or
                    anyone else for any decision made or action taken in reliance on the information given by the
                    Service or for any consequential, special or similar damages, even if advised of the possibility of
                    such damages.
                  </p><br></br>
                </div>
              </div>
            </CardBody>
          </Card>

        </div>
      </Container>


    </React.Fragment>

  )
}

export default Disclaimer

