const ViewIndicator = ({ count = 0 }) => {
    return <div className="d-flex align-items-center">
        <span className="me-2">
            <svg
                width="22"
                height="16"
                viewBox="0 0 22 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10.9961 5C10.2004 5 9.43738 5.31607 8.87477 5.87868C8.31216 6.44129 7.99609 7.20435 7.99609 8C7.99609 8.79565 8.31216 9.55871 8.87477 10.1213C9.43738 10.6839 10.2004 11 10.9961 11C11.7917 11 12.5548 10.6839 13.1174 10.1213C13.68 9.55871 13.9961 8.79565 13.9961 8C13.9961 7.20435 13.68 6.44129 13.1174 5.87868C12.5548 5.31607 11.7917 5 10.9961 5ZM10.9961 13C9.67001 13 8.39824 12.4732 7.46056 11.5355C6.52288 10.5979 5.99609 9.32608 5.99609 8C5.99609 6.67392 6.52288 5.40215 7.46056 4.46447C8.39824 3.52678 9.67001 3 10.9961 3C12.3222 3 13.5939 3.52678 14.5316 4.46447C15.4693 5.40215 15.9961 6.67392 15.9961 8C15.9961 9.32608 15.4693 10.5979 14.5316 11.5355C13.5939 12.4732 12.3222 13 10.9961 13ZM10.9961 0.5C5.99609 0.5 1.72609 3.61 -0.00390625 8C1.72609 12.39 5.99609 15.5 10.9961 15.5C15.9961 15.5 20.2661 12.39 21.9961 8C20.2661 3.61 15.9961 0.5 10.9961 0.5Z"
                    fill="#959595"
                />
            </svg>
        </span>
        <span
            style={{
                fontSize: 14,
                color: "#959595"
            }}
        >
            {`${count} ${count === 1 ? 'View' : 'Views'}`}
        </span>
    </div>
}

export default ViewIndicator;