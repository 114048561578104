import React from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import CardSectionTitle from "../CardSectionTitle"
import _ from "lodash"
import CustomTable from "../CustomTable"
import DoubleLineItem from "../TwoLineItem"

const ProponentInformation = ({ data = [] }) => {
  if (!data.length) {
    return null
  }
  const details = _.get(data, "[0]", {})
  const tableData = _.get(details, "data", [])
  const items = _.get(details, "items", [])
  const chunkedItems = _.chunk(items, 4)

  return (
    <Card className="rounded-5 bg-transparent shadow-none border">
      <CardBody>
        <CardSectionTitle
          icon={
            <img alt={_.get("details", "label")} src="/icons/proponent.svg" />
          }
          hasBottomMargin={!tableData.length}
          title={_.get(details, "label", "N/A")}
          style={{ margin: "30px" }}
        />
        {tableData.length ? (
          <div style={{ marginInline: "17px" }}>
            <CustomTable
              data={_.get(details, "data", [])}
              headers={_.get(details, "headers", []).map(item => {
                let width = ""
                if (item.label === "Proponent Name") {
                  width = "20%"
                } else if (item.label === "Contact Name") {
                  width = "20%"
                }
                return {
                  label: item.label,
                  key: item.key,
                  width,
                }
              })}
            />
            <div className="mb-3" />
          </div>
        ) : null}

        {chunkedItems.map((chunk, rowIndex) => (
          <Row key={rowIndex} className="row-cols-1 row-cols-md-4 mb-3">
            {chunk.map((item, colIndex) => (
              <Col key={colIndex}>
                <DoubleLineItem
                  title={_.get(item, "label", "N/A")}
                  body={_.get(item, "value", "N/A")}
                />
              </Col>
            ))}
          </Row>
        ))}
      </CardBody>
    </Card>
  )
}

export default ProponentInformation
