import React from "react"
import PropTypes from "prop-types"

/*
    This component is a simple component that displays the title of a table.
    It takes a single prop, title, which is the title of the table.
  
    // Example usage:
    <TableHeader title="Credits Generated " />
  
    required props:
    - title: string (title of the table)
*/

export default function TableHeader({ title }) {
  return (
    <div>
      <div
        style={{
          backgroundColor: "#F8F8F8",
          padding: "16px",
          margin: "10px 0px 10px 0px",
        }}
      >
        <p
          className="m-0 p-0"
          style={{
            color: "#9C9C9C",
            fontFamily: "poppins",
            fontWeight: "700",
            fontSize: "16px",
            textAlign: "left",
            lineHeight: "24px",
          }}
        >
          {title}
        </p>
      </div>
    </div>
  )
}

TableHeader.propTypes = {
  title: PropTypes.string,
}
