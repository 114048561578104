/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import * as urls from "../../../helpers/url_helper"


export const resetPasswordTokenValidationApi = createApi({
  reducerPath: "resetPasswordTokenVerification",
  baseQuery: fetchBaseQuery({ baseUrl: urls.REACT_APP_API_URL }),
  endpoints: builder => ({
    verifyResetPasswordToken: builder.mutation({
      query: ({ token }) => ({
        url: `/auth/checkResetPasswordValidity/${token}`,
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      })
    })
  })
})

export const {
  useVerifyResetPasswordTokenMutation
} = resetPasswordTokenValidationApi
