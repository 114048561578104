import "../../caronespaceai.css"
import "../../Chatbox.css"
const TokenUsage = ({ data }) => {
  return (
    <div className="chat-box-token-container mb-1 mt-1 ml-1 mr-1">
      <div
        className="chat-box-token-progress-wrapper"
        role="progressbar"
        aria-valuenow={data.percentage}
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div
          className={`chat-box-token-progress-fill ${
            data.percentage >= 100
              ? "chat-box-token-progress-fill-red"
              : "chat-box-token-progress-fill-gradient"
          }`}
          style={{ width: `${data.percentage}%` }}
        ></div>
      </div>
      <div className="chat-box-token-info">
        <span className="chat-box-token-info-text">Daily usage</span>
        <span className="chat-box-token-info-text">
          {data.used}/{data.limit}
        </span>
      </div>
    </div>
  )
}

export default TokenUsage
