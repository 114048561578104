import React from 'react';
import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom"
import Footer from './VerticalLayout/Footer';
// import HeaderNonAuth from './HeaderNonAuth';

const NonAuthLayout = (props) => {
  return (
    <React.Fragment>
      {/* {props.children} */}
      <div id="layout-wrapper">
        {/* <HeaderNonAuth  /> */}

        <div>{props.children}</div>
        <Footer   />
      </div>
    </React.Fragment>
  );
}

NonAuthLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object
}

export default withRouter(NonAuthLayout)
