import { createSlice } from "@reduxjs/toolkit"

const homeSlice = createSlice({
  name: "home",
  initialState: {
    metrics: { totalCredits: 0, availableCredits: 0, retiredCredits: 0 },
    loading: false,
    lastUpdatedDate: "",
  },
  reducers: {
    setMetrics(state, action) {
      state.metrics = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setLastUpdatedDate(state, action) {
      state.lastUpdatedDate = action.payload
    },
  },
})

export const { setMetrics, setLoading ,setLastUpdatedDate} = homeSlice.actions

export default homeSlice.reducer
