import React from "react"
import { ReactComponent as CSAILogo } from "../../assets/images/csai.svg"

function CSAI({ size = "100%" }) {
  return (
    <CSAILogo
      style={{
        fill: "#13536C",
        width: size,
        height: size,
        padding: 0,
      }}
    />
  )
}

export default CSAI
