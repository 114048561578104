/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import _ from "lodash"
import * as urls from "../../../helpers/url_helper"


export const favoriteApi = createApi({
  reducerPath: "favoriteApi",
  baseQuery: fetchBaseQuery({ baseUrl: urls.REACT_APP_API_URL }),
  endpoints: builder => ({
    getMyFavorites: builder.query({
      query: ({ id }) => {
        return `/publish/detailViewPageByslug/${id}`
      },
      transformResponse: response => {
        const details = _.get(response, "payload")
        return details
      }
    }),

    addToFavorites: builder.mutation({
      query: (payload) => {
        const token = localStorage.getItem("authToken")
        return {
          url: "/favs/addToFavourites/",
          method: "POST",
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            "Authorization": `Bearer ${token}`
          }
        }
      }
    }),
    removeFromFavorites: builder.mutation({
      query: (payload) => {
        const token = localStorage.getItem("authToken")
        return {
          url: "/favs/removeFromFavourites/",
          method: "POST",
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            "Authorization": `Bearer ${token}`
          }
        }
      }
    })
  })
})

export const { useGetMyFavoritesQuery, useAddToFavoritesMutation, useRemoveFromFavoritesMutation } = favoriteApi
