import UserProfile from "../pages/Authentication/user-profile"

import ForgetPwd from "../pages/Authentication/ForgetPassword"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"

import Dashboard from "../pages/Dashboard/index"
import Home from "../pages/Home/index"

import AboutUs from "pages/AboutUs/index"
import ContactUs from "pages/ContactUs"
import DetailsView from "pages/DetailsView"
import HelpCenter from "pages/HelpCenter"
import Landing from "pages/Landing"
import Disclaimer from "../pages/Disclaimer/index"
import Policy from "../pages/Policy/index"
import TermsConditions from "../pages/TermsConditions/index"
import VerifyEmail from "../pages/EmailVerify/index"
import BookDemo from "../pages/BookDemo/index"
import Favorites from "../pages/Favorites/index"
import { Redirect } from "react-router-dom"
import Analytics from "../pages/Analytics"
import LatestUpdates from "../pages/LatestUpdates"
import Comparison from "../pages/Comparison"
import Map from "pages/MapView"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/home/:type?:/name?", component: Home }
]

const RedirectToDefault = () => {
  return <Redirect to="/carbon-projects" />
}

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/profile", component: UserProfile },
  { path: "/home/:type?/:search?", component: Home },
  { path: "/carbon-project/:id", component: DetailsView },
  { path: "/terms-and-conditions", component: TermsConditions },
  { path: "/privacy-policy", component: Policy },
  { path: "/disclaimer", component: Disclaimer },
  { path: "/about-us", component: AboutUs },
  { path: "/faq", component: HelpCenter },
  { path: "/contactus", component: ContactUs },
  { path: "/carbon-projects", component: Home },
  { path: "/carbon-projects/registries/:certified", component: Home },
  { path: "/carbon-projects/registries", component: RedirectToDefault },
  { path: "/carbon-projects/countries/:country", component: Home },
  { path: "/carbon-projects/countries", component: RedirectToDefault },
  { path: "/carbon-projects/project-types/:project_types", component: Home },
  { path: "/carbon-projects/project-types", component: RedirectToDefault },
  { path: "/carbon-projects/developers/:developer", component: Home },//MicroEnergy Credits : South Pole : The Gold Standard : Verra
  { path: "/carbon-projects/developers", component: RedirectToDefault },
  { path: "/carbon-projects/regions/:region", component: Home },//Africa : Asia : Europe : Latin America : North America : Oceania
  { path: "/carbon-projects/regions", component: RedirectToDefault },
  { path: "/carbon-projects/project-status/:status", component: Home },//Under Development :Under Validation
  { path: "/carbon-projects/project-status", component: RedirectToDefault },
  { path: "/carbon-projects/sectoral-scopes/:sectoral_scope", component: Home },
  { path: "/carbon-projects/sectoral-scopes", component: RedirectToDefault },
  { path: "/carbon-projects/extra-seals/:seal", component: Home },
  { path: "/carbon-projects/extra-seals", component: RedirectToDefault },
  { path: "/map", component: Map },
  { path: "/verify-email/:token", component: VerifyEmail },
  { path: "/book-demo", component: BookDemo },
  { path: "/favorites", component: Favorites },
  { path: "/analytics", component: Analytics },
  { path: "/latest-updates", component: LatestUpdates },
  { path: "/comparison", component: Comparison }
]

const publicNonAuthLayoutRoutes = [{ path: "/", component: Landing }]

export { authProtectedRoutes, publicNonAuthLayoutRoutes, publicRoutes }
