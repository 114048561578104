import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  Row,
  Col,
  Card,
  Form,
  Nav,
  NavItem,
  NavLink,
  CardBody,
  CardTitle,
  CardText,
  Container,
  Table,
  TabContent,
  TabPane,
  Button,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

//Import Breadcrumb
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
const CarbonCrediting = () => {
    
    

    return (
        <React.Fragment>
          <div className="">
            <Container fluid={true}>
            <div className="Carbon_Credit">
                      <h5>What are Carbon Credits?</h5>
                      <p>Carbon credits, often referred to as <strong>carbon allowances</strong>, can be thought of as a unit of measurement; however, they have a “tradeable” component. <strong>Carbon credits</strong> ARE NOT the same as <strong>carbon offsets.</strong><br/><br/>
                      Carbon credits only exist in jurisdictions that are governed by what’s called a “Cap & Trade” system (such as the California <strong>Cap-and-Trade Program</strong>, which is overseen by the California Air Resources Board).<br/><br/>
                      Carbon credits are created by the governing organization and allocated to individual companies within that jurisdiction. A single credit represents 1 tonne of CO2e (or <strong>Carbon dioxide</strong> equivalent) that the company is allowed to emit.
                      </p>
                      <p><strong>Key Highlights</strong></p>
                      <div className="key-highligts">
                        <p><i className="fas fa-check-circle"></i>  A carbon credit represents 1 tonne of CO2e that an organization is permitted to emit.</p>
                        <p><i className="fas fa-check-circle"></i>  Carbon credits only exist in markets with Cap & Trade regulations.</p>
                        
                        <p><i className="fas fa-check-circle"></i>  Management teams that emit less than their limit may resell carbon credits on the corresponding carbon market.</p>
                        <p><i className="fas fa-check-circle"></i>  Carbon credits are NOT the same as carbon offsets.</p>
                      </div>
                      </div>

                  
                  <div className="credits_work">
                    <h5>How Do Carbon Credits Work?</h5>
                    <p>The number of credits issued to a particular company or organization represents its emissions limit (or “cap” from <strong>Cap & Trade)</strong>. <br/><br/>
                    If a management team is able to limit company emissions below its cap, then the organization has a surplus of <strong>carbon credits</strong>; they may wish to retain these for future use (or sale); alternatively, they may sell them immediately into the compliance <a href="https://corporatefinanceinstitute.com/resources/esg/carbon-markets/">carbon market</a>, which is overseen by the regulatory body.<br/><br/>
                    
                    If a management team cannot keep company emissions under its limit, then they are non-compliant and must make up that difference. Over-emitters turn to the <strong>carbon market</strong> to purchase carbon credits from an “under-emitter” within their Cap & Trade network.
                    </p>
                  </div>

                  
                  <div className="same_offset">
                    <h5>Are Carbon Credits the Same as Offsets?</h5>
                    <p>They are not. Both carbon credits and offsets are measured in tonnes of CO2e, which can make it confusing for people because offsets and credits are absolutely not the same thing.<br/><br/>
                    Unlike carbon credits, offsets are not created or distributed by a specific regulatory body. They are also not limited to individual regulatory jurisdictions (like carbon credits are) – in fact, they may trade freely on any number of “<a href="https://corporatefinanceinstitute.com/resources/esg/voluntary-carbon-market/">voluntary markets</a>” around the world.
                    </p>

                    <p><strong>Credits vs. Offsets</strong></p>
                    <p>Any organization (public, private, governmental, etc.) can elect to engage in carbon reduction projects – either because management/leadership believes it’s the right thing to do or because they wish to generate carbon offsets, which can, in turn, be monetized on the <a href="https://corporatefinanceinstitute.com/resources/esg/carbon-markets/">carbon markets</a>.<br/><br/>
                    Carbon reduction projects generally fall into one of two categories; these are nature-based or mechanical. Nature-based initiatives include reforestation and wetland rejuvenation projects; solutions that “naturally” sequester carbon in the environment. Mechanical solutions are generally investments in new technologies that create increased efficiencies or reduced emissions (like renewable energy projects or direct carbon capture technologies).<br/><br/>
                    If <strong>carbon credits</strong> are a measurement unit to “cap” emissions (meaning permissible emissions), <strong>carbon offsets</strong> can be thought of as a measurement unit to “compensate” an organization for investing in green projects or initiatives (whether natural or technological) that <strong>remove emissions</strong>. <br/><br/>
                    Once an offset is created, it can be retained by the organization that completed the project, or it may be traded on a voluntary carbon market.
                    </p>
                    </div>

                    <div className="source-link">
                    <p><strong>Source:</strong></p>
                    <a href="https://corporatefinanceinstitute.com/resources/esg/carbon-credit/">https://corporatefinanceinstitute.com/resources/esg/carbon-credit/</a><br/>
                    </div>
            </Container>
          </div>
        </React.Fragment>
      );
    }

export default CarbonCrediting
