import React from "react"

import { Card, CardBody, Container } from "reactstrap"
import { Helmet } from "react-helmet"

const TermsNConditions = () => {

  return (
    <React.Fragment>
      <Helmet>
        <title>CarbonHub Terms and Conditions: Understanding Your Rights</title>
        <meta name="description" content="Read CarbonHubs's Terms and Conditions to learn about your rights, responsibilities, and our platform policies. Stay informed about our terms of service." />
      </Helmet>
      <Container fluid>
        <div className="disclaimer-container">
          <Card>
            <CardBody>
              <div className="contion-card">
                <h1 className="term-h" style={{ fontSize: "32.5px" }}>Terms and Conditions</h1>
                <p>Please read these terms and conditions carefully before using Our Service.</p>

                <div className="header">
                  <h2 style={{ fontSize: "26px" }}>Interpretation and Definitions</h2>
                </div>
                <br></br>

                <div className="sub-header">
                  <p><strong>Interpretation</strong></p>
                  <p>The words of which the initial letter is capitalized have meanings defined under the following
                    conditions. The following definitions shall have the same meaning regardless of whether they appear
                    in singular or in plural.
                  </p>
                </div>
                <br></br>

                <div className="sub-header">
                  <p><strong>Definitions</strong></p>
                  <p>For the purposes of this Privacy Policy:</p><br />
                  <p><strong>Account</strong> means a unique account created for You to access our Service or parts of
                    our Service.<br /><br />
                    <strong>Affiliate</strong> means an entity that controls, is controlled by or is under common
                    control with a party, where "control" means ownership of 50% or more of the shares, equity interest
                    or other securities entitled to vote for election of directors or other managing
                    authority.<br /><br />
                    <strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this
                    Agreement) refers to CarbonHub Enterprises Ltd., Vancouver, BC, Canada.<br /><br />
                    <strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any
                    other device by a website, containing the details of Your browsing history on that website among its
                    many uses.<br /><br />
                    <strong>Country</strong> refers to: British Columbia, Canada<br /><br />
                    <strong>Device</strong> means any device that can access the Service such as a computer, a cellphone
                    or a digital tablet.<br /><br />
                    <strong>Personal Data</strong> is any information that relates to an identified or identifiable
                    individual.<br /><br />
                    <strong>Service</strong> refers to the Website.<br /><br />
                    <strong>Service Provider</strong> means any natural or legal person who processes the data on behalf
                    of the Company. It refers to third-party companies or individuals employed by the Company to
                    facilitate the Service, to provide the Service on behalf of the Company, to perform services related
                    to the Service or to assist the Company in analyzing how the Service is used.<br /><br />
                    <strong>Third-party Social Media Service</strong> refers to any website or any social network
                    website through which a User can log in or create an account to use the Service.<br /><br />
                    <strong>Usage Data</strong> refers to data collected automatically, either generated by the use of
                    the Service or from the Service infrastructure itself (for example, the duration of a page
                    visit).<br /><br />
                    <p className="carbon-link"><strong>Website</strong> refers to CarbonHub, accessible from: <a
                      href="">https://www.carbonhub.ai/</a></p>
                    <p><strong>You</strong> means the individual accessing or using the Service, or the company, or
                      other legal entity on behalf of which such individual is accessing or using the Service, as
                      applicable.</p>
                  </p>
                </div>
                <br></br>

                <div className="title-bar">
                  <h2 style={{ fontSize: "26px" }}>Acknowledgment</h2><br></br>
                  <p>These are the Terms and Conditions governing the use of this Service and the agreement that
                    operates between You and the Company. These Terms and Conditions set out the rights and obligations
                    of all users regarding the use of the Service.<br /><br />
                    Your access to and use of the Service is conditioned on Your acceptance of and compliance with these
                    Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access
                    or use the Service.<br /><br />
                    By accessing or using the Service You agree to be bound by these Terms and Conditions. If You
                    disagree with any part of these Terms and Conditions then You may not access the Service.
                    You represent that you are over the age of 18. The Company does not permit those under 18 to use the
                    Service.<br /><br />
                    Your access to and use of the Service is also conditioned on Your acceptance of and compliance with
                    the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the
                    collection, use and disclosure of Your personal information when You use the Application or the
                    Website and tells You about Your privacy rights and how the law protects You. Please read Our
                    Privacy Policy carefully before using Our Service.
                  </p><br />
                </div>

                <div className="title-bar">
                  <h2 style={{ fontSize: "26px" }}>Links to Other Websites</h2><br />
                  <p>Our Service may contain links to third-party web sites or services that are not owned or controlled
                    by the Company.<br /><br />
                    The Company has no control over, and assumes no responsibility for, the content, privacy policies,
                    or practices of any third party web sites or services. You further acknowledge and agree that the
                    Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or
                    alleged to be caused by or in connection with the use of or reliance on any such content, goods or
                    services available on or through any such web sites or services.<br /><br />
                    We strongly advise You to read the terms and conditions and privacy policies of any third-party web
                    sites or services that You visit.
                  </p><br />
                </div>

                <div className="title-bar">
                  <h2 style={{ fontSize: "26px" }}>Termination</h2><br></br>
                  <p>We may terminate or suspend Your access immediately, without prior notice or liability, for any
                    reason whatsoever, including without limitation if You breach these Terms and
                    Conditions.<br /><br />
                    Upon termination, Your right to use the Service will cease immediately.
                  </p><br />
                </div>

                <div className="title-bar">
                  <h2 style={{ fontSize: "26px" }}>Limitation of Liability</h2><br></br>
                  <p>Notwithstanding any damages that You might incur, the entire liability of the Company and any of
                    its suppliers under any provision of this Terms .<br /><br />
                    To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be
                    liable for any special, incidental, indirect, or consequential damages whatsoever (including, but
                    not limited to, damages for loss of profits, loss of data or other information, for business
                    interruption, for personal injury, loss of privacy arising out of or in any way related to the use
                    of or inability to use the Service, third-party software and/or third-party hardware used with the
                    Service, or otherwise in connection with any provision of this Terms), even if the Company or any
                    supplier has been advised of the possibility of such damages and even if the remedy fails of its
                    essential purpose.<br /><br />
                    Some states do not allow the exclusion of implied warranties or limitation of liability for
                    incidental or consequential damages, which means that some of the above limitations may not apply.
                    In these states, each party's liability will be limited to the greatest extent permitted by law.
                  </p><br />
                </div>

                <div className="title-bar">
                  <h2 style={{ fontSize: "26px" }}>"AS IS" and "AS AVAILABLE" Disclaimer</h2><br></br>
                  <p>The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without
                    warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own
                    behalf and on behalf of its Affiliates and its and their respective licensors and service providers,
                    expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect
                    to the Service, including all implied warranties of merchantability, fitness for a particular
                    purpose, title and non-infringement, and warranties that may arise out of course of dealing, course
                    of performance, usage or trade practice. Without limitation to the foregoing, the Company provides
                    no warranty or undertaking, and makes no representation of any kind that the Service will meet Your
                    requirements, achieve any intended results, be compatible or work with any other software,
                    applications, systems or services, operate without interruption, meet any performance or reliability
                    standards or be error free or that any errors or defects can or will be corrected.<br /><br />
                    Without limiting the foregoing, neither the Company nor any of the company's provider makes any
                    representation or warranty of any kind, express or implied: (i) as to the operation or availability
                    of the Service, or the information, content, and materials or products included thereon; (ii) that
                    the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency
                    of any information or content provided through the Service; or (iv) that the Service, its servers,
                    the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan
                    horses, worms, malware, timebombs or other harmful components.<br /><br />
                    Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on
                    applicable statutory rights of a consumer, so some or all of the above exclusions and limitations
                    may not apply to You. But in such a case the exclusions and limitations set forth in this section
                    shall be applied to the greatest extent enforceable under applicable law.
                  </p><br />
                </div>

                <div className="title-bar">
                  <h2 style={{ fontSize: "26px" }}>Governing Law</h2>
                  <p>The laws of the British Columbia, Canada, excluding its conflicts of law rules, shall govern this
                    Terms and Your use of the Service. Your use of the Application may also be subject to other local,
                    state, national, or international laws.</p><br />
                </div>

                <div className="title-bar">
                  <h2 style={{ fontSize: "26px" }}>Disputes Resolution</h2>
                  <p>If You have any concern or dispute about the Service, You agree to first try to resolve the dispute
                    informally by contacting the Company. Dispute if any will be in accordance with laws of British
                    Columbia, Canada.</p><br />
                </div>

                <div className="title-bar">
                  <h2 style={{ fontSize: "26px" }}>Severability and Waiver</h2>
                  <p><strong>Severability</strong><br />If any provision of these Terms is held to be unenforceable or
                    invalid, such provision will be changed and interpreted to accomplish the objectives of such
                    provision to the greatest extent possible under applicable law and the remaining provisions will
                    continue in full force and effect.</p>


                  <p><strong>Waiver</strong><br />Except as provided herein, the failure to exercise a right or to
                    require performance of an obligation under these Terms shall not effect a party's ability to
                    exercise such right or require such performance at any time thereafter nor shall the waiver of a
                    breach constitute a waiver of any subsequent breach.</p>
                  <br />
                </div>

                <div className="title-bar">
                  <h2 style={{ fontSize: "26px" }}>Translation Interpretation</h2>
                  <p>These Terms and Conditions may have been translated if We have made them available to You on our
                    Service. You agree that the original English text shall prevail in the case of a dispute.</p><br />
                </div>

                <div className="title-bar">
                  <h2 style={{ fontSize: "26px" }}>Changes to These Terms and Conditions</h2>
                  <p>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a
                    revision is material We will make reasonable efforts to provide at least 30 days' notice prior to
                    any new terms taking effect. What constitutes a material change will be determined at Our sole
                    discretion.<br />
                    By continuing to access or use Our Service after those revisions become effective, You agree to be
                    bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop
                    using the website and the Service.
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </Container>


    </React.Fragment>

  )
}

export default TermsNConditions

