import DashedCircle from "assets/images/DashedCircle.svg"
import { useSelector } from "react-redux"
import { PuffLoader } from "react-spinners"

const MetricsCard = ({ title, value }) => {
  const loading = useSelector(state => state.home.loading)
  // const loading = true;
  return (
    <div
      className="p-3"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign:"start"
      }}
    >
      <div className="round-bg">
        <div className="d-flex flex-row">
          <div style={{ position: "relative" }} className="blink">
            <img
              alt={title}
              src={DashedCircle}
              className="circle-dash left-aligned-image"
            />
            <div
              className="round-bg-image-content"
              style={{
                position: "absolute",
                top: "60%",
                left: "20%",
                transform: "translateY(-50%)",
              }}
            >
              <p className="image-text" style={{marginTop:-4}}>
                {loading ? (
                  <PuffLoader size={49} color="rgba(255,255,255,0.3)" />
                ) : (
                  value
                )}
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              // justifyContent: "flex-start",
            }}
          >
            <div className="text-white" style={{ fontSize: "14px" }}>
              {title}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MetricsCard
