import { Check, Microscope, Zap } from "lucide-react"
import React, { useRef, useEffect} from "react"

const AgentSetting = ({
  user_id,
  toolHandler,
  advanceMode,
  toolWindowHandler,
  selectedTool,
  isRefreshBtnActive,
  onClose
}) => {
  const dropdownRef = useRef(null)
  const toolOptions = [
    {
      label: "Essential",
      value: "essential",
      dtl: "Simple, less accurate, but fast",
    },
    // {
    //   label: "Advance",
    //   value: "advanced",
    //   dtl: "Think deep, high accuracy, but slow",
    // },
  ]
  if (advanceMode) {
    toolOptions.push({
      label: "Advanced",
      value: "advanced",
      dtl: "Complex, more accurate, but slow",
    })
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            onClose();
        }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
}, [onClose]);


  return (
    <div className="chat-agent-setting-container">
      {toolWindowHandler && ( // Conditionally render a tool window
        <div className="chat-agent-setting-window" ref={dropdownRef}>
          <ul className="chat-agent-setting-list">
            {toolOptions.map(option => (
              <li key={option.value} className="chat-agent-setting-item">
                <button
                  className="chat-agent-setting-button"
                  onClick={() => toolHandler(option.value, user_id)}
                >
                  <div className="chat-agent-setting-content">
                    <div className="chat-agent-setting-icon">
                      {option.value === "essential" ? (
                        <Zap size={24} />
                      ) : (
                        <Microscope size={24} />
                      )}
                    </div>
                    <div className="chat-agent-setting-details">
                      <p>{option.label}</p>
                      <p className="chat-agent-setting-description">
                        {option.dtl}
                      </p>
                      {option.value === "advanced" ? (
                        <p className="chat-agent-setting-warning">
                          ⚠️ Heavy token usage
                        </p>
                      ) : (
                        <p></p>
                      )}
                    </div>
                    <div className="chat-agent-setting-check">
                      {selectedTool === option.value ? (
                        <Check size={24} />
                      ) : (
                        <span />
                      )}
                    </div>
                  </div>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default AgentSetting
