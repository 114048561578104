import React, { useCallback, useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import { debounce } from "lodash"


const PeriodRangeSlider = ({ min, max, onChange, existMax, existMin }) => {
  const [minVal, setMinVal] = useState(min)
  const [maxVal, setMaxVal] = useState(max)
  const [tempMin, setTempMin] = useState(min)
  const [tempMax, setTempMax] = useState(max)
  const [initialState, setInitialState] = useState(1)
  const minValRef = useRef(min)
  const maxValRef = useRef(max)
  const range = useRef(null)

  if (initialState === 1) {
    if (existMin) {
      const value = Math.min(Number(existMin), maxVal - 1)
      setMinVal(value)
      setTempMin(value)
      minValRef.current = value
    }
    else{
      setMinVal(min)
      setTempMin(min)
      minValRef.current = min
    }
    if (existMax) {
      const value = Math.max(Number(existMax), minVal + 1)
      setMaxVal(value)
      setTempMax(value)
      maxValRef.current = value
    }
    else{
      setMaxVal(max)
      setTempMax(max)
      maxValRef.current = max
    }
    setInitialState(0)
  }

  // Convert to percentage
  const getPercent = useCallback(
    value => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  )

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal)
    const maxPercent = getPercent(maxValRef.current)

    if (range.current) {
      range.current.style.left = `${minPercent}%`
      range.current.style.width = `${maxPercent - minPercent}%`
    }
  }, [minVal, getPercent])

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current)
    const maxPercent = getPercent(maxVal)

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`
    }
  }, [maxVal, getPercent])

  // Get min and max values when their state changes
  useEffect(() => {
    onChange({ min: minVal })
  }, [minVal])
  useEffect(() => {
    onChange({ max: maxVal })
  }, [maxVal])
  useEffect(() => {
    setInitialState(1)
  }, [existMax, existMin])

  const debouncedSetMaxVal = useCallback(
    debounce(value => {
      if (value > max) {
        const value = Math.max(Number(max), minVal + 1)
        setMaxVal(value)
        setTempMax(value)
        maxValRef.current = value
      } else {
        const tempValue = Math.max(Number(value), minVal + 1)
        setMaxVal(tempValue)
        setTempMax(tempValue)
        maxValRef.current = tempValue
      }
    }, 1500),
    [] // dependencies
  )
  const debouncedSetMinVal = useCallback(
    debounce(value => {
      if (value < min) {
        const value = Math.min(Number(min), maxValRef.current - 1);
        setMinVal(value);
        setTempMin(value);
        minValRef.current = value;
      } else {
        const tempValue = Math.min(Number(value), maxValRef.current - 1);
        setMinVal(tempValue);
        setTempMin(tempValue);
        minValRef.current = tempValue;
      }
    }, 1500),
    [] // dependencies
  );

  return (
    <div className="container-ranger">
      <input
        type="range"
        min={min}
        max={max}
        value={minVal}
        onChange={event => {
          const value = Math.min(Number(event.target.value), maxVal - 1)
          setMinVal(value)
          setTempMin(value)
          minValRef.current = value
        }}
        className="thumb thumb--left"
        style={{ zIndex: minVal > max - 100 && "5" }}
      />

      <input
        type="range"
        min={min}
        max={max}
        value={maxVal}
        onChange={event => {
          const value = Math.max(Number(event.target.value), minVal + 1)
          setMaxVal(value)
          setTempMax(value)
          maxValRef.current = value
        }}
        className="thumb thumb--right"
      />

      <div className="slider">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
        {/* <div className="slider__left-value">{minVal}</div>
        <div className="slider__right-value">{maxVal}</div> */}
      </div>

      <br />
      <Row>
        <Col md="3">
          <div className="range-input-box">
            <label className="range-text">Start Year</label>
            {/* <h6 className="range-input">{minVal}</h6> */}

            <input
              className="dropdown-range-input"
              type={"number"}
              value={tempMin}
              min={min}
              onChange={event => {
                // if (event.target.value < minVal) {
                //   const value = Math.min(Number(minVal), maxVal - 1)
                //   setMinVal(value)
                //   minValRef.current = value
                // } else {
                //   const value = Math.min(Number(event.target.value), maxVal - 1)
                //   setMinVal(value)
                //   minValRef.current = value
                // }
                let value = event.target.value;
                setTempMin(value);
                debouncedSetMinVal(value);
              }}
            />
          </div>
        </Col>
        <Col md="1">
          <h4 className="dash">-</h4>
        </Col>
        <Col md="3">
          <div className="range-input-box">
            <label className="range-text">End Year</label>
            {/* <h5 className="range-input">{maxVal}</h5> */}
            <input
              className="dropdown-range-input"
              type="number"
              value={tempMax}
              max={max}
              onChange={event => {
                // if (event.target.value > maxVal) {
                //   const value = Math.max(Number(maxVal), minVal + 1)
                //   setMaxVal(value)
                //   maxValRef.current = value
                // } else {
                //   const value = Math.max(Number(event.target.value), minVal + 1)
                //   setMaxVal(value)
                //   maxValRef.current = value
                // }
                let value = event.target.value
                setTempMax(value)
                debouncedSetMaxVal(value)
              }}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}

PeriodRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default PeriodRangeSlider
