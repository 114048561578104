import PropTypes from "prop-types"
import { Modal } from "reactstrap"
import ShareButton from "./ShareIconButton"
import React from "react"
import Swal from "sweetalert2"

/*
required props:
- open: boolean (control modal visibility)
- onClose: function (close modal)
- projectUrl: string (url to share)
*/

export default function SharePopUp({ open, onClose, projectUrl }) {

  const handleAction = action => {

    //handle copy to clipboard action
    if (action.type === "copy") {
      navigator.clipboard.writeText(projectUrl)
      Swal.fire({
        title: "Success",
        text: "Project URL was copied to clipboard!",
        icon: "success",
        toast: true,
        timer: 2000,
        showConfirmButton: false,
        showCancelButton: false,
        position: "top-end"
      }).then()
    }
    //handle social media share action
    if (action.type === "social") {
      switch (action.label) {
        case "Facebook":
          window.open
            ? window.open(
              `https://www.facebook.com/sharer/sharer.php?u=${projectUrl}`,
              "_blank"
            )
            : ""
          break
        case "Twitter":
          window.open
            ? window.open(
              `https://twitter.com/intent/tweet?url=${projectUrl}`,
              "_blank"
            )
            : ""
          break
        case "LinkedIn":
          window.open
            ? window.open(
              `https://www.linkedin.com/shareArticle?url=${projectUrl}`,
              "_blank"
            )
            : ""
          break
        case "Email":
          window.open
            ? window.open(
              `mailto:?subject=Check out this project&body=${projectUrl}`,
              "_blank"
            )
            : ""
          break
        default:
          break
      }
    }
  }

  //list of actions to display
  const actions = [
    {
      icon: "/icons/shareIcons/url.png",
      label: "Copy link",
      type: "copy"
    },
    {
      icon: "/icons/shareIcons/fb.png",
      label: "Facebook",
      type: "social"
    },
    {
      icon: "/icons/shareIcons/x.png",
      label: "Twitter",
      type: "social"
    },
    {
      icon: "/icons/shareIcons/linkedin.png",
      type: "social",
      label: "LinkedIn"
    },
    {
      icon: "/icons/shareIcons/mail.png",
      type: "social",
      label: "Email"
    }
  ]

  return (
    <Modal size="md" isOpen={open} centered onToggle={onClose}>
      <div
        className="modal-header"
        style={{
          borderBottom: 0
        }}
      >
        <h4 className="modal-title mt-0" style={{fontWeight:600}}>Share Project Via</h4>
        <button
          onClick={() => {
            onClose()
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            marginBottom: "20px",
            marginTop: "20px",
            flexDirection: "row"
          }}
        >
          {actions.map((action, index) => (
            <ShareButton type={action.type} icon={action.icon} label={action.label} key={index} onClick={() => {
              handleAction(action)
            }} />
          ))}
        </div>
      </div>
    </Modal>
  )
}

SharePopUp.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  projectUrl: PropTypes.string
}
