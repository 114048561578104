import { Card, CardBody, Col, Row } from "reactstrap"
import React from "react"
import { useMediaQuery } from "react-responsive"
import DoubleLineItem from "../TwoLineItem"
import CardSectionTitle from "../CardSectionTitle"
import _ from "lodash"

const SECTORA_SCOPE_ICONS = [
  {
    id: 768,
    label: 1,
    title: "1.No Poverty",
  },
  {
    id: 769,
    label: 2,
    title: "2.Zero Hunger",
  },
  {
    id: 770,
    label: 3,
    title: "3.Good Health and well being",
  },
  {
    id: 771,
    label: 4,
    title: "4.Quality education",
  },
  {
    id: 772,
    label: 5,
    title: "5.Gender equality",
  },
  {
    id: 773,
    label: 6,
    title: "6.Clean water and sanitation",
  },
  {
    id: 774,
    label: 7,
    title: "7.Affordable and clean energy",
  },
  {
    id: 775,
    label: 8,
    title: "8.Decent work and economic growth",
  },
  {
    id: 776,
    label: 9,
    title: "9.Industry, innovation and infrastructure",
  },
  {
    id: 777,
    label: 10,
    title: "10.Reduced inequalities",
  },
  {
    id: 778,
    label: 11,
    title: "11.Sustainable cities and communities",
  },
  {
    id: 779,
    label: 12,
    title: "12.Responsible consumption and production",
  },
  {
    id: 780,
    label: 13,
    title: "13.Climate action",
  },
  {
    id: 781,
    label: 14,
    title: "14.Life below water",
  },
  {
    id: 782,
    label: 15,
    title: "15.life on land",
  },
  {
    id: 783,
    label: 16,
    title: "16.Peace, justice and strong institution",
  },
  {
    id: 784,
    label: 17,
    title: "17.Partnership for the goals",
  },
]

function capitalizeWord(str) {
  if (!str) {
    return str
  }
  if (str === "n/a") {
    return str.toUpperCase()
  }

  return str.charAt(0).toUpperCase() + str.slice(1)
}

const ApprovalsCertifications = ({ data = [] }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const filteredData = data ? data.filter(item => item.label !== "UNSDGs") : []

  const content = isMobile ? (
    filteredData.map((item, index) => (
      <div key={index} style={{ marginBottom: "1rem",marginLeft:'30px' }}>
        <DoubleLineItem
          title={item.label ? item.label : "Sample label"}
          body={item.value ? capitalizeWord(item.value) : "N/A"}
          tooltip={item.tooltip ? item.tooltip : "Sample tooltip"}
        />
      </div>
    ))
  ) : (
    <Row style={{marginLeft:'20px'}}>
      {[...Array(5)].map((_, index) => {
        const item = index < filteredData.length ? filteredData[index] : null

        return (
          <Col key={index}>
            {item ? (
              <DoubleLineItem
                title={item.label ? item.label : "Sample label"}
                body={item.value ? capitalizeWord(item.value) : "N/A"}
                tooltip={item.tooltip ? item.tooltip : "Sample tooltip"}
              />
            ) : (
              <div style={{ height: "100%", width: "100%" }}></div> // Empty div for empty columns
            )}
          </Col>
        )
      })}
    </Row>
  )

  const goals = data.find(item => item.label === "UNSDGs")
  let icons = []
  if (goals) {
    icons = _.get(goals, "value", "")
      .split(",")
      .map(item => {
        try {
          const itemInt = parseInt(item)
          return SECTORA_SCOPE_ICONS.find(item => item.id === itemInt)
        } catch (error) {
          return null
        }
      })
      .filter(item => !_.isUndefined(item) && !_.isNull(item))
  }
  return (
    <Card className="bg-transparent border rounded-5">
      <CardBody>
        <CardSectionTitle
          title="Approvals and Certification"
          icon={
            <img src="/icons/approvals.svg" alt="Approvals and Certification" />
          }
        />
        {content}
        <Row style={{
          marginLeft: "20px",
        }}>
          <Col>
            <DoubleLineItem
              title="UNSDGs"
              tooltip={goals?.tooltip}
              body={
                icons.length ? (
                  <div className="d-flex flex-wrap gap-3">
                    {icons.map((icon, index) => {
                      if (icon) {
                        return (
                          <div className="icon-container" key={index}>
                            <img
                              src={`/icons/sdg/${icon.label}.jpg`}
                              width={64}
                              height={64}
                              title={icon.title}
                              alt={`Icon ${icon}`}
                              style={{ borderRadius: "6px" }}
                            />
                          </div>
                        )
                      }
                      return null
                    })}
                  </div>
                ) : (
                  "N/A"
                )
              }
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
export default ApprovalsCertifications
