import CardSectionTitle from "../CardSectionTitle"
import DoubleLineItem from "../TwoLineItem"
import _ from "lodash"
import React from "react"
import { Card, CardBody, Col, Row } from "reactstrap"

const ProjectInformation = ({ data = [] }) => {
  return (
    <Card className="bg-transparent rounded rounded-5 border shadow-none">
      <CardBody>
        <CardSectionTitle
          title="Project Information"
          icon={
            <img alt="Project Information" src={"/icons/project-details.svg"} />
          }
        />
        <Row
          className="row-cols-1 row-cols-md-3 "
          style={{ marginLeft: "20px" }}
        >
          {data.map((item, index) => {
            return (
              <Col key={index}>
                <DoubleLineItem
                  title={_.get(item, "label", "Sample item")}
                  body={_.get(item, "value", "N/A")}
                  tooltip={_.get(item, "tooltip", "Sample tooltip")}
                />
              </Col>
            )
          })}
        </Row>
      </CardBody>
    </Card>
  )
}

export default ProjectInformation
