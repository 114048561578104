import React from 'react';

const TopHeader = ({activeHash, setActiveHash}) => {

    const headerOptions = [
        {
            id:1,
            name: "Project Brief",
            hash: "projectBrief"
        },
        {
            id: 2,
            name: "Project Information",
            hash: "projectInformation"
        },
        {
            id: 3,
            name: "Registry Information",
            hash: "registryInformation"
        },
        {
            id: 4,
            name: "Production Profile",
            hash: "productionProfile"
        },
        {
            id: 5,
            name: "Issuance History",
            hash: "issuanceHistory"
        },
        {
            id: 6,
            name: "Proponent Information",
            hash: "proponentInformation"
        },
        {
            id: 7,
            name: "Approvals & Certifications",
            hash: "approvalsAndCertifications"
        },
        {
            id: 8,
            name: "Project Links",
            hash: "projectLinks"
        }
    ]

    return (
        <div
        className='mobile_header'
        style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            backgroundColor: "#F8F8F8",
            height: 72,
            marginTop: 32,
            borderRadius: 8,
            overflowX: "auto"
        }}
        >
            {headerOptions.map(option => (
                <button
                    key={option.id}
                    onClick={() => setActiveHash(option.hash)}
                    style={{
                        backgroundColor: activeHash === option.hash ? "#015F81" : "transparent",
                        border: "none",
                        color: activeHash === option.hash ? "#fff" : "#49505761",
                        fontSize: activeHash === option.hash ? 16 : 14,
                        fontWeight: activeHash === option.hash ? 700 : 400,
                        marginLeft: 16,
                        marginTop: 0,
                        borderRadius: 8,
                        cursor: "pointer",
                        minWidth: 147,
                        height: 56,
                        padding: "0 16px"
                    }}
                >
                    {option.name}
                </button>
            ))}
        </div>
    );
};

export default TopHeader;