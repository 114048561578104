import FavoritesButton from "pages/DetailsView/FavoritesButton"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { InfinitySpin } from "react-loader-spinner"
import ReactPaginate from "react-paginate"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { Alert, Table } from "reactstrap"
import {
  setPageSize,
  setSearchPalylod,
  setTempPayload,
} from "store/RTK-Store/projectsSlice"
import { usePostProjectWithParamsAndPayloadMutation } from "store/RTK-Store/services/projectApi"
import {
  setMaxPages,
  setPage,
  setProjects,
  setTotalProjects,
} from "store/RTK-Store/tableSlice"
import Swal from "sweetalert2"
import { formatClicks } from "../../pages/DetailsView"
import TableButton from "./Comparison/TableButton"
import transformPayload from "./payloadTransform"
import "../../assets/scss/Table.css"
import Select from "react-select"

export function capitalizeEachWord(str) {
  // Split the string into an array of words
  const words = str.split(" ")

  // Iterate through each word and capitalize the first letter
  const capitalizedWords = words.map(word => {
    let lowerCaseWord = word.toLowerCase()
    // Capitalize the first letter of each word
    return lowerCaseWord.charAt(0).toUpperCase() + lowerCaseWord.slice(1)
  })

  // Join the capitalized words back into a single string
  return capitalizedWords.join(" ")
}

const sectoralScopeIconsId = [
  {
    id: 768,
    label: 1,
    title: "1.No Poverty",
  },
  {
    id: 769,
    label: 2,
    title: "2.Zero Hunger",
  },
  {
    id: 770,
    label: 3,
    title: "3.Good Health and well being",
  },
  {
    id: 771,
    label: 4,
    title: "4.Quality education",
  },
  {
    id: 772,
    label: 5,
    title: "5.Gender equality",
  },
  {
    id: 773,
    label: 6,
    title: "6.Clean water and sanitation",
  },
  {
    id: 774,
    label: 7,
    title: "7.Affordable and clean energy",
  },
  {
    id: 775,
    label: 8,
    title: "8.Decent work and economic growth",
  },
  {
    id: 776,
    label: 9,
    title: "9.Industry, innovation and infrastructure",
  },
  {
    id: 777,
    label: 10,
    title: "10.Reduced inequalities",
  },
  {
    id: 778,
    label: 11,
    title: "11.Sustainable cities and communities",
  },
  {
    id: 779,
    label: 12,
    title: "12.Responsible consumption and production",
  },
  {
    id: 780,
    label: 13,
    title: "13.Climate action",
  },
  {
    id: 781,
    label: 14,
    title: "14.Life below water",
  },
  {
    id: 782,
    label: 15,
    title: "15.life on land",
  },
  {
    id: 783,
    label: 16,
    title: "16.Peace, justice and strong institution",
  },
  {
    id: 784,
    label: 17,
    title: "17.Partnership for the goals",
  },
]

const splitSGDs = inputString => {
  const valuesArray = inputString.split(",")

  const idArray = valuesArray
    .map(value => {
      const parts = value.split(".")
      if (parts.length === 2) {
        return parts[0].trim()
      }
      return null
    })
    .filter(id => id !== null)

  return idArray.map(id => {
    return sectoralScopeIconsId.find(icon => icon.label === parseInt(id))
  })
}

const TableView = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    certified,
    sectoral_scope,
    country,
    project_types,
    developer,
    region,
    status,
    seal,
  } = useParams()
  const pageSize = useSelector(state => state.projects.pageSize)
  // const pageSize = useSelector(state => state.projects.pageSize)
  const projects = useSelector(state => state.projectsTable.projects)
  const maxPages = useSelector(state => state.projectsTable.maxPages)
  const totalProjects = useSelector(state => state.projectsTable.totalProjects)
  const page = useSelector(state => state.projectsTable.page)
  const tab = useSelector(state => state.projects.currentTab)
  const view = useSelector(state => state.projects.projectView)
  const dropdownValues = useSelector(state => state.projects.dropdownData)
  const projectType = useSelector(state => state.projects.projectsTypeData)
  const dropdownLoading = useSelector(state => state.projects.loading)
  const registryStausData = useSelector(
    state => state.projects.registryStausData
  )
  const searchPayload = useSelector(state => state.projects.searchPalylod)
  const tempPayload = useSelector(state => state.projects.tempPayload)
  const extraSealsData = useSelector(state => state.projects.sealsData)

  // const [tempPayload, setTempPayload] = useState({})
  const [tempPage, setTempPage] = useState(1)
  const [isFilteredData, setIsFilteredData] = useState(false)
  const [title, setTitle] = useState(
    "Carbon Projects Database - Find Certified and Non-Certified Initiatives"
  )
  const [metaDesc, setMetaDesc] = useState(
    "Browse through the most comprehensive carbon project database. From renewable energy to forestry and industrial projects, find initiatives that align with your sustainability goals."
  )
  const [sectoralScopeId, setSectoralScopeId] = useState("")
  const [loading, setLoading] = useState(true)
  const [postProjectWithParamsAndPayload, { isLoading: projectDataLoading }] =
    usePostProjectWithParamsAndPayloadMutation()

  useEffect(() => {
    setLoading(projectDataLoading)
  }, [projectDataLoading])
  const projectTypes = [
    {
      name: "All",
      icon: "fa-grip-horizontal",
      id: 1,
    },
    {
      icon: "fa-tree",
      name: "Nature Based",
      id: 2,
    },
    {
      icon: "fa-cloud-sun-rain",
      name: "Energy",
      id: 3,
    },
    {
      icon: "fa-home",
      name: "Household",
      id: 4,
    },
    {
      icon: "fa-industry",
      name: "Industrial",
      id: 5,
    },
    {
      icon: "fa-trash-restore",
      name: "Waste Management",
      id: 6,
    },
    {
      icon: "fa-train",
      name: "Transport",
      id: 7,
    },
    {
      icon: "fa-poll-h",
      name: "Other",
      id: 8,
    },
  ]
  const projectStatusTypes = [
    {
      id: 1,
      label: "Registered",
    },
    {
      id: 2,
      label: "Listed",
    },
    {
      id: 3,
      label: "Under Development",
    },
    {
      id: 4,
      label: "On Hold",
    },
    {
      id: 5,
      label: "Rejected",
    },
    {
      id: 6,
      label: "Inactive",
    },
  ]
  // // Bug fix for changin view when pages are different between grid and table
  useEffect(() => {
    let sectoralPayload = searchPayload?.sectoralScopes
    let countryPayload = searchPayload?.country
    let regionPayload = searchPayload?.region
    let projectTypePayload = searchPayload?.projectTypes
    let registryPayload = searchPayload?.registry
    let statusPayload = searchPayload?.registryStatus
    let projectTitle = searchPayload?.projectTitle
    let maxCarbonCreditsPayload = searchPayload?.maxCarbonCredits
    let minCarbonCreditsPayload = searchPayload?.minCarbonCredits
    let endPeriodPayload = searchPayload?.endCreditingPeriod
    let startCreditingPeriodPayload = searchPayload?.startCreditingPeriod
    // let projectStatusPayload = searchPayload?.projectStatus;
    let developerPayload = searchPayload?.projectDeveloper
    let extraSeals = searchPayload?.extraSeals
    let tempPayload = searchPayload
    if (
      (seal ||
        certified ||
        sectoral_scope ||
        country ||
        project_types ||
        developer ||
        region ||
        status) &&
      projectType?.length
    ) {
      // console.log('trigger 1');

      let payload = {
        projectTitle: tempPayload?.projectTitle || "",
        minCarbonCredits: tempPayload?.minCarbonCredits || null,
        maxCarbonCredits: tempPayload?.maxCarbonCredits || null,
        country: tempPayload?.country || [],
        projectTypes: tempPayload?.projectTypes || [],
        sectoralScopes: tempPayload?.sectoralScopes || [],
        registryStatus: tempPayload?.registryStatus || [],
        region: tempPayload?.region || [],
        registry: tempPayload?.registry || [],
        extraSeals: tempPayload?.extraSeals || [],
        startCreditingPeriod: tempPayload?.startCreditingPeriod || null,
        endCreditingPeriod: tempPayload?.endCreditingPeriod || null,
        projectDeveloper: tempPayload?.projectDeveloper || "",
        projectStatus: tempPayload?.projectStatus || [],
      }
      let title = ""
      let metaDescStr = ""
      if (dropdownValues && country) {
        const con = country.replaceAll("-", " ").toLowerCase()
        title = capitalizeEachWord(con)
        const countryId = dropdownValues.host_coun.find(
          item => item.label.replaceAll("-", " ").toLowerCase() === con
        )
        if (!countryId) {
          history.push("/404")
        }
        metaDescStr = `Explore the top carbon projects in ${title} on CarbonHub. From renewable energy to conservation, discover how each project is contributing to the country’s carbon reduction and environmental sustainability efforts.`
        payload.country = countryId?.id.toString() ?? ""
      }
      if (projectType && project_types) {
        const project_type = project_types.replaceAll("-", " ")
        title = capitalizeEachWord(project_type)
        const typeID = projectType.find(
          item =>
            item.label
              .replaceAll("-", " ")
              .replaceAll("/", " ")
              .replace(/\s+/g, " ")
              .toLowerCase() === title.toLowerCase()
        )
        if (!typeID) {
          history.push("/404")
        }
        payload.projectTypes = typeID?.id.toString() ?? ""
        metaDescStr = `Find the best ${title} projects on CarbonHub. Each project contributes to global sustainability goals through innovative and effective carbon reduction and sequestration practices.`
      }
      if (dropdownValues && region) {
        const region_title = region.replaceAll("-", " ")
        title = capitalizeEachWord(region_title)
        const typeID = dropdownValues?.regi?.find(
          item =>
            item.label.replaceAll("-", " ").toLowerCase() ===
            title.toLowerCase()
        )
        if (!typeID) {
          history.push("/404")
        }
        payload.region = typeID?.id.toString() ?? ""
        metaDescStr = `Explore the top carbon credit projects in ${title} on CarbonHub. Find impactful initiatives in ${title} that are driving progress in sustainability and offering valuable carbon reduction solutions.`
      }
      if (registryStausData && status) {
        const status_title = status.replaceAll("-", " ")
        title = capitalizeEachWord(status_title)
        const typeID = registryStausData?.find(
          item =>
            item.label.replaceAll("-", " ").toLowerCase() ===
            title.toLowerCase()
        )
        if (!typeID) {
          history.push("/404")
        }
        payload.registryStatus = typeID?.id.toString() ?? ""
      }
      if (developer) {
        const project_type = developer.replaceAll("-", " ")
        title = developer
        payload.projectDeveloper = title ?? ""
        metaDescStr = `Discover a range of ${title} carbon projects at CarbonHub. Delve into projects that are shaping the future of sustainability with innovative strategies for reducing carbon emissions.`
      }
      if (projectTypes && sectoral_scope) {
        setLoading(true)
        const sectoralScope = sectoral_scope.replaceAll("-", " ").toLowerCase()
        // Keyword map
        const keywordMap = {
          "innovative carbon projects": "other",
          "sustainable transport projects": "transport",
          "waste management solutions": "waste management",
          "household carbon projects": "household",
          "nature based projects": "nature based",
          "industrial carbon solutions": "industrial",
          "renewable energy projects": "energy",
        }

        // Check if sectoralScope exists in the keywordMap
        const trueKeyWord = keywordMap[sectoralScope] ?? sectoralScope

        title = capitalizeEachWord(sectoralScope)
        // console.log(title);
        const sectoralScopeId = projectTypes.find(
          item => item.name.toLowerCase() === trueKeyWord.toLowerCase()
        )
        if (!sectoralScopeId) {
          history.push("/404")
        }
        metaDescStr = `Explore ${capitalizeEachWord(
          sectoralScope
        )} carbon projects to invest on CarbonHub. Discover initiatives committed to reducing emissions and advancing sustainability in the ${title} sector.`
        if (sectoralScopeId) {
          const id = sectoralScopeId.id
          if (id !== 1) {
            payload = {
              ...payload,
              sectoralScopes: `${id}`,
            }
          }
        }
      }
      if (dropdownValues && certified) {
        const registry = certified
          .replaceAll("-certified-projects", "")
          .replaceAll("-", " ")
        title = capitalizeEachWord(registry)
        title = `Comprehensive List of ${title} Certified Carbon Projects | CarbonHub`
        metaDescStr = `Join CarbonHub in advancing climate action through ${title} certified carbon projects. Explore a curated list spanning renewable energy, forestry, and beyond to support verified carbon offset and reduction initiatives worldwide.`
        const registryId = dropdownValues?.regi_str?.find(
          item => item.label.toLowerCase() === registry
        )
        if (!registryId) {
          history.push("/404")
        }
        if (searchPayload) {
          payload = { ...searchPayload }
        }
        payload = {
          ...payload,
          registry: registryId?.id.toString() ?? "",
        }
      }
      if (seal && extraSealsData) {
        const seal_title = seal.replaceAll("-", " ").toLowerCase().trim()
        const sealId = extraSealsData?.find(
          item => item.label.toLowerCase().trim() === seal_title
        )
        title = sealId?.label ?? "Certified Carbon Projects | CarbonHub"

        if (!sealId) {
          history.push("/404")
        }

        if (searchPayload) {
          payload = { ...searchPayload }
        }

        payload = {
          ...payload,
          extraSeals: sealId?.id ?? "",
        }

        metaDescStr = `Explore carbon projects on CarbonHub with the esteemed ${title} certification. These projects meet high standards for sustainability and carbon reduction, contributing to environmental excellence.`
      }
      dispatch(setSearchPalylod(payload))
      // console.log('triggered');
      setIsFilteredData(true)

      if (status) {
        let dateNow = new Date().getFullYear()
        let titleString = `
        Best
        Carbon
        Reduction: ${title} in ${dateNow}
      |
        CarbonHub`
        metaDescStr = `Discover a range of ${title} carbon projects at CarbonHub. Delve into projects that are shaping the future of sustainability with innovative strategies for reducing carbon emissions.`

        setTitle(titleString)
        // console.log(titleString);
      } else if (sectoral_scope) {
        let dateNow = new Date().getFullYear()
        let titleWords = title.split(" ")

        if (
          titleWords[titleWords.length - 1] === "Projects" ||
          titleWords[titleWords.length - 1] === "Solutions"
        ) {
          titleWords.pop()
        }

        let newTitle = titleWords.join(" ")

        let titleString
        if (newTitle === "Other") {
          titleString = `
        Unique
        and
        Innovative
        Carbon
        Projects
        to
        Invest in ${dateNow} | Innovative
        Solutions
        CarbonHub`
        } else if (newTitle === "Nature Based") {
          titleString = `
        Best
        Top ${newTitle}
        Carbon
        Projects
        to
        Invest in ${dateNow} | ${newTitle} Solutions - CarbonHub`
        } else {
          titleString = `
        Best
        Top ${newTitle}
        Based
        Carbon
        Projects
        to
        Invest in ${dateNow} | ${newTitle} Solutions - CarbonHub`
        }
        setTitle(titleString)
      } else if (developer) {
        let titleString = `${title}
        Carbon
        Reduction
        Projects | CarbonHub`
        setTitle(titleString)
      } else if (project_types) {
        let dateNow = new Date().getFullYear()
        let titleString = `${title}
        Carbon
        Reduction
        Projects
        to
        Invest in ${dateNow} | CarbonHub`
        setTitle(titleString)
      } else if (region) {
        let dateNow = new Date().getFullYear()
        let titleString = `${title}
        Region
        Carbon
        Credit
        Projects
        To
        Invest in ${dateNow} | Leading
        Initiatives - CarbonHub`
        setTitle(titleString)
      } else if (country) {
        let dateNow = new Date().getFullYear()
        let titleString = `
        Best
        Carbon
        Reduction
        Projects
        to
        Invest in ${title} ${dateNow} | CarbonHub`
        setTitle(titleString)
      } else if (seal) {
        let titleString = `Certified ${title} Carbon Projects | CarbonHub`
        setTitle(titleString)
      } else {
        setTitle(title)
      }
      setMetaDesc(metaDescStr)
    } else {
      if (
        !sectoralPayload &&
        !countryPayload &&
        !regionPayload &&
        !projectTypePayload &&
        !registryPayload &&
        !statusPayload &&
        !developerPayload &&
        !projectTitle &&
        !extraSeals &&
        !maxCarbonCreditsPayload &&
        !minCarbonCreditsPayload &&
        !endPeriodPayload &&
        !startCreditingPeriodPayload &&
        !(
          seal ||
          certified ||
          sectoral_scope ||
          country ||
          project_types ||
          developer ||
          region ||
          status
        )
      ) {
        let payload = {
          projectTitle: "",
          minCarbonCredits: null,
          maxCarbonCredits: null,
          country: [],
          projectTypes: [],
          sectoralScopes: [],
          registryStatus: [],
          region: [],
          registry: [],
          extraSeals: [],
          startCreditingPeriod: null,
          endCreditingPeriod: null,
          projectDeveloper: "",
          projectStatus: [],
        }
        // console.log('trigger 2');
        dispatch(setSearchPalylod(payload))
        setIsFilteredData(true)
      }
    }
  }, [
    certified,
    dropdownValues,
    sectoral_scope,
    country,
    project_types,
    projectType,
  ])

  useEffect(() => {
    setLoading(true)
    setIsFilteredData(true)
    let currentPage = page // Use a local variable to determine the current page

    if (!_.isEqual(searchPayload, tempPayload) && searchPayload) {
      dispatch(setTempPayload(searchPayload))
      dispatch(setPage(1))
      currentPage = 1
    } else {
      setTempPage(page)
    }
    if (
      view === "table" &&
      !projectDataLoading &&
      searchPayload &&
      !dropdownLoading
    ) {
      let tempPayload = searchPayload
      const transformedPayload = transformPayload(tempPayload)
      postProjectWithParamsAndPayload({
        type: tab,
        page: currentPage, // Use currentPage here
        pageSize,
        payload: transformedPayload,
      })
        .unwrap()
        .then(res => {
          dispatch(setMaxPages(res.payload?.totalPages ?? 1))
          dispatch(setProjects(res.payload?.projectData ?? []))
          dispatch(setTotalProjects(res.payload?.allPublishProjects ?? null))
          setIsFilteredData(false)
        })
        .catch(error => {
          Swal.fire({
            title: "Error",
            text: "Something went wrong! (" + error.message + ")",
            icon: "error",
          })
          setIsFilteredData(false)
        })
    }
  }, [tab, view, page, searchPayload, dropdownLoading, pageSize])

  // useEffect(() => {
  //   setTempPage(page)
  // }, [page])

  // useEffect(() => {
  //   if(!_.isEqual(searchPayload, tempPayload) && searchPayload){
  //     // console.log('searchPayload', searchPayload);
  //     // console.log('tempPayload', tempPayload);
  //     setTempPayload(searchPayload)
  //     dispatch(setPage(1))
  //   }
  // }, [searchPayload])
  // Render

  const handlePageSizeChange = event => {
    dispatch(setPageSize(Number(event.value)))
    dispatch(setPage(1))
  }

  const [marginPagesDisplayed, setMarginPagesDisplayed] = useState(3)

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(max-width: 576px)").matches) {
        setMarginPagesDisplayed(2)
      } else {
        setMarginPagesDisplayed(3)
      }
    }

    handleResize() // Set initial value
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const pageOptions = [
    { id: 1, value: 25, label: "25" },
    { id: 2, value: 50, label: "50" },
    { id: 3, value: 100, label: "100" },
  ]

  return (
    <div
      className="bg-white"
      style={{
        minHeight: "90vh",
        padding: "5px 12px 0 10px",
      }}
    >
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={metaDesc} />
      </Helmet>
      {isFilteredData && (
        <div className="d-flex align-items-center justify-content-center">
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{
              minHeight: "80vh",
            }}
          >
            <InfinitySpin color="#36d7b7" secondaryColor="#36d7b7" />
            <br />
            <span className="text-secondary">Loading Projects...</span>
          </div>
        </div>
      )}
      {projects.length ? (
        <>
          <div
            style={{
              marginBottom: "16px",
              overflowX: "auto",
              position: "relative",
            }}
          >
            {loading ? (
              <div className="d-flex align-items-center justify-content-center">
                <div
                  className="d-flex flex-column align-items-center justify-content-center"
                  style={{
                    minHeight: "80vh",
                  }}
                >
                  <InfinitySpin color="#36d7b7" secondaryColor="#36d7b7" />
                  <br />
                  <span className="text-secondary">Loading Projects...</span>
                </div>
              </div>
            ) : (
              <div className="table-container">
                <Table className="table table-striped mb-0">
                  <thead>
                    <tr>
                      <th></th>
                      <th>CH ID</th>
                      <th>Registry</th>
                      <th style={{ whiteSpace: "nowrap" }}>Registry ID</th>
                      <th>Project Name</th>
                      <th>Status</th>
                      <th>Developer</th>
                      <th>Country</th>
                      <th style={{ whiteSpace: "nowrap" }}>Sectoral Scope</th>
                      <th>Type</th>
                      <th>Methodology</th>
                      <th>SDGs</th>
                      <th>Seals</th>
                      <th style={{ whiteSpace: "nowrap" }}>
                        Estimated Emission Reduction
                      </th>
                      <th style={{ whiteSpace: "nowrap" }}>
                        Total Credits Issued
                      </th>
                      <th style={{ whiteSpace: "nowrap" }}>
                        Total Credits Retired
                      </th>
                      <th style={{ whiteSpace: "nowrap" }}>
                        Total Credits Available
                      </th>
                      <th style={{ whiteSpace: "nowrap" }}>
                        Total Buffer Credits
                      </th>
                      <th style={{ whiteSpace: "nowrap" }}>Project Length</th>
                      <th style={{ whiteSpace: "nowrap" }}>Crediting Period</th>
                      <th>Views</th>
                      <th style={{ whiteSpace: "nowrap" }}>Action</th>
                      <th style={{ whiteSpace: "nowrap" }}></th>
                    </tr>
                  </thead>

                  <tbody style={{ whiteSpace: "nowrap" }}>
                    {projects.map(item => {
                      let fmtViews = formatClicks(item.clicks ?? 0)
                      if (fmtViews === "0") {
                        fmtViews = "-"
                      }
                      return (
                        <tr
                          key={item.id}
                          // onClick={async () => {
                          //   let url = "/carbon-project/" + item.slug
                          //   if (certified) {
                          //     url = `${url} ? from = carbon - projects /${certified}`
                          //   }
                          //   history.push(url)
                          // }}
                          onClick={async () => {
                            let url = `/carbon-project/${item.slug}`
                            if (certified) {
                              url = `${url}?from=carbon-projects/${certified}`
                            }
                            window.open(url, "_blank")
                          }}
                          className="table-row-hover"
                        >
                          <td>
                            <FavoritesButton
                              iconWidth={15}
                              iconHeight={15}
                              favorite={item?.isFavourite}
                              projectId={item.id}
                              hasText={false}
                              styles={{
                                marginTop: -4,
                              }}
                            />
                          </td>
                          <td>{item?.csp_id ? item.csp_id : "-"}</td>
                          <td>{item?.registry ? item.registry : "-"}</td>
                          <td>{item?.registry_Id ? item.registry_Id : "-"}</td>
                          <td title={item?.project_name}>
                            {item?.project_name
                              ? item.project_name.length > 32
                                ? item.project_name.substring(0, 32) + "..." // Truncate to 32 characters and add '...'
                                : item.project_name
                              : "-"}
                          </td>
                          <td>
                            {item?.project_status ? item.project_status : "-"}
                          </td>
                          <td title={item?.project_developer}>
                            {item?.project_developer
                              ? item.project_developer.length > 25
                                ? item.project_developer.substring(0, 25) +
                                  "..." // Truncate to 32 characters and add '...'
                                : item.project_developer
                              : "-"}
                          </td>
                          <td>{item?.country ? item.country : "-"}</td>
                          <td title={item?.project_sectoral_scopes}>
                            {item?.project_sectoral_scopes
                              ? item.project_sectoral_scopes.length > 20
                                ? item.project_sectoral_scopes.substring(
                                    0,
                                    20
                                  ) + "..." // Truncate to 32 characters and add '...'
                                : item.project_sectoral_scopes
                              : "-"}
                          </td>
                          <td>
                            {item?.project_types ? item.project_types : "-"}
                          </td>
                          <td title={item?.methodology}>
                            {item?.methodology
                              ? item.methodology.length > 8
                                ? item.methodology.substring(0, 8) + "..." // Truncate to 32 characters and add '...'
                                : item.methodology
                              : "-"}
                          </td>
                          {/* <td>
                              
                            </td> */}
                          <td>
                            {item?.SDGs?.length === 0
                              ? "-"
                              : splitSGDs(item?.SDGs)?.map((element, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      display: "inline-block",
                                      marginRight: "5px",
                                    }}
                                  >
                                    <img
                                      className="table-sdg-icon"
                                      src={`/icons/sdg/${element.label}.jpg`}
                                      alt={element.title}
                                      title={element.title}
                                    />
                                  </div>
                                ))}
                          </td>

                          <td>{item?.seals ? item.seals : "-"}</td>
                          <td>
                            {item?.estimated_credits
                              ? new Intl.NumberFormat().format(
                                  item.estimated_credits
                                )
                              : "-"}
                          </td>
                          <td>
                            {item?.total_credits_issued
                              ? new Intl.NumberFormat().format(
                                  item.total_credits_issued
                                )
                              : "-"}
                          </td>
                          <td>
                            {item?.total_credits_retired
                              ? new Intl.NumberFormat().format(
                                  item.total_credits_retired
                                )
                              : "-"}
                          </td>
                          <td>
                            {item?.total_credits_avaliable
                              ? new Intl.NumberFormat().format(
                                  item.total_credits_avaliable
                                )
                              : "-"}
                          </td>
                          <td>
                            {item?.total_credit_buffer
                              ? new Intl.NumberFormat().format(
                                  item.total_credit_buffer
                                )
                              : "-"}
                          </td>
                          <td>
                            {item?.project_lenght ? item.project_lenght : "-"}
                          </td>
                          <td>{item?.crediting_period_range ?? "-"}</td>
                          <td>{fmtViews}</td>
                          <td>
                            <button
                              className="view-btn-tbl bg-transparent"
                              style={{ fontSize: 14, fontWeight: "400" }}
                              onClick={async () => {
                                history.push("/carbon-project/" + item.slug)
                              }}
                            >
                              View Details
                            </button>
                          </td>
                          <td>
                            <TableButton
                              project_data={item}
                              location={"table"}
                              style={{ margin: 0, padding: 0 }}
                            />
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
          <div
            className="d-flex align-items-center pagi-mv"
            style={{
              paddingTop: "-10px",
              fontSize: "8px",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* <select
                value={pageSize}
                onChange={handlePageSizeChange}
                className="form-select mobile_pageSize"
                style={{ width: "100px" }}
              >
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select> */}
              <Select
                // value={pageSize}
                onChange={handlePageSizeChange}
                options={pageOptions}
                // defaultValue={{ id:1, value: 25, label: "25" }}
                value={pageOptions.filter(option => option.value === pageSize)}
                placeholder=""
                className="mobile_pageSize"
                components={{
                  IndicatorSeparator: () => null,
                }}
                styles={{
                  menu: (provided, state) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
              />
              <span
                className="text-secondary d-none d-sm-block"
                style={{ marginLeft: "10px", fontSize: "12px" }}
              >
                {`Showing ${pageSize * (page - 1) + 1} to ${
                  pageSize * page > totalProjects
                    ? totalProjects
                    : pageSize * page
                } of ${totalProjects} Projects`}
              </span>
              <span
                className="text-secondary d-block d-sm-none"
                style={{ marginLeft: "10px", fontSize: "10px" }}
              >
                {`of ${totalProjects} Projects`}
              </span>
            </div>
            <ReactPaginate
              breakLabel="..."
              nextLabel="Next"
              pageRangeDisplayed={3}
              pageCount={maxPages}
              previousLabel="Prev"
              containerClassName="pagination d-flex align-items-center"
              pageClassName="mobile_page page-item"
              pageLinkClassName="mobile_item page-link"
              previousLinkClassName="mobile_item page-link bg-light me-2 text-dark"
              nextLinkClassName="mobile_item page-link bg-light ms-2 text-dark"
              previousClassName="page-item"
              nextClassName="page-item"
              activeClassName="active"
              renderOnZeroPageCount={null}
              marginPagesDisplayed={marginPagesDisplayed}
              forcePage={page - 1}
              onPageChange={event => {
                dispatch(setPage(event.selected + 1))
              }}
            />
          </div>
        </>
      ) : null}
      {projects.length === 0 && !loading && !sectoralScopeId ? (
        <Alert className="text-center bg-white shadow shadow-sm">
          <div className="d-flex align-items-center justify-content-center">
            <span className="text-secondary">
              There are no projects available that match your query; please try
              again using different parameters.
            </span>
          </div>
        </Alert>
      ) : null}
    </div>
  )
}

export default TableView
