import React from "react"
import { Table } from "reactstrap"
import { formatClicks } from "../DetailsView"
import FavoritesButton from "../DetailsView/FavoritesButton"
import ReactPaginate from "react-paginate"
import { InfinitySpin } from "react-loader-spinner"
import { useHistory } from "react-router-dom"

function LatestProjectsTable({ projects = [], loading = false, maxPages = 1, page = 1, onPageChange }) {
  const sectoralScopeIconsId = [
    {
      id: 768,
      label: 1,
      title: "1.No Poverty"
    },
    {
      id: 769,
      label: 2,
      title: "2.Zero Hunger"
    },
    {
      id: 770,
      label: 3,
      title: "3.Good Health and well being"
    },
    {
      id: 771,
      label: 4,
      title: "4.Quality education"
    },
    {
      id: 772,
      label: 5,
      title: "5.Gender equality"
    },
    {
      id: 773,
      label: 6,
      title: "6.Clean water and sanitation"
    },
    {
      id: 774,
      label: 7,
      title: "7.Affordable and clean energy"
    },
    {
      id: 775,
      label: 8,
      title: "8.Decent work and economic growth"
    },
    {
      id: 776,
      label: 9,
      title: "9.Industry, innovation and infrastructure"
    },
    {
      id: 777,
      label: 10,
      title: "10.Reduced inequalities"
    },
    {
      id: 778,
      label: 11,
      title: "11.Sustainable cities and communities"
    },
    {
      id: 779,
      label: 12,
      title: "12.Responsible consumption and production"
    },
    {
      id: 780,
      label: 13,
      title: "13.Climate action"
    },
    {
      id: 781,
      label: 14,
      title: "14.Life below water"
    },
    {
      id: 782,
      label: 15,
      title: "15.life on land"
    },
    {
      id: 783,
      label: 16,
      title: "16.Peace, justice and strong institution"
    },
    {
      id: 784,
      label: 17,
      title: "17.Partnership for the goals"
    }
  ]
  const splitSGDs = (inputString) => {
    const valuesArray = inputString.split(",")
    const idArray = valuesArray
      .map(value => {
        const parts = value.split(".")
        if (parts.length === 2) {
          return parts[0].trim()
        }
        return null
      })
      .filter(id => id !== null)

    return idArray.map(id => {
      return sectoralScopeIconsId.find(icon => icon.label === parseInt(id))
    })
  }
  const history = useHistory()
  const from = window.location.pathname?.replace("/", "").trim()
  return <>
    {loading && <div className="d-flex align-items-center justify-content-center">
      <div
        className="d-flex flex-column align-items-center justify-content-center"
        style={{
          minHeight: "80vh"
        }}
      >
        <InfinitySpin color="#36d7b7" secondaryColor="#36d7b7" />
        <br />
        <span className="text-secondary">Loading Projects...</span>
      </div>
    </div>}
    {projects.length && !loading ? (
      <>
        <div
          style={{
            marginBottom: "16px",
            overflowX: "auto",
            position: "relative"
          }}
        >
          <div className="table-container">
            <Table className="table table-striped mb-0">
              <thead>
                <tr>
                  <th></th>
                  <th>CH ID</th>
                  <th>Registry</th>
                  <th style={{ whiteSpace: "nowrap" }}>Registry ID</th>
                  <th>Project Name</th>
                  <th>Status</th>
                  <th>Developer</th>
                  <th>Country</th>
                  <th style={{ whiteSpace: "nowrap" }}>Sectoral Scope</th>
                  <th>Type</th>
                  <th>Methodology</th>
                  <th>SDGs</th>
                  <th>Seals</th>
                  <th style={{ whiteSpace: "nowrap" }}>
                    Estimated Emission Reduction
                  </th>
                  <th style={{ whiteSpace: "nowrap" }}>
                    Total Credits Issued
                  </th>
                  <th style={{ whiteSpace: "nowrap" }}>
                    Total Credits Retired
                  </th>
                  <th style={{ whiteSpace: "nowrap" }}>
                    Total Credits Available
                  </th>
                  <th style={{ whiteSpace: "nowrap" }}>
                    Total Buffer Credits
                  </th>
                  <th style={{ whiteSpace: "nowrap" }}>Project Length</th>
                  <th style={{ whiteSpace: "nowrap" }}>Crediting Period</th>
                  <th>Views</th>
                  <th style={{ whiteSpace: "nowrap" }}>Action</th>
                </tr>
              </thead>

              <tbody style={{ whiteSpace: "nowrap" }}>
                {projects.map(item => {
                  let fmtViews = formatClicks(item.clicks ?? 0)
                  if (fmtViews === "0") {
                    fmtViews = "-"
                  }
                  return (
                    <tr
                      key={item.id}
                      onClick={async () => {
                        let url = "/carbon-project/" + item.slug
                        // history.push(`${url}?from=${from}`)
                        window.open(`${url}?from=${from}`, '_blank');

                      }}
                      className="table-row-hover"
                    >
                      <td>
                        <FavoritesButton
                          iconWidth={15}
                          iconHeight={15}
                          favorite={item?.isFavourite}
                          projectId={item.id}
                          hasText={false}
                          styles={{
                            marginTop: -4
                          }}
                        />
                      </td>
                      <td>{item?.csp_id ? item.csp_id : "-"}</td>
                      <td>{item?.registry ? item.registry : "-"}</td>
                      <td>{item?.registry_Id ? item.registry_Id : "-"}</td>
                      <td title={item?.project_name}>
                        {item?.project_name
                          ? item.project_name.length > 32
                            ? item.project_name.substring(0, 32) + "..." // Truncate to 32 characters and add '...'
                            : item.project_name
                          : "-"}
                      </td>
                      <td>
                        {item?.project_status ? item.project_status : "-"}
                      </td>
                      <td title={item?.project_developer}>
                        {item?.project_developer
                          ? item.project_developer.length > 25
                            ? item.project_developer.substring(0, 25) +
                            "..." // Truncate to 32 characters and add '...'
                            : item.project_developer
                          : "-"}
                      </td>
                      <td>{item?.country ? item.country : "-"}</td>
                      <td title={item?.project_sectoral_scopes}>
                        {item?.project_sectoral_scopes
                          ? item.project_sectoral_scopes.length > 20
                            ? item.project_sectoral_scopes.substring(
                              0,
                              20
                            ) + "..." // Truncate to 32 characters and add '...'
                            : item.project_sectoral_scopes
                          : "-"}
                      </td>
                      <td>
                        {item?.project_types ? item.project_types : "-"}
                      </td>
                      <td title={item?.methodology}>
                        {item?.methodology
                          ? item.methodology.length > 8
                            ? item.methodology.substring(0, 8) + "..." // Truncate to 32 characters and add '...'
                            : item.methodology
                          : "-"}
                      </td>
                      {/* <td>

                            </td> */}
                      <td>
                        {item?.SDGs?.length === 0
                          ? "-"
                          : splitSGDs(item?.SDGs)?.map((element, index) => (
                            <div
                              key={index}
                              style={{
                                display: "inline-block",
                                marginRight: "5px"
                              }}
                            >
                              <img
                                className="table-sdg-icon"
                                src={`/icons/sdg/${element.label}.jpg`}
                                alt={element.title}
                                title={element.title}
                              />
                            </div>
                          ))}
                      </td>

                      <td>{item?.seals ? item.seals : "-"}</td>
                      <td>
                        {item?.estimated_credits
                          ? new Intl.NumberFormat().format(
                            item.estimated_credits
                          )
                          : "-"}
                      </td>
                      <td>
                        {item?.total_credits_issued
                          ? new Intl.NumberFormat().format(
                            item.total_credits_issued
                          )
                          : "-"}
                      </td>
                      <td>
                        {item?.total_credits_retired
                          ? new Intl.NumberFormat().format(
                            item.total_credits_retired
                          )
                          : "-"}
                      </td>
                      <td>
                        {item?.total_credits_avaliable
                          ? new Intl.NumberFormat().format(
                            item.total_credits_avaliable
                          )
                          : "-"}
                      </td>
                      <td>
                        {item?.total_credit_buffer
                          ? new Intl.NumberFormat().format(
                            item.total_credit_buffer
                          )
                          : "-"}
                      </td>
                      <td>
                        {item?.project_lenght ? item.project_lenght : "-"}
                      </td>
                      <td>{item?.crediting_period_range ?? "-"}</td>
                      <td>{fmtViews}</td>
                      <td>
                        <button
                          className="view-btn-tbl bg-transparent"
                          style={{ fontSize: 14, fontWeight: "400" }}
                          onClick={async () => {
                            let url = "/carbon-project/" + item.slug
                            history.push(`${url}?from=${from}`)
                          }}
                        >
                          View Details
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>

        </div>
        <div
          className="d-flex justify-content-end align-items-center pagi-mv"
          style={{ paddingTop: "-20px", fontSize: "12px" }}
        >
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next"
            pageRangeDisplayed={3}
            pageCount={maxPages}
            previousLabel="Prev"
            containerClassName="pagination d-flex align-items-center"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousLinkClassName="page-link bg-light me-2 text-dark"
            nextLinkClassName="page-link bg-light ms-2 text-dark"
            previousClassName="page-item"
            nextClassName="page-item"
            activeClassName="active"
            renderOnZeroPageCount={null}
            forcePage={page - 1}
            onPageChange={selected => {
              onPageChange(selected)
            }}
          />
        </div>
      </>
    ) : null}</>
}

export default LatestProjectsTable