import React from "react"

const MapLegend = ({ legendItems, isMobile }) => {
  return (
    <div
      className="map-legend"
      style={{
        ...(isMobile
          ? {}
          : {
              position: "absolute",
              top: "30px",
              right: "30px",
              backgroundColor: "white",
              padding: "10px",
              zIndex: 1000,
              borderRadius: "8px",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            }),
      }}
    >
      {!isMobile &&
        legendItems.map((item, index) => (
          <div
            key={index}
            className="map-legend-item"
            style={{
              marginBottom: "5px",
              paddingLeft: index === 3 ? "4px" : "0px",
            }}
          >
            <img
              src={item.src}
              alt={item.alt}
              width={item.width}
              height={item.height}
            />
            {/* change font family */}
            <div
              style={{
                fontFamily: "Poppins",
              }}
            >
              {item.text}
            </div>
          </div>
        ))}
    </div>
  )
}

export default MapLegend
