import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { InfinitySpin } from "react-loader-spinner"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { Alert, Col, Container, Row } from "reactstrap"
import {
  setCurrentPage,
  setMaxPages,
  setSearchPalylod,
  setVisibleProjects,
} from "store/RTK-Store/projectsSlice"
import { usePostProjectWithParamsAndPayloadMutation } from "store/RTK-Store/services/projectApi"
import ProjectCard from "./ProjectCard"
import { capitalizeEachWord } from "./Table"
import transformPayload from "./payloadTransform"


export default function GridView() {
  const {
    certified,
    sectoral_scope,
    country,
    project_types,
    developer,
    region,
    status,
    seal,
  } = useParams()

  const dispatch = useDispatch()
  const triggerRef = useRef(null)
  const history = useHistory()
  const dropdownValues = useSelector(state => state.projects.dropdownData)
  const [metaDesc, setMetaDesc] = useState(
    "Browse through the most comprehensive carbon project database. From renewable energy to forestry and industrial projects, find initiatives that align with your sustainability goals."
  )
  const projectType = useSelector(state => state.projects.projectsTypeData)
  const dropdownLoading = useSelector(state => state.projects.loading)
  const registryStausData = useSelector(
    state => state.projects.registryStausData
  )
  const extraSealsData = useSelector(state => state.projects.sealsData)

  const projectTypes = [
    {
      name: "All",
      icon: "fa-grip-horizontal",
      id: 1,
    },
    {
      icon: "fa-tree",
      name: "Nature Based",
      id: 2,
    },
    {
      icon: "fa-cloud-sun-rain",
      name: "Energy",
      id: 3,
    },
    {
      icon: "fa-home",
      name: "Household",
      id: 4,
    },
    {
      icon: "fa-industry",
      name: "Industrial",
      id: 5,
    },
    {
      icon: "fa-trash-restore",
      name: "Waste Management",
      id: 6,
    },
    {
      icon: "fa-train",
      name: "Transport",
      id: 7,
    },
    {
      icon: "fa-poll-h",
      name: "Other",
      id: 8,
    },
  ]

  const projectStatusTypes = [
    {
      id: 1,
      label: "Registered",
    },
    {
      id: 2,
      label: "Listed",
    },
    {
      id: 3,
      label: "Under Development",
    },
    {
      id: 4,
      label: "On Hold",
    },
    {
      id: 5,
      label: "Rejected",
    },
    {
      id: 6,
      label: "Inactive",
    },
  ]

  useEffect(() => {
    let sectoralPayload = searchPayload?.sectoralScopes
    let countryPayload = searchPayload?.country
    let regionPayload = searchPayload?.region
    let projectTypePayload = searchPayload?.projectTypes
    let registryPayload = searchPayload?.registry
    let statusPayload = searchPayload?.registryStatus
    let developerPayload = searchPayload?.projectDeveloper
    let projectStatusPayload = searchPayload?.projectStatus
    let projectTitle = searchPayload?.projectTitle
    let extraSeals = searchPayload?.extraSeals
    let maxCarbonCreditsPayload = searchPayload?.maxCarbonCredits
    let minCarbonCreditsPayload = searchPayload?.minCarbonCredits
    let endPeriodPayload = searchPayload?.endCreditingPeriod
    let startCreditingPeriodPayload = searchPayload?.startCreditingPeriod
    let tempPayload = searchPayload

    // console.log(status);
    if (
      (seal ||
        certified ||
        sectoral_scope ||
        country ||
        project_types ||
        developer ||
        region ||
        status) &&
      projectType?.length != 0
    ) {
      let payload = {
        projectTitle: tempPayload?.projectTitle || "",
        minCarbonCredits: tempPayload?.minCarbonCredits || null,
        maxCarbonCredits: tempPayload?.maxCarbonCredits || null,
        country: tempPayload?.country || [],
        projectTypes: tempPayload?.projectTypes || [],
        sectoralScopes: tempPayload?.sectoralScopes || [],
        registryStatus: tempPayload?.registryStatus || [],
        region: tempPayload?.region || [],
        registry: tempPayload?.registry || [],
        extraSeals: tempPayload.extraSeals || [],
        startCreditingPeriod: tempPayload?.startCreditingPeriod || null,
        endCreditingPeriod: tempPayload?.endCreditingPeriod || null,
        projectDeveloper: tempPayload?.projectDeveloper || "",
        projectStatus: tempPayload?.projectStatus || [],
      }
      let title = ""
      let metaDescStr = ""
      if (dropdownValues && certified) {
        const registry = certified
          .replaceAll("-certified-projects", "")
          .replaceAll("-", " ")
        title = capitalizeEachWord(registry)
        title = `Comprehensive List of ${title} Certified Carbon Projects | CarbonHub`
        metaDescStr = `Join CarbonHub in advancing climate action through ${title} certified carbon projects. Explore a curated list spanning renewable energy, forestry, and beyond to support verified carbon offset and reduction initiatives worldwide.`
        const registryId = dropdownValues?.regi_str?.find(
          item => item.label.toLowerCase() === registry
        )
        if (!registryId) {
          history.push("/404")
        }
        if (searchPayload) {
          payload = { ...searchPayload }
        }
        payload = {
          ...payload,
          registry: registryId?.id ?? "",
        }
        // setIsFilteredData(true)
        // dispatch(setSearchPalylod(payload))
      }
      if (dropdownValues && country) {
        const con = country.replaceAll("-", " ").toLowerCase()
        title = capitalizeEachWord(con)
        const countryId = dropdownValues.host_coun.find(
          item => item.label.replaceAll("-", " ").toLowerCase() === con
        )
        if (!countryId) {
          history.push("/404")
        }
        metaDescStr = `Explore the top carbon projects in ${title} on CarbonHub. From renewable energy to conservation, discover how each project is contributing to the country’s carbon reduction and environmental sustainability efforts.`
        payload.country = countryId?.id.toString() ?? ""
      }
      if (projectType && project_types) {
        const project_type = project_types.replaceAll("-", " ")
        title = capitalizeEachWord(project_type)
        // const typeID = projectType.find(
        //   item =>
        //     item.label.replaceAll("-", " ").toLowerCase() === title.toLowerCase()
        // )
        const typeID = projectType.find(
          item =>
            item.label
              .replaceAll("-", " ")
              .replaceAll("/", " ")
              .replace(/\s+/g, " ")
              .toLowerCase() === title.toLowerCase()
        )
        if (!typeID) {
          history.push("/404")
        }
        payload.projectTypes = typeID?.id.toString() ?? ""
        metaDescStr = `Find the best ${title} projects on CarbonHub.Each project contributes to global sustainability goals through innovative and effective carbon reduction and sequestration practices.`
      }
      if (dropdownValues && region) {
        const region_title = region.replaceAll("-", " ")
        title = capitalizeEachWord(region_title)
        const typeID = dropdownValues?.regi?.find(
          item =>
            item.label.replaceAll("-", " ").toLowerCase() ===
            title.toLowerCase()
        )
        if (!typeID) {
          history.push("/404")
        }
        payload.region = typeID?.id.toString() ?? ""
        metaDescStr = `Explore the top carbon credit projects in ${title} on CarbonHub. Find impactful initiatives in ${title} that are driving progress in sustainability and offering valuable carbon reduction solutions.`
      }
      if (registryStausData && status) {
        const status_title = status.replaceAll("-", " ")
        title = capitalizeEachWord(status_title)
        const typeID = registryStausData?.find(
          item =>
            item.label.replaceAll("-", " ").toLowerCase() ===
            title.toLowerCase()
        )
        if (!typeID) {
          history.push("/404")
        }
        payload.registryStatus = typeID?.id.toString() ?? ""
      }
      if (developer) {
        const project_type = developer.replaceAll("-", " ")
        title = developer
        payload.projectDeveloper = title ?? ""
        metaDescStr = `Discover a range of ${title} carbon projects at CarbonHub. Delve into projects that are shaping the future of sustainability with innovative strategies for reducing carbon emissions.`
      }
      if (projectTypes && sectoral_scope) {
        // setLoading(true)
        const sectoralScope = sectoral_scope.replaceAll("-", " ")
        // Keyword map
        const keywordMap = {
          "innovative carbon projects": "other",
          "sustainable transport projects": "transport",
          "waste management solutions": "waste management",
          "household carbon projects": "household",
          "nature based projects": "nature based",
          "industrial carbon solutions": "industrial",
          "renewable energy projects": "energy",
        }

        metaDescStr = `
        Explore ${capitalizeEachWord(sectoralScope)}
        carbon
        projects
        to
        invest
        on
        CarbonHub.Discover
        initiatives
        committed
        to
        reducing
        emissions
        and
        advancing
        sustainability in the [Sector
        Name
      ]
        sector.`

        // Check if sectoralScope exists in the keywordMap
        const trueKeyWord = keywordMap[sectoralScope] ?? sectoralScope

        title = capitalizeEachWord(sectoralScope)
        // console.log(title);
        const sectoralScopeId = projectTypes.find(
          item => item.name.toLowerCase() === trueKeyWord.toLowerCase()
        )
        // console.log(sectoralScopeId);
        if (!sectoralScopeId) {
          history.push("/404")
        }
        if (sectoralScopeId) {
          const id = sectoralScopeId.id
          if (id !== 1) {
            payload = {
              ...payload,
              sectoralScopes: `${id}`,
            }
          }
        }
      }
      if (seal && extraSealsData) {
        const seal_title = seal.replaceAll("-", " ").toLowerCase().trim()

        const sealId = extraSealsData?.find(
          item => item.label.toLowerCase() === seal_title
        )
        title = sealId?.label ?? "Certified Carbon Projects | CarbonHub"
        if (!sealId) {
          history.push("/404")
        }
        if (searchPayload) {
          payload = { ...searchPayload }
        }
        payload = {
          ...payload,
          extraSeals: sealId?.id ?? "",
        }
        metaDescStr = `Explore carbon projects on CarbonHub with the esteemed ${title} certification. These projects meet high standards for sustainability and carbon reduction, contributing to environmental excellence.`
      }
      dispatch(setSearchPalylod(payload))
      // setIsFilteredData(true)

      if (status) {
        let dateNow = new Date().getFullYear()
        let titleString = `
        Best
        Carbon
        Reduction: ${title} in ${dateNow}
      |
        CarbonHub`
        metaDescStr = `Discover a range of ${title} carbon projects at CarbonHub. Delve into projects that are shaping the future of sustainability with innovative strategies for reducing carbon emissions.`
        setTitle(titleString)
        // console.log(titleString);
      } else if (sectoral_scope) {
        let dateNow = new Date().getFullYear()
        let titleWords = title.split(" ")

        if (
          titleWords[titleWords.length - 1] === "Projects" ||
          titleWords[titleWords.length - 1] === "Solutions"
        ) {
          titleWords.pop()
        }

        let newTitle = titleWords.join(" ")

        let titleString
        if (newTitle === "Other") {
          titleString = `
        Unique
        and
        Innovative
        Carbon
        Projects
        to
        Invest in ${dateNow} | Innovative
        Solutions
        CarbonHub`
        } else if (newTitle === "Nature Based") {
          titleString = `
        Best
        Top ${newTitle}
        Carbon
        Projects
        to
        Invest in ${dateNow} | ${newTitle} Solutions - CarbonHub`
        } else {
          titleString = `
        Best
        Top ${newTitle}
        Based
        Carbon
        Projects
        to
        Invest in ${dateNow} | ${newTitle} Solutions - CarbonHub`
        }
        setTitle(titleString)
      } else if (developer) {
        let titleString = `${title}
        Carbon
        Reduction
        Projects | CarbonHub`
        setTitle(titleString)
      } else if (project_types) {
        let dateNow = new Date().getFullYear()
        let titleString = `${title}
        Carbon
        Reduction
        Projects
        to
        Invest in ${dateNow} | CarbonHub`
        setTitle(titleString)
      } else if (region) {
        let dateNow = new Date().getFullYear()
        let titleString = `${title}
        Region
        Carbon
        Credit
        Projects
        To
        Invest in ${dateNow} | Leading
        Initiatives - CarbonHub`
        setTitle(titleString)
      } else if (country) {
        let dateNow = new Date().getFullYear()
        let titleString = `
        Best
        Carbon
        Reduction
        Projects
        to
        Invest in ${title} ${dateNow} | CarbonHub`
        setTitle(titleString)
      } else if (seal) {
        let titleString = `Certified ${title} Carbon Projects | CarbonHub`
        setTitle(titleString)
      } else {
        setTitle(title)
      }
      setMetaDesc(metaDescStr)
    } else {
      if (
        !sectoralPayload &&
        !countryPayload &&
        !regionPayload &&
        !projectTypePayload &&
        !registryPayload &&
        !statusPayload &&
        !developerPayload &&
        !projectTitle &&
        !extraSeals &&
        !maxCarbonCreditsPayload &&
        !minCarbonCreditsPayload &&
        !endPeriodPayload &&
        !startCreditingPeriodPayload
      ) {
        let payload = {
          projectTitle: "",
          minCarbonCredits: null,
          maxCarbonCredits: null,
          country: [],
          projectTypes: [],
          sectoralScopes: [],
          registryStatus: [],
          region: [],
          registry: [],
          extraSeals: [],
          startCreditingPeriod: null,
          endCreditingPeriod: null,
          projectDeveloper: "",
          projectStatus: [],
        }
        // console.log('triggered');
        dispatch(setSearchPalylod(payload))
        // setIsFilteredData(true)
      }
    }
  }, [certified, dropdownValues, sectoral_scope, country, project_types])

  const visibleProjects = useSelector(state => state.projects.visibleProjects)
  const searchPayload = useSelector(state => state.projects.searchPalylod)
  const currentTab = useSelector(state => state.projects.currentTab)
  const view = useSelector(state => state.projects.projectView)
  const pageSize = useSelector(state => state.projects.pageSize)
  const currentPage = useSelector(state => state.projects.currentPage)
  const canLoadMore = useSelector(state => state.projects.canLoadMore)
  const [title, setTitle] = useState(
    "Carbon Projects Database - Find Certified and Non-Certified Initiatives"
  )

  const [postProjectWithParamsAndPayload, { isLoading }] =
    usePostProjectWithParamsAndPayloadMutation()

  const callPostProjectMutation = async () => {
    try {
      // console.log('payload', searchPayload);
      let tempPayload = searchPayload;
      const transformedPayload = transformPayload(tempPayload);
      const response = await postProjectWithParamsAndPayload({
        type: currentTab,
        page: currentPage,
        pageSize,
        payload: transformedPayload,
      })
      dispatch(setVisibleProjects(response?.data?.payload?.projectData))
      dispatch(setMaxPages(response?.data?.payload?.totalPages ?? 1))
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (view === "grid" && searchPayload && !dropdownLoading) {
      callPostProjectMutation().catch()
    }
  }, [currentTab, currentPage, searchPayload])
  useEffect(() => {
    if (certified) {
      const registry = certified
        .replaceAll("-certified-projects", "")
        .replaceAll("-", " ")
      setTitle(capitalizeEachWord(registry))
    }
  }, [certified])

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && canLoadMore && !isLoading) {
          dispatch(setCurrentPage(currentPage + 1))
        }
      },
      { threshold: 0.5 }
    )
    if (triggerRef.current) {
      observer.observe(triggerRef.current)
    }
    return () => observer.disconnect()
  }, [triggerRef, canLoadMore, isLoading])

  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={metaDesc} />
      </Helmet>
      <Container fluid style={{ minHeight: "515px" }}>
        <Row
          className="row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-4 row-cols-xxl-6"
          style={{ padding: "30px 12px 0 24px" }}
        >
          {visibleProjects.map(project => (
            <ProjectCard
              key={project.id}
              project={project}
              onProjectSelected={slug => {
                history.push("/carbon-project/" + slug)
              }}
            />
          ))}
          {canLoadMore && (
            <Col>
              <div
                ref={triggerRef}
                style={{ height: "1px", visibility: "hidden" }}
              />
            </Col>
          )}
        </Row>
        {isLoading && (
          <div className="d-flex align-items-center justify-content-center">
            <div
              className="d-flex flex-column align-items-center justify-content-center"
              style={{ minHeight: "80vh" }}
            >
              <InfinitySpin color="#36d7b7" secondaryColor="#36d7b7" />
              <br />
              <span className="text-secondary">Loading Projects...</span>
            </div>
          </div>
        )}
        {!visibleProjects.length && !isLoading && (
          <Alert className="text-center bg-white shadow shadow-sm">
            <div className="d-flex align-items-center justify-content-center">
              <span className="text-secondary">
                There are no projects available that match your query; please
                try again using different parameters.
              </span>
            </div>
          </Alert>
        )}
      </Container>
    </React.Fragment>
  )
}
