import { createSlice } from "@reduxjs/toolkit"
import _ from "lodash"
export const tableSlice = createSlice({
  name: "projectsTable",
  initialState: {
    projects: [],
    page: 1,
    maxPages: 1,
    loading: false,
    totalProjects: null,
  },
  reducers: {
    setProjects: (state, action) => {
      state.projects = [..._.uniqBy(action.payload, "id")]
    },
    setPage: (state, action) => {
      state.page = action.payload
    },
    setMaxPages: (state, action) => {
      state.maxPages = action.payload
    },
    setTotalProjects: (state, action) => {
      state.totalProjects = action.payload
    }
  },
})

export const { setProjects, setMaxPages, setPage, setPageSize, setTotalProjects} =
  tableSlice.actions

const tableReducer = tableSlice.reducer

export default tableReducer
