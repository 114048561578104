/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit"
import {
  getAllProjects,
  getAllTableProjects,
  getDropdownDataApi,
  getProjectByCSPID,
  getProjectMetrics,
} from "core/ProjectApi"
import _, { set } from "lodash"

function comparePayloads(payload1, payload2) {
  return JSON.stringify(payload1) !== JSON.stringify(payload2)
}

// Slice with the necessary actions and reducer
export const projectsSlice = createSlice({
  name: "projects",
  initialState: {
    visibleProjects: [],
    metrics: [],
    sectoralScopeData: {},
    projectsTypeData: [],
    SectoralScopeData: [],
    RegStatusData: [],
    currentPage: 1,
    pageSize: 25,
    currentTab: 1,
    canLoadMore: true,
    searchPalylod: undefined,
    tempPayload: undefined,
    loading: false,
    projectView: "table",
    filterCount: 0,
    maxPages: 1,
    currentSeoTitle: "",
    maxCreditValue: null,
    minCreditingPeriod: null,
    maxCreditingPeriod: null,
  },
  reducers: {
    setVisibleProjects: (state, action) => {
      state.canLoadMore =
        action.payload.length === state.pageSize &&
        state.currentPage <= state.maxPages
      const projects = [...state.visibleProjects, ...action.payload]
      state.visibleProjects = _.uniqBy(projects, "csp_id")
    },
    setSearchPalylod: (state, action) => {
      state.searchPalylod = action.payload
      // Check if searchPalylod has changed and set currentPage to 1 clear the previous loaded projects
      if (!comparePayloads(state.searchPalylod, action.payload)) {
        state.currentPage = 1
        state.visibleProjects = []
      }
    },
    setTempPayload: (state, action) => {
      state.tempPayload = action.payload
    },
    setSearchPalylodEmpty: (state, action) => {
      state.searchPalylod = {}
    },
    setPageSizeReset: state => {
      state.pageSize = 25
    },
    updateVisibleProjects: (state, action) => {
      state.canLoadMore =
        action.payload.length === state.pageSize &&
        state.currentPage <= state.maxPages
      state.visibleProjects = [...state.visibleProjects, ...action.payload]
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setFilterCount: (state, action) => {
      state.filterCount = action.payload
    },
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload
      state.visibleProjects = []
      state.currentPage = 1
    },
    setProjectView: (state, action) => {
      state.projectView = action.payload
    },
    setMaxPages: (state, action) => {
      state.maxPages = action.payload
    },
    setSeoTitle: (state, action) => {
      state.currentSeoTitle = action.payload
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload
    },
    setMaxCreditValue: (state, action) => {
      state.maxCreditValue = action.payload
    },
    setMinCreditingPeriod: (state, action) => {
      state.minCreditingPeriod = action.payload
    },
    setMaxCreditingPeriod: (state, action) => {
      state.maxCreditingPeriod = action.payload
    }
  },
  extraReducers: {
    [getAllProjects.fulfilled]: (state, action) => {
      state.projects = action.payload
      state.loading = false
    },
    [getAllProjects.pending]: state => {
      state.loading = true
      state.error = null
    },
    [getAllProjects.rejected]: (state, action) => {
      state.loading = false
      state.error = "Unable to get user list (" + action.error.message + ")"
    },
    [getAllTableProjects.fulfilled]: (state, action) => {
      state.projectslist = action.payload
      state.loading = false
    },
    [getAllTableProjects.pending]: state => {
      state.loading = true
      state.error = null
    },
    [getAllTableProjects.rejected]: (state, action) => {
      state.loading = false
      state.error = "Unable to get user list (" + action.error.message + ")"
    },
    [getProjectByCSPID.fulfilled]: (state, action) => {
      state.projectById = action.payload
      state.loading = false
    },
    [getProjectByCSPID.pending]: state => {
      state.loading = true
      state.error = null
    },
    [getProjectByCSPID.rejected]: (state, action) => {
      state.loading = false
      state.error = "Unable to get user list (" + action.error.message + ")"
    },
    [getProjectMetrics.fulfilled]: (state, action) => {
      state.metrics = action.payload
      state.loading = false
    },
    [getProjectMetrics.pending]: state => {
      state.loading = true
      state.error = null
    },
    [getProjectMetrics.rejected]: (state, action) => {
      state.loading = false
      state.error = "Unable to get user list (" + action.error.message + ")"
    },
    // All dropdown Data
    [getDropdownDataApi.pending]: (state, _) => {
      state.loading = true
      state.error = null
    },
    [getDropdownDataApi.fulfilled]: (state, action) => {
      state.dropdownData = action.payload["dropdownData"]
      state.projectsTypeData = action.payload["projectType"]
      state.registryStausData = action.payload["registryStausData"]
      state.sectoralScopeData = action.payload["sectoralScopeData"]
      state.sealsData = action.payload["sealsData"]
      state.loading = false
    },
    [getDropdownDataApi.rejected]: (state, action) => {
      state.loading = false
      state.error = "Unable to get dropdown data (" + action.error.message + ")"
    },
  },
})

export const {
  setVisibleProjects,
  setSearchPalylodEmpty,
  setPageSizeReset,
  setCurrentPage,
  setCurrentTab,
  setSearchPalylod,
  setProjectView,
  setFilterCount,
  setMaxPages,
  setSeoTitle,
  setTempPayload,
  setPageSize,
  setMaxCreditValue,
  setMinCreditingPeriod,
  setMaxCreditingPeriod
} = projectsSlice.actions

export default projectsSlice.reducer
