import { createSlice } from "@reduxjs/toolkit"
// import { stat } from "fs"

export const mapSlice = createSlice({
  name: "map",
  initialState: {
    existingPayload: {},
    existingType: undefined,
    countryMarkers: [],
    regionMarkers: [],
    projectMarkers: [],
    visibleProjectMarkers: [],
    visibleCountryMarkers: [],
    zoom: 2,
    loading: false,
    currentView: "region",
    error: undefined,
    currentCenter: [0, 0]
  },
  reducers: {
    setMarkers: (state, action) => {
      state.markers = action.payload
    },
    setCountryMarkers: (state, action) => {
      state.countryMarkers = action.payload
    },
    setRegionMarkers: (state, action) => {
      state.regionMarkers = action.payload
    },
    setProjectMarkers: (state, action) => {
      state.projectMarkers = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setCurrentView: (state, action) => {
      if (state.currentView !== action.payload) {
        state.currentView = action.payload
      }
    },
    setVisibleProjectMarkers: (state, action) => {
      state.visibleProjectMarkers = action.payload
    },
    setVisibleCountryMarkers: (state, action) => {
      state.visibleCountryMarkers = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
    setPayload: (state, action) => {
      state.existingPayload = action.payload
    },
    setProjectType: (state, action) => {
      state.existingType = action.payload
    },
    setCurrentZoom: (state, action) => {
      state.zoom = action.payload
    },
    setCurrentCenter: (state, action) => {
      state.currentCenter = action.payload
    }
  }
})

export const {
  setError,
  setLoading,
  setCurrentView,
  setCountryMarkers,
  setRegionMarkers,
  setProjectMarkers,
  setVisibleProjectMarkers,
  setVisibleCountryMarkers,
  setPayload,
  setProjectType,
  setCurrentZoom,
  setCurrentCenter
} = mapSlice.actions
export default mapSlice.reducer
