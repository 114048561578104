import classNames from "classnames"
import { Image, OverlayTrigger } from "react-bootstrap"
import React from "react"

const LocationsDropdown = ({ locations = [] }) => {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ color: "rgb(161,161,161)" }}
    >
      {/* {JSON.stringify(locations)} */}
      <Image src="/icons/locations-icon.svg" width={18} height={18} />
      <span className="fw-bold ms-3">Locations</span>
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        rootClose
        overlay={
          <div
            className="bg-white p-2 shadow shadow-lg d-flex flex-column"
            style={{
              color: "#ADADAD",
              fontSize: "14px",
              justifyItems: "center",
              minWidth: "320px",
              right: 0,
              borderRadius: 8,
            }}
          >
            <div className="mb-0 pb-0">
              {locations?.map((item, index) => {
                return (
                  <div
                    className={classNames({
                      "fw-bold": index === 0,
                      "p-2": true,
                    })}
                    key={item.id}
                  >
                    {item.countryName}, {item.state_province}, {item.latitude}
                    &deg;N, {item.longitude}&deg;E
                  </div>
                )
              })}
            </div>
          </div>
        }
      >
        <a
          className="ms-2 text-decoration-none"
          style={{ color: "rgb(161,161,161)" }}
        >
          <i className="mdi fs-5 mdi-chevron-down" />
        </a>
      </OverlayTrigger>
    </div>
  )
}

export default LocationsDropdown
