import FavoritesButton from "pages/DetailsView/FavoritesButton"
import React, { useEffect, useState } from "react"
import { InfinitySpin } from "react-loader-spinner"
import ReactPaginate from "react-paginate"
import { Alert, Table } from "reactstrap"
import { usePostProjectWithParamsAndPayloadMutation } from "../../store/RTK-Store/services/projectApi"
import { useHistory } from "react-router-dom"

const sectoralScopeIconsId = [
  {
    id: 768,
    label: 1,
    title: "1.No Poverty"
  },
  {
    id: 769,
    label: 2,
    title: "2.Zero Hunger"
  },
  {
    id: 770,
    label: 3,
    title: "3.Good Health and well being"
  },
  {
    id: 771,
    label: 4,
    title: "4.Quality education"
  },
  {
    id: 772,
    label: 5,
    title: "5.Gender equality"
  },
  {
    id: 773,
    label: 6,
    title: "6.Clean water and sanitation"
  },
  {
    id: 774,
    label: 7,
    title: "7.Affordable and clean energy"
  },
  {
    id: 775,
    label: 8,
    title: "8.Decent work and economic growth"
  },
  {
    id: 776,
    label: 9,
    title: "9.Industry, innovation and infrastructure"
  },
  {
    id: 777,
    label: 10,
    title: "10.Reduced inequalities"
  },
  {
    id: 778,
    label: 11,
    title: "11.Sustainable cities and communities"
  },
  {
    id: 779,
    label: 12,
    title: "12.Responsible consumption and production"
  },
  {
    id: 780,
    label: 13,
    title: "13.Climate action"
  },
  {
    id: 781,
    label: 14,
    title: "14.Life below water"
  },
  {
    id: 782,
    label: 15,
    title: "15.life on land"
  },
  {
    id: 783,
    label: 16,
    title: "16.Peace, justice and strong institution"
  },
  {
    id: 784,
    label: 17,
    title: "17.Partnership for the goals"
  }
]

const splitSGDs = inputString => {
  const valuesArray = inputString.split(",")

  const idArray = valuesArray
    .map(value => {
      const parts = value.split(".")
      if (parts.length === 2) {
        return parts[0].trim()
      }
      return null
    })
    .filter(id => id !== null)

  return idArray.map(id => {
    return sectoralScopeIconsId.find(icon => icon.label === parseInt(id))
  })
}

const payload = {
  projectTitle: "",
  minCarbonCredits: null,
  maxCarbonCredits: null,
  country: "",
  projectTypes: "",
  sectoralScopes: "",
  registryStatus: "",
  region: "",
  extraSeals: "",
  startCreditingPeriod: null,
  endCreditingPeriod: null,
  projectDeveloper: "",
  isFavourite: true
}

const FavoritesTableView = () => {
  const [loading, setLoading] = useState(false)
  const [getFavorites] = usePostProjectWithParamsAndPayloadMutation()
  const [projects, setProjects] = useState([])
  const [maxPages, setMaxPages] = useState(0)

  const [currentPage, setCurrentPage] = useState(1)

  const handleGetFavorites = () => {
    setLoading(true)
    getFavorites({ page: currentPage, payload })
      .unwrap()
      .then(data => {
        if (data?.error) {
          throw new Error(data?.payload)
        }
        setMaxPages(data?.payload?.totalPages)
        setProjects(data?.payload?.projectData)
      })
      .catch(error => {
        console.error(error)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    handleGetFavorites()
  }, [currentPage])
  const history = useHistory()
  return (
    <div
      className="bg-white"
      style={{
        minHeight: "50vh"
      }}
    >
      {projects.length ? (
        <>
          <div
            style={{
              marginBottom: "16px",
              overflowX: "auto",
              position: "relative"
            }}
          >
            <div className="table-container">
              <Table className="table table-striped mb-0">
                <thead>
                  <tr>
                    <th></th>
                    <th>CH ID</th>
                    <th>Registry</th>
                    <th style={{ whiteSpace: "nowrap" }}>Registry ID</th>
                    <th>Project Name</th>
                    <th>Status</th>
                    <th>Developer</th>
                    <th>Country</th>
                    <th style={{ whiteSpace: "nowrap" }}>Sectoral Scope</th>
                    <th>Type</th>
                    <th>Methodology</th>
                    <th>SDGs</th>
                    <th>Seals</th>
                    <th style={{ whiteSpace: "nowrap" }}>Estimated Emission Reduction</th>
                    <th style={{ whiteSpace: "nowrap" }}>
                      Total Credits Issued
                    </th>
                    <th style={{ whiteSpace: "nowrap" }}>
                      Total Credits Retired
                    </th>
                    <th style={{ whiteSpace: "nowrap" }}>
                      Total Credits Available
                    </th>
                    <th style={{ whiteSpace: "nowrap" }}>
                      Total Buffer Credits
                    </th>
                    <th style={{ whiteSpace: "nowrap" }}>Project Length</th>
                    <th style={{ whiteSpace: "nowrap" }}>Crediting Period</th>
                    <th style={{ whiteSpace: "nowrap" }}>Action</th>
                  </tr>
                </thead>

                <tbody style={{ whiteSpace: "nowrap" }}>
                  {projects.map(item => {
                    return (
                      <tr
                        key={item.id}
                        onClick={async () => {
                          let url = "/carbon-project/" + item.slug + "?from=favorites"
                          // history.push(url)
                          window.open(url, '_blank');
                        }}
                        className="table-row-hover"
                      >
                        <td>
                          <FavoritesButton
                            id={item.id}
                            favorite={item.isFavourite}
                            hasText={false}
                            iconWidth={15}
                            iconHeight={15}
                            styles={{ marginTop: -4 }}
                            projectId={item.id}
                            onToggle={isFavourite => {
                              if (!isFavourite) {
                                const newProjects = projects.filter(p => {
                                  return p.id !== item.id
                                })
                                setProjects(newProjects)
                              }
                            }}
                          />
                        </td>
                        <td>{item?.csp_id ? item.csp_id : "-"}</td>
                        <td>{item?.registry ? item.registry : "-"}</td>
                        <td>{item?.registry_Id ? item.registry_Id : "-"}</td>
                        <td title={item?.project_name}>
                          {item?.project_name
                            ? item.project_name.length > 32
                              ? item.project_name.substring(0, 32) + "..." // Truncate to 32 characters and add '...'
                              : item.project_name
                            : "-"}
                        </td>
                        <td>
                          {item?.project_status ? item.project_status : "-"}
                        </td>
                        <td title={item?.project_developer}>
                          {item?.project_developer
                            ? item.project_developer.length > 25
                              ? item.project_developer.substring(0, 25) + "..." // Truncate to 32 characters and add '...'
                              : item.project_developer
                            : "-"}
                        </td>
                        <td>{item?.country ? item.country : "-"}</td>
                        <td title={item?.project_sectoral_scopes}>
                          {item?.project_sectoral_scopes
                            ? item.project_sectoral_scopes.length > 20
                              ? item.project_sectoral_scopes.substring(0, 20) +
                              "..." // Truncate to 32 characters and add '...'
                              : item.project_sectoral_scopes
                            : "-"}
                        </td>
                        <td>
                          {item?.project_types ? item.project_types : "-"}
                        </td>
                        <td title={item?.methodology}>
                          {item?.methodology
                            ? item.methodology.length > 8
                              ? item.methodology.substring(0, 8) + "..." // Truncate to 32 characters and add '...'
                              : item.methodology
                            : "-"}
                        </td>
                        {/* <td>

                            </td> */}
                        <td>
                          {item?.SDGs?.length === 0
                            ? "-"
                            : splitSGDs(item?.SDGs)?.map((element, index) => (
                              <div
                                key={index}
                                style={{
                                  display: "inline-block",
                                  marginRight: "5px"
                                }}
                              >
                                <img
                                  className="table-sdg-icon"
                                  src={`/icons/sdg/${element.label}.jpg`}
                                  alt={element.title}
                                  title={element.title}
                                />
                              </div>
                            ))}
                        </td>

                        <td>{item?.seals ? item.seals : "-"}</td>
                        <td>
                          {item?.estimated_credits
                            ? new Intl.NumberFormat().format(
                              item.estimated_credits
                            )
                            : "-"}
                        </td>
                        <td>
                          {item?.total_credits_issued
                            ? new Intl.NumberFormat().format(
                              item.total_credits_issued
                            )
                            : "-"}
                        </td>
                        <td>
                          {item?.total_credits_retired
                            ? new Intl.NumberFormat().format(
                              item.total_credits_retired
                            )
                            : "-"}
                        </td>
                        <td>
                          {item?.total_credits_avaliable
                            ? new Intl.NumberFormat().format(
                              item.total_credits_avaliable
                            )
                            : "-"}
                        </td>
                        <td>
                          {item?.total_credit_buffer
                            ? new Intl.NumberFormat().format(
                              item.total_credit_buffer
                            )
                            : "-"}
                        </td>
                        <td>
                          {item?.project_lenght ? item.project_lenght : "-"}
                        </td>
                        <td>
                          {item?.crediting_period_range
                            ? item.crediting_period_range
                            : "-"}
                        </td>
                        <td>
                          <button
                            className="view-btn-tbl"
                            onClick={async () => {
                              history.push("/carbon-project/" + item.slug)
                            }}
                          >
                            View Details
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          </div>
          <div
            className="d-flex justify-content-end align-items-center pagi-mv"
            style={{ paddingTop: "-20px", fontSize: "12px",paddingBottom: "50px" }}
          >
            <ReactPaginate
              breakLabel="..."
              nextLabel="Next"
              pageRangeDisplayed={3}
              pageCount={maxPages}
              previousLabel="Prev"
              containerClassName="pagination d-flex align-items-center"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link bg-light me-2 text-dark"
              nextLinkClassName="page-link bg-light ms-2 text-dark"
              previousClassName="page-item"
              nextClassName="page-item"
              activeClassName="active"
              renderOnZeroPageCount={null}
              forcePage={1 - 1}
              onPageChange={event => {
                setCurrentPage(event.selected + 1)
              }}
            />
          </div>
        </>
      ) : null}
      {loading ? (
        <div className="d-flex align-items-center justify-content-center">
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{
              minHeight: "80vh"
            }}
          >
            <InfinitySpin color="#36d7b7" secondaryColor="#36d7b7" />
            <br />
            <span className="text-secondary">Loading Projects...</span>
          </div>
        </div>
      ) : null}
      {projects.length === 0 && !loading ? (
        <Alert className="text-center bg-white shadow shadow-sm">
          <div className="d-flex align-items-center justify-content-center">
            <span className="text-secondary">
              There are no projects available that match your query; please try
              again using different parameters.
            </span>
          </div>
        </Alert>
      ) : null}
    </div>
  )
}

export default FavoritesTableView
