import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Col } from "reactstrap"
import SelectedProjectCard from "components/ProjectComparison/SelectedProjectCard/indexMobile"

/*
    This component is a simple component that displays the title of a section.
    It takes a single prop, title, which is the title of the section.
  
    // Example usage:
    <SectionHeader title="Project Comparison" />
  
    required props:
    - title: string (title of the section)
*/

export default function SectionHeader({ title, projectsToShow, onRemoveProject, onAddProject }) {



  return (
    <div style={{
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      alignItems: "center",
      alignContent: "flex-start",
      backgroundColor: "#EFF4F4"
    }}>
      <Col
        lg={3}
        xs={2}
        md={3}
        style={{
          backgroundColor: "#EFF4F4",
          borderRadius: "4px",
          padding: "16px",
          overflowX: "auto"
        }}
      >
        <p
          className="m-0 p-0"
          style={{
            color: "#495057",
            fontFamily: "poppins",
            fontWeight: "700",
            fontSize: "18px",
            textAlign: "left",
            lineHeight: "27px"
          }}
        >
          {title}
        </p>
      </Col>
      {projectsToShow.map((project, index) => {
        if (!project) {
          return (
            <Col key={index}
                 style={{ display: "flex", alignItems: "center", minWidth: 300, paddingLeft: 16, minHeight: 120 }}>
              <SelectedProjectCard
                key={index}
                id={null}
                title={null}
                subTitle={null}
                index={index}
                onRemoveProject={onRemoveProject}
                onAddProject={onAddProject}
                maxWith="100%"
              />
            </Col>
          )
        } else {
          return (
            <Col key={index}
            xs={2}
            className="mobile_col"
                 style={{ display: "flex", alignItems: "center", minWidth: 300, paddingLeft: 16, minHeight: 120 }}>
              <SelectedProjectCard
                key={index}
                id={project.id}
                title={project.title}
                subTitle={project.country}
                index={index}
                onRemoveProject={onRemoveProject}
                maxWith="100%"
                slug={project?.slug}
                onAddProject={onAddProject}
              />
            </Col>
          )
        }
      })}
    </div>
  )
}

SectionHeader.propTypes = {
  title: PropTypes.string
}
