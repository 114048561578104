/* eslint-disable react/jsx-no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { Link, useHistory, useRouteMatch } from "react-router-dom"
import Select, { components } from "react-select"
import { Button, Card, CardBody, Col, Row } from "reactstrap"
import Logo_cs from "../../assets/images/CHLogoTitleDark.png"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import Swal from "sweetalert2"
import { logout } from "store/RTK-Store/authSlice"

//i18n
import { withTranslation } from "react-i18next"
// Redux Store
import { getDropdownDataApi } from "core/ProjectApi"
import { checkAuthTokenValidity } from "core/ApiUsers"
import {
  setCurrentTab,
  setFilterCount,
  setSearchPalylod,
  setSearchPalylodEmpty,
  setPageSizeReset,
} from "store/RTK-Store/projectsSlice"
import Earth from "../../assets/images/earth.svg"
import {
  changeSidebarType,
  showRightSidebarAction,
  toggleLeftmenu,
} from "../../store/actions"
import AvailableRangeSliders from "./CreaditsAvailableMobileSlider"
import AvailableRangeSlider from "./CreaditsAvailableSlider"
import jwtDecode from "jwt-decode"
import AnalyticsIcon from "../../assets/images/analyticsIcon.svg"
import { markAsRead } from "store/RTK-Store/notificationsSlice"
import CompareButton from "./CompareButton"
import { removeAllComparedProjects } from "store/RTK-Store/comparisonSlice"
import transformPayload from "components/Home/payloadTransform"
import { useGetMaxCreditsQuery, useGetCreditingYearsQuery} from "store/RTK-Store/services/projectApi"
import { setMaxCreditValue, setMaxCreditingPeriod, setMinCreditingPeriod} from "store/RTK-Store/projectsSlice"
import { set } from "lodash"
// crediting filter drowdown in header
function CustomsDropdown({
  handleClearCredits,
  definingCredit,
  selectedMinCredits,
  selectedMaxCredits,
  children,
  handleKeyDown,
  maxCreditsVal
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [mouseInside, setMouseInside] = useState(false)
  
  const formatCredits = value => {
    if (value >= 1e9) {
      return (value / 1e9).toFixed(1) + "B"
    } else if (value >= 1e6) {
      return (value / 1e6).toFixed(1) + "M"
    } else if (value >= 1e3) {
      return (value / 1e3).toFixed(1) + "K"
    } else {
      return value.toString()
    }
  }
  // console.log(mouseInside,isOpen);
  
  return (
    <div
      tabIndex={0}
      onBlur={() => !mouseInside && setIsOpen(false)}
      onKeyDown={e => {
        handleKeyDown(e, setIsOpen)
      }}
    >
      <div
        className="credit-dropdown-1"
        style={{ paddingTop: "8px", color: "#495057", fontWeight: 500 }}
        onClick={() => setIsOpen(true)}
      >
        {definingCredit ? (
          <label style={{ paddingTop: "3px", paddingRight: "12px" }}>
            Carbon Credits
          </label>
        ) : (
          <>
            <div>
              {selectedMinCredits != 0 && selectedMaxCredits != maxCreditsVal ? (
                <label style={{ paddingTop: "5px", paddingRight: "12px" }}>
                  {formatCredits(selectedMinCredits)}...
                </label>
              ) : (
                <>
                  {selectedMinCredits != 0 && (
                    <label style={{ paddingTop: "5px", paddingRight: "12px" }}>
                      {formatCredits(selectedMinCredits)}
                    </label>
                  )}
                  {selectedMaxCredits != maxCreditsVal && (
                    <label style={{ paddingTop: "5px", paddingRight: "12px" }}>
                      {formatCredits(selectedMaxCredits)}
                    </label>
                  )}
                </>
              )}
            </div>
            <label
              className="clear-btn-2"
              color="#495057"
              onClick={(e)=>{
                handleClearCredits(e)
                setIsOpen(false)
              }}
              aria-label="Clear"
            >
              {/* &times;  */}
              <svg
                style={{ color: "#495057" }}
                height="20"
                fill="#495057"
                width="20"
                viewBox="0 0 20 20"
                aria-hidden="true"
                focusable="false"
                className="css-tj5bde-Svg"
              >
                <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
              </svg>
            </label>
            &nbsp;&nbsp;&nbsp;&nbsp;
          </>
        )}

        <div style={{ color: "#495057", paddingTop: "2px" }}>
          <svg
            style={{ color: "#495057" }}
            height="20"
            width="20"
            fill="#495057"
            viewBox="0 0 20 20"
            aria-hidden="true"
            focusable="false"
            className="css-8mmkcg"
          >
            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
          </svg>
        </div>
      </div>
      {isOpen && <div 
      className="credit-content-1"
      onMouseEnter={() => setMouseInside(true)}
      onMouseLeave={() => setMouseInside(false)}
      >{children}</div>}
    </div>
  )
}

function CustomsDropdowns({
  handleClearCredits,
  definingCredit,
  selectedMinCredits,
  selectedMaxCredits,
  children,
  handleKeyDown,
  maxCreditsVal
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [mouseInside, setMouseInside] = useState(false)
  const dropdownRef = useRef(null)
  const formatCredits = value => {
    if (value >= 1e9) {
      return (value / 1e9).toFixed(1) + "B"
    } else if (value >= 1e6) {
      return (value / 1e6).toFixed(1) + "M"
    } else if (value >= 1e3) {
      return (value / 1e3).toFixed(1) + "K"
    } else {
      return value.toString()
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside)

    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [])

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }
  const redirectToBookDemo = () => {
    history.push("/book-demo")
  }

  return (
    <div
      style={{ border: "1px solid #E6DEDE" }}
      ref={dropdownRef}
      tabIndex={0}
      onBlur={() => {
        if (!mouseInside) {
          setIsOpen(false)
        }
      }}
      onMouseEnter={() => setMouseInside(true)}
      onMouseLeave={() => setMouseInside(false)}
      onClick={() => setIsOpen(true)}
      // onKeyDown={e => {
      //   handleKeyDown(e, setIsOpen)
      // }}
    >
      {/* <div
        style={{ color: "black", fontWeight: 500, padding:"9px 0", fontSize:"11px"}}
        onClick={() => setIsOpen(!isOpen)}
      >
        Carbon Credits
      </div> */}
      {definingCredit ? (
        <label
          style={{
            padding: "9px 0px 0px",
            fontSize: "11px",
            fontWeight: 500,
            color: "black",
          }}
        >
          Carbon Credits
        </label>
      ) : (
        <>
          <div
            style={{
              display: "flex",
            }}
          >
            <div>
              {selectedMinCredits != 0 && selectedMaxCredits != maxCreditsVal ? (
                <label style={{ paddingTop: "5px", paddingRight: "12px" }}>
                  {formatCredits(selectedMinCredits)} -{" "}
                  {formatCredits(selectedMaxCredits)}
                </label>
              ) : (
                <>
                  {selectedMinCredits != 0 && (
                    <label style={{ paddingTop: "5px", paddingRight: "12px" }}>
                      {formatCredits(selectedMinCredits)}
                    </label>
                  )}
                  {selectedMaxCredits != maxCreditsVal && (
                    <label style={{ paddingTop: "5px", paddingRight: "12px" }}>
                      {formatCredits(selectedMaxCredits)}
                    </label>
                  )}
                </>
              )}
            </div>
            {/* <div>
              <button
                onClick={redirectToBookDemo}
                className="landing-btn-search d-none d-md-block"
                style={{
                  marginTop: "14px",
                  marginRight: "10px",
                  height: "27px",
                  fontSize: "11px",
                  fontWeight: 700,
                  width: "114px",
                }}
              >
                Book a Demo
              </button>
            </div> */}
            <label
              className="clear-btn-2"
              color="#495057"
              onClick={handleClearCredits}
              aria-label="Clear"
              style={{
                paddingTop: "1.5px",
              }}
            >
              {/* &times;  */}
              <svg
                style={{ color: "#495057" }}
                height="20"
                fill="#495057"
                width="20"
                viewBox="0 0 20 20"
                aria-hidden="true"
                focusable="false"
                className="css-tj5bde-Svg"
              >
                <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
              </svg>
            </label>
            &nbsp;&nbsp;&nbsp;&nbsp;
          </div>
        </>
      )}
      {isOpen && <div>{children}</div>}
    </div>
  )
}

const Header = props => {
  const dispatch = useDispatch()
  const dropdownValues = useSelector(state => state.projects.dropdownData)
  const projectTypes = useSelector(state => state.projects.projectsTypeData)
  const [search, setsearch] = useState(false)
  const searchpalyload = useSelector(state => state.projects.searchPalylod)
  const [selectedName, setSelectedName] = useState(
    searchpalyload?.projectTitle || ""
  )
  const [selectedCountry, setSelectedCountry] = useState(
    searchpalyload?.country || []
  )
  const [selectedType, setSelectedType] = useState(
    searchpalyload?.projectTypes || []
  )
  const [selectedMinCredits, setSelectedMinCredits] = useState(
    searchpalyload?.minCarbonCredits || 0
  )
  const [maxCreditsVal, setMaxCreditsVal] = useState(0)

  const [selectedMaxCredits, setSelectedMaxCredits] = useState(
    searchpalyload?.maxCarbonCredits || maxCreditsVal
  )
  const definingCredit =
    selectedMinCredits == 0 && parseInt(selectedMaxCredits) == parseInt(maxCreditsVal)
  const definingMinCredit = selectedMinCredits == 0
  const definingMaxCredit = parseInt(selectedMaxCredits) == parseInt(maxCreditsVal)
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)
  const emptyArray = Object.create(null)
  const [clearStatus, setClearStatus] = useState(false)
  const isHomeRoute = useRouteMatch("/carbon-projects")
  const { data: maxCredits } = useGetMaxCreditsQuery()
  const { data: creditingYears } = useGetCreditingYearsQuery()

  const handleClearStatusChange = newClearStatus => {
    setClearStatus(newClearStatus)
  }
  
  const payload = {
    projectTitle: selectedName,
    minCarbonCredits: definingMinCredit ? null : selectedMinCredits,
    maxCarbonCredits: definingMaxCredit ? null : selectedMaxCredits,
    country: selectedCountry,
    projectTypes: selectedType,
    // keep advance filter results
    sectoralScopes: searchpalyload?.sectoralScopes || [],
    registry: searchpalyload?.registry || [],
    registryStatus: searchpalyload?.registryStatus || [],
    region: searchpalyload?.region || [],
    extraSeals: searchpalyload?.extraSeals || [],
    startCreditingPeriod: searchpalyload?.startCreditingPeriod || null,
    endCreditingPeriod: searchpalyload?.endCreditingPeriod || null,
    projectDeveloper: searchpalyload?.projectDeveloper || "",
    projectStatus: searchpalyload?.projectStatus || [],
  }
  const handleSearchClick = () => {
    if (isHomeRoute && isHomeRoute.isExact) {
      dispatch(setSearchPalylod(payload))
    } else {
      history.push("/carbon-projects")
      dispatch(setSearchPalylod(payload))
      if (history.location.pathname.indexOf("home") == -1) {
        history.push("/carbon-projects")
      }
    }
  }

  const toggleLoginModal = () => {
    setIsLoginModalOpen(!isLoginModalOpen)
  }
  const history = useHistory()

  function clearFilterStates() {
    setSelectedName("")
    setSelectedType([])
    setSelectedCountry([])
    setSelectedMinCredits("")
    setSelectedMaxCredits("")
    dispatch(setSearchPalylod(emptyArray))
  }

  // console.log(selectedMaxCredits, maxCreditsVal);
  
  let count = 0

  if (Object.keys(searchpalyload ?? {}).length === 0) {
    count = 0
  } else {
    for (const key in searchpalyload) {
      if (
        searchpalyload[key] !== null &&
        searchpalyload[key] !== "" &&
        searchpalyload[key] !== 0 &&
        searchpalyload[key] !== 10000000000 &&
        searchpalyload[key].length !== 0
      ) {
        count++
      }
    }
  }

  useEffect(() => {
    if (!dropdownValues) {
      dispatch(getDropdownDataApi())
    }
  }, [dropdownValues])

  useEffect(() => {
    dispatch(setFilterCount(count))
  }, [count])

  useEffect(() => {
    setSelectedName(searchpalyload?.projectTitle || "")
    setSelectedCountry(searchpalyload?.country || [])
    setSelectedType(searchpalyload?.projectTypes || [])
    setSelectedMinCredits(searchpalyload?.minCarbonCredits || 0)
    setSelectedMaxCredits(searchpalyload?.maxCarbonCredits || maxCreditsVal)
  }, [searchpalyload])

  useEffect(() => {
    if(maxCredits){
      let maxCreditsVal = Number(_.get(maxCredits, "payload.maxCredits",0)).toFixed(0);
      setMaxCreditsVal(maxCreditsVal)
      setSelectedMaxCredits(maxCreditsVal)
      dispatch(setMaxCreditValue(maxCreditsVal))
    }
    
  }, [maxCredits])

  useEffect(() => {
    if(creditingYears){
      let min = _.get(creditingYears, "payload.minCreditingPeriod",null);
      let max = _.get(creditingYears, "payload.maxCreditingPeriod",null);
      if(min){
        dispatch(setMinCreditingPeriod(min))
      }
      if(max){
        dispatch(setMaxCreditingPeriod(max))
      }
    }
  }, [creditingYears])
  
  

  useEffect(() => {
    const newPayload = {
      ...searchpalyload,
      projectTitle: selectedName,
      minCarbonCredits: definingMinCredit ? null : selectedMinCredits,
      maxCarbonCredits: definingMaxCredit ? null : selectedMaxCredits,
      country: selectedCountry,
      projectTypes: selectedType,
    }
    if (!_.isEqual(newPayload, searchpalyload) && searchpalyload) {
      dispatch(setSearchPalylod(newPayload))
    }
  }, [selectedCountry, selectedType])
  useEffect(() => {
    if (clearStatus) {
      setSelectedMinCredits(0)
      setSelectedMaxCredits(10000000000)
      dispatch(setSearchPalylod(payload))
      setClearStatus(false)
    }
  }, [clearStatus])

  const style = {
    control: base => ({
      ...base,
      border: 0,
      borderLeft: "1px solid rgb(238, 228, 228)",
      borderRadius: 0,
      boxShadow: "none",
      backgroundColor: "transparent",
      height: "40px",
      minHeight: "38px",
      maxHeight: "38px",
      // minWidth:'117px'
    }),
    multiValue: base => ({
      ...base,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "100%",
      maxHeight: "38px",
      zIndex: 1,
    }),
    valueContainer: base => ({
      ...base,
      overflow: "auto",
      // height: '38px',
      // minHeight: '34px',
      maxWidth: "100%",
      maxHeight: "34px",
    }),
    menuList: base => ({
      ...base,
      padding: "10px",
      height: "170px",
      placeholder: {
        color: "black",
        fontWeight: "bold",
      },
    }),
    menu: base => ({
      ...base,
      background: "#fff",
      color: "black",
      width: "300px",
      height: "170px",
      marginTop: "20px",
      borderRadius: "10px",
      overflow: "auto",
    }),
    placeholder: base => ({
      ...base,
      color: "black",
      fontWeight: 500,
    }),
    dropdownIndicator: base => ({
      ...base,
      fontWeight: 50,
      color: "rgb(48, 46, 46)",
      "&:hover": { color: "rgb(48, 46, 46)" },
    }),
  }

  const style1 = {
    control: base => ({
      ...base,
      border: 0,
      borderLeft: "1px solid rgb(238, 228, 228)",
      borderRadius: 0,
      boxShadow: "none",
      backgroundColor: "transparent",
      height: "40px",
      minHeight: "38px",
      maxHeight: "38px",
      // minWidth:'117px'
    }),
    multiValue: base => ({
      ...base,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "100%",
      maxHeight: "38px",
      zIndex: 1,
    }),
    valueContainer: base => ({
      ...base,
      overflow: "auto",
      // height: '38px',
      // minHeight: '34px',
      maxHeight: "34px",
    }),
    menuList: base => ({
      ...base,
      padding: "10px",
      height: "170px",
      placeholder: {
        color: "black",
        fontWeight: "bold",
      },
    }),
    menu: base => ({
      ...base,
      background: "#fff",
      color: "black",
      width: "300px",
      height: "170px",
      marginTop: "20px",
      borderRadius: "10px",
      overflow: "auto",
    }),
    placeholder: base => ({
      ...base,
      color: "black",
      fontWeight: 500,
    }),
    dropdownIndicator: base => ({
      ...base,
      fontWeight: 50,
      color: "rgb(48, 46, 46)",
      "&:hover": { color: "rgb(48, 46, 46)" },
    }),
  }

  const dropdownRef = useRef(null)

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])
  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);

  const handleClick = () => {
    setsearch(!search)
  }

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setsearch(false)
    }
  }

  // when click on clear button in keyword search
  const handleClearName = () => {
    setSelectedName("")
    const updatedPayload = {
      ...searchpalyload, // Copy existing payload
      projectTitle: "", // Clear the projectTitle
    }
    dispatch(setSearchPalylod(updatedPayload))
  }

  const handleClearcredits = event => {
    event.stopPropagation() // Stop the event from propagating up the DOM
    setSelectedMinCredits(0)
    setSelectedMaxCredits(maxCreditsVal)
     
    const definingCredit = true
    const updatedPayload = {
      ...searchpalyload,
      minCarbonCredits: definingCredit ? null : selectedMinCredits,
      maxCarbonCredits: definingCredit ? null : selectedMaxCredits,
    }
    dispatch(setSearchPalylod(updatedPayload))
  }

  // when click on logo clear all filter
  const handleLinkClick = () => {
    dispatch(setSearchPalylodEmpty())
    dispatch(setCurrentTab(1))
    dispatch(setPageSizeReset())
  }
  const redirectToBookDemo = () => {
    history.push("/book-demo")
  }

  const token = localStorage.getItem("authToken")
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (token) {
      setIsLoading(true)

      dispatch(checkAuthTokenValidity())
        .unwrap()
        .then(isLogout => {
          if (isLogout) {
            dispatch(logout())
            dispatch(removeAllComparedProjects())
            dispatch(markAsRead())
            Swal.fire({
              title: "Warning",
              text: "You have been logged out.  Please contact support if you face any problems.",
              icon: "warning",
              toast: true,
              timer: 2000,
              position: "top-end",
              showConfirmButton: false,
              showCancelButton: false,
            })
            history.push("/")
          }
        })
        .catch(error => {
          Swal.fire({
            title: "Error",
            text: `Something went wrong! (${error.message})`,
            icon: "error",
          })
          setIsLoading(false)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [])

  // let showBookADemo = true;
  const [showBookADemo, setShowBookADemo] = useState(true)
  useEffect(() => {
    if (token != null) {
      const decoded = jwtDecode(token)
      if (decoded?.role_id === 1) {
        setShowBookADemo(false)
      } else {
        setShowBookADemo(true)
      }
    } else {
      setShowBookADemo(true)
    }
  }, [token])

  useEffect(() => {
    if (token != null) {
      const decoded = jwtDecode(token)
      if (decoded?.role_id === 1) {
        setShowBookADemo(false)
      } else {
        setShowBookADemo(true)
      }
    } else {
      setShowBookADemo(true)
    }
  })
  // const AnalyticsIcon = () => {
  //   return (
  //     <svg
  //       width="42"
  //       height="42"
  //       viewBox="0 0 42 42"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //       style={{ marginTop: -4 }}
  //     >
  //       <path
  //         fillRule="evenodd"
  //         clipRule="evenodd"
  //         d="M9.625 31.5C9.625 31.2679 9.71719 31.0454 9.88128 30.8813C10.0454 30.7172 10.2679 30.625 10.5 30.625H31.5C31.7321 30.625 31.9546 30.7172 32.1187 30.8813C32.2828 31.0454 32.375 31.2679 32.375 31.5C32.375 31.7321 32.2828 31.9546 32.1187 32.1187C31.9546 32.2828 31.7321 32.375 31.5 32.375H10.5C10.2679 32.375 10.0454 32.2828 9.88128 32.1187C9.71719 31.9546 9.625 31.7321 9.625 31.5Z"
  //         fill="#83A4BC"
  //       />
  //       <path
  //         d="M15.75 20.125H12.25C11.7668 20.125 11.375 20.5168 11.375 21V31.5C11.375 31.9832 11.7668 32.375 12.25 32.375H15.75C16.2332 32.375 16.625 31.9832 16.625 31.5V21C16.625 20.5168 16.2332 20.125 15.75 20.125Z"
  //         fill="#83A4BC"
  //       />
  //       <path
  //         d="M22.75 9.625H19.25C18.7668 9.625 18.375 10.0168 18.375 10.5V31.5C18.375 31.9832 18.7668 32.375 19.25 32.375H22.75C23.2332 32.375 23.625 31.9832 23.625 31.5V10.5C23.625 10.0168 23.2332 9.625 22.75 9.625Z"
  //         fill="#83A4BC"
  //       />
  //       <path
  //         d="M29.75 14.875H26.25C25.7668 14.875 25.375 15.2668 25.375 15.75V31.5C25.375 31.9832 25.7668 32.375 26.25 32.375H29.75C30.2332 32.375 30.625 31.9832 30.625 31.5V15.75C30.625 15.2668 30.2332 14.875 29.75 14.875Z"
  //         fill="#83A4BC"
  //       />
  //     </svg>
  //   )
  // }

  const refCountry = useRef(null)
  const refType = useRef(null)

  const ValueContainer_country = ({ children, ...props }) => {
    const selectedOptions = props.getValue()
    const count = selectedOptions.length
    const [values, input] = children

    const isUserTyping = props.selectProps.inputValue.length > 0

    useEffect(() => {
      if (refCountry?.current && props.selectProps.menuIsOpen) {
        refCountry.current.focus()
      }
    }, [props.selectProps.menuIsOpen])

    return (
      <components.ValueContainer {...props}>
        {!isUserTyping &&
          (count > 0
            ? `${count} option${count > 1 ? "s" : ""} selected`
            : props.selectProps.placeholder)}
        {input}
      </components.ValueContainer>
    )
  }
  const ValueContainer_type = ({ children, ...props }) => {
    const selectedOptions = props.getValue()
    const count = selectedOptions.length
    let [values, input] = children
    const isUserTyping = props.selectProps.inputValue.length > 0

    useEffect(() => {
      if (refType?.current && props.selectProps.menuIsOpen) {
        refType.current.focus()
      }
    }, [props.selectProps.menuIsOpen])

    return (
      <components.ValueContainer {...props}>
        {!isUserTyping &&
          (count > 0
            ? `${count} option${count > 1 ? "s" : ""} selected`
            : props.selectProps.placeholder)}
        {input}
      </components.ValueContainer>
    )
  }

  const refCountry_mobile = useRef(null)
  const refType_mobile = useRef(null)

  const ValueContainer_country_mobile = ({ children, ...props }) => {
    const selectedOptions = props.getValue()
    const count = selectedOptions.length
    const [values, input] = children

    const isUserTyping = props.selectProps.inputValue.length > 0

    useEffect(() => {
      if (refCountry_mobile?.current && props.selectProps.menuIsOpen) {
        refCountry_mobile.current.focus()
      }
    }, [props.selectProps.menuIsOpen])

    return (
      <components.ValueContainer {...props}>
        {!isUserTyping &&
          (count > 0
            ? `${count} option${count > 1 ? "s" : ""} selected`
            : props.selectProps.placeholder)}
        {input}
      </components.ValueContainer>
    )
  }
  const ValueContainer_type_mobile = ({ children, ...props }) => {
    const selectedOptions = props.getValue()
    const count = selectedOptions.length
    let [values, input] = children
    const isUserTyping = props.selectProps.inputValue.length > 0

    useEffect(() => {
      if (refType_mobile?.current && props.selectProps.menuIsOpen) {
        refType_mobile.current.focus()
      }
    }, [props.selectProps.menuIsOpen])

    return (
      <components.ValueContainer {...props}>
        {!isUserTyping &&
          (count > 0
            ? `${count} option${count > 1 ? "s" : ""} selected`
            : props.selectProps.placeholder)}
        {input}
      </components.ValueContainer>
    )
  }

  const formatOptionLabel = ({ label, value }, { context, selectValue }) => {
    if (context === "menu") {
      const isSelected = selectValue.some(option => option.value === value)
      return (
        <div>
          <input
            type="checkbox"
            checked={isSelected}
            onChange={() => null}
            style={{ marginRight: "8px" }}
          />
          {label}
        </div>
      )
    }
    return label
  }

  const handleKeyDown = (e, setIsOpen) => {
    if (e.key === "Enter") {
      const newPayload = {
        ...searchpalyload,
        projectTitle: selectedName,
        minCarbonCredits: definingCredit ? null : selectedMinCredits,
        maxCarbonCredits: definingCredit ? null : selectedMaxCredits,
        country: selectedCountry,
        projectTypes: selectedType,
      }
      // console.log(newPayload);
      
      if (!_.isEqual(newPayload, searchpalyload) && searchpalyload) {
        dispatch(setSearchPalylod(newPayload))
        setIsOpen(false)
      }
    }
  }
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex ">
            {/* <div className="brand-logo d-none d-lg-block"> */}
            <div className="brand-logo d-lg-block">
              <Link
                to="/"
                className="logo logo-light"
                onClick={handleLinkClick}
              >
                <span className="logo-sm">
                  <img src={Logo_cs} alt="" height="40" />
                </span>
                <span className="logo-lg">
                  <img src={Logo_cs} alt="" height="40" />
                </span>
              </Link>
            </div>
          </div>

          {/* <div className="d-flex"> */}
          <form className="app-search d-none d-lg-block">
            <div className="position-relative">
              {/* <input
                  type="text"
                  className="form-control"
                  placeholder={props.t("Search") + "..."}
                  /> */}

              <div
                className="btn-group btn-bar"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  maxWidth: "840px",
                  // padding: "0 10px",
                }}
              >
                <Col sm="3" className="input-col">
                  <>
                    <input
                      type="text"
                      name="name"
                      style={{
                        width: "160px",
                        paddingLeft: "5%",
                      }}
                      className="input-btn-1"
                      autoComplete="off"
                      value={selectedName}
                      onKeyDown={e => {
                        if (e.key === "Enter") {
                          handleSearchClick()
                        }
                      }}
                      placeholder="Search for Carbon Projects"
                      onChange={e => {
                        setSelectedName(e.target.value)
                      }}
                    />
                    {selectedName && (
                      <label
                        className="clear-btn-1"
                        onClick={handleClearName}
                        aria-label="Clear"
                      >
                        {/* &times;  */}
                        <svg
                          style={{ color: "#495057" }}
                          fill="#495057"
                          height="20"
                          width="20"
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                          focusable="false"
                          className="css-tj5bde-Svg"
                        >
                          <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                        </svg>
                      </label>
                    )}
                  </>
                </Col>
                <Col sm="2" className="customize-dropdown">
                  <CustomsDropdown
                    handleClearCredits={handleClearcredits}
                    definingCredit={definingCredit}
                    selectedMinCredits={selectedMinCredits}
                    selectedMaxCredits={selectedMaxCredits}
                    handleKeyDown={handleKeyDown}
                    maxCreditsVal={maxCreditsVal}
                  >
                    <AvailableRangeSlider
                      min={0}
                      max={maxCreditsVal==0?10000000:maxCreditsVal}
                      existMax={selectedMaxCredits}
                      existMin={selectedMinCredits}
                      setClearStatus={handleClearStatusChange}
                      onChange={([min, max]) => {
                        if (min != null) {
                          setSelectedMinCredits(min)
                        }
                        if (max != null) {
                          setSelectedMaxCredits(max)
                        }
                        // if (min != 0) {
                        //   setSelectedMinCredits(null)
                        // }
                        // if (max != 10000000000) {
                        //   setSelectedMinCredits(null)
                        // }
                      }}
                    />
                  </CustomsDropdown>
                </Col>
                <Col
                  sm="3"
                  className=""
                  style={{
                    fontWeight: 500,
                    minWidth: "210px",
                  }}
                >
                  <Select
                    isMulti
                    styles={style}
                    ref={refCountry}
                    // hideSelectedOptions={false}
                    className=" dropdown-btn-group "
                    classNamePrefix="select"
                    // isOptionDisabled={option => selectedCountry?.includes(option.id)}
                    isSearchable={true}
                    name="countries"
                    closeMenuOnSelect={false}
                    // options={dropdownValues?.host_coun.map(obj => {
                    //   return { id: obj.id, value: obj.id, label: obj.label }
                    // })}
                    options={dropdownValues?.host_coun
                      .map(obj => ({
                        id: obj.id,
                        value: obj.id,
                        label: obj.label,
                      }))
                      .sort((a, b) => {
                        const isSelectedA = selectedCountry.includes(a.id)
                        const isSelectedB = selectedCountry.includes(b.id)
                        if (isSelectedA && !isSelectedB) return -1
                        if (!isSelectedA && isSelectedB) return 1
                        return 0
                      })}
                    value={dropdownValues?.host_coun
                      .filter(obj => selectedCountry?.includes(obj.id))
                      .map(obj => {
                        return { id: obj.id, value: obj.id, label: obj.label }
                      })}
                    placeholder="Countries"
                    onChange={selectedOption => {
                      // console.log(selectedOption)
                      if (selectedOption) {
                        setSelectedCountry(selectedOption.map(obj => obj.id))
                      } else {
                        setSelectedCountry([])
                      }
                    }}
                    isClearable={true}
                    formatOptionLabel={formatOptionLabel}
                    components={{
                      ValueContainer: ValueContainer_country,
                    }}
                    hideSelectedOptions={false}
                  />
                </Col>
                <Col
                  sm="3"
                  className=""
                  style={{
                    fontWeight: 500,
                    minWidth: "210px",
                  }}
                >
                  <Select
                    isMulti
                    styles={style1}
                    ref={refType}
                    className="dropdown-btn-group"
                    classNamePrefix="select"
                    // hideSelectedOptions={false}
                    // isOptionDisabled={option => selectedType?.includes(option.id)}
                    isSearchable={true}
                    title={projectTypes.filter(obj =>
                      obj.id == selectedType ? selectedType : ""
                    )}
                    closeMenuOnSelect={false}
                    name="projectTypes"
                    // options={projectTypes?.map(obj => {
                    //   return { id: obj.id, value: obj.id, label: obj.label }
                    // })}
                    options={projectTypes
                      .map(obj => ({
                        id: obj.id,
                        value: obj.id,
                        label: obj.label,
                      }))
                      .sort((a, b) => {
                        const isSelectedA = selectedType.includes(a.id)
                        const isSelectedB = selectedType.includes(b.id)
                        if (isSelectedA && !isSelectedB) return -1
                        if (!isSelectedA && isSelectedB) return 1
                        return 0
                      })}
                    value={projectTypes
                      .filter(obj => selectedType?.includes(obj.id))
                      .map(obj => {
                        return { id: obj.id, value: obj.id, label: obj.label }
                      })}
                    placeholder="Project Type"
                    onChange={selectedOption => {
                      if (selectedOption) {
                        setSelectedType(selectedOption.map(obj => obj.id))
                      } else {
                        setSelectedType(null)
                      }
                    }}
                    isClearable={true}
                    formatOptionLabel={formatOptionLabel}
                    components={{
                      ValueContainer: ValueContainer_type,
                    }}
                    hideSelectedOptions={false}
                  />
                </Col>

                {/* <Col sm="1" style={{ width: "40px" }} /> */}

                <Col
                  sm="1"
                  className="reset-btn-container"
                  style={{
                    marginLeft: "15px",
                    marginTop: "-2px",
                  }}
                >
                  <Button
                    className="search-btn1"
                    style={{ paddingTop: "8px" }}
                    onClick={() => {
                      handleSearchClick()
                    }}
                  >
                    Search
                  </Button>
                </Col>

                {/* End Temp Button */}
              </div>

              {/* <span className="bx bx-search-alt" /> */}
            </div>
          </form>

          <div
            className="dropdown serch-sm d-inline-block  ms-2 "
            ref={dropdownRef}
          >
            <button
              onClick={handleClick}
              type="button"
              className="btn serch-sm header-item noti-icon search-icon-bar"
              id="page-header-search-dropdown"
            >
              <i className="mdi mdi-magnify mdi-icon" />{" "}
              <span className="tag-search">Search</span>
            </button>

            <div
              className={`dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 ${
                search ? "show" : ""
              }`}
              aria-labelledby="page-header-search-dropdown"
            >
              <Card>
                <CardBody className="dropdown-cardbody">
                  <div>
                    <Row className="gap" style={{ position: "relative" }}>
                      <input
                        type="text"
                        name="name"
                        className="input-btn-2"
                        autoComplete="off"
                        value={selectedName}
                        placeholder="Search for carbon project"
                        onChange={e => {
                          setSelectedName(e.target.value)
                        }}
                      />
                      {selectedName && (
                        <label
                          onClick={handleClearName}
                          aria-label="Clear"
                          className="clear-icon"
                        >
                          <svg
                            style={{ color: "#495057" }}
                            fill="#495057"
                            height="20"
                            width="20"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                            focusable="false"
                          >
                            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                          </svg>
                        </label>
                      )}
                    </Row>
                    <Row className="gap">
                      <CustomsDropdowns
                        handleClearCredits={handleClearcredits}
                        definingCredit={definingCredit}
                        selectedMinCredits={selectedMinCredits}
                        selectedMaxCredits={selectedMaxCredits}
                        maxCreditsVal={maxCreditsVal}
                      >
                        <AvailableRangeSliders
                          min={0}
                          max={maxCreditsVal==0?10000000:maxCreditsVal}
                          setClearStatus={handleClearStatusChange}
                          existMax={selectedMaxCredits}
                          existMin={selectedMinCredits}
                          onChange={([min, max]) => {
                            if (min != null) {
                              setSelectedMinCredits(min)
                            }
                            if (max != null) {
                              setSelectedMaxCredits(max)
                            }
                          }}
                        />
                      </CustomsDropdowns>
                    </Row>
                    <Row className="gap">
                      <Select
                        isMulti
                        ref={refCountry_mobile}
                        className=" dropdown-btn-group-3 mobile-select-container"
                        styles={{
                          ...style,
                          placeholder: provided => ({
                            ...provided,
                            color: "#000", // Custom color for the placeholder
                          }),
                          valueContainer: provided => ({
                            ...provided,
                            marginLeft: "4px",
                            fontWeight: 500,
                            color: "#000", // Custom color for the value container
                          }),
                        }}
                        style={{ border: "2px solid #cccccc" }}
                        classNamePrefix="select"
                        isSearchable={true}
                        isClearable
                        name="countries"
                        closeMenuOnSelect={false}
                        // options={dropdownValues?.host_coun}
                        options={dropdownValues?.host_coun
                          .map(obj => ({
                            id: obj.id,
                            value: obj.id,
                            label: obj.label,
                          }))
                          .sort((a, b) => {
                            const isSelectedA = selectedCountry.includes(a.id)
                            const isSelectedB = selectedCountry.includes(b.id)
                            if (isSelectedA && !isSelectedB) return -1
                            if (!isSelectedA && isSelectedB) return 1
                            return 0
                          })}
                        placeholder="Country"
                        // value={dropdownValues?.host_coun.filter(obj =>
                        //   obj.id == selectedCountry ? selectedCountry : ""
                        // )}
                        value={dropdownValues?.host_coun
                          .filter(obj => selectedCountry?.includes(obj.id))
                          .map(obj => {
                            return {
                              id: obj.id,
                              value: obj.id,
                              label: obj.label,
                            }
                          })}
                        // onChange={selectedOption => {
                        //   if (selectedOption) {
                        //     setSelectedCountry(`${selectedOption.id}`)
                        //   } else {
                        //     setSelectedCountry(null) // Clear the selection
                        //   }
                        // }}
                        onChange={selectedOption => {
                          console.log(selectedOption)

                          if (selectedOption) {
                            setSelectedCountry(
                              selectedOption.map(obj => obj.id)
                            )
                          } else {
                            setSelectedCountry([])
                          }
                        }}
                        formatOptionLabel={formatOptionLabel}
                        components={{
                          ValueContainer: ValueContainer_country_mobile,
                        }}
                        hideSelectedOptions={false}
                      />
                    </Row>
                    <Row className="gap">
                      <Select
                        styles={{
                          ...style1,
                          placeholder: provided => ({
                            ...provided,
                            color: "#000", // Custom color for the placeholder
                          }),
                          valueContainer: provided => ({
                            ...provided,
                            marginLeft: "4px",
                            fontWeight: 500,
                            color: "#000", // Custom color for the value container
                          }),
                        }}
                        isMulti
                        ref={refType_mobile}
                        className="dropdown-btn-group-3 mobile-select-container"
                        classNamePrefix="select"
                        isSearchable={true}
                        closeMenuOnSelect={false}
                        name="projectTypes"
                        // options={projectTypes}
                        options={projectTypes
                          .map(obj => ({
                            id: obj.id,
                            value: obj.id,
                            label: obj.label,
                          }))
                          .sort((a, b) => {
                            const isSelectedA = selectedType.includes(a.id)
                            const isSelectedB = selectedType.includes(b.id)
                            if (isSelectedA && !isSelectedB) return -1
                            if (!isSelectedA && isSelectedB) return 1
                            return 0
                          })}
                        // value={projectTypes.filter(obj =>
                        //   obj.id == selectedType ? selectedType : ""
                        // )}
                        value={projectTypes
                          .filter(obj => selectedType?.includes(obj.id))
                          .map(obj => {
                            return {
                              id: obj.id,
                              value: obj.id,
                              label: obj.label,
                            }
                          })}
                        placeholder="Project Type"
                        // isClearable={true}
                        // onChange={selectedOption => {
                        //   if (selectedOption) {
                        //     setSelectedType(`${selectedOption.id}`)
                        //   } else {
                        //     setSelectedType(null)
                        //   }
                        // }}
                        onChange={selectedOption => {
                          if (selectedOption) {
                            setSelectedType(selectedOption.map(obj => obj.id))
                          } else {
                            setSelectedType(null)
                          }
                        }}
                        isClearable={true}
                        formatOptionLabel={formatOptionLabel}
                        components={{
                          ValueContainer: ValueContainer_type_mobile,
                        }}
                        hideSelectedOptions={false}
                        // onMenuClose={() => setsearch(true)}
                      />
                    </Row>
                    <Row>
                      <Button
                        className="search-btn2"
                        onClick={() => {
                          handleSearchClick()
                          setsearch(false)
                        }}
                      >
                        Search
                      </Button>
                    </Row>
                    <Row>
                      <Button
                        className="reset-btn2"
                        onClick={() => {
                          clearFilterStates()
                        }}
                        color="rgb(54, 51, 51)"
                        fontWeight="400"
                      >
                        Reset
                      </Button>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>

          <div className="d-flex align-items-center exclude-dropdown">
            {showBookADemo ? (
              <div>
                <button
                  onClick={redirectToBookDemo}
                  className="landing-btn-search d-none d-md-block me-2"
                  style={{
                    height: 30,
                    fontSize: 12,
                    fontWeight: 700,
                    width: 114,
                    marginTop: -14,
                  }}
                >
                  Book a Demo
                </button>
              </div>
            ) : null}

            <button
              onClick={() => {
                history.push("/analytics")
              }}
              className="btn btn-text me-0 me-md-3 d-flex align-items-center analytics-button"
              style={{
                marginTop: -14,
                color: "#495057",
                border: "none",
                height: "30px",
              }}
            >
              <img
                className="pt-4 pb-4"
                src={AnalyticsIcon}
                style={{ borderRadius: "10px", width: "45px" }}
                alt="Logo"
              />
              <span className="d-none d-lg-flex hide_large">Analytics</span>
            </button>

            <div className="d-flex align-items-center justify-content-center">
              {/* <img className="lan-btn" src={Earth} />
              <div className="lantext-btn">EN</div> */}
              {token && (
                <CompareButton
                  // project_data={details}
                  iconWidth={22}
                  iconHeight={28}
                  location="detail"
                  // imageStyles={{marginTop: 4}}
                  style={{ marginRight: 0, marginTop: -14, padding: 0 }}
                />
              )}
            </div>
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
