import React from "react"

export default function DoubleLineItem({ title, body, tooltip, iconSize = '19px', marginBottom = '1rem' }) {
  return (
    <React.Fragment>
      <div className="flex-row two-line-title">
        {tooltip && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <i className="bx bx-info-circle me-2" style={{ cursor: 'pointer', color: '#9C9C9C', fontSize: iconSize }} title={tooltip} />
            <h5 style={{ fontSize: '16px', fontFamily: 'Poppins', fontWeight: 700, color: '#9C9C9C', paddingTop: "10px" }}>{title}</h5>
          </div>
        )}
        {!tooltip && (
          <h5 style={{ fontSize: '16px', fontFamily: 'Poppins', fontWeight: 700, color: '#9C9C9C', paddingTop: "10px", paddingLeft: "28px" }}>{title}</h5>
        )}
        <div style={{ marginBottom: marginBottom, paddingLeft: "28px" }}>
          <div className="fw-bold" style={{ fontSize: '16px', fontFamily: 'Poppins', fontWeight: 500, color: '#495057', overflowWrap: 'anywhere' }}>{body}</div>
        </div>
      </div>

    </React.Fragment>
  )
}
