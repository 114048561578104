import "bootstrap/dist/css/bootstrap.min.css"
import PropTypes from "prop-types"
import React, { useState, useEffect} from "react"

import { connect, useSelector } from "react-redux"
import { Link, Route, BrowserRouter as Router, Switch } from "react-router-dom"
// import crypto from 'crypto';

// Import Routes all
import {
  authProtectedRoutes,
  publicNonAuthLayoutRoutes,
  publicRoutes,
} from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";

import Map from "pages/MapView"
// Import scss
import "./assets/scss/theme.scss"

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

import NonAuthLayout from "components/NonAuthLayout"
import { Helmet } from "react-helmet"
import fakeBackend from "./helpers/AuthType/fakeBackend"

import ChatBox from "components/ChatBot/ChatBox"
import jwtDecode from "jwt-decode"
import { v5 as uuidv5 } from 'uuid';

// Activating fake backend
fakeBackend()

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

const styles = {
  container: {
    textAlign: "center",
    padding: "20%",
  },
  header: {
    fontSize: "2em",
    color: "#f44336",
  },
  text: {
    fontSize: "1.2em",
    marginTop: "20px",
  },
}

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>404 Not found | CarbonHub</title>
      </Helmet>
      <div style={styles.container}>
        <h1 style={styles.header}>404 - Page Not Found</h1>
        <p style={styles.text}>
          Sorry, the page you are looking for does not exist.
        </p>
        <p style={styles.text}>
          Go back to <Link to="/">the home page</Link>.
        </p>
      </div>
    </>
  )
}

const App = props => {
  // const [isOpen, setIsOpen] = useState(false)
  // const [user, setUser] = useState("c3386d2e-f7aa-40ac-a8f9-7016e6d0b8ef")
  const [uuid, setUuid] = useState("")

  const token = useSelector(state => state.auth.token)
  
  useEffect(() => {
    if(token){
      const user = token? jwtDecode(token): null
      const email = user?.email
    
      // Using a predefined namespace for DNS
      const namespace = uuidv5.DNS; 
      if(email){
        setUuid(uuidv5(email, namespace))
      }
    }
    else{
      setUuid("")
    }
  }, [token])
  

  // console.log(uuid);
  //create a hash that doesnt change from the email


  
  

  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()
  // const [user, setUser] = useState("c3386d2e-f7aa-40ac-a8f9-7016e6d0b8ef")

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              // layout={NonAuthLayout}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}

          {publicNonAuthLayoutRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              // layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          <Route path="*" component={NotFound} />
          {/* <Route path="/map" component={Map} /> */}
        </Switch>
      </Router>
      {/* <ChatButton isOpen={isOpen} onToggle={() => setIsOpen(!isOpen)} /> */}
      <ChatBox userId={uuid} />
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
