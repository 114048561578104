/* eslint-disable react-hooks/exhaustive-deps */
// datatable related plugins
import { getDropdownDataApi } from "core/ProjectApi"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import { InfinitySpin } from "react-loader-spinner"
import { Col, Input, Row } from "reactstrap"
import { setCurrentTab, setFilterCount, setSearchPalylod } from "store/RTK-Store/projectsSlice"
import AvailableRangeSlider from "./CreaditAvailableSlider"
import PeriodRangeSlider from "./CreaditPeriodSlider"
import { useHistory } from "react-router-dom"
import { set } from "lodash"

const Filtering = ({ onClose }) => {
  const dispatch = useDispatch()

  const ensureArray = (data) => Array.isArray(data) ? data : [];

  const registryStatus = ensureArray(useSelector((state) => state.projects.registryStausData)).map((item) => {
    return { id: item.id, value: item.id, label: item.label };
  });

  const projectTypes = ensureArray(useSelector((state) => state.projects.projectsTypeData)).map((item) => {
    return { id: item.id, value: item.id, label: item.label };
  });

  const sectoralScopes = ensureArray(useSelector((state) => state.projects.sectoralScopeData)).map((item) => {
    return { id: item.id, value: item.id, label: item.label };
  });

  const sealsList = ensureArray(useSelector((state) => state.projects.sealsData)).map((item) => {
    return { id: item.id, value: item.id, label: item.label };
  });
  const dropdownValues = useSelector((state) => state.projects.dropdownData)
  const loadingDropdown = useSelector((state) => state.projects.loading)
  const searchpalyload = useSelector(state => state.projects.searchPalylod)
  const maxCredit_var = useSelector(state => state.projects.maxCreditValue)
  const maxCreditPeriod_var = useSelector(state=>state.projects.maxCreditingPeriod)
  const minCreditPeriod_var = useSelector(state=>state.projects.minCreditingPeriod)

  const [maxCreditsVal, setMaxCreditsVal] = useState(maxCredit_var)

  const [maxCreditPeriod, setMaxCreditPeriod] = useState(maxCreditPeriod_var)
  const [minCreditPeriod, setMinCreditPeriod] = useState(minCreditPeriod_var)

  const registryData = (dropdownValues?.regi_str || []).map((item) => {
    return { id: item.id, value: item.id, label: item.label }
  })
  const countryData = (dropdownValues?.host_coun || []).map((item) => {
    return { id: item.id, value: item.id, label: item.label }
  })
  const regionData = (dropdownValues?.regi || []).map((item) => {
    return { id: item.id, value: item.id, label: item.label }
  })


  const [selectedProjectType, setProjectType] = useState(searchpalyload.projectTypes || [])
  const [selectedName, setSelectedName] = useState(searchpalyload.projectTitle || "")
  const [selectedRegistry, setRegistry] = useState(searchpalyload.registry || [])
  const [selectedStatus, setStatus] = useState(searchpalyload.registryStatus || [])
  const [selectedMinPeriod, setSelectedMinPeriod] = useState(searchpalyload.startCreditingPeriod || minCreditPeriod)
  const [selectedMaxPeriod, setSelectedMaxPeriod] = useState(searchpalyload.endCreditingPeriod || maxCreditPeriod)
  const [selectedMinCredit, setSelectedMinCredit] = useState(searchpalyload.minCarbonCredits || 0)
  const [selectedMaxCredit, setSelectedMaxCredit] = useState(searchpalyload.maxCarbonCredits || maxCreditsVal)
  const [selectedMainCategories, setMainCategories] = useState(searchpalyload.sectoralScopes || [])
  const [selectedextraVerificationSeals, setextraVerificationSeals] = useState(searchpalyload.extraSeals || [])
  const [selectedRegion, setRegion] = useState(searchpalyload.region || [])
  const [selectedHostCountry, setHostCountry] = useState(searchpalyload.country || [])
  const [developerName, setSelectedDeveloperName] = useState(searchpalyload.projectDeveloper || "")
  const definingCredit = parseInt(selectedMinCredit) === 0 && parseInt(selectedMaxCredit) === parseInt(maxCreditsVal)
  const definingPeriod = parseInt(selectedMinPeriod) === parseInt(minCreditPeriod) && parseInt(selectedMaxPeriod)=== parseInt(maxCreditPeriod) || !selectedMinPeriod || !selectedMaxPeriod
  
  let count = 0


  const payload = {
    projectTitle: selectedName?.toString(),
    country: selectedHostCountry,
    projectTypes: selectedProjectType,
    sectoralScopes: selectedMainCategories,
    registry: selectedRegistry,
    registryStatus: selectedStatus,
    region: selectedRegion,
    extraSeals: selectedextraVerificationSeals,
    projectDeveloper: developerName,
    minCarbonCredits: definingCredit ? null : selectedMinCredit,
    maxCarbonCredits: definingCredit ? null : selectedMaxCredit,
    startCreditingPeriod: definingPeriod ? null : selectedMinPeriod,
    endCreditingPeriod: definingPeriod ? null : selectedMaxPeriod,
    projectStatus: ""
  }
  const handleSearchClick = () => {
    // console.log(definingPeriod, selectedMinPeriod, selectedMaxPeriod);
    
    dispatch(setSearchPalylod(payload))
    history.push("/carbon-projects")
    onClose()
  }

  useEffect(() => {
    if (Object.keys(searchpalyload).length === 0) {
      count = 0
    } else {
      for (const key in searchpalyload) {
        if (searchpalyload[key] !== null && searchpalyload[key] !== "" && searchpalyload[key] !== 0 && searchpalyload[key] !== maxCreditsVal && searchpalyload[key] !== undefined && searchpalyload[key]?.length != 0) {
          // console.log(searchpalyload[key]);
          count++
        }
      }
    }
    setHostCountry((searchpalyload.country) || [])
    setProjectType((searchpalyload.projectTypes) || [])
    setMainCategories((searchpalyload.sectoralScopes) || [])
    setRegistry((searchpalyload.registry) || [])
    setStatus((searchpalyload.registryStatus) || [])
    setRegion((searchpalyload.region) || [])
    setextraVerificationSeals(searchpalyload.extraSeals || [])
    setSelectedMinPeriod(searchpalyload.startCreditingPeriod || minCreditPeriod)
    setSelectedMaxPeriod(searchpalyload.endCreditingPeriod || maxCreditPeriod)
    setSelectedMinCredit(searchpalyload.minCarbonCredits || 0)
    setSelectedMaxCredit(searchpalyload.maxCarbonCredits || maxCreditsVal)
    setSelectedName(searchpalyload.projectTitle || "")
    setSelectedDeveloperName(searchpalyload.projectDeveloper || "")
  }, [searchpalyload])

  useEffect(() => {
    if (!dropdownValues) {
      dispatch(getDropdownDataApi())
    }
  }, [dropdownValues])

  useEffect(() => {
    dispatch(setFilterCount(count))
  }, [count, searchpalyload])
  
  useEffect(() => {
    if(maxCredit_var){
      setMaxCreditsVal(maxCredit_var)
      if(!searchpalyload.maxCarbonCredits){
        setSelectedMaxCredit(maxCredit_var)
      }
    }
  }, [maxCredit_var])

  useEffect(() => {
    if(maxCreditPeriod_var){
      setMaxCreditPeriod(maxCreditPeriod_var)
      if(!searchpalyload.endCreditingPeriod){
        setSelectedMaxPeriod(maxCreditPeriod_var)
      }
    }
  }, [maxCreditPeriod])
  
  useEffect(() => {
    if(minCreditPeriod_var){
      setMinCreditPeriod(minCreditPeriod_var)
      if(!searchpalyload.startCreditingPeriod){
        setSelectedMinPeriod(minCreditPeriod_var)
      }
      
      
    }
  }, [minCreditPeriod_var])
  
  


  function clearFilterStates() {
    setProjectType([])
    setSelectedName("")
    setRegistry([])
    setStatus([])
    setSelectedMinPeriod(minCreditPeriod)
    setSelectedMaxPeriod(maxCreditPeriod)
    setSelectedMinCredit(0)
    setSelectedMaxCredit(maxCreditsVal)
    setMainCategories([])
    setextraVerificationSeals([])
    setRegion([])
    setHostCountry([])
    setSelectedDeveloperName("")

    const clearedPayload = {
      projectTitle: "",
      minCarbonCredits: null,
      maxCarbonCredits: null,
      country: [],
      projectTypes: [],
      sectoralScopes: [],
      registry: [],
      registryStatus: [],
      region: [],
      extraSeals: [],
      startCreditingPeriod: null,
      endCreditingPeriod: null,
      projectDeveloper: "",
      projectStatus: []
    }
    dispatch(setSearchPalylod(clearedPayload))
    dispatch(setCurrentTab(1))
    history.push("/carbon-projects")    
    // onClose()
  }

  
  const history = useHistory()

  return (
    <React.Fragment>

      <form>
        {loadingDropdown ? (
          <div style={{
            width: "100%",
            height: "400px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column"
          }}>
            <InfinitySpin color="#36d7b7" />
            <h5 style={{ marginTop: "20px" }}>Loading...</h5>
          </div>
        ) : (
          <>
            <div className="customer-field-container pt-3">
              <Row>
                <Col md="12">
                  <Input
                    type="name"
                    className="customer-input"
                    placeholder="Title Keyword Search..."
                    value={selectedName}
                    onChange={(e) => {
                      setSelectedName(e.target.value)
                    }}
                  />
                </Col>
              </Row>
            </div>
            <br /><br />
            <Row>
              <h6>Crediting Period</h6><br /><br />
              <div className="credit-period">
                <PeriodRangeSlider
                  min={minCreditPeriod}
                  max={maxCreditPeriod}
                  existMax={selectedMaxPeriod}
                  existMin={selectedMinPeriod}
                  onChange={({ min, max }) => {
                    if (min != null) {
                      setSelectedMinPeriod(min)
                    }
                    if (min === minCreditPeriod) {
                      setSelectedMinPeriod(null)
                    }
                    if (max != null) {
                      setSelectedMaxPeriod(max)
                    }
                    if (max === maxCreditPeriod) {
                      setSelectedMaxPeriod(null)
                    }
                  }}
                />
              </div>
              <br />

            </Row>
            <br /><br />

            <div className="customer-field-container pt-3">
              <Row>
                <Col md="6">
                  <h6>Registry</h6>
                  <Select
                    closeMenuOnSelect={false}
                    isMulti
                    className="select-input1"
                    classNamePrefix="select"
                    // options={dropdownValues?.regi_str}
                    options={registryData}
                    placeholder="Select Registry"
                    value={registryData?.filter(obj => selectedRegistry?.includes(obj.id))}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setRegistry(selectedOption.map(obj => obj.id))
                      } else {
                        setRegistry([])
                      }
                    }}
                    isClearable={true}
                  />

                  <br />
                </Col>

                <Col md="6">
                  <h6>Project Type</h6>
                  <Select
                    isMulti
                    closeMenuOnSelect={false}
                    className="select-input2"
                    classNamePrefix="select"
                    isSearchable={true}
                    name="projectTypes"
                    options={projectTypes ? projectTypes : []}
                    value={projectTypes?.filter(obj => selectedProjectType?.includes(obj.id))}
                    placeholder="Select Project Type"
                    onChange={(selectedOption) => {
                      console.log(selectedOption)
                      if (selectedOption) {
                        setProjectType(selectedOption.map(obj => obj.id))                        
                      } else {
                        setProjectType([])
                      }
                    }}
                    isClearable={true}
                  />
                  <br />
                </Col>

                <Col md="6">
                  <h6>Sectoral Scope</h6>
                  <Select
                    isMulti
                    closeMenuOnSelect={false}
                    className="select-input3"
                    classNamePrefix="select"
                    isSearchable={true}
                    name="mainCategories"
                    options={sectoralScopes ? sectoralScopes : []}
                    value={sectoralScopes?.filter(obj => selectedMainCategories?.includes(obj.id))}
                    placeholder="Select Sectoral Scope"
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setMainCategories(selectedOption.map(obj => obj.id))
                      } else {
                        setMainCategories([])
                      }
                    }}
                    isClearable={true}
                  />
                  <br />
                </Col>


                <Col md="6">
                  <h6>Status</h6>
                  <Select
                    isMulti
                    closeMenuOnSelect={false}
                    className="select-input4"
                    classNamePrefix="select"
                    isSearchable={true}
                    name="statuses"
                    options={registryStatus ? registryStatus : []}
                    value={registryStatus?.filter(obj => selectedStatus?.includes(obj.id))}
                    placeholder="Select Status"
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setStatus(selectedOption.map(obj => obj.id))
                      } else {
                        setStatus([])
                      }
                    }}
                    isClearable={true}
                  />

                </Col>

              </Row>
              <br /><br />

              <Row>
                <h6>Credits Available</h6><br /><br />
                <AvailableRangeSlider
                  min={0}
                  max={maxCreditsVal}
                  existMax={selectedMaxCredit}
                  existMin={selectedMinCredit}
                  onChange={({ min, max }) => {
                    if (min != null) {
                      setSelectedMinCredit(min)
                    }
                    if (min === 0) {
                      setSelectedMinCredit(null)
                    }
                    if (max != null) {
                      setSelectedMaxCredit(max)
                    }
                    if (max === maxCreditsVal) {
                      setSelectedMaxCredit(null)
                    }
                  }}
                />
                {/* </div> */}
                <br />


              </Row>
              <br /><br />

              <Row>
                <Col md="6">
                  <h6>Country</h6>
                  <Select
                    isMulti
                    closeMenuOnSelect={false}
                    className="select-input5"
                    classNamePrefix="select"
                    isSearchable={true}
                    name="countries"
                    // options={dropdownValues?.host_coun}
                    options={countryData}
                    // value={dropdownValues?.host_coun?.filter(obj => obj.id === selectedHostCountry ? selectedHostCountry : "")}
                    value={countryData?.filter(obj => selectedHostCountry?.includes(obj.id))}
                    placeholder="Select the Country"
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setHostCountry(selectedOption.map(obj => obj.id))
                      } else {
                        setHostCountry([]) // Clear the selection
                      }
                    }}
                    isClearable={true} // Allow the selection to be cleared
                  />
                  <br />
                </Col>

                <Col md="6">
                  <h6>Region</h6>
                  <Select
                    isMulti
                    closeMenuOnSelect={false}
                    className="select-input6"
                    classNamePrefix="select"
                    isSearchable={true}
                    name="regions"
                    // options={dropdownValues?.regi}
                    options={regionData}
                    // value={dropdownValues?.regi?.filter(obj => obj.id === selectedRegion ? selectedRegion : "")}
                    value={regionData?.filter(obj => selectedRegion?.includes(obj.id))}
                    placeholder="Select Region"
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setRegion(selectedOption.map(obj => obj.id))
                      } else {
                        setRegion([])
                      }
                    }}
                    isClearable={true}
                  />

                  <br />
                </Col>

                <Col md="6">
                  <h6>Extra Seals</h6>
                  <Select
                    isMulti
                    closeMenuOnSelect={false}
                    className="pb-3"
                    classNamePrefix="select"
                    isSearchable={true}
                    name="extraVerifications"
                    options={sealsList}
                    value={sealsList?.filter(obj => selectedextraVerificationSeals?.includes(obj.id))}
                    placeholder="Select Extra Seals"
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setextraVerificationSeals(selectedOption.map(obj => obj.id))
                      } else {
                        setextraVerificationSeals([]) // Clear the selection
                      }
                    }}
                    isClearable={true} // Allow the selection to be cleared
                  />

                </Col>

                <Col md="6">
                  <h6>Project Developer</h6>
                  <Input
                    type="name"
                    className="customer-input"
                    placeholder="Type Developer Name"
                    value={developerName}
                    onChange={(e) => {
                      setSelectedDeveloperName(e.target.value)
                    }}
                  />
                </Col>
              </Row>


            </div>


            <div className="modal-footer mt-3">

              <button
                type="reset"
                style={{ backgroundColor: "#F46A6A", color: "#ffffff" }}
                className="btn "
                onClick={() => {
                  clearFilterStates()
                }}
              >Clear
              </button>
              <button
                type="button"
                className="btn"
                style={{ backgroundColor: "#7BC7A9", color: "#ffffff" }}
                onClick={() => {
                  handleSearchClick()
                  // eslint-disable-next-line no-undef
                  // tog_view_screen()

                }}
              >Filter
              </button>

            </div>
          </>
        )}
      </form>

    </React.Fragment>

  )
}


export default Filtering