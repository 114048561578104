import { initPasswordReset, resetPassword } from "core/ApiUsers"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import { Alert, Button, Col, Form, FormFeedback, Input, Row } from "reactstrap"
import successIcon from "../../assets/images/success_Icon.png"

// Formik validation
import { useFormik } from "formik"
import * as Yup from "yup"

// import images
import Logo_cs from "assets/images/CarbonhubAiFinalLogo.svg"
import Swal from "sweetalert2"

const ForgotPassword = props => {
  //meta title
  const [activeTab, setActiveTab] = useState(1)
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Please enter you email")
        .email("Invalid email"),
    }),
    onSubmit: payload => {
      setIsLoading(true)

      dispatch(initPasswordReset({ email: payload.email }, props.history))
        .then(response => {
          setEmail(payload.email)
          if (response && response.payload.error === false) {
            props.closeModal()
            Swal.fire(
              "Success",
              response.payload.payload.message,
              "success"
            ).then(() => {
              document.title =
                " CarbonHub: Your Gateway to the World's Largest Carbon Project Database"
            })
          }
          if (response.payload.error === true) {
            Swal.fire({
              title: "Oops!",
              text: response?.payload?.payload.message,
              icon: "error",
              iconColor: "#F46A6A",
            })
          }
        })
        .catch(error => {
          console.err(error)
        })
        .finally(() => setIsLoading(false))
    },
  })

  useEffect(() => {
    if (props.resetToken) {
      setActiveTab(2)
    }
  }, [])

  const validationFinishForm = useFormik({
    enableReinitialize: true,

    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please enter your password"),
      confirmPassword: Yup.string()
        .required("Please enter your password")
        .test("passwords-match", "Passwords must match", function (value) {
          return this.parent.password === value
        }),
    }),
    onSubmit: payload => {
      const token = props.resetToken
      const data = {
        password: payload.password,
      }
      setIsLoading(true)
      dispatch(resetPassword({ ...data, token: token }, props.history)).then(
        response => {
          if (response.payload.error === false && token) {
            setIsLoading(false)
            setActiveTab(3)
          } else {
            setIsLoading(false)
            Swal.fire({
              title: "Oops!",
              text: response.payload.payload.message,
              icon: "error",
              iconColor: "#F46A6A",
            })
          }
        }
      )
    },
  })
  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))
  const [email, setEmail] = useState("")
  const handleLoginRoute = () => {
    props.history.push("/?login=true")
    props.closeModal()
  }
  return (
    <React.Fragment>
      <div style={{ padding: " 5px 30px", backgroundColor: "#F3F3F8" }}>
        {activeTab === 1 && (
          <div id="sec-1">
            <div className="text-center mb-4">
              <img
                className="pt-4 pb-4"
                src={Logo_cs}
                style={{ borderRadius: "10px" }}
                alt="Logo"
              />
              <h3 className="pt-1 pb-0">Forgot your password?</h3>
              <h6
                className="pt-0 pb-2"
                style={{ marginBottom: "2px", color: "#495057" }}
              >
                Enter your account’s email address and we’ll send you a link to
                reset your password.
              </h6>
            </div>
            <div className="p-2">
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()

                  return false
                }}
              >
                {error ? <Alert color="danger">{error}</Alert> : null}
                <div className="mb-3">
                  <Row>
                    <Col md="12">
                      <label htmlFor="email">Email Address</label>
                      <Input
                        name="email"
                        className="form-control"
                        placeholder="Enter Your Email Address"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                          !!(
                            validation.touched.email && validation.errors.email
                          )
                        }
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">
                          {validation.errors.email}
                        </FormFeedback>
                      ) : null}
                    </Col>
                  </Row>
                </div>
                <div className="mt-4 mb-3 d-grid">
                  <button
                    style={{ fontWeight: 700, backgroundColor: "#7AA5BF" }}
                    className="login-button btn btn-secondary"
                    type="submit"
                    disabled={isLoading}
                  >
                    Send Reset Link
                  </button>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <button
                    style={{ color: "#7AA5BF" }}
                    className="btn btn-link"
                    onClick={() => props.openSignUp()}
                    type="button"
                  >
                    Back to Login
                  </button>
                </div>

                <div className=" text-center" style={{ marginTop: 94 }}>
                  <p>Powered by CarbonHub © {new Date().getFullYear()}</p>
                </div>
              </Form>
            </div>
          </div>
        )}
        {activeTab === 2 && (
          <div id="sec-2">
            <div className="text-center mb-4">
              <img
                className="pt-4 pb-4"
                src={Logo_cs}
                style={{ borderRadius: "10px" }}
                width="160px"
                alt="Logo"
              />
            </div>
            <div>
              <h3 style={{ flex: 1, textAlign: "center", margin: "0" }}>
                Password Reset
              </h3>
            </div>

            <div className="p-2" style={{ marginTop: "49px" }}>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validationFinishForm.handleSubmit()

                  return false
                }}
              >
                {error ? <Alert color="danger">{error}</Alert> : null}
                <div className="mb-3">
                  <Row>
                    <Col md="12">
                      <label htmlFor="email">New Password</label>
                      <Input
                        name="password"
                        className="form-control"
                        placeholder="Enter Your New Password"
                        type="password"
                        onChange={validationFinishForm.handleChange}
                        onBlur={validationFinishForm.handleBlur}
                        value={validationFinishForm.values.password || ""}
                        invalid={
                          validationFinishForm.touched.password &&
                          validationFinishForm.errors.password
                            ? true
                            : false
                        }
                      />
                      {validationFinishForm.touched.password &&
                      validationFinishForm.errors.password ? (
                        <FormFeedback type="invalid">
                          {validationFinishForm.errors.password}
                        </FormFeedback>
                      ) : null}
                    </Col>
                  </Row>
                </div>
                <div className="mb-3">
                  <Row>
                    <Col md="12">
                      <label htmlFor="email">Re-Enter Password</label>
                      <Input
                        name="confirmPassword"
                        className="form-control"
                        placeholder="Enter Your New Password"
                        type="password"
                        onChange={validationFinishForm.handleChange}
                        onBlur={validationFinishForm.handleBlur}
                        value={
                          validationFinishForm.values.confirmPassword || ""
                        }
                        invalid={
                          validationFinishForm.touched.confirmPassword &&
                          validationFinishForm.errors.confirmPassword
                            ? true
                            : false
                        }
                      />
                      {validationFinishForm.touched.confirmPassword &&
                      validationFinishForm.errors.confirmPassword ? (
                        <FormFeedback type="invalid">
                          {validationFinishForm.errors.confirmPassword}
                        </FormFeedback>
                      ) : null}
                    </Col>
                  </Row>
                </div>{" "}
                <div className="mt-4 mb-3 d-grid">
                  <button
                    style={{ fontWeight: 700, backgroundColor: "#7AA5BF" }}
                    className="login-button btn btn-secondary"
                    type="submit"
                    disabled={isLoading}
                  >
                    Save
                  </button>
                </div>
                <div className="text-center">
                  <button
                    style={{ color: "#7AA5BF" }}
                    className="btn btn-link"
                    onClick={() => props.openSignUp()}
                  >
                    Back to Login
                  </button>
                </div>
                <div className=" text-center" style={{ marginTop: 50 }}>
                  <p>Powered by CarbonHub © {new Date().getFullYear()}</p>
                </div>
              </Form>
            </div>
          </div>
        )}
        {activeTab === 3 && (
          <div id="sec-1">
            <div className="text-center">
              <img
                className="pt-4 pb-4"
                src={Logo_cs}
                style={{ borderRadius: "10px" }}
                width="160px"
                alt="Logo"
              />
            </div>

            <div className="text-center mb-4">
              <img
                onClick={() => setActiveTab(1)}
                src={successIcon}
                style={{ height: "108px" }}
              />
            </div>
            <div className="mt-4 text-center">
              <p>Your password has been changed successfully.</p>
            </div>
            <div className="mt-4 mb-3 d-grid">
              <Button
                style={{ fontWeight: 700, backgroundColor: "#7AA5BF" }}
                className="login-button btn btn-secondary"
                // to="/?login=true"
                onClick={() => handleLoginRoute()}
              >
                Return to login
              </Button>
            </div>
            <div className=" text-center" style={{ marginTop: 94 }}>
              <p>Powered by CarbonHub © {new Date().getFullYear()}</p>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default withRouter(ForgotPassword)

ForgotPassword.propTypes = {
  history: PropTypes.object,
}
