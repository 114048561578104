import React, { useState } from "react"

import { Card, CardBody, Col, Modal, Row } from "reactstrap"

import CarbonCrediting from "components/AboutUs/CarbonCredit"
import CarbonOffsetting from "components/AboutUs/CarbonOffset"
import CarbonVersioning from "components/AboutUs/CarbonVersion"
import first_icon from "../../assets/images/Carbon Credits1.svg"
import third_icon from "../../assets/images/Carbon Offset.svg"
import second_icon from "../../assets/images/carbon credits.svg"
import { Helmet } from "react-helmet"

const AboutUs = () => {
  const [modal_carbon_credits, setmodal_view_screen] = useState(false)
  const [modal_carbon_version, setmodal_carbon_version] = useState(false)
  const [modal_carbon_offset, setmodal_carbon_offset] = useState(false)

  function tog_carbon_credits() {
    setmodal_view_screen(!modal_carbon_credits)
  }

  function tog_carbon_version() {
    setmodal_carbon_version(!modal_carbon_version)
  }

  function tog_carbon_offset() {
    setmodal_carbon_offset(!modal_carbon_offset)
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>
          About CarbonHub: Our Mission, Vision, and Carbon Initiatives
        </title>
        <meta
          name="description"
          content="Learn about CarbonHub, the leading platform in carbon project data. Discover our mission to facilitate global carbon reduction through a comprehensive project database."
        />
      </Helmet>
      {/* <Container fluid> */}
      <div className="image-container">
        <div className="aboutus-bg">
          <div className="page-content-web ">
            <div className="aboutus-textcontent">
              <h1 style={{ fontSize: 33, color: "white" }}>About CarbonHub</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="abt-content">
        <Card className="abt-card">
          <CardBody>
            <p>
              CarbonHub is a market intelligence platform specializing in carbon
              markets. We consolidate all available public data related to
              projects in carbon markets to offer a centralized source of market
              information. Our platform utilizes advanced analytics and
              real-time data aggregation to provide comprehensive insights to
              the sector. CarbonHub gathers data from all major carbon
              registries with the goal of bringing more transparency,
              uniformity, and integrity to the sector.
            </p>
            <span>
              CarbonHub has the goal of displaying the complexity and diversity
              of voluntary carbon markets as one simple unified entity. Our
              objectives are:
            </span>
            <ul className="mt-3">
              <li>
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Consolidation: &nbsp;
                </span>
                Provide a comprehensive database of carbon offset projects from
                multiple registries
              </li>
              <li>
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Transparency:&nbsp;
                </span>
                Offer detailed information and updates on each project to
                increase transparency and trust
              </li>
              <li>
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Engagement: &nbsp;
                </span>
                Encourage participation in carbon offset initiatives by making
                it easier to find, compare, and support projects.
              </li>
              <li>
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Analytics: &nbsp;
                </span>
                Utilize advanced analytics to offer data insights on individual
                projects, comparison charts, and overall market performance,
                helping users make informed decisions.
              </li>
              <li>
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Innovation: &nbsp;
                </span>
                Constantly refine and enhance our platform by adding features
                and responding to this dynamic market.
              </li>
            </ul>
            <h2 className="policy-h" style={{ marginTop: 25 }}>
              Contact Us
            </h2>
            <span>
              Have questions or want to learn more about our work? Reach out at:
            </span>
            <ul className="mt-2">
              <li>
                <span style={{ fontWeight: "bold" }}>Email: &nbsp;</span>
                info@carbonhub.ai
              </li>
              <li>
                <span style={{ fontWeight: "bold" }}>Phone: &nbsp;</span>
                +1 604 283 7679
              </li>
              <li>
                <span style={{ fontWeight: "bold" }}>Address: &nbsp;</span>
                595 Burrard Street, Suit 1723, Vancouver, BC V7X 1J1.
              </li>
            </ul>
            <span>Stay connected through our social media channels:</span>
            <ul className="mt-2">
              <li>
                <span style={{ fontWeight: "bold" }}>Facebook: &nbsp;</span>
                <a
                  style={{ color: "#3A6B58" }}
                  target="_blank"
                  href="https://www.facebook.com/CarbonHubAI"
                  title="Twitter"
                >
                  https://www.facebook.com/CarbonHubAI
                </a>
              </li>
              <li>
                <span style={{ fontWeight: "bold" }}>Twitter: &nbsp;</span>
                <a
                  style={{ color: "#3A6B58" }}
                  target="_blank"
                  href="https://x.com/CarbonHubAI"
                  title="Twitter"
                >
                  https://x.com/CarbonHubAI
                </a>
              </li>
              <li>
                <span style={{ fontWeight: "bold" }}>LinkedIn: &nbsp;</span>
                <a
                  style={{ color: "#3A6B58" }}
                  target="_blank"
                  href="https://www.linkedin.com/in/carbonhub-ai-44ba75321"
                  title="LinkedIn"
                >
                  https://www.linkedin.com/in/carbonhub-ai-44ba75321
                </a>
              </li>
              {/* <li>
                <span style={{ fontWeight: "bold" }}>Instagram: &nbsp;</span>
                <a
                  style={{ color: "#3A6B58" }}
                  // target="_blank"
                  // href="https://x.com/CarbonHubAI"
                  title="Twitter"
                >
                [Link]
                </a>
              </li> */}
            </ul>
            {/* <Row>
              <Col md="4">
                <img src={first_icon} className="abt-icon"></img>
                <div className="icon-content">
                  <h4>Know about Carbon Credits</h4>
                  <p>
                    Carbon credits, often referred to as carbon allowances, can
                    be thought of as a unit of measurement; however, they have a
                    “tradeable” component. Carbon credits ARE NOT the same as
                    carbon offsets.
                    <br />
                    <br />
                    Carbon credits only exist in jurisdictions that are governed
                    by what’s called a “Cap & Trade” system (such as the
                    California...{" "}
                  </p>
                  <div className="readmore-btn">
                    <a
                      onClick={async () => {
                        setTimeout(() => {
                          tog_carbon_credits()
                        }, 200)
                      }}
                    >
                      Read more
                      <i className="bx bx-right-arrow-alt"></i>
                    </a>
                  </div>
                </div>
              </Col>

              <Col md="4">
                <img src={second_icon} className="abt-icon"></img>
                <div className="icon-content">
                  <h4>What are carbon credits</h4>
                  <p>
                    As governments pressure the private sector to limit
                    greenhouse gas emissions, the world’s largest companies have
                    turned to a financial product to offset their environmental
                    footprints — carbon credits. <br />
                    <br />
                    It’s a hot market, hitting all-time highs in volume and on
                    track to be worth $1 billion in 2021, according to Ecosystem
                    Marketplace,{" "}
                  </p>
                  <div className="readmore-btn">
                    <a
                      onClick={async () => {
                        setTimeout(() => {
                          tog_carbon_version()
                        }, 200)
                      }}
                    >
                      Read more
                      <i className="bx bx-right-arrow-alt"></i>
                    </a>
                  </div>
                </div>
              </Col>

              <Col md="4">
                <img src={third_icon} className="abt-icon"></img>
                <div className="icon-content">
                  <h4>What is a Carbon Offset?</h4>
                  <p>
                    The terms carbon offset and carbon offset credit (or simply
                    “offset credit”) are used interchangeably, though they can
                    mean slightly different things. A carbon offset broadly
                    refers to a reduction in GHG emissions – or an increase in
                    carbon storage (e.g., through land restoration or the
                    planting of trees) – that is used to compensate for
                    emissions that occur elsewhere.{" "}
                  </p>
                  <br />
                  <div className="readmore-btn">
                    <a
                      onClick={async () => {
                        setTimeout(() => {
                          tog_carbon_offset()
                        }, 200)
                      }}
                    >
                      Read more
                      <i className="bx bx-right-arrow-alt"></i>
                    </a>
                  </div>
                </div>
              </Col>
            </Row> */}
          </CardBody>
        </Card>
      </div>

      {/* For first Readmore button */}
      <Modal
        size="lg"
        isOpen={modal_carbon_credits}
        toggle={() => {
          tog_carbon_credits()
        }}
        // centered
        className="first-popscreen"
      >
        <div className="modal-header">
          <h4 className="modal-title mt-0" id="exampleModalFullscreenLabel">
            Know about Carbon Credits
          </h4>
          <button
            onClick={() => {
              setmodal_view_screen(false)
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {/* <div className="modal-content" >  */}
          <CarbonCrediting />
        </div>

        {/* </div> */}
      </Modal>

      {/* For second Readmore button */}
      <Modal
        size="lg"
        isOpen={modal_carbon_version}
        toggle={() => {
          tog_carbon_version()
        }}
        className="second-popscreen"
      >
        <div className="modal-header">
          <h4 className="modal-title mt-0" id="exampleModalFullscreenLabel">
            What are Carbon Credits?
          </h4>
          <button
            onClick={() => {
              setmodal_carbon_version(false)
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {/* <div className="modal-content" >  */}
          <CarbonVersioning />
          {/* </div> */}
        </div>
      </Modal>

      {/* For third Readmore buttton    */}

      <Modal
        size="lg"
        isOpen={modal_carbon_offset}
        toggle={() => {
          tog_carbon_offset()
        }}
        className="third-popscreen"
      >
        <div className="modal-header">
          <h4 className="modal-title mt-0" id="exampleModalFullscreenLabel">
            What is a Carbon Offset?
          </h4>
          <button
            onClick={() => {
              setmodal_carbon_offset(false)
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="modal-content">
            <CarbonOffsetting />
          </div>
        </div>
      </Modal>
      <br />
      <br />
      <br />
      <Modal
        size="lg"
        isOpen={modal_carbon_offset}
        toggle={() => {
          tog_carbon_offset()
        }}
        className="third-popscreen"
      >
        <div className="modal-header">
          <h4 className="modal-title mt-0" id="exampleModalFullscreenLabel">
            What is a Carbon Offset?
          </h4>
          <button
            onClick={() => {
              setmodal_carbon_offset(false)
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {/* <div className="modal-content" >  */}
          <CarbonOffsetting />
          {/* </div> */}
        </div>
      </Modal>

      {/* </Container> */}
    </React.Fragment>
  )
}

export default AboutUs
