import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import { Alert, Col, Form, FormFeedback, Input, Row } from "reactstrap"

// Formik validation
import { useFormik } from "formik"
import * as Yup from "yup"

// import images
import Logo_cs from "assets/images/CarbonhubAiFinalLogo.svg"
import eye from "assets/images/eye.png"
import { useHistory, useLocation } from "react-router-dom"
//Import config
import { postLogin } from "core/ApiUsers"
import { setToken } from "store/RTK-Store/authSlice"
import Swal from "sweetalert2"
import jwtDecode from "jwt-decode"

const Login = props => {
  const dispatch = useDispatch()
  const [showPassword, setShowPassword] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const token = localStorage.getItem("authToken")
  let userRole = null
  let userRoleName = ""
  let userName = ""

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: payload => {
      const trimmedPayload = {
        ...payload,
        email: payload.email.trim(),
        password: payload.password.trim(),

      }

      dispatch(postLogin(trimmedPayload, props.history)).then(response => {
        if (
          response.payload.error === false &&
          response.payload.payload.token
        ) {
          localStorage.setItem("authToken", response.payload.payload.token)
          dispatch(setToken(response.payload.payload.token))
          props.closeModal()
          Swal.fire({
            title: "Success",
            text: "Welcome back!",
            toast: true,
            timer: 2000,
            showConfirmButton: false,
            showCancelButton: false,
            icon: "success",
            position: "top-end",
          })
          const decodedToken = jwtDecode(response.payload.payload.token)
          let roleId = decodedToken.role_id
          if ([1, 2].includes(roleId)) {
            if (location.pathname === "/book-demo") {
              history.push("/")
            }
          }
        } else {
          Swal.fire({
            title: "Oops!",
            text: response.payload.payload,
            icon: "error",
            iconColor: "#F46A6A",
          })
        }
      })
    },
  })

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))

  return (
    <React.Fragment style={{ zIndex: 999999 }}>
      <div style={{ padding: "5px 30px", backgroundColor: "#F3F3F8" }}>
        <div className="text-center mb-4">
          <img
            className="pt-4 pb-4"
            src={Logo_cs}
            style={{ borderRadius: "10px" }}
            alt="Logo"
          />
          <h3 className="pt-1 pb-0">LOGIN</h3>
          <h6
            className="pt-0 pb-2"
            style={{ marginBottom: "2px", color: "#495057" }}
          >
            Sign up to explore about Carbon Projects Database!
          </h6>
        </div>
        <div className="p-2">
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            {error ? <Alert color="danger">{error}</Alert> : null}
            <div className="mb-3">
              <Row>
                <Col md="12">
                  <label htmlFor="email">Email</label>
                  <Input
                    name="email"
                    className="form-control"
                    placeholder="Enter Your Email Address"
                    type="email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      !!(validation.touched.email && validation.errors.email)
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </Col>
              </Row>
            </div>
            <div>
              <label htmlFor="email">Password</label>
              <div className="position-relative align-items-center">
                <div className="d-flex">
                  <Input
                    name="password"
                    value={validation.values.password || ""}
                    type={!showPassword ? "password" : "text"}
                    placeholder="Enter Your Password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      !!(
                        validation.touched.password &&
                        validation.errors.password
                      )
                    }
                  ></Input>

                  <div
                    className="position-absolute"
                    style={{
                      right:
                        validation.touched.password &&
                        validation.errors.password
                          ? 17
                          : 0,
                    }}
                  >
                    <button
                      type="button"
                      className="btn-link btn"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <img
                        src={eye}
                        style={{ borderRadius: "10px" }}
                        height="10px"
                        alt="eye"
                      />
                    </button>
                  </div>
                </div>
              </div>
              {validation.touched.password && validation.errors.password ? (
                <p
                  className="text-danger mt-1"
                  style={{ fontSize: "10.4px" }}
                  type="invalid"
                >
                  {validation.errors.password}
                </p>
              ) : null}
            </div>
            <div style={{ marginTop: "10px" }}>
              <p
                style={{ cursor: "pointer", color: "#7AA5BF" }}
                onClick={() => props.openForgotPassword()}
              >
                Forgot password?
              </p>
            </div>
            <div className="mt-4 mb-3 d-grid">
              <button
                style={{ fontWeight: 700, backgroundColor: "#7AA5BF" }}
                className="login-button btn btn-secondary"
                type="submit"
              >
                Login
              </button>
            </div>
            <div className="mt-4 text-center" style={{ color: "#495057" }}>
              <p>
                Don’t have an account?
                <button
                  type="button"
                  className="btn btn-text p-0 mx-1"
                  style={{ fontWeight: "700", color: "#495057" }}
                  onClick={() => props.openSignUp()}
                >
                  Signup
                </button>
                instead.
              </p>
            </div>
            <div className=" text-center" style={{ marginTop: 94 }}>
              <p>Powered by CarbonHub © {new Date().getFullYear()}</p>
            </div>
          </Form>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
