import React from "react"
import CardSectionTitle from "../CardSectionTitle"
import { Card, CardBody } from "reactstrap"
import CustomTable from "../CustomTable"

const Documents = ({ data, date, dateVisible }) => {
  // console.log(date);

  return (
    <Card className="shadow-none bg-transparent">
      <CardBody>
        <CardSectionTitle
          title="Documents"
          icon={<img src="/icons/docs.svg" alt="Documents" />}
        />
        <div className="mx-4">
          <CustomTable
            tableType="documents"
            fontSize={16}
            data={data}
            date={date}
            dateVisible={dateVisible}
            headers={[
              {
                label: "Name",
                key: "document_name",
                width: "50%"
              },
              {
                label: "Category",
                key: "document_type",
                width: "30%"
              },
              {
                label: "Date Uploaded",
                key: "date",
                width: "20%"
              }
            ]}
          />
        </div>
      </CardBody>
    </Card>
  )
}
export default Documents
