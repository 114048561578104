import React, { useEffect, useRef, useState } from "react"
import { useChat } from "@ai-sdk/react"
import Message from "./ui/chatBox/Messages"
import Thinking from "./ui/chatBox/Thinking"
import Loading from "./ui/chatBox/Loading"
import {
  ChevronDown,
  History,
  Microscope,
  Plus,
  Minus,
  Send,
  Zap,
} from "lucide-react"
import AgentSetting from "./ui/chatBox/AgentSetting"
import Agent from "./ui/chatBox/Agent"
import useDevice from "helpers/useDevice"
import TokenUsage from "./ui/chatBox/TokenUsage"
import CSAI from "./CSAILogo"
import "./Chatbox.css"
const ChatBox = ({ userId }) => {
  const apiUrl = "https://chatbot_staging.carbonhub.ai"
  const [isOpen, setIsOpen] = useState(false)
  const { isMobile } = useDevice()
  const chatBoxEndRef = useRef()
  const [waitToLoadRecentChats, setWaitToLoadRecentChats] = useState(true)
  const [waitToCheckSubscription, setWaitToCheckSubscription] = useState(true)
  const [data, setData] = useState({})
  const [threadData, setThreadData] = useState(null)
  const [chats, setChats] = useState(null)
  const [isToolWindowOpen, setIsToolWindowOpen] = useState(false)
  const [selectedTool, setSelectedTool] = useState("essential")
  const [duration, setDuration] = useState(15000)
  const [isRefreshed, setIsRefreshed] = useState(false)
  const [isRefreshActive, setIsRefreshActive] = useState(false)
  const [isLoadOldChat, setIsLoadOldChat] = useState(false)
  const [isLoadRecentChats, setIsLoadRecentChats] = useState(true)
  const [showMessages, setShowMessages] = useState(false)
  const [errorMessages, setErrorMessages] = useState(false)
  const [isAdvanceModeActive, setIsAdvanceModeActive] = useState(true)
  const stateUpdates = [
    "Analysing the question...",
    "Collecting thoughts...",
    "Gathering information...",
    "Preparing the answer...",
  ]

  const {
    messages,
    setMessages,
    input,
    handleInputChange,
    handleSubmit,
    isLoading,
  } = useChat({
    api: `${apiUrl}/chat`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: {
      agent_type: selectedTool,
      user_id: userId,
    },
    streamMode: "text",
  })
  useEffect(() => {
    if (userId && waitToCheckSubscription) {
      fetch(`${apiUrl}/subscription`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          user_id: userId,
        },
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === "active") {
            setWaitToCheckSubscription(false)
          }
          setIsAdvanceModeActive(data.advance_mode)
        })
        .catch(err => {
          setErrorMessages(true)
          console.log("Could not fetch subscription data.", err)
        })
    }
  }, [waitToCheckSubscription, userId])
  useEffect(() => {
    if (isLoadRecentChats && !waitToCheckSubscription) {
      if (userId) {
        fetchRecentChats()
        setIsLoadRecentChats(false)
      }
    }
  }, [waitToCheckSubscription, isLoadRecentChats, userId])
  useEffect(() => {
    if (!isLoading && !waitToCheckSubscription) {
      if (userId) {
        fetchUsageData()
      }
      scrollToBottom()
    }
  }, [waitToCheckSubscription, isLoading, userId])
  useEffect(() => {
    if (isRefreshed) {
      setIsRefreshed(false)
    }
  }, [isRefreshed])
  useEffect(() => {
    if (!isLoadOldChat && showMessages) {
      if (userId) {
        fetchInitChatMessages(threadData)
        setIsLoadOldChat(true)
      }
    }
  }, [showMessages, isLoadOldChat, userId])
  useEffect(() => {
    if (messages != null) {
      scrollToBottom()
    }
  }, [messages, setMessages])
  useEffect(() => {
    scrollToBottom()
  }, [messages])
  useEffect(() => {
    if (threadData != null) {
      setShowMessages(true)
    }
  }, [threadData])

  async function isThreadEmpty() {
    try {
      const response = await fetch(`${apiUrl}/empty`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          user_id: userId,
        },
      })
      return await response.json()
    } catch (e) {
      setErrorMessages(true)
      console.log("Unable to create new chat.")
    }
  }

  const scrollToBottom = () => {
    chatBoxEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }
  const fetchUsageData = async () => {
    try {
      const response = await fetch(`${apiUrl}/usage`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          user_id: userId,
        },
      })
      if (!response.ok) {
        console.log("Could not fetch data")
      }
      const data = await response.json()
      setData(data)
    } catch (e) {
      setErrorMessages(true)
      console.log("Could not fetch usage data.")
    }
  }
  const fetchRecentChats = async () => {
    fetch(`${apiUrl}/recent`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        user_id: userId,
      },
    })
      .then(response => response.json())
      .then(data => {
        setChats(data)
        data.length === 0
          ? setShowMessages(true)
          : setWaitToLoadRecentChats(false)
      })
      .catch(err => {
        setErrorMessages(true)
        console.log("Could not fetch recent chats.")
      })
  }
  const fetchInitChatMessages = async threadId => {
    if (showMessages) {
      fetch(`${apiUrl}/chathistory`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          user_id: userId,
          thread_id: threadId,
        },
      })
        .then(response => response.json())
        .then(data => {
          setMessages(data)
        })
        .catch(err => {
          setErrorMessages(true)
          console.log("Could not fetch chat history.")
        })
    }
  }
  const toggleToolWindow = () => {
    setIsToolWindowOpen(!isToolWindowOpen)
  }
  const refreshMemory = async () => {
    const threadHasChat = await isThreadEmpty()
    if (threadHasChat) {
      try {
        const response = await fetch(`${apiUrl}/refresh`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
            user_id: userId,
          },
        })
        const data = await response.json()
        setIsRefreshed(true)
        setIsRefreshActive(true)
        setMessages(data)
        setShowMessages(true)
      } catch (e) {
        setErrorMessages(true)
        console.log("create new chat failed.")
      }
    }
  }
  const handleToolWindow = async value => {
    setSelectedTool(value)

    if (value === "essential") {
      setDuration(20000)
    } else {
      setDuration(60000)
    }

    setIsToolWindowOpen(false)
  }
  const handleCloseToolWindow = () => {
    setIsToolWindowOpen(false)
  }
  const loadCurrentActiveThread = () => {
    if (threadData == null && showMessages === false) {
      setShowMessages(true)
    }
  }

  const toggleChat = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div
      className="chat-box-sticky"
      style={{
        zIndex: isMobile ? 1100 : 10000,
      }}
    >
      {isOpen ? (
        <div className="chat-box-card">
          <div className="chat-box-header">
            <h3 className="chat-box-title">
              {/* <span className="chat-box-title-gradient" style={{
                fontSize: 24,
                fontFamily: "Rajdhani"
              }}>CARBONHUB</span> */}
              <font
                size="5"
                face="Rajdhani"
                className="chat-box-title-gradient"
              >
                CARBONHUB
              </font>
            </h3>
            <button onClick={toggleChat} className="chat-box-close">
              <Minus />
            </button>
          </div>
          <div className="chat-box-content">
            {showMessages ? (
              <div id="chatbox" className="chat-box-messages">
                {messages.map(m => (
                  <Message key={m.id} message={m.content} actor={m.role} />
                ))}
                {isLoading ? (
                  <Thinking messages={stateUpdates} duration={duration} />
                ) : null}
                <div ref={chatBoxEndRef} />
              </div>
            ) : waitToLoadRecentChats ? (
              <Loading />
            ) : (
              <div className="chat-box-recent-chats">
                <div className="chat-box-recent-header">
                  <h2 className="chat-box-recent-title">
                    Recent Conversations
                  </h2>
                </div>
                <div className="chat-box-recent-list">
                  {chats.map(chat => (
                    <button
                      key={chat.id}
                      onClick={() => setThreadData(chat.id)}
                      className={`chat-box-chat-button ${
                        chat.status === "ACTIVE" ? "chat-box-chat-active" : ""
                      }`}
                    >
                      <div className="chat-box-chat-content">
                        <History className="chat-box-history-icon" size={28} />
                        <p className="chat-box-chat-title">{chat.title}</p>
                      </div>
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="chat-box-footer">
            <AgentSetting
              user_id={userId}
              toolWindowHandler={isToolWindowOpen}
              toolHandler={handleToolWindow}
              selectedTool={selectedTool}
              advanceMode={isAdvanceModeActive}
              isRefreshBtnActive={isRefreshed}
              onClose={handleCloseToolWindow}
            />

            <div className="chat-box-buttons">
              <button className="chat-box-refresh-btn" onClick={refreshMemory}>
                <div className="chat-box-refresh-content">
                  <Plus size={18} className="chat-box-refresh-icon" />
                  <div
                    className={`chat-box-refresh-text ${
                      !isRefreshActive
                        ? "chat-box-refresh-visible"
                        : "chat-box-refresh-hidden"
                    }`}
                  >
                    <span className="chat-box-refresh-text-content">
                      New chat
                    </span>
                  </div>
                </div>
              </button>
              <button className="chat-box-tool-btn" onClick={toggleToolWindow}>
                <div className="chat-box-tool-content">
                  <Agent />
                  <div className="chat-box-tool-icon-container">
                    {selectedTool === "essential" ? (
                      <Zap size={14} className="chat-box-tool-icon-active" />
                    ) : (
                      <Microscope
                        size={14}
                        className="chat-box-tool-icon-inactive"
                      />
                    )}
                  </div>
                  <div className="chat-box-tool-dropdown">
                    <ChevronDown
                      className="chat-box-tool-dropdown-icon"
                      size={16}
                    />
                  </div>
                </div>
              </button>
            </div>

            <div className="chat-box-input-container">
              <form onSubmit={handleSubmit} className="chat-box-input-form">
                <textarea
                  placeholder="Enter your message here..."
                  value={input}
                  onChange={handleInputChange}
                  onClick={loadCurrentActiveThread}
                  onKeyDown={e => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault()
                      handleSubmit(e)
                    }
                  }}
                  className="chat-box-textarea"
                />
                <button
                  type="submit"
                  style={{
                    backgroundColor: "#111827",
                  }}
                >
                  <Send size="18" className="chat-box-send-icon" />
                </button>
              </form>
            </div>

            <TokenUsage data={data} />
          </div>
        </div>
      ) : userId ? (
        <button onClick={toggleChat} className="chat-box-toggle-btn">
          <CSAI size={"100%"} />
        </button>
      ) : (
        <button
          className="chat-box-toggle-btn"
          onClick={() => {
            const currentUrl = window.location.href
            window.location.href = `${currentUrl}?login=true`
          }}
        >
          <CSAI size={"100%"} />
        </button>
      )}
    </div>
  )
}

export default ChatBox
