import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  Row,
  Col,
  Card,
  Form,
  Nav,
  NavItem,
  NavLink,
  CardBody,
  CardTitle,
  CardText,
  Container,
  Table,
  TabContent,
  TabPane,
  Button,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

//Import Breadcrumb
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
const CarbonOffsetting = () => {
    
    

    return (
        <React.Fragment>
          <div className="">
            <Container fluid={true}>
            
                    <p>The terms carbon offset and carbon offset credit (or simply “offset credit”) are used interchangeably, though they can mean slightly different things. A carbon offset broadly refers to a reduction in GHG emissions – or an increase in carbon storage (e.g., through land restoration or the planting of trees) – that is used to compensate for emissions that occur elsewhere. A carbon offset credit is a transferrable instrument certified by governments or independent certification bodies to represent an emission reduction of one metric tonne of CO2, or an equivalent amount of other GHGs (see Text Box, below). The purchaser of an offset credit can “retire” it to claim the underlying reduction towards their own GHG reduction goals.<br/><br/>
                    The key concept is that offset credits are used to convey a net climate benefit from one entity to another. Because GHGs mix globally in the atmosphere, it does not matter where exactly they are reduced.<a href="https://www.offsetguide.org/understanding-carbon-offsets/what-is-a-carbon-offset/#_ftn1">[1]</a> From a climate change perspective, the effects are the same if an organization: (a) ceases an emission-causing activity; or (b) enables an equivalent emission-reducing activity somewhere else in the world. Carbon offsets are intended to make it easier and more cost-effective for organizations to pursue the second option.
                    As explained later in this guide, offset claims are only defensible under a set of rigorous conditions. Although organizations sometimes use other kinds of investments to make GHG reduction claims – such as the purchases of “renewable energy credits” – these other instruments usually do not meet the criteria for effective carbon offset claims.<br/><br/>

                    Carbon offsetting is possible because climate change is a non-localized problem. Greenhouse gases mix throughout the atmosphere, so reducing them anywhere contributes to overall climate protection.
                    </p>
                    <p><strong>Source:</strong></p>
                    <a href="https://www.offsetguide.org/understanding-carbon-offsets/what-is-a-carbon-offset/">https://www.offsetguide.org/understanding-carbon-offsets/what-is-a-carbon-offset/</a><br/>
            </Container>
          </div>
        </React.Fragment>
      );
    }

export default CarbonOffsetting
