export default function MapTooltip({ marker }) {
    return(
        <div>
            <h3 className="mb-0" style={{
                fontFamily : 'Poppins',
                fontSize : '12px',
                fontWeight : '400',
            }}>{marker.registry}  |  {marker.registry_id}  |  Credits {marker.total_credits_issued}</h3>
        </div>
    )
}