import { createApi } from "@reduxjs/toolkit/query/react"
import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react"
import * as urls from "../../../helpers/url_helper"


const token = localStorage.getItem("authToken")

export const notificationsApi = createApi({
  reducerPath: "notificationsApi",
  keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({ baseUrl: urls.REACT_APP_API_URL }),
  endpoints: builder => ({
    getLatestProjectsCount: builder.query({
      query() {
        const newToken = localStorage.getItem("authToken")

        return {
          url: "/publish/getLatestCreatedCountAlert",
          headers: {
            Authorization: `Bearer ${newToken}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      },
    }),
  }),
})

export const { useGetLatestProjectsCountQuery,useLazyGetLatestProjectsCountQuery } = notificationsApi
