import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { addToComparedProjects } from "store/RTK-Store/comparisonSlice"

// import { enabledButton_Grid, disabledButton_Grid } from "./LogoSVGs/index"
import Swal from "sweetalert2"

const TableButton = ({
  location,
  text,
  onClick,
  style,
  iconWidth,
  iconHeight,
  project_data,
}) => {
  const dispatch = useDispatch()
  const comparedProjects = useSelector(
    state => state.comparison.comparedProjects
  )
  const [enabled, setEnabled] = useState(true)
  const token = localStorage.getItem("authToken")
  const history = useHistory()

  useEffect(() => {
    // console.log(comparedProjects);
    if (comparedProjects) {
      let comparedProjectsIds = comparedProjects.map(project => project.id)
      if (comparedProjectsIds.includes(project_data.id)) {
        setEnabled(false)
      } else {
        setEnabled(true)
      }
    }
  }, [comparedProjects])

  const addToComparison = project_data => {
    if (token) {
      let comparedIds = comparedProjects.map(project => project.id)
      if (comparedIds.includes(project_data.id)) {
        console.log("Already added")
        Swal.fire({
          title: "Already added",
          text: "This project is already added to the comparison list.",
          toast: true,
          timer: 1000,
          showConfirmButton: false,
          showCancelButton: false,
          icon: "warning",
          position: "top-end",
        })
        return
      } else if (comparedIds.length >= 3) {
        Swal.fire({
          title: "Maximum limit reached",
          text: "You can only compare upto 3 projects.",
          toast: true,
          timer: 1300,
          showConfirmButton: false,
          showCancelButton: false,
          icon: "warning",
          position: "top-end",
        })
      } else {
        // console.log(project_data);
        dispatch(
          addToComparedProjects({
            id: project_data.id,
            title: project_data.project_name,
            country: project_data.country,
            slug:project_data.slug,
          })
        )
      }
    } else {
      const path = history.location.pathname
      history.push(`${path}?login=true`)
    }
  }

  return (
    <button
      // onClick={onClick}
      onClick={e => {
        addToComparison(project_data)
        e.stopPropagation()
      }}
      // disabled={!enabled}
      className="btn btn-primary btn-sm comparison_grid_button p-0"
      style={{
        ...style,
        backgroundColor: "transparent",
        border: "none",
        color: enabled ? "#015F81" : "#D3D3D3",
        fontFamily: "Poppins",
        fontSize: "14px",
        
        // pointerEvents: enabled? "auto" : "none",
      }}
    >
      Compare
    </button>
  )
}

export default TableButton
