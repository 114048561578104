/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, useRef } from "react"
import { Col, Input, Modal, ModalBody, Row } from "reactstrap"
import { IconButton } from "rsuite"
import _ from "lodash"
import { InfinitySpin } from "react-loader-spinner"
import { usePostProjectWithParamsAndPayloadMutation } from "store/RTK-Store/services/projectApi"
import { sectoralScopes } from "components/Home/MapCard"
import GridCardHeader from "components/Home/GridCardHeader"
import { CircleFlag } from "react-circle-flags"
import { RegistryIcon } from "components/Home/RegistryLabel"

const CloseIcon = ({ size = 24, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10m-2 0a8 8 0 1 1-16 0 8 8 0 0 1 16 0M7.756 9.167a1 1 0 1 1 1.415-1.414L12 10.585l2.828-2.828a1 1 0 1 1 1.414 1.414L13.415 12l2.828 2.83a1 1 0 1 1-1.415 1.414L12 13.414l-2.83 2.83a1 1 0 0 1-1.414-1.414l2.83-2.83z"
        fill={color}
      />
    </svg>
  )
}

const SearchIcon = props => {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.485 11.999a5.3 5.3 0 0 0 3.261-1.126l2.928 2.931.942-.943-2.928-2.93a5.3 5.3 0 0 0 1.125-3.266c0-2.94-2.39-5.333-5.328-5.333a5.337 5.337 0 0 0-5.329 5.333A5.34 5.34 0 0 0 7.485 12m0-9.335a4 4 0 0 1 3.997 4c0 2.206-1.793 4-3.997 4a4 4 0 0 1-3.996-4c0-2.206 1.792-4 3.996-4"
        fill="#74788D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.424 5.722c.253.253.392.588.392.943h1.332c0-.71-.277-1.38-.782-1.886-1.008-1.008-2.757-1.008-3.764 0l.94.944c.506-.505 1.379-.504 1.882 0"
        fill="#74788D"
      />
    </svg>
  )
}

// Debounce function (as defined earlier)
function debounce(func, delay) {
  let timeoutId
  return function (...args) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      func.apply(this, args)
    }, delay)
  }
}

const EmptyIndicator = () => {
  return (
    <div className="d-flex h-100 align-items-center justify-content-center">
      <div style={{ color: "#F46A6A", fontSize: 14 }}>No results found!</div>
    </div>
  )
}

function formatNumber(num) {
  if (num == "N/A") {
    return num
  }
  // Convert input to number if it's a string
  num = typeof num === "string" ? parseFloat(num) : num

  // Define thresholds and corresponding suffixes
  const thresholds = [
    [1_000_000_000, "B"],
    [1_000_000, "M"],
    [1_000, "K"],
  ]

  // Check against thresholds
  for (const [threshold, suffix] of thresholds) {
    if (Math.abs(num) >= threshold) {
      return `${Math.round(num / threshold)}${suffix}`
    }
  }

  // If number is smaller than 1000, return it as is
  return `${Math.round(num)}`
}

const CustomMapCard = ({ project, onClick }) => {
  // console.log(project)
  const registryStatus = _.get(project, "project_status", "N/A")
  const displayTitle = _.truncate(_.get(project, "project_name"), {
    length: 40,
  })
  const creditsIssued = formatNumber(
    _.get(project, "total_credits_issued", "N/A")
  )
  let sectoralScopeIcons = []
  const secIds = _.get(project, "project_sectoral_scopes_ids", "")
  if (secIds.length) {
    sectoralScopeIcons = secIds.split(",").map(id => parseInt(id))
  }
  return (
    <div className="p-2">
      <div
        onClick={() => {
          onClick(project)
        }}
        className="bg-white h-100 hovered-card"
        style={{
          overflow: "hidden",
          borderRadius: "15px",
          paddingLeft: "18px",
          paddingRight: "18px",
          paddingBottom: "18px",
          paddingTop: "10px",
          boxShadow:
            "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
        }}
        key={project?.id}
      >
        <GridCardHeader project={project} showActions={false} />
        <div className="mb-1" />
        <div
          style={{
            color: "#495057",
            fontWeight: 600,
            fontSize: 16,
            overflow: "clip",
            textOverflow: "ellipsis",
            lineHeight: "1.2rem",
            height: "2.4rem",
          }}
          className="mb-1"
          title={_.get(project, "project_name")}
        >
          {displayTitle}
        </div>
        <Row>
          <Col className="">
            <div
              className=" text-start pt-3 pb-4"
              style={{
                fontSize: "10px",
                color: "#959595",
                lineHeight: "10px",
                maxHeight: "10px",
                // textOverflow: 'ellipsis',
                marginTop: "-5px",
                overflow: "clip",
                fontWeight: 500,
              }}
            >
              {registryStatus}
            </div>
            <div className="d-flex justify-content-start align-items-center">
              {_.get(project, "countryEmojis.code", undefined) ? (
                <CircleFlag
                  className="me-2"
                  countryCode={_.get(
                    project,
                    "countryEmojis.code"
                  ).toLowerCase()}
                  height="18px"
                />
              ) : null}
              <div
                className="text-end"
                style={{
                  fontSize: "11px",
                  lineHeight: "11px",
                  height: "11px",
                  overflow: "clip",
                  fontWeight: 700,
                }}
              >
                {_.truncate(_.get(project, "country", "N/A"), { length: 20 })}
              </div>
            </div>
          </Col>
          <Col>
            <div
              className="d-flex justify-content-end align-items-center text-start pt-3 pb-4"
              style={{
                fontSize: "11px",
                fontFamily: "Poppins",
                color: "#495057",
                lineHeight: "10px",
                maxHeight: "10px",
                // textOverflow: 'ellipsis',
                overflow: "clip",
                fontWeight: 500,
              }}
            >
              Credits Issued
            </div>
            <div
              className="mb-0 d-flex justify-content-end align-items-center"
              style={{
                fontSize: "29px",
                color: "#34C38F",
                fontWeight: 700,
                lineHeight: "10px",
              }}
            >
              {creditsIssued}
            </div>
          </Col>
        </Row>
        <div className="bottemofcard">
          <div
            style={{
              width: "100%",
              borderBottom: "1px solid rgba(217, 217, 217, 0.23)",
            }}
            className="my-2"
          ></div>
        </div>
        <Row
          className="row-cols-2 d-flex align-items-start"
          style={{ height: "18px" }}
        >
          <Col md={8} className="registry-label1">
            <RegistryIcon registry={_.get(project, "registry")} />
            <span
              style={{ color: "#959595" }}
              className="registry-label ms-1 fw-bold text-end"
            >
              {_.get(project, "registry", "N/A").toUpperCase()}
            </span>
          </Col>
          <Col className="text-end" md={4}>
            {sectoralScopeIcons.slice(0, 3).map((icon, index) => {
              const scope = sectoralScopes.find(item => item.id === icon)
              return (
                <span key={index}>
                  <span
                    style={{
                      fontSize: 16,
                      marginRight: "4px",
                      opacity: 0.75,
                    }}
                  >
                    {sectoralScopes.find(item => item.id === scope?.id)?.img}
                  </span>
                </span>
              )
            })}
            {sectoralScopeIcons.length > 3 && (
              <span
                className="fw-bold ms-2"
                style={{
                  fontSize: "11px",
                  color: "#495057",
                  position: "absolute",
                  top: "-3px",
                  right: "-2px",
                }}
              >
                <sup>+{sectoralScopeIcons.length - 3}</sup>
              </span>
            )}
          </Col>
        </Row>
      </div>
    </div>
  )
}

const ProjectPicker = ({ open = true, toggle, onProjectSelected }) => {
  const [searchResults, setSearchResults] = useState([])
  const [searchQuery, setSearchQuery] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [getProjects, { isLoading }] =
    usePostProjectWithParamsAndPayloadMutation()

  const debouncedSearch = useCallback(
    debounce(query => setSearchQuery(query), 300),
    []
  )

  const updateProjects = page => {
    const pageSize = 12
    getProjects({
      type: 1,
      page: page,
      pageSize: pageSize,
      payload: {
        projectTitle: searchQuery,
        minCarbonCredits: null,
        maxCarbonCredits: null,
        country: "",
        projectTypes: "",
        sectoralScopes: "",
        registry: "",
        registryStatus: "",
        region: "",
        extraSeals: "",
        startCreditingPeriod: null,
        endCreditingPeriod: null,
        projectDeveloper: "",
        projectStatus: "",
      },
    })
      .unwrap()
      .then(data => {
        const { error, payload } = data
        if (error) {
          // Process error
        } else {
          const { projectData, totalPages } = payload
          setSearchResults(prevResults =>
            page === 1 ? projectData : [...prevResults, ...projectData]
          )
          const canLoadMore =
            projectData.length === pageSize && currentPage <= totalPages
          setCanLoadMore(canLoadMore)
        }
      })
      .catch(error => {
        console.error("Error fetching projects:", error)
      })
  }

  useEffect(() => {
    if (searchQuery && searchQuery.length && !isLoading) {
      setSearchResults([])
      setCurrentPage(1)
      updateProjects(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery])
  // Lazy loading
  const triggerRef = useRef(null)
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && canLoadMore && !isLoading) {
          setCurrentPage(prevPage => {
            const nextPage = prevPage + 1
            updateProjects(nextPage)
            return nextPage
          })
        }
      },
      { threshold: 0.5 }
    )
    if (triggerRef.current) {
      observer.observe(triggerRef.current)
    }
    return () => observer.disconnect()
  }, [triggerRef, canLoadMore, isLoading, currentPage])
  return (
    <Modal centered isOpen={open} toggle={toggle} size="xl" style={{
      zIndex: 1200
    }}>
      <ModalBody>
        <div className="d-flex mb-3 align-items-center justify-content-between">
          <div>
            <h1 className="fs-5 mb-0 fw-bold" style={{ color: "#495057" }}>
              Add Project
            </h1>
          </div>
          <div>
            <IconButton
              appearance="subtle"
              circle
              size="sm"
              className="p-0"
              icon={<CloseIcon size={24} color="#959595" />}
              onClick={toggle}
            />
          </div>
        </div>
        <div className="position-relative d-flex align-items-center">
          <div className="p-2 position-absolute">
            <SearchIcon size={18} />
          </div>
          <Input
            className="bg-light border-0 rounded rounded-5"
            style={{
              paddingLeft: "32px",
            }}
            placeholder="Search by project title, description, country, sectoral scope..."
            onChange={e => {
              const query = _.get(e, "target.value", "")
              debouncedSearch(query)
            }}
          />
        </div>
        <div style={{ height: "50vh", overflowY: "auto" }} className="p-3">
          {searchQuery.length && !searchResults.length && isLoading ? (
            <div className="d-flex h-100 align-items-center justify-content-center">
              <InfinitySpin />
            </div>
          ) : null}
          {!searchQuery.length ? (
            <div className="d-flex h-100 align-items-center justify-content-center">
              <div style={{ color: "#7AA5BF", fontSize: 14 }}>
                Search a project to add to the comparison.
              </div>
            </div>
          ) : searchResults.length > 0 ? (
            <>
              <Row className="row-cols-1 row-cols-md-1 row-cols-lg-2 row-cols-xl-3">
                {searchResults.map(project => (
                  <CustomMapCard
                    project={project}
                    key={project["id"]}
                    onClick={project => {
                      onProjectSelected(project)
                    }}
                  />
                ))}
                {canLoadMore && (
                  <Col>
                    <div
                      ref={triggerRef}
                      style={{ height: "1px", visibility: "hidden" }}
                    />
                  </Col>
                )}
              </Row>
              {isLoading && searchResults.length ? (
                <div className="p-3 d-flex align-items-center justify-content-center">
                  <InfinitySpin />
                </div>
              ) : null}
            </>
          ) : (
            <EmptyIndicator />
          )}
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ProjectPicker
