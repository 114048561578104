import React from "react"
import { InlineWidget } from "react-calendly"
import { Helmet } from "react-helmet"

export default function BookDemo() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Book a Demo | CarbonHub</title>
      </Helmet>
      <div style={{ marginTop: "120px", paddingBottom: "120px" }}>
        <h1
          style={{
            fontSize: "38px",
            fontWeight: 600,
            textAlign: "center",
            color: "#495057",
          }}
        >
          Schedule a live demo today
        </h1>
        <div
          style={{
            marginTop: "30px",
            fontSize: "18px",
            fontWeight: 400,
          }}
          className="d-flex flex-column align-items-center"
        >
          <div className="text-center" style={{ maxWidth: '1024px' }}>Want to learn more about CarbonHub and how it can be configured or customized for your business?</div>
          <div className="text-center" style={{ marginBottom: 34 }}>Book a demo by filling out the below form.</div>
        </div>
        <InlineWidget url="https://calendly.com/carbonespacejira/30min" />
      </div>
    </React.Fragment>
  )
}
