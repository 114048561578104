import React from "react"
import {
  Container,
} from "reactstrap"


const Dashboard = () => {
  //meta title
  document.title="Forget Password | Skote - React Admin & Dashboard Template";
  return (
    <React.Fragment>
      <Container fluid>
      <div className="image-container">
        <div className="top-bg"> 
          <div className="page-content-web">
            <h1>Dashboard</h1>
          </div>
        </div>
      </div>
      
      </Container>
    

  </React.Fragment>
  );
}

export default Dashboard;
