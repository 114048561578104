import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { setNotification } from "store/RTK-Store/notificationsSlice"
import React from "react"

function AlertsBanner({ icon, title, timestamp, destination, onClose }) {
  const [fixed, setFixed] = useState(true)

  //removed scroll event listener

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY > 293) {
  //       setFixed(false)
  //     } else {
  //       setFixed(true)
  //     }
  //   }
  //   window.addEventListener("scroll", handleScroll)
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll)
  //   }
  // }, [])
  const history = useHistory()
  const dispatch = useDispatch()
  return (
    <div
      className={`d-flex justify-content-between align-items-center px-3`}
      style={{
        background: "#ABDEEA",
        height: "54px",
        width: "100%",
        display: "flex",
        zIndex: 180,
      }}
    >
      <div className="d-flex gap-2 align-items-center flex-row">
        {icon ? (
          icon
        ) : (
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.92363 3.8845C5.81873 2.33413 7.29306 1.20284 9.02227 0.739497C10.7515 0.276156 12.5939 0.518719 14.1443 1.41383C15.6947 2.30893 16.826 3.78326 17.2893 5.51247C17.7526 7.24168 17.5101 9.08413 16.615 10.6345L15.1873 13.1072L14.6314 16.9622C14.6138 17.0834 14.5669 17.1985 14.4946 17.2974C14.4223 17.3963 14.3269 17.476 14.2168 17.5296C14.1066 17.5831 13.9849 17.6088 13.8625 17.6045C13.7401 17.6001 13.6206 17.5659 13.5145 17.5047L0.524108 10.0047C0.41806 9.94337 0.328646 9.85701 0.26368 9.75316C0.198715 9.64931 0.160175 9.53113 0.151431 9.40894C0.142688 9.28676 0.164006 9.16429 0.213521 9.05224C0.263037 8.9402 0.339243 8.84199 0.435479 8.7662L3.496 6.35722L4.92363 3.8845ZM3.75241 13.6006C3.57302 14.2416 3.61249 14.9242 3.86458 15.5403C4.11667 16.1564 4.56699 16.6709 5.1443 17.0023C5.71997 17.3365 6.39066 17.4693 7.05026 17.3796C7.70986 17.2898 8.32072 16.9827 8.78618 16.5069L3.75241 13.6006Z"
              fill="#006A8C"
            />
          </svg>
        )}
        <span
          className="fw-bold"
          style={{ fontSize: "14px", color: "#006A8C" }}
        >
          {title}
        </span>
        <span
          style={{
            fontSize: "14px",
            fontWeight: "lighter",
            fontStyle: "italic",
            color: "#006A8C",
          }}
        >
          {timestamp}
        </span>
      </div>
      <div className="d-flex align-items-center">
        {destination && (
          <button
            className="btn fw-bold btn-primary px-4 me-1"
            style={{
              borderRadius: "18px",
              width: "128px",
              fontSize: "14px",
              height: "36px",
              background: "#86C2D6",
              border: "none",
              color: "#006A8C",
            }}
            onClick={() => {
              dispatch(setNotification(undefined))
              history.push(destination)
            }}
          >
            View
          </button>
        )}
        <button onClick={onClose} className="btn btn-text">
          <svg
            width="16"
            height="16"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.28867 10.6216L13.5917 15.9246C13.8731 16.206 14.2547 16.3641 14.6527 16.3641C15.0506 16.3641 15.4323 16.206 15.7137 15.9246C15.9951 15.6432 16.1531 15.2616 16.1531 14.8636C16.1531 14.4657 15.9951 14.084 15.7137 13.8026L10.4087 8.49962L15.7127 3.19662C15.8519 3.05729 15.9624 2.89189 16.0377 2.70987C16.1131 2.52785 16.1518 2.33277 16.1518 2.13577C16.1517 1.93877 16.1129 1.74371 16.0375 1.56172C15.962 1.37974 15.8515 1.21439 15.7122 1.07512C15.5728 0.935855 15.4074 0.825396 15.2254 0.75005C15.0434 0.674704 14.8483 0.635948 14.6513 0.635995C14.4543 0.636041 14.2593 0.674889 14.0773 0.75032C13.8953 0.825752 13.7299 0.93629 13.5907 1.07562L8.28867 6.37862L2.98567 1.07562C2.84736 0.932293 2.6819 0.817943 2.49893 0.739244C2.31596 0.660546 2.11915 0.619074 1.91999 0.61725C1.72082 0.615426 1.52328 0.653286 1.3389 0.72862C1.15452 0.803955 0.986995 0.915255 0.84609 1.05603C0.705185 1.1968 0.593727 1.36422 0.518219 1.54853C0.44271 1.73284 0.404664 1.93034 0.406301 2.12951C0.407937 2.32868 0.449222 2.52553 0.527748 2.70857C0.606274 2.89161 0.720468 3.05718 0.863667 3.19562L6.16867 8.49962L0.864667 13.8036C0.721468 13.9421 0.607275 14.1076 0.528749 14.2907C0.450223 14.4737 0.408937 14.6706 0.407301 14.8697C0.405664 15.0689 0.44371 15.2664 0.519219 15.4507C0.594727 15.635 0.706185 15.8024 0.84709 15.9432C0.987995 16.084 1.15552 16.1953 1.3399 16.2706C1.52428 16.346 1.72182 16.3838 1.92099 16.382C2.12015 16.3802 2.31696 16.3387 2.49993 16.26C2.6829 16.1813 2.84836 16.0669 2.98667 15.9236L8.28867 10.6216Z"
              fill="#719EAC"
            />
          </svg>
        </button>
      </div>
    </div>
  )
}

export default AlertsBanner
