import { sectoralScopes } from "components/Home/ProjectCard"
import React from "react"

const SectoralScopeIndicator = ({ scopes = [] }) => {
  return (
    <React.Fragment>
      <div className="d-flex" style={{ paddingTop: "4px" }}>
        {scopes?.slice(0, 3).map((icon, index) => {
          const scope = sectoralScopes.find(item => `${item.id}` === `${icon}`)
          return (
            <div key={index}>
              {scope && (
                <div className="d-flex gap-2 align-items-start">
                  {scopes?.length === 1 && (
                    <span
                      className="fw-bold d-flex align-items-center"
                      style={{ fontSize: "16px" }}
                    >
                      <div className="fs-3 me-2">{scope.img}</div>
                      <span
                        className="fs-6"
                        style={{
                          color: "#959595",
                          fontWeight: "bold",
                        }}
                      >
                        {scope.label}
                      </span>
                    </span>
                  )}
                  {scopes?.length < 3 && scopes?.length > 1 && (
                    <span className="fw-bold ms-2" style={{ fontSize: "16px" }}>
                      <div className="fs-3">{scope.img}</div>
                    </span>
                  )}
                  {scopes?.length >= 3 && (
                    <span className="fw-bold ms-2" style={{ fontSize: "75px" }}>
                      <div className="fs-5">{scope.img}</div>
                    </span>
                  )}
                </div>
              )}
            </div>
          )
        })}
        {scopes?.length > 3 && (
          <span
            className="fw-bold ms-2"
            style={{ fontSize: "11px", color: "#495057", marginTop: "-3px" }}
          >
            +{scopes.length - 3}
          </span>
        )}
      </div>
    </React.Fragment>
  )
}

export default SectoralScopeIndicator
