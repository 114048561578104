import React from "react"
import PropTypes from "prop-types"
/*
    This component is a simple component that displays the number of projects selected by the user.
    It takes a single prop, count, which is the number of projects selected.
  
    // Example usage:
    <SelectedProjects count={5} />

    required props:
    - count: number (number of projects selected)
*/

export default function SelectedProjects({ count }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#ffff",
        maxWidth: "425px",
        minWidth: "425px",
        padding: "4px",
        marginLeft: "45px"
      }}
    >
      <span
        style={{
          color: "#495057",
          fontFamily: "poppins",
          fontWeight: "600",
          fontSize: "20px",
          lineHeight: "30px",
        }}
      >
        Projects Selected
      </span>
      <div className="d-flex align-items-center">
        <svg
          width="39"
          height="44"
          viewBox="0 0 39 44"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.5 0L39 11V33L19.5 44L0 33V10.9999L19.5 0ZM4.33327 15.6443V30.5555L17.3333 37.8888V22.9777L4.33327 15.6443ZM34.6666 15.6444L21.6666 22.9777V37.8888L34.6666 30.5555V15.6444ZM19.5 4.88887L6.71663 12.1001L19.5 19.3111L32.2834 12.0999L19.5 4.88887Z"
            fill="#3F6A84"
          />
        </svg>
        <span
          style={{
            color: "#495057",
            fontFamily: "poppins",
            fontWeight: "600",
            fontSize: "48px",
            lineHeight: "72px",
            marginLeft: "31px",
          }}
        >
          {count || 0}
        </span>
      </div>
    </div>
  )
}

SelectedProjects.propTypes = {
  count: PropTypes.number,
}
