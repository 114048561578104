import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Range } from "react-range"

const AvailableRangeSliders = ({ min, max, onChange, existMax, existMin }) => {
  const [values, setValues] = useState([min, max])
  const [isInteractingMin, setIsInteractingMin] = useState(false);
  const [isInteractingMax, setIsInteractingMax] = useState(false);

  useEffect(() => {
    if (existMax !== undefined) {
      setValues(prevValues => [
        prevValues[0],
        Math.min(prevValues[1], existMax),
      ])
    }
  }, [existMax])

  useEffect(() => {
    if (existMin !== undefined) {
      setValues(prevValues => [
        Math.max(prevValues[0], existMin),
        prevValues[1],
      ])
    }
  }, [existMin])

  const handleChange = newValues => {
    setValues(newValues)
    onChange(newValues)
  }

  const formatCredits = (value) => {
    if (value >= 1e9) {
      return (value / 1e9).toFixed(1) + 'B';
    } else if (value >= 1e6) {
      return (value / 1e6).toFixed(1) + 'M';
    } else if (value >= 1e3) {
      return (value / 1e3).toFixed(1) + 'K';
    } else {
      return value;
    }
  };


  return (
    <div className="p-3 bg-white rounded-3">
      <div>
        <Range
          step={1}
          min={min}
          max={max}
          values={values}
          allowOverlap={true}
          onChange={handleChange}
          renderThumb={({ props}) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: "20px",
                width: "20px",
                backgroundColor: "#fff",
                border: "0.5px solid #7e8cbc",
                borderRadius: "50%",
                boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.3)",
              }}
            />
          )}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: "6px",
                width: "100%",
                backgroundColor: "#d0d0d0", // Color for the unselected part
                position: "relative",
                borderRadius: "4px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  left: `${((values[0] - min) / (max - min)) * 100}%`,
                  right: `${100 - ((values[1] - min) / (max - min)) * 100}%`,
                  height: "100%",
                  backgroundColor: "#7e8cbc", // Color for the selected part
                  borderRadius: "4px",
                }}
              />
              {children}
            </div>
          )}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "15px",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <div className="dropdown-range-input-box">
            <label className="mobile-dropdown-range-text">
              Min
            </label>
            <input
              type={isInteractingMin ? "number" : "text"}
              className="dropdown-range-input"
              // value={isInteractingMin ? values[0] : formatCredits(values[0])}
              value={isInteractingMin ? parseInt(values[0]) : formatCredits(values[0])}
              // onChange={e => handleChange([e.target.value, values[1]])}
              onChange={e => 
                handleChange([parseInt(e.target.value), parseInt(values[1])])
              }
              onFocus={() => setIsInteractingMin(true)}
              onBlur={() => setIsInteractingMin(false)}
            />
          </div>
          <p
            style={{
              fontSize: "15px",
              fontWeight: "bold",
              color: "#7e8cbc",
              padding: "0",
              margin: "0",
              marginInline: "10px",
            }}
          >
            -
          </p>
          <div className="dropdown-range-input-box">
            <label className="mobile-dropdown-range-text">
              Max
            </label>
            <input
              className="dropdown-range-input"
              type={isInteractingMax ? "number" : "text"}
              // value={values[1]}
              // value={isInteractingMax ? values[1] : formatCredits(values[1])}
              value={isInteractingMax ? parseInt(values[1]) : formatCredits(values[1])}
              // onChange={e => handleChange([values[0], e.target.value])}
              onChange={e => handleChange([parseInt(values[0]), parseInt(e.target.value)])}
              onFocus={() => setIsInteractingMax(true)}
              onBlur={() => setIsInteractingMax(false)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

AvailableRangeSliders.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  existMax: PropTypes.number,
  existMin: PropTypes.number,
}

export default AvailableRangeSliders
