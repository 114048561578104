import React, { useCallback, useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import { debounce } from "lodash"

const AvailableRangeSlider = ({ min, max, onChange, existMax, existMin }) => {
  const [minVal, setMinVal] = useState(min)
  const [maxVal, setMaxVal] = useState(max)
  const [tempMin, setTempMin] = useState(min)
  const [tempMax, setTempMax] = useState(max)
  const [initialState, setInitialState] = useState(1)
  const minValRef = useRef(min)
  const maxValRef = useRef(max)
  const range = useRef(null)
  const [isInteractingMin, setIsInteractingMin] = useState(false)
  const [isInteractingMax, setIsInteractingMax] = useState(false)

  if (initialState === 1) {
    if (existMin || existMin == 0) {
      // console.log('triggered');
      const value = Math.min(Number(existMin), maxVal - 1)
      setMinVal(value)
      setTempMin(value)
      minValRef.current = value
    }
    if (existMax) {
      const value = Math.max(Number(existMax), minVal + 1)
      setMaxVal(value)
      setTempMax(value)
      maxValRef.current = value
    }
    setInitialState(0)
  }

  const formatCredits = value => {
    if (value >= 1e9) {
      return (value / 1e9).toFixed(1) + "B"
    } else if (value >= 1e6) {
      return (value / 1e6).toFixed(1) + "M"
    } else if (value >= 1e3) {
      return (value / 1e3).toFixed(1) + "K"
    } else {
      return value
    }
  }

  // Convert to percentage
  const getPercent = useCallback(
    value => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  )

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal)
    const maxPercent = getPercent(maxValRef.current)

    if (range.current) {
      range.current.style.left = `${minPercent}%`
      range.current.style.width = `${maxPercent - minPercent}%`
    }
  }, [minVal, getPercent])

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current)
    const maxPercent = getPercent(maxVal)

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`
    }
  }, [maxVal, getPercent])

  // Get min and max values when their state changes
  useEffect(() => {
    onChange({ min: minVal })
  }, [minVal])

  useEffect(() => {
    onChange({ max: maxVal })
  }, [maxVal])

  useEffect(() => {
    setInitialState(1)
  }, [existMax, existMin])

  // const debouncedSetMaxVal = debounce((value) => {
  // if(value>max){
  //   const value = Math.max(Number(max), minVal + 1);
  //   setMaxVal(value);
  //   setTempMax(value);
  //   maxValRef.current = value;
  // }
  // else{
  //   const tempValue = Math.max(Number(value), minVal + 1);
  //   setMaxVal(tempValue);
  //   setTempMax(tempValue);
  //   maxValRef.current = tempValue;
  // }
  // }, 500);
  const debouncedSetMaxVal = useCallback(
    debounce(value => {
      if (parseInt(value) > parseInt(max)) {
        const value = Math.max(Number(max), minVal + 1)
        setMaxVal(value)
        setTempMax(value)
        maxValRef.current = value
      } else {
        const tempValue = Math.max(Number(value), minVal + 1)
        setMaxVal(tempValue)
        setTempMax(tempValue)
        maxValRef.current = tempValue
      }
    }, 500),
    [] // dependencies
  )
  const debouncedSetMinVal = useCallback(
    debounce(value => {
      if (value < min) {
        const value = Math.min(Number(min), maxValRef.current - 1);
        setMinVal(value);
        setTempMin(value);
        minValRef.current = value;
      } else {
        const tempValue = Math.min(Number(value), maxValRef.current - 1);
        setMinVal(tempValue);
        setTempMin(tempValue);
        minValRef.current = tempValue;
      }
    }, 500),
    [] // dependencies
  );
  return (
    <div className="credit-avlable">
      <div className="container-ranger">
        <input
          type="range"
          min={min}
          max={max}
          value={minVal}
          onChange={event => {
            const value = Math.min(Number(event.target.value), maxVal - 1)
            setMinVal(value)
            minValRef.current = value
          }}
          className="thumb thumb--left"
          style={{ zIndex: minVal > max - 100 && "5" }}
        />
        <input
          type="range"
          min={min}
          max={max}
          value={maxVal}
          onChange={event => {
            const value = Math.max(Number(event.target.value), minVal + 1)
            setMaxVal(value)
            setTempMax(value)
            maxValRef.current = value
          }}
          className="thumb thumb--right"
        />

        <div className="slider">
          <div className="slider__track" />
          <div ref={range} className="slider__range" />
          {/* <div className="slider__left-value">{minVal}</div>
        <div className="slider__right-value">{maxVal}</div> */}
        </div>

        <br />

        <Row>
          {/* <div className="cre-available-box"> */}
          <Col md="5">
            <div className="range-input-box">
              <label className="range-text">Min</label>
              {/* <h5 className="dropdown-range-input">{minVal}</h5> */}
              <input
                className="dropdown-range-input"
                type={isInteractingMin ? "number" : "text"}
                // value={minVal}
                value={isInteractingMin ? tempMin : formatCredits(minVal)}
                min={0}
                // onChange={event => {
                //   console.log(event.target.value)

                //   if (event.target.value > max) {
                //     const value = Math.min(Number(max), maxVal - 1)
                //     setMinVal(value)
                //     minValRef.current = value
                //   } else {
                //     const value = Math.min(
                //       Number(event.target.value),
                //       maxVal - 1
                //     )
                //     setMinVal(value)
                //     minValRef.current = value
                //   }
                // }}
                onChange={event => {
                  let value = event.target.value;
                  setTempMin(value);
                  debouncedSetMinVal(value);
                }}
                onFocus={() => setIsInteractingMin(true)}
                onBlur={() => setIsInteractingMin(false)}
              />
            </div>
          </Col>
          <Col md="1">
            <h4 className="dash">&nbsp;&nbsp;-&nbsp;&nbsp;</h4>
          </Col>
          <Col md="5">
            <div className="range-input-box">
              <label className="range-text">Max</label>
              {/* <h5 className="dropdown-range-input">{maxVal}</h5> */}
              <input
                className="dropdown-range-input"
                type={isInteractingMax ? "number" : "text"}
                // value={tempMax}
                value={isInteractingMax ? tempMax : formatCredits(tempMax)}
                max={max}
                onChange={event => {
                  let value = event.target.value
                  setTempMax(value)
                  debouncedSetMaxVal(value)
                }}
                onFocus={() => setIsInteractingMax(true)}
                onBlur={() => setIsInteractingMax(false)}
              />
            </div>
          </Col>
          {/* </div>  */}
        </Row>
      </div>
    </div>
  )
}

AvailableRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default AvailableRangeSlider
