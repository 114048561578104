import React from "react"
import ACR from "../../../src/assets/images/registry/ACR.png"
import CAR from "../../../src/assets/images/registry/CAR.jpg"
import CD from "../../../src/assets/images/registry/CD.png"
import GS from "../../../src/assets/images/registry/GS.jpg"
import VERA from "../../../src/assets/images/registry/VERA.png"
import { Col, Row } from "reactstrap"

export const RegistryIcon = ({ registry }) => {
  return (
    <span>
      {registry === "Clean Development Mechanism" ? (
        <img src={CD} width={16} height={16} alt="CD" />
      ) : registry === "Verra" ? (
        <img src={VERA} width={20} height={20} alt="VERA" />
      ) : registry === "Gold Standard" ? (
        <img src={GS} width={16} height={16} alt="GS" />
      ) : registry === "Climate Action Reserve" ? (
        <img src={CAR} width={17} height={17} alt="CAR" />
      ) : registry === "American Carbon Registry" ? (
        <img
          style={{ objectFit: "contain" }}
          src={ACR}
          width={21}
          height={18}
          alt="ACR"
        />
      ) : (
        <span style={{ height: "18px" }}></span>
      )}
    </span>
  )
}
export default function RegistryLabel({ registry, prjstatus }) {
  if (registry) {
    return (
      <Row className="d-flex align-items-center justify-content-between">
        <Col>
          {/* <label className="plain_leb">{developer}</label> */}
          <span
            className="ps-1 "
            style={{
              display: "block",
              fontSize: 11,
              color: "#959595",
              fontWeight: "bold",
              textAlign: "left",
            }}
          >
            {prjstatus}
          </span>
        </Col>

        <Col className="text-end">
          <div
            title={registry}
            className="d-flex align-items-center justify-content-end"
          >
            {registry === "Clean Development Mechanism" ? (
              <img src={CD} width={16} height={16} alt="CD" />
            ) : registry === "Verra" ? (
              <img src={VERA} width={16} height={16} alt="VERA" />
            ) : registry === "Gold Standard" ? (
              <img src={GS} width={16} height={16} alt="GS" />
            ) : registry === "Climate Action ..." ? (
              <img src={CAR} width={17} height={17} alt="CAR" />
            ) : registry === "American Carbon..." ? (
              <img src={ACR} width={21} height={18} alt="ACR" />
            ) : null}
            <span
              className="ps-1 "
              style={{
                display: "block",
                fontSize: 11,
                color: "#959595",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              {registry}
            </span>
          </div>
        </Col>
      </Row>
    )
  }
  return (
    <div style={{ fontSize: 16, fontWeight: "bold", color: "#959595" }}>
      No registry
    </div>
  )
}
