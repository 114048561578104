/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import _ from "lodash"
import * as urls from "../../../helpers/url_helper"


const token = localStorage.getItem("authToken")

export const projectsApi = createApi({
  reducerPath: "projectsApi",
  keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({ baseUrl: urls.REACT_APP_API_URL }),
  endpoints: builder => ({
    getProjectsByType: builder.query({
      query: ({ type, page = 1, pageSize = 6 }) => {
        return `/publish/getProjectDataGrid/${type}?page=${page}&pageSize=${pageSize}`
      },
      transformResponse: response => {
        return _.get(response, "payload.projectData", [])
      },
    }),
    getProjectByCSPId: builder.query({
      query: ({ id }) => {
        return {
          url: `/publish/detailViewPageByslug/${id}`,
          method: "GET", // Change to GET method
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      },
      transformResponse: response => {
        return _.get(response, "payload")
      },
    }),
    postProjectWithParamsAndPayload: builder.mutation({
      query: ({ t = 1, page = 1, pageSize = 12, payload }) => {
        let type = t
        const token = localStorage.getItem("authToken")
        if (payload && payload.sectoralScopes?.length>0) {
          type = payload.sectoralScopes
        }
        return {
          url: `/publish/advanceSearch/${type}?page=${page}&pageSize=${pageSize}`,
          method: "POST",
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      },
      invalidatesTags: ["Projects"],
    }),
    getProjectsByRegion: builder.mutation({
      query: ({ payload, type = 1 }) => {
        return {
          url: "/mapview/projectCountByRegions/" + type,
          method: "POST",
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      },
      invalidatesTags: ["Map"],
    }),
    getMapData: builder.mutation({
      query: ({ payload, type }) => {
        return {
          url: "mapview/getprojectsFoMapView/" + type,
          method: "POST",
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      },
      invalidatesTags: ["Map"],
    }),
    getProjectsByLocation: builder.mutation({
      query: ({
        type,
        payload,
        lat1 = -180,
        lng1 = -90,
        lat2 = 180,
        lng2 = 90,
      }) => {
        const url = `/mapview/projectsByLocation/${type}?pointOneLat=${lat1}&pointOneLong=${lng1}&pointTwoLat=${lat2}&pointTwoLong=${lng2}`
        return {
          url,
          method: "POST",
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      },
    }),
    getPopupDetails: builder.query({
      query: ({ id }) => {
        return {
          url: `/mapview/getPopupDeatils/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      },
    }),
    getLatestProjects: builder.query({
      query({ page = 1, pageSize = 25 }) {
        const tokenNew = localStorage.getItem("authToken")
        return {
          url: `/publish/getLatestProjects?page=${page}&pageSize=${pageSize}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${tokenNew}`,
          },
        }
      },
    }),
    getProjectKML: builder.query({
      query: ({ slug }) => {
        return {
          url: `/publish/getKMLFile/${slug}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      },
    }),
    getMaxCredits: builder.query({
      query: () => {
        return {
          url: "/crediting/getMaxCreditsAvailable",
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      },
    }),
    getFeaturedProjects: builder.query({
      query: () => {
        return {
          url: "/publish/getFeatureProjects?page=1&pageSize=20",
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      }
    }),
    getCreditingYears : builder.query({
      query: () => {
        return {
          url: "/crediting/getCreditingPeriods",
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      }
    })
  }),
})

export const {
  useGetProjectByCSPIdQuery,
  usePostProjectWithParamsAndPayloadMutation,
  useGetMapDataMutation,
  useGetPopupDetailsQuery,
  useGetLatestProjectsQuery,
  useLazyGetProjectKMLQuery,
  useGetMaxCreditsQuery,
  useGetFeaturedProjectsQuery,
  useGetCreditingYearsQuery
} = projectsApi
