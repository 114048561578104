import Filtering from "components/Home/FilterPopup"
import GridView from "components/Home/GridView"
import ProjectTypePicker from "components/Home/ProjectTypePicker"
import TableView from "components/Home/Table"
import ViewSelector from "components/Home/ViewSelector"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { Button, Container, Modal, Row, Spinner } from "reactstrap"
import { setCurrentTab, setProjectView } from "store/RTK-Store/projectsSlice"
import MapView from "../../components/Home/Map/MapView"

const Home = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const currentTab = useSelector(state => state.projects.currentTab)
  const dropdownLoading = useSelector(state => state.projects.loading)
  const filterCount = useSelector(state => state.projects.filterCount)
  const currentView = useSelector(state => state.projects.projectView)
  const visibleProjects = useSelector(state => state.projects.visibleProjects)
  const [modal_view_screen, setmodal_view_screen] = useState(false)
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)

  const token = searchParams.get("token")

  useEffect(() => {
    if (token) {
      localStorage.setItem("authToken", token)
      history.push("/carbon-projects")
    }
  }, [token])

  function tog_view_screen() {
    setmodal_view_screen(!modal_view_screen)
  }

  return (
    <React.Fragment>
      <div className="home-content">
        {/* Header */}
        {/* hero banner removed */}
        {/* <Hero /> */}
        {/* Project type picker */}
        <ProjectTypePicker
          onTabSelected={tab => {
            dispatch(setCurrentTab(tab))
          }}
          preSelectedTab={currentTab}
          defaultValue={1}
        >
          <Button
            outline
            className="filter-btn"
            disabled={dropdownLoading || visibleProjects === 0}
            onClick={e => {
              e.preventDefault()
              tog_view_screen()
            }}
            style={{
              minWidth: "100px",
              minHeight: "30px",
              position: "relative",
            }}
          >
            {dropdownLoading ? (
              <Spinner size="sm" />
            ) : (
              <>
                <i className="fas fa-filter"></i> <span>Filter</span>
              </>
            )}
            {/* Add a badge with the badgeNumber in the top right corner */}
            {filterCount !== 0 ? (
              <span
                className="badge"
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  fontSize: "10px",
                  backgroundColor: "#588BAA",
                  color: "white",
                  borderRadius: "50%",
                  padding: "4px 6px",
                  marginRight: "-5px",
                }}
              >
                {filterCount}
              </span>
            ) : null}
          </Button>
        </ProjectTypePicker>
        {/* Projects container */}
        <Container
          className="bg-white mobile_pad"
          style={{
            backgroundColor: "#F5F5F5",
            paddingBottom:
              currentView === "map"
                ? window.innerWidth < 950
                  ? "90px"
                  : "0px"
                : "140px",
          }}
          fluid
        >
          <Row style={{ margin: 0 }}>
            <>
              <>
                {currentView === "map" && (
                  <Container
                    fluid
                    style={{ minHeight: "515px", paddingTop: "60px" }}
                  >
                    <MapView />
                  </Container>
                )}
                {currentView === "table" && (
                  <Container
                    fluid
                    style={{ minHeight: "515px", paddingTop: "50px" }}
                  >
                    <TableView />
                  </Container>
                )}
                {currentView === "grid" && (
                  <Container
                    fluid
                    style={{ minHeight: "515px", paddingTop: "50px" }}
                  >
                    <GridView />
                  </Container>
                )}
              </>
            </>
          </Row>
          <ViewSelector
            view={currentView}
            onChange={view => {
              dispatch(setProjectView(view))
            }}
          />
        </Container>

        <Modal
          size="lg"
          isOpen={modal_view_screen}
          zIndex={1700}
          toggle={() => {
            tog_view_screen()
          }}
        >
          <div className="modal-header">
            <h4 className="modal-title mt-0" id="exampleModalFullscreenLabel">
              Project Filter
            </h4>
            <button
              onClick={() => {
                setmodal_view_screen(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Filtering
              onClose={() => {
                setmodal_view_screen(!modal_view_screen)
              }}
              onOpen={() => {
                setmodal_view_screen(modal_view_screen)
              }}
            />
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default Home
